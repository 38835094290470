import React, { useState, useEffect, useContext } from 'react';
import db from '@/utils/database';
import LoggedInAdminTemplate from '@/templates/loggedInAdmin';
import { Table, toaster } from 'evergreen-ui';
import { TableRow, TableCell } from '@/components/Table/style';
import { ViewLink } from '@/components/InvoiceTable/style';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import Button from '@/elements/Button';
import { DataStore } from '@aws-amplify/datastore';
import { Hub } from 'aws-amplify';
import { Role, ConsultingRequirement, Skill } from '@/models';
import Modal from '@/elements/Modal';
import { LinksContainer } from './style';
import { FinalForm } from '@/elements/Form';
import { TextInput } from '@/elements/Form';
import { ButtonGroupContainer } from '@/elements/Form/style';
import Type from '@/elements/Type';
import { Spacer } from '@/elements/Spacer/style';
import { orderBy } from 'lodash';
import { AuthContext } from '@/context/auth';
import { isUserAdmin } from '@/utils/isUserAdmin';

async function listRoles(setRoles) {
  const roles = await db.getAll('role');
  setRoles(orderBy(roles, [(role) => role.name.toLowerCase()], ['asc']));
}

async function listSkills(setSkills) {
  const skills = await db.getAll('skill');
  setSkills(skills.map((x) => x.name));
}

async function listRequirements(setRequirements) {
  const requirements = await db.getAll('consultingRequirement');
  setRequirements(requirements.map((x) => x.title));
}

export const RoleAdmin = () => {
  const [roles, setRoles] = useState([]);
  const [requirements, setRequirements] = useState([]);
  const [skills, setSkills] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showeditModal, setShowEditModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState({});

  const { user } = useContext(AuthContext);

  async function handleDelete() {
    if (skills.includes(selectedRole.name)) {
      toaster.danger(
        'Unable to delete. Role name is associated with a skill. Please delete all association of the role before deleting.'
      );
      return;
    }

    if (requirements.includes(selectedRole.name)) {
      toaster.danger(
        'Unable to delete. Role name is associated with a requirement. Please delete all association of the role before deleting.'
      );
      return;
    }

    const toDelete = await DataStore.query(Role, selectedRole.id);
    await DataStore.delete(toDelete);
    setShowDeleteModal(false);

    /**
     * TODO: remove association with a skill as well
     */
  }

  const checkIfRoleExists = (name) => {
    return roles.filter((x) => x.name === name).length > 0;
  };

  const handleAdd = async (values) => {
    try {
      const { name } = values;

      if (checkIfRoleExists(name)) {
        toaster.danger('Role name already exists');
        return;
      }

      await db.add('role', {
        name,
      });
      setShowAddModal(false);
    } catch (e) {
      console.warn('Something went wrong', e);
    }
  };

  const handleEdit = async (values) => {
    try {
      const { name } = values;

      if (checkIfRoleExists(name) && name !== selectedRole.name) {
        toaster.danger('Role name already exists');
        return;
      }

      await db.update(
        'role',
        {
          name,
        },
        selectedRole.id
      );
      setShowEditModal(false);
    } catch (e) {
      console.warn('Something went wrong', e);
    }
  };

  const handleArchive = async (role, archived) => {
    try {
      await db.update(
        'role',
        {
          archived,
        },
        role.id
      );
    } catch (e) {
      console.warn('Something went wrong', e);
    }
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    listRoles(setRoles);
    listRequirements(setRequirements);
    listSkills(setSkills);

    const listenerData = (data) => {
      if (data.payload.event === 'ready') {
        listRoles(setRoles);
        listRequirements(setRequirements);
        listSkills(setSkills);
      }
    };
    Hub.listen('datastore', listenerData);

    const subscription = DataStore.observe(Role).subscribe((msg) => {
      listRoles(setRoles);
    });

    const subscriptionRequirements = DataStore.observe(
      ConsultingRequirement
    ).subscribe((msg) => {
      listRequirements(setRequirements);
    });

    const subscriptionSkills = DataStore.observe(Skill).subscribe((msg) => {
      listSkills(setSkills);
    });

    const handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';
      if (condition === 'online') {
        listRoles(setRoles);
        listRequirements(setRequirements);
        listSkills(setSkills);
      }
    };

    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      subscription.unsubscribe();
      subscriptionRequirements.unsubscribe();
      subscriptionSkills.unsubscribe();
      Hub.remove('datastore', listenerData);

      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, [user]);

  const activeRoles = roles.filter((x) => !x.archived);
  const inactiveRoles = roles.filter((x) => x.archived);

  return (
    <LoggedInAdminTemplate hasTabs>
      <Grid>
        <Row>
          <Col xs={3}>
            <Button width={'100%'} border onClick={() => setShowAddModal(true)}>
              Add Role
            </Button>
          </Col>
          <Col xs={9}>
            <Row>
              {!!roles && (
                <>
                  <Col xs={12}>
                    <Type.H4>Roles</Type.H4>
                    <Spacer desktop={20} />
                  </Col>
                  <Col xs={12}>
                    <Table>
                      <Table.Body height={'auto'}>
                        {!!activeRoles &&
                          activeRoles.map((x, index) => (
                            <TableRow
                              key={index}
                              height={'auto'}
                              variation={'admin'}
                            >
                              <TableCell>{x.name}</TableCell>
                              <TableCell
                                flexBasis={200}
                                flexShrink={0}
                                flexGrow={0}
                                paddingX={0}
                              >
                                <LinksContainer>
                                  {isUserAdmin(user) && (
                                    <ViewLink
                                      onClick={() => {
                                        handleArchive(x, true);
                                      }}
                                    >
                                      Archive
                                    </ViewLink>
                                  )}
                                  
                                  <ViewLink
                                    onClick={() => {
                                      setShowEditModal(true);
                                      setSelectedRole(x);
                                    }}
                                  >
                                    Edit
                                  </ViewLink>
                                  {isUserAdmin(user) && (
                                    <ViewLink
                                      onClick={() => {
                                        setShowDeleteModal(true);
                                        setSelectedRole(x);
                                      }}
                                    >
                                      Delete
                                    </ViewLink>
                                  )}
                                </LinksContainer>
                              </TableCell>
                            </TableRow>
                          ))}
                      </Table.Body>
                    </Table>

                    {!!inactiveRoles.length && (
                      <>
                        <Spacer desktop={50} />
                        <Type.H7 style={{ margin: '10px 0' }}>Archived</Type.H7>
                        <Table>
                          <Table.Body height={'auto'}>
                            {inactiveRoles.map((x, index) => (
                              <TableRow
                                key={'u' + index}
                                height={'auto'}
                                variation={'admin'}
                              >
                                <TableCell>{x.name}</TableCell>
                                <TableCell
                                  flexBasis={200}
                                  flexShrink={0}
                                  flexGrow={0}
                                  paddingX={0}
                                >
                                  <LinksContainer>
                                    <ViewLink
                                      onClick={() => {
                                        handleArchive(x, false);
                                      }}
                                    >
                                      Unarchive
                                    </ViewLink>
                                    <ViewLink
                                      onClick={() => {
                                        setShowEditModal(true);
                                        setSelectedRole(x);
                                      }}
                                    >
                                      Edit
                                    </ViewLink>
                                    <ViewLink
                                      onClick={() => {
                                        setShowDeleteModal(true);
                                        setSelectedRole(x);
                                      }}
                                    >
                                      Delete
                                    </ViewLink>
                                  </LinksContainer>
                                </TableCell>
                              </TableRow>
                            ))}
                          </Table.Body>
                        </Table>
                      </>
                    )}

                    <Modal
                      title="Delete Role"
                      isShown={showDeleteModal}
                      handleModalState={setShowDeleteModal}
                      handleAction={() => handleDelete()}
                      type={'action'}
                      alignment={'center'}
                      buttonText="Yes"
                      shouldCloseOnEscapePress={false}
                      shouldCloseOnOverlayClick={false}
                    >
                      <p>
                        Are you sure you want to delete {selectedRole.name}?
                      </p>
                    </Modal>

                    <Modal
                      title="Edit Role"
                      isShown={showeditModal}
                      handleModalState={setShowEditModal}
                      type="plain"
                      alignment="center"
                      shouldCloseOnEscapePress={false}
                      shouldCloseOnOverlayClick={false}
                    >
                      <FinalForm
                        onSubmit={handleEdit}
                        initialValues={{
                          name: selectedRole.name,
                        }}
                        render={({ handleSubmit, values }) => {
                          return (
                            <form onSubmit={handleSubmit}>
                              <TextInput
                                name="name"
                                component="input"
                                value={values.name}
                                type="text"
                                validationType="required"
                                placeholder="What is the role?"
                              />
                              <ButtonGroupContainer justifyContent="flex-end">
                                <Button>Update</Button>
                                <Button
                                  simple
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowEditModal(false);
                                  }}
                                >
                                  Cancel
                                </Button>
                              </ButtonGroupContainer>
                            </form>
                          );
                        }}
                      ></FinalForm>
                    </Modal>

                    <Modal
                      title="Add New Role"
                      isShown={showAddModal}
                      handleModalState={setShowAddModal}
                      type="plain"
                      alignment="center"
                      shouldCloseOnEscapePress={false}
                      shouldCloseOnOverlayClick={false}
                    >
                      <FinalForm
                        onSubmit={handleAdd}
                        render={({ handleSubmit }) => {
                          return (
                            <form onSubmit={handleSubmit}>
                              <TextInput
                                name="name"
                                component="input"
                                type="text"
                                validationType="required"
                                placeholder="What is the role?"
                              />
                              <ButtonGroupContainer justifyContent="flex-end">
                                <Button>Add</Button>
                                <Button
                                  simple
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowAddModal(false);
                                  }}
                                >
                                  Cancel
                                </Button>
                              </ButtonGroupContainer>
                            </form>
                          );
                        }}
                      ></FinalForm>
                    </Modal>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
      </Grid>
    </LoggedInAdminTemplate>
  );
};
