import React from 'react';
import { Tab } from '@/elements/Tabs/style';
import { useRouteMatch, Link } from 'react-router-dom';

export const TabLink = ({ children, to, activeOnlyWhenExact }) => {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });

  return (
    <Tab active={match}>
      <Link to={to}>{children}</Link>
    </Tab>
  );
};
