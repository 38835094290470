import styled from 'styled-components';
import { colour, font } from '@/styles/variables';

export const RiskTableWrapper = styled.section``;

export const RiskModalContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  p {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const RiskModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const RiskModalRow = styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const OwnerLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const OwnerLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 19px;
  height: 19px;
  background-color: ${colour.lightGrey};
  border: 1px solid ${colour.white};
  margin-left: -5px;

  &:first-child {
    margin-left: 0;
  }

  img {
    width: 15px;
    height: 15px;
    object-fit: contain;
  }
`;

export const RiskItemDescContainer = styled.div`
  .title {
    color: ${colour.grey};
    margin-bottom: 5px;
    text-transform: uppercase;
  }
`;

export const StatusText = styled.div`
  text-transform: capitalize;
`;

export const ViewLink = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50px;
  color: ${colour.grey} !important;
  width: 100%;
  text-align: right;

  svg {
    margin-left: 5px;
  }

  * {
    color: ${colour.grey} !important;
  }
`;

export const TableTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const FilterButton = styled.div`
  display: flex;
  align-items: center;
  color: ${colour.charcoal};
  text-transform: capitalize;
  font-size: 14px;
  line-height: 16px;
  font-family: ${font.primary};

  svg {
    margin-left: 5px;
    width: 8px;
    height: 4px;
  }
`;
