import db from './database';

export const getOrganizationLogo = async (orgId) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!orgId) {
        resolve({ logo: '', name: '-' });
      }
      if (orgId.split(',').length > 1) {
        resolve({ logo: '', name: 'Multiple' });
      }

      const res = await db.search('organization', orgId, 'id');
      resolve({ logo: res[0].logoImage, name: res[0].name });
    } catch (error) {
      resolve({ logo: '', name: '-' });
    }
  });
};
