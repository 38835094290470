import React, { useState, useEffect } from 'react';
import db from '@/utils/database';
import { s3Upload } from '@/utils/awsLib';
import Button from '@/elements/Button';
import { CheckboxGroup } from '@/elements/Form';
import { TextInput, FinalForm } from '@/elements/Form';
import arrayMutators from 'final-form-arrays';
import { PhotoUploadField } from '@/elements/Form';
import ErrorBox from '@/elements/ErrorBox';
import Type from '@/elements/Type';
import { OrgAreasInput } from '@/elements/Form/Fields/OrgAreasInput';
import { Spacer } from '@/elements/Spacer/style';
import { uniq } from 'lodash';

export const EditOrganization = (props) => {
  const [logoImage, setLogoImage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  const { organization, setActiveView } = props;

  useEffect(() => {
    if (!logoImage) {
      setLogoImage(organization.logoImage);
    }
  }, [logoImage, organization]);

  const onSubmit = async (values) => {
    const { name, type, photoImage, areas } = values;

    if (submitting) {
      return;
    }

    setSubmitting(true);

    if (!photoImage && !logoImage) {
      setError('Please provide a client logo.');
      return;
    }

    const logoImageNew = photoImage
      ? await s3Upload('organizationLogos', photoImage)
      : logoImage;

    const orgData = {
      name,
      type,
      logoImage: logoImageNew,
      areas: [''],
    };

    if (areas && areas.length !== 0) {
      orgData.areas = areas;
    }

    try {
      // if update, get profile again
      const res = await db.search('organization', organization.id, 'id');
      let currentOrg = { ...res[0] };

      // overwrite the profile with the new one
      orgData._version = currentOrg._version;
      orgData._lastChangedAt = currentOrg._lastChangedAt;

      await db.update('organization', orgData, organization.id).then(() => {
        setSubmitting(false);
        setActiveView('table');
      });
    } catch (e) {
      setError(
        typeof error === 'string'
          ? error
          : error.message ||
              'An unexpected error occurred. Please contact the site administrator.'
      );
      setSubmitting(false);
    }
  };

  return (
    <>
      {error && <ErrorBox>{error}</ErrorBox>}
      <FinalForm
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={{
          name: organization.name,
          type: uniq(organization.type),
          areas: organization.areas,
        }}
        render={({ handleSubmit, values, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Type.H6>Overview</Type.H6>
              <PhotoUploadField
                name="photoImage"
                component="input"
                label="Client logo*"
                defaultImage={organization.logoImage}
              />
              <TextInput
                name="name"
                component="input"
                type="text"
                value={values.name}
                validationType="required"
                label="Client"
                showAppend
              />
              <CheckboxGroup
                name="type"
                label="Type of client"
                validationType="requiredArray"
                value={values.type}
                options={['Project', 'Consulting']}
              />

              <OrgAreasInput
                name="areas"
                value={values.areas}
                type="text"
                label="Departments/Areas"
              />

              <Spacer desktop={15} />

              <Button disabled={submitting}>Done</Button>
            </form>
          );
        }}
      ></FinalForm>
    </>
  );
};
