import styled from 'styled-components';

export const LinksContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  * {
    width: auto;
    display: inline-block;
    cursor: pointer;
    margin-left: 10px;
  }
`;

export const ProfilePhoto = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
`;

export const FormRowConsultant = styled.div`
  display: flex;
  justify-content: space-between;

  .toggle {
    flex: 0 0 70px;
    max-width: 70px;
  }

  .date {
    flex: 0 0 calc(100% - 90px);
    max-width: calc(100% - 90px);
    display: flex;
    justify-content: space-between;

    > div {
      flex: 0 0 calc(50% - 10px);
      max-width: calc(50% - 10px);
    }
  }
`;
