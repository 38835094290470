import styled from 'styled-components';
import { breakpoint, colour } from '@/styles/variables';

export const CardWrapper = styled.div`
  padding: 40px;
  border: 1px solid ${colour.lightGreyOpacity};
  border-radius: 10px;

  @media (max-width: ${breakpoint.max.medium}) {
    box-shadow: none;
    padding: 0 0px 40px;
  }
`;
