// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ConsultantStatus = {
  "DRAFT": "draft",
  "NEW": "new",
  "SHORTLIST": "shortlist",
  "ACCEPTED": "accepted",
  "REJECTED": "rejected"
};

const { Note, Skill, Role, Organization, ConsultingRequirement, Profile, Project, Consultant, Notification, File, ConsultantEducation, ConsultantExperience, ClientTask, Document, Risk, Invoice, ContactDetail, ConsultantRequirementSkill } = initSchema(schema);

export {
  Note,
  Skill,
  Role,
  Organization,
  ConsultingRequirement,
  Profile,
  Project,
  Consultant,
  Notification,
  ConsultantStatus,
  File,
  ConsultantEducation,
  ConsultantExperience,
  ClientTask,
  Document,
  Risk,
  Invoice,
  ContactDetail,
  ConsultantRequirementSkill
};