import React, { useState, useEffect } from "react";
import db from '@/utils/database';
import { ConsultantStatus } from "@/models";
import styled from "styled-components";
import { TextInput, Select, TagInput, Popover, Button, Pane, Menu, Textarea, Tablist, SidebarTab, Paragraph } from "evergreen-ui";
import { AddOrganization } from "./Organization/AddOrganization"

const AdminMain = () => {
    const tabs = [
        { name: 'Skills', render: <SkillAdmin /> },
        { name: 'Organizations', render: <AddOrganization /> },
        { name: 'ConsultingRequirements', render: <ConsultingRequirementAdmin /> },
        { name: 'Consultants', render: <ConsultantAdmin /> },
    ]
    const [state, setState] = useState({
        selectedIndex: 0,
    });
    return <Pane display="flex" >
        <Tablist marginBottom={16} flexBasis={240} marginRight={24}>
            {tabs.map((tab, index) => (
                <SidebarTab
                    key={tab.name}
                    id={tab.name}
                    onSelect={() => setState({ ...state, selectedIndex: index })}
                    isSelected={index === state.selectedIndex}
                    aria-controls={`panel-${tab.name}`}
                >
                    {tab.name}
                </SidebarTab>
            ))}
        </Tablist>
        <Pane padding={16} background="tint1" flex="1">
            {tabs.map((tab, index) => (
                <Pane
                    key={tab.name}
                    id={`panel-${tab.name}`}
                    role="tabpanel"
                    aria-labelledby={tab.name}
                    aria-hidden={index !== state.selectedIndex}
                    display={index === state.selectedIndex ? 'block' : 'none'}
                >
                    <Paragraph>Panel {tab.name}</Paragraph>
                    {tab.render}
                </Pane>
            ))}
        </Pane>
    </Pane>
}


const SkillAdmin = () => {
    const initSkill = {
        name: ""
    }
    const [skill, setSkill] = useState(initSkill)
    const [skills, setSkills] = useState([])

    useEffect(() => {
        const fetchData = async () => setSkills(await db.getAll('skill'))
        fetchData()
    }, [])

    const editskill = (key, value) => {
        let obj = {}
        obj[key] = value
        setSkill({ ...skill, ...obj })
    }

    return (
        <Wrap>
            <h2>Skills</h2>
            <fieldset>
                <TextInput type="text" placeholder="Search" onChange={async (e) => { setSkills(await db.search('skill', e.target.value)) }} />
                <Menu>
                    <Menu.Group>
                        {skills.length ? skills.map(x => <div key={x.id}><Menu.Item onSelect={e => setSkill(x)}>{x.name}</Menu.Item><Menu.Divider /></div>) : <Menu.Item>No items</Menu.Item>}
                    </Menu.Group>
                </Menu>
            </fieldset>
            <fieldset>
                <code>{JSON.stringify(skill)}</code>
                <TextInput type="text" placeholder="name" value={skill.name} onChange={e => editskill('name', e.target.value)} />
                <Pane>
                    <Button onClick={async (e) => { await db.add('skill', skill); setSkills(await db.getAll('skill')) }}>Add skill</Button>
                    <Button onClick={async (e) => { await db.update('skill', skill, skill.id); setSkills(await db.getAll('skill')) }}>Update skill</Button>
                    <Button onClick={async (e) => { await db.delete('skill', skill.id); setSkills(await db.getAll('skill')) }}>Delete skill</Button>
                </Pane>
            </fieldset>
        </Wrap>
    )
}



const ConsultingRequirementAdmin = () => {
    const initConsultingRequirement = {
        title: "Front end",
        projectDetails: "A lot of details",
        areas: ['Web', 'Mobile'],
        consultants: [],
        numberOfPositions: 1,
        idealStartDate: '2020-1-2',
        duration: '2days',
        skills:['HTML', 'CSS'],
        organization: {id: "ec960d8e-5750-4053-9e4a-6d4d6cc83eca"}
    }
    const [consultingRequirement, setConsultingRequirement] = useState(initConsultingRequirement)
    const [consultingRequirements, setConsultingRequirements] = useState([])
    const [organizations, setOrganizations] = useState([])
    const [consultants, setConsultants] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            setConsultingRequirements(await db.getAll('consultingRequirement'))
            setConsultants(await db.getAll('consultant'))
            setOrganizations(await db.getAll('organization'))
            console.log(await db.getAll('organization'))
        }
        fetchData()
    }, [])

    const editConsultingRequirement = (key, value) => {
        let obj = {}
        obj[key] = value
        setConsultingRequirement({ ...consultingRequirement, ...obj })
    }

    return (
        <Wrap>
            <h2>ConsultingRequirements</h2>
            <fieldset>
                <TextInput type="text" placeholder="Search" onChange={async (e) => { setConsultingRequirements(await db.search('consultingRequirement', e.target.value)) }} />
                <Menu>
                    <Menu.Group>
                        {consultingRequirements.length ? consultingRequirements.map(x => <div key={x.id}><Menu.Item onSelect={e => setConsultingRequirement(x)}>{x.title}</Menu.Item><Menu.Divider /></div>) : <Menu.Item>No items</Menu.Item>}
                    </Menu.Group>
                </Menu>
            </fieldset>
            <fieldset>
                <code>{JSON.stringify(consultingRequirement)}</code>
                <TextInput type="text" placeholder="title" value={consultingRequirement.title} onChange={e => editConsultingRequirement('title', e.target.value)} />
                <TextInput type="text" placeholder="projectDetails" value={consultingRequirement.projectDetails} onChange={e => editConsultingRequirement('projectDetails', e.target.value)} />
                <Select type="text" defaultValue='' placeholder="organization" onChange={e => editConsultingRequirement( 'organization', organizations.find(x => x.id === e.target.value))}>
                    <option disabled value=''>Organization</option>
                    {organizations.length && organizations.map(x => <option value={x.id}>{x.name}</option>)}
                </Select>
                <TagInput
                    inputProps={{ placeholder: 'Department(s)/Area(s) (press enter after each)' }}
                    values={consultingRequirement.areas}
                    onChange={values => {
                        setConsultingRequirements({ ...consultingRequirement, areas: values })
                    }}
                />
                <Select type="text" defaultValue='' placeholder="consultants" onChange={e => setConsultingRequirements({ ...consultingRequirement, consultants: consultants.find(x => x.id === e.target.value) })}>
                    <option disabled value=''>Consultants</option>
                    {consultants.length && consultants.map(x => <option value={x.id}>{x.name}</option>)}
                </Select>
                <TextInput type="number" placeholder="numberOfPositions" value={consultingRequirement.numberOfPositions} onChange={e => editConsultingRequirement('numberOfPositions', e.target.value)} />
                <TextInput type="date" placeholder="idealStartDate" value={consultingRequirement.idealStartDate} onChange={e => editConsultingRequirement('idealStartDate', e.target.value)} />
                <TextInput type="text" placeholder="duration" value={consultingRequirement.duration} onChange={e => editConsultingRequirement('duration', e.target.value)} />
                <TagInput
                    inputProps={{ placeholder: 'skills (press enter after each)' }}
                    values={consultingRequirement.skills}
                    onChange={values => {
                        setConsultingRequirements({ ...consultingRequirement, skills: values })
                    }}
                />
                <Pane>
                    <Button onClick={async (e) => { console.log(consultingRequirement); await db.add('consultingRequirement', consultingRequirement); setConsultingRequirements(await db.getAll('consultingRequirement')) }}>Add consultingRequirement</Button>
                    <Button onClick={async (e) => { await db.update('consultingRequirement', consultingRequirement, consultingRequirement.id); setConsultingRequirements(await db.getAll('consultingRequirement')) }}>Update consultingRequirement</Button>
                    <Button onClick={async (e) => { await db.delete('consultingRequirement', consultingRequirement.id); setConsultingRequirements(await db.getAll('consultingRequirement')) }}>Delete consultingRequirement</Button>
                </Pane>
            </fieldset>
        </Wrap>
    )
}

const ConsultantAdmin = () => {
    const initConsultant = {
        name: "Sample Cook",
        organization: "Apple",
        areas: ["Mobile Department"],
        yearsOfExperience: 3,
        status: ConsultantStatus.ACCEPTED,
        summary: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        educations: [{ institute: "RMIT University", accolade: 'Master', course: "IT", completion: 2014 }, { institute: "Melbourne University", accolade: 'Master', course: "IT", completion: 2012 }],
        experiences: [{ role: "Manager", tenure: "", company: 'NAB', completion: "2015", responsibilities: "Managing people" }],
        skills: ["HTML", "CSS"],
        interviewDate: "2020-1-1",
        photo: "",
        idealStartDate: '2020-1-2',
        startDate: '2020-1-4',
    }
    const [consultant, setConsultant] = useState(initConsultant)
    const [consultants, setConsultants] = useState([])
    const [organizations, setOrganizations] = useState([])
    const [consultingRequirements, setConsultingRequirements] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            setConsultingRequirements(await db.getAll('consultingRequirement'))
            setConsultants(await db.getAll('consultant'))
            setOrganizations(await db.getAll('organization'))
        }
        fetchData()
    }, [])

    const editConsultant = (key, value) => {
        let obj = {}
        obj[key] = value
        setConsultant({ ...consultant, ...obj })
    }

    const editConsultantEdu = (index, key, value) => {
        let educations = consultant.educations
        educations[index][key] = value
        setConsultant({ ...consultant, educations })
    }

    return (
        <Wrap>
            <h2>Consultants</h2>
            <fieldset>
                <TextInput type="text" placeholder="Search" onChange={async (e) => { setConsultants(await db.search('consultant', e.target.value)) }} />
                <Menu>
                    <Menu.Group>
                        {consultants.length ? consultants.map(x => <div key={x.id}><Menu.Item onSelect={e => setConsultant(x)}>{x.name}</Menu.Item><Menu.Divider /></div>) : <Menu.Item>No items</Menu.Item>}
                    </Menu.Group>
                </Menu>
            </fieldset>
            <fieldset>
                {/* <legend>addConsultant</legend> */}
                <code>{JSON.stringify(consultant)}</code>
                <TextInput type="text" placeholder="name" value={consultant.name} onChange={e => editConsultant('name', e.target.value)} />
                <Select type="text" defaultValue='' placeholder="organization" onChange={e => setConsultant({ ...consultant, organization: organizations.find(x => x.id === e.target.value) })}>
                    <option disabled value=''>Organization</option>
                    {organizations.length && organizations.map(x => <option value={x.id}>{x.name}</option>)}
                </Select>
                <TagInput
                    inputProps={{ placeholder: 'Department(s)/Area(s) (press enter after each)' }}
                    values={consultant.areas}
                    onChange={values => {
                        setConsultant({ ...consultant, areas: values })
                    }}
                />
                <Select type="text" defaultValue='' placeholder="organization" onChange={e => setConsultant({ ...consultant, consultingRequirement: consultingRequirements.find(x => x.id === e.target.value) })}>
                    <option disabled value=''>consultingRequirement</option>
                    {consultingRequirements.length && consultingRequirements.map(x => <option value={x.id}>{x.name}</option>)}
                </Select>
                <TextInput type="number" placeholder="yearsOfExperience" value={consultant.yearsOfExperience} onChange={e => editConsultant('yearsOfExperience', e.target.value)} />
                <Select type="text" defaultValue='' placeholder="status" onChange={e => setConsultant({ ...consultant, status: e.target.value })}>
                    <option disabled value=''>Status</option>
                    {Object.keys(ConsultantStatus).map(key => <option key={key} value={ConsultantStatus[key]}>{ConsultantStatus[key]}</option>)}
                </Select>
                <Textarea type="text" placeholder="summary" value={consultant.summary} onChange={e => editConsultant('summary', e.target.value)} />
                <Pane>

                    <Popover
                        bringFocusInside
                        content={<>
                            <h4>Education</h4>
                            {consultant.educations.map((x, i) =>
                                <Pane key={'edu-' + i}>
                                    <h6>{x.institute}</h6>
                                    <TextInput placeholder="institute" value={x.institute} width="100%" onChange={e => editConsultantEdu(i, 'institute', e.target.value)} />
                                    <TextInput placeholder="accolade" value={x.accolade} width="100%" onChange={e => editConsultantEdu(i, 'accolade', e.target.value)} />
                                    <TextInput placeholder="course" value={x.course} width="100%" onChange={e => editConsultantEdu(i, 'course', e.target.value)} />
                                    <TextInput placeholder="completion" value={x.completion} width="100%" onChange={e => editConsultantEdu(i, 'completion', e.target.value)} />
                                </Pane>
                            )}
                        </>}
                    >
                        <Button>Edit Education</Button>
                    </Popover>
                    <Popover
                        bringFocusInside
                        content={<>
                            <h4>Experience</h4>
                            {consultant.experiences.map((x, i) =>
                                <Pane key={'exp-' + i}>
                                    <h6>{x.institute}</h6>
                                    <TextInput placeholder="role" value={x.role} width="100%" onChange={e => editConsultantEdu(i, 'role', e.target.value)} />
                                    <TextInput placeholder="tenure" value={x.tenure} width="100%" onChange={e => editConsultantEdu(i, 'tenure', e.target.value)} />
                                    <TextInput placeholder="company" value={x.company} width="100%" onChange={e => editConsultantEdu(i, 'company', e.target.value)} />
                                    <TextInput placeholder="completion" value={x.completion} width="100%" onChange={e => editConsultantEdu(i, 'completion', e.target.value)} />
                                    <TextInput placeholder="responsibilities" value={x.responsibilities} width="100%" onChange={e => editConsultantEdu(i, 'responsibilities', e.target.value)} />
                                </Pane>
                            )}
                        </>}
                    >
                        <Button>Edit Experience</Button>
                    </Popover>
                </Pane>
                <TagInput
                    inputProps={{ placeholder: 'skills (press enter after each)' }}
                    values={consultant.skills}
                    onChange={values => {
                        setConsultant({ ...consultant, skills: values })
                    }}
                />
                <TextInput type="text" placeholder="interviewDate" value={consultant.interviewDate} onChange={e => editConsultant('interviewDate', e.target.value)} />
                <TextInput type="text" placeholder="idealStartDate" value={consultant.idealStartDate} onChange={e => editConsultant('idealStartDate', e.target.value)} />
                <TextInput type="text" placeholder="startDate" value={consultant.startDate} onChange={e => editConsultant('startDate', e.target.value)} />
                <Pane>
                    <Button onClick={async (e) => { await db.add('consultant', consultant); setConsultants(await db.getAll('consultant')) }}>Add consultant</Button>
                    <Button onClick={async (e) => { await db.update('consultant', consultant, consultant.id); setConsultants(await db.getAll('consultant')) }}>Update consultant</Button>
                    <Button onClick={async (e) => { await db.delete('consultant', consultant.id); setConsultants(await db.getAll('consultant')) }}>Delete consultant</Button>
                </Pane>

            </fieldset>
        </Wrap>
    )
}



export default AdminMain;

export const Wrap = styled.section`
    legend {
        display: inline-block;
        background: #eee;
        font-size: 16px;
        font-weight: bold;
        padding: 2px 10px;
    }
    fieldset{
        border: 1px solid #333;
        padding: 20px;
        margin: 20px;
        display: flex;
    }
`