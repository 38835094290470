import styled from 'styled-components';
import { colour, breakpoint } from '@/styles/variables';

export const DocumentListingWrapper = styled.section``;

export const DocumentItemsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: -32px;
  margin-left: ${({ count }) => (!count || count === 0 ? '0' : '-16px')};
  margin-right: ${({ count }) => (!count || count === 0 ? '0' : '-16px')};

  @media (max-width: ${breakpoint.max.larger}) {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
`;

export const DocumentItemWrapper = styled.a`
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${colour.lightGreyOpacity};
  flex: 0 0 calc((100% / 3) - 32px);
  max-width: calc((100% / 3) - 32px);
  background-color: #fff;
  margin-bottom: 32px;
  text-decoration: none;
  margin-left: 16px;
  margin-right: 16px;

  @media (max-width: ${breakpoint.max.larger}) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 15px;
    margin-left: 0;
    margin-right: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .inner-container {
    padding: 25px 20px;
    display: flex;
    align-items: center;
    color: ${colour.charcoal};

    svg {
      min-width: 30px;
      object-fit: cover;
    }
  }
`;
export const DocumentItemInfo = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;

  h6 {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 5px;
  }

  p {
    font-size: 14px;
    line-height: 16px;
  }
`;
