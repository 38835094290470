import styled from 'styled-components';

export const LinksContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  * {
    width: auto;
    display: inline-block;
    cursor: pointer;
    margin-left: 10px;
  }
`;

export const MoreTextContainer = styled.span`
  color: ${({ theme }) => theme.colour.primary};
  font-weight: 600;
`;
