import React, { useState, useEffect, useContext } from 'react';
import db from '@/utils/database';
import LoggedInAdminTemplate from '@/templates/loggedInAdmin';
import { Table, toaster } from 'evergreen-ui';
import {
  TableRow,
  TableCell,
  TableHead,
  HeaderCell,
} from '@/components/Table/style';
import { ViewLink } from '@/components/InvoiceTable/style';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import Button from '@/elements/Button';
import { DataStore } from '@aws-amplify/datastore';
import { Hub } from 'aws-amplify';
import { Skill, Role } from '@/models';
import Modal from '@/elements/Modal';
import { LinksContainer, MoreTextContainer } from './style';
import { FinalForm } from '@/elements/Form';
import { TextInput } from '@/elements/Form';
import { CheckboxGroup } from '@/elements/Form';
import arrayMutators from 'final-form-arrays';
import { ButtonGroupContainer } from '@/elements/Form/style';
import Type from '@/elements/Type';
import { Spacer } from '@/elements/Spacer/style';
import { orderBy } from 'lodash';
import { AuthContext } from '@/context/auth';

async function listSkills(setSkills) {
  const skills = await db.getAll('skill');
  setSkills(orderBy(skills, [(skill) => skill.name.toLowerCase()], ['asc']));
}

async function listRoles(setRoles) {
  const roles = await db.getAll('role');
  const rolesNames = roles.map((x) => x.name);
  setRoles(rolesNames);
}

export const SkillAdmin = () => {
  const [skills, setSkills] = useState([]);
  const [roles, setRoles] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showeditModal, setShowEditModal] = useState(false);

  const [selectedSkill, setSelectedSkill] = useState({});

  const { user } = useContext(AuthContext);

  async function handleDelete(id) {
    const toDelete = await DataStore.query(Skill, id);
    await DataStore.delete(toDelete);
    setShowDeleteModal(false);
  }

  const checkIfSkillExists = (name) => {
    return skills.filter((x) => x.name === name).length > 0;
  };

  const handleAdd = async (values) => {
    try {
      const { name, rolesAdd } = values;

      if (checkIfSkillExists(name)) {
        toaster.danger('Skill already exists');
        return;
      }

      await db.add('skill', {
        name,
        roles: rolesAdd,
      });
      setShowAddModal(false);
    } catch (e) {
      console.warn('Something went wrong', e);
    }
  };

  const handleEdit = async (values) => {
    try {
      const { name, rolesEdit } = values;

      if (checkIfSkillExists(name) && name !== selectedSkill.name) {
        toaster.danger('Skill already exists');
        return;
      }

      await db.update(
        'skill',
        {
          name,
          roles: rolesEdit,
        },
        selectedSkill.id
      );
      setShowEditModal(false);
    } catch (e) {
      console.warn('Something went wrong', e);
    }
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    listSkills(setSkills);
    listRoles(setRoles);

    const listenerData = (data) => {
      if (data.payload.event === 'ready') {
        listSkills(setSkills);
        listRoles(setRoles);
      }
    };
    Hub.listen('datastore', listenerData);

    const subscription = DataStore.observe(Skill).subscribe((msg) => {
      listSkills(setSkills);
    });

    const subscriptionRoles = DataStore.observe(Role).subscribe((msg) => {
      listRoles(setRoles);
    });

    const handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';
      if (condition === 'online') {
        listSkills(setSkills);
      }
    };

    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      subscription.unsubscribe();
      subscriptionRoles.unsubscribe();
      Hub.remove('datastore', listenerData);;

      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, [user]);

  return (
    <LoggedInAdminTemplate hasTabs>
      <Grid>
        <Row>
          <Col xs={3}>
            <Button width={'100%'} border onClick={() => setShowAddModal(true)}>
              Add Skill
            </Button>
          </Col>
          <Col xs={9}>
            <Row>
              {!!skills && (
                <>
                  <Col xs={12}>
                    <Type.H4>Skills</Type.H4>
                    <Spacer desktop={20} />
                  </Col>
                  <Col xs={12}>
                    <Table>
                      <TableHead paddingX={0}>
                        <HeaderCell></HeaderCell>
                        <HeaderCell>Associated Role</HeaderCell>
                        <HeaderCell
                          flexBasis={200}
                          flexShrink={0}
                          flexGrow={0}
                          paddingX={0}
                        ></HeaderCell>
                      </TableHead>
                      <Table.Body height={'auto'}>
                        {!!skills &&
                          skills.map((x, index) => (
                            <TableRow
                              key={index}
                              height={'auto'}
                              variation={'admin'}
                            >
                              <TableCell>{x.name}</TableCell>
                              <TableCell>
                                {x.roles && (
                                  <>
                                    {x.roles[0]}
                                    {x.roles.length > 1 && (
                                      <MoreTextContainer
                                        title={x.roles.slice(1, x.roles.length)}
                                      >
                                        &nbsp;+{x.roles.length - 1} Other
                                        {x.roles.length - 1 > 1 ? 's' : ''}
                                      </MoreTextContainer>
                                    )}
                                  </>
                                )}
                              </TableCell>
                              <TableCell
                                flexBasis={200}
                                flexShrink={0}
                                flexGrow={0}
                                paddingX={0}
                              >
                                <LinksContainer>
                                  <ViewLink
                                    onClick={() => {
                                      setShowEditModal(true);
                                      setSelectedSkill(x);
                                    }}
                                  >
                                    Edit
                                  </ViewLink>
                                  <ViewLink
                                    onClick={() => {
                                      setShowDeleteModal(true);
                                      setSelectedSkill(x);
                                    }}
                                  >
                                    Delete
                                  </ViewLink>
                                </LinksContainer>
                              </TableCell>
                            </TableRow>
                          ))}
                      </Table.Body>
                    </Table>

                    <Modal
                      title="Delete Skill"
                      isShown={showDeleteModal}
                      handleModalState={setShowDeleteModal}
                      handleAction={() => handleDelete(selectedSkill.id)}
                      type={'action'}
                      alignment={'center'}
                      buttonText="Yes"
                      shouldCloseOnEscapePress={false}
                      shouldCloseOnOverlayClick={false}
                    >
                      <p>
                        Are you sure you want to delete {selectedSkill.name}?
                      </p>
                    </Modal>

                    <Modal
                      title="Edit Skill"
                      isShown={showeditModal}
                      handleModalState={setShowEditModal}
                      type="plain"
                      alignment="center"
                      shouldCloseOnEscapePress={false}
                      shouldCloseOnOverlayClick={false}
                    >
                      <FinalForm
                        onSubmit={handleEdit}
                        initialValues={{
                          name: selectedSkill.name,
                          rolesEdit: selectedSkill.roles,
                        }}
                        mutators={{
                          ...arrayMutators,
                        }}
                        render={({ handleSubmit, values }) => {
                          return (
                            <form onSubmit={handleSubmit}>
                              <TextInput
                                name="name"
                                component="input"
                                type="text"
                                value={values.name}
                                validationType="required"
                                placeholder="What is the skill?"
                              />
                              {!!roles && (
                                <CheckboxGroup
                                  name="rolesEdit"
                                  label="Associated Roles"
                                  validationType="requiredArray"
                                  options={roles}
                                  value={values.rolesEdit}
                                />
                              )}
                              <ButtonGroupContainer justifyContent="flex-end">
                                <Button>Update</Button>
                                <Button
                                  simple
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowEditModal(false);
                                  }}
                                >
                                  Cancel
                                </Button>
                              </ButtonGroupContainer>
                            </form>
                          );
                        }}
                      ></FinalForm>
                    </Modal>

                    <Modal
                      title="Add New Skill"
                      isShown={showAddModal}
                      handleModalState={setShowAddModal}
                      type="plain"
                      alignment="center"
                      shouldCloseOnEscapePress={false}
                      shouldCloseOnOverlayClick={false}
                    >
                      <FinalForm
                        onSubmit={handleAdd}
                        mutators={{
                          ...arrayMutators,
                        }}
                        render={({ handleSubmit, values }) => {
                          return (
                            <form onSubmit={handleSubmit}>
                              <TextInput
                                name="name"
                                component="input"
                                type="text"
                                validationType="required"
                                placeholder="What is the skill?"
                              />
                              {!!roles && (
                                <CheckboxGroup
                                  name="rolesAdd"
                                  label="Associated Roles"
                                  validationType="requiredArray"
                                  options={roles}
                                  value={values.rolesAdd}
                                />
                              )}
                              <ButtonGroupContainer justifyContent="flex-end">
                                <Button type="submit">Add</Button>
                                <Button
                                  simple
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowAddModal(false);
                                  }}
                                >
                                  Cancel
                                </Button>
                              </ButtonGroupContainer>
                            </form>
                          );
                        }}
                      ></FinalForm>
                    </Modal>
                  </Col>
                </>
              )}
            </Row>
          </Col>
          {/* <button onClick={handleSubmit}>add</button> */}
        </Row>
      </Grid>
    </LoggedInAdminTemplate>
  );
};
