import React from 'react';
import { SideSheet, Avatar } from 'evergreen-ui';
import Icons from '@/elements/Icons';
import Type from '@/elements/Type';
import Button from '@/elements/Button';
import {
    ModalHeader,
    ModalButtonContainer,
    ModalBody,
    HeaderWrap,
    RoleTitle
} from './style';
import { colour } from '@/styles/variables';

const SideModal = (props) => {
  const {
    isShown,
    handleModalState,
    handleAction,
    type,
    buttonText,
    alignment,
    intent,
    shouldCloseOnOverlayClick,
    shouldCloseOnEscapePress,
    isTall,
    noCancel,
    closeFromExternal,
    headerFunctions,
    profile
  } = props;

  return (
      <SideSheet
        position='bottom'
        isShown={isShown}
        onCloseComplete={() => handleModalState(false)}
        containerProps={{ paddingTop: '30px', paddingLeft: '80px', paddingRight: '80px', paddingBottom: '30px', height: '100vh', position: 'relative' }}
        shouldCloseOnEscapePress={shouldCloseOnEscapePress}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      >
        {({ close }) => (
          <>
           {closeFromExternal && closeFromExternal(close)}
            <ModalHeader alignment={alignment} isTall={isTall} style={{zIndex: 1, height: '108px', backgroundColor: '#fff', top: 0, left: 0, alignItems: 'center', position: 'fixed', width: 'calc(100% - 160px', marginLeft: '80px', marginRight: '80px'}}>
              {type !== 'action' && type !== 'plain' && (
                <Icons.BackArrow onClick={close} />
              )}
              <div>
                  {headerFunctions}
              </div>
            </ModalHeader>
            <div style={{maxWidth: '1024px', margin: '80px auto 0 auto'}}>
            <ModalHeader alignment={alignment} isTall={isTall}>
              <HeaderWrap>
                <Avatar src={profile.photo} name={profile.name} size={90} />
                <Type.H3 style={{margin: '0 auto'}} color={colour.blue}>{props.title}</Type.H3>
                <RoleTitle>
                  <Type.P6>{profile.consultingRequirement.title}</Type.P6>
                </RoleTitle>
              </HeaderWrap>
            </ModalHeader>
            <ModalBody alignment={alignment} isTall={isTall}>
              {props.children}
            </ModalBody>
            {type === 'action' && (
              <ModalButtonContainer isTall={isTall}>
                {!noCancel && (
                  <Button
                    simple
                    onClick={close}
                    style={{
                      color: intent === 'danger' ? colour.charcoal : ''
                    }}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  className={intent}
                  onClick={() => {
                    handleAction && handleAction();
                    close();
                  }}
                >
                  {buttonText || 'Done'}
                </Button>
              </ModalButtonContainer>
            )}
            </div>
          </>
        )}
      </SideSheet>
  );
};

export default SideModal;
