import React, { useState, useEffect, useContext } from 'react';
import LoggedInAdminTemplate from '@/templates/loggedInAdmin';
import { Table } from 'evergreen-ui';
import {
  TableHead,
  HeaderCell,
  TableRow,
  TableCell,
} from '@/components/Table/style';
import { ViewLink } from '@/components/InvoiceTable/style';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import Button from '@/elements/Button';
import { API, Auth } from 'aws-amplify';
import Modal from '@/elements/Modal';
import { AddUser } from './AddUser';
import { EditUser } from './EditUser';
import { LinksContainer } from './style';
import Type from '@/elements/Type';
import { Spacer } from '@/elements/Spacer/style';
import { colour } from '@/styles/variables';
import { getOrganizationLogo } from '@/utils/getOrganizationLogo';
import { getCognitoFieldValue } from '@/utils/getCognitoFieldValue';
import { capitalize } from '@/utils/capitalize';
import { AuthContext } from '@/context/auth';
import { Divider } from '@/elements/Divider/style';
import db from '@/utils/database';
import { orderBy } from 'lodash';
import { isUserEditProfileNotAdmin } from '@/utils/isUserAdmin';

async function grabClientLogos(users, setLogos) {
  if (!users || users.length < 1) {
    setLogos(undefined);
  }

  const logos = await Promise.all(
    users.map(async (x) => {
      const logo = await getOrganizationLogo(
        getCognitoFieldValue('custom:organization', x.Attributes)
      );

      return logo;
    })
  );

  setLogos(logos);
}

async function listUsers(setUsers, setLogos) {
  let apiName = 'AdminQueries';
  let path = '/listUsers';
  let myInit = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };

  const { ...rest } = await API.get(apiName, path, myInit);

  const users = rest.Users.filter((x) => x.Enabled);

  setUsers(users);
  grabClientLogos(users, setLogos);
}

async function listOrganizations(setOrganizations) {
  const organizations = await db.getAll('organization');
  setOrganizations(
    orderBy(organizations, [(org) => org.name.toLowerCase()], ['asc'])
  );
}

const Users = () => {
  const [users, setUsers] = useState([]);
  const [activeView, setActiveView] = useState('table');

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedUser, setSelectedUser] = useState({});

  const [updated, setUpdated] = useState(false);

  const [logos, setLogos] = useState(undefined);

  const [organizations, setOrganizations] = useState([]);

  const { user } = useContext(AuthContext);

  const userTableRow = (x, index) => {
    return (
      <TableRow
        key={index}
        height={'auto'}
        variation={'admin'}
      >
        <TableCell>
          {getCognitoFieldValue(
            'custom:name',
            x.Attributes
          )}
        </TableCell>
        <TableCell>
          {capitalize(
            getCognitoFieldValue(
              'custom:userGroup',
              x.Attributes
            ) === 'p-c'
              ? 'P&C'
              : getCognitoFieldValue(
                  'custom:userGroup',
                  x.Attributes
                )
          )}
        </TableCell>
        <TableCell>
          {(logos && logos[index]?.name) || ''}
        </TableCell>
        <TableCell>
          {getCognitoFieldValue(
            'email',
            x.Attributes
          )}
        </TableCell>
        <TableCell
          flexBasis={200}
          flexShrink={0}
          flexGrow={0}
          paddingX={0}
        >
          <LinksContainer>
            <ViewLink
              onClick={() => {
                setActiveView('edit');
                setSelectedUser(x);
              }}
            >
              Edit
            </ViewLink>
            <ViewLink
              onClick={() => {
                setShowDeleteModal(true);
                setSelectedUser(x);
              }}
            >
              Delete
            </ViewLink>
          </LinksContainer>
        </TableCell>
      </TableRow>
    )
  }

  async function handleDelete(username) {
    let apiName = 'AdminQueries';
    let path = '/disableUser';
    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
      body: {
        username,
      },
    };

    await API.post(apiName, path, myInit);

    setUpdated(true);
    setShowDeleteModal(false);
  }

  useEffect(() => {
    if (!user) {
      return;
    }

    listUsers(setUsers, setLogos);

    const handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';

      if (condition === 'online') {
        listUsers(setUsers, setLogos);
      }
    };

    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, [user]);

  useEffect(() => {
    listOrganizations(setOrganizations);
  }, []);

  useEffect(() => {
    if (updated) {
      listUsers(setUsers, setLogos);
      setUpdated(false);
    }
  }, [updated]);

  return (
    <LoggedInAdminTemplate hasTabs>
      <Grid>
        <Row>
          {activeView === 'add' && (
            <Col xsOffset={2} xs={8}>
              <Button
                simple
                withArrow
                colour={colour.charcoal}
                onClick={() => setActiveView('table')}
              >
                Back to users
              </Button>
              <Spacer desktop={20} />
              <Type.H4>Add User</Type.H4>
              <Divider desktop={20} />
              <AddUser
                setActiveView={setActiveView}
                setUpdated={setUpdated}
                organizations={organizations}
                loginUser={user}
              />
            </Col>
          )}
          {activeView === 'edit' && (
            <Col xsOffset={2} xs={8}>
              <Button
                simple
                withArrow
                colour={colour.charcoal}
                onClick={() => setActiveView('table')}
              >
                Back to users
              </Button>
              <Spacer desktop={20} />
              <Type.H4>Edit User</Type.H4>
              <Divider desktop={20} />
              <EditUser
                setActiveView={setActiveView}
                user={selectedUser}
                setUpdated={setUpdated}
                organizations={organizations}
                loginUser={user}
              />
            </Col>
          )}
          {activeView === 'table' && (
            <>
              <Col xs={3}>
                <Button
                  width={'100%'}
                  border
                  onClick={() => setActiveView('add')}
                >
                  Add User
                </Button>
              </Col>
              <Col xs={9}>
                <Row>
                  {activeView === 'table' && (
                    <>
                      <Col xs={12}>
                        <Type.H4>Users</Type.H4>
                        <Spacer desktop={20} />
                      </Col>
                      <Col xs={12}>
                        {!!users && (
                          <Table>
                            <TableHead paddingX={0}>
                              <HeaderCell>Name</HeaderCell>
                              <HeaderCell>Type</HeaderCell>
                              <HeaderCell>Client</HeaderCell>
                              <HeaderCell>Email</HeaderCell>
                              <HeaderCell
                                flexBasis={200}
                                flexShrink={0}
                                flexGrow={0}
                                paddingX={0}
                              ></HeaderCell>
                            </TableHead>
                            <Table.Body height={'auto'}>
                              {users.map((x, index) => {
                                if(isUserEditProfileNotAdmin(user)) {
                                  if(getCognitoFieldValue('custom:userGroup', x.Attributes) === 'client') {
                                    return userTableRow(x, index);
                                  } else {
                                    return null;
                                  }
                                } else {
                                  return userTableRow(x, index);
                                }
                              })}
                            </Table.Body>
                          </Table>
                        )}

                        <Modal
                          title="Delete User"
                          isShown={showDeleteModal}
                          handleModalState={setShowDeleteModal}
                          handleAction={() =>
                            handleDelete(selectedUser.Username)
                          }
                          type={'action'}
                          alignment={'center'}
                          buttonText="Yes"
                          shouldCloseOnEscapePress={false}
                          shouldCloseOnOverlayClick={false}
                        >
                          <p>
                            Are you sure you want to delete{' '}
                            {selectedUser.Attributes &&
                              getCognitoFieldValue(
                                'email',
                                selectedUser.Attributes
                              )}
                            ?
                          </p>
                        </Modal>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            </>
          )}
        </Row>
      </Grid>
    </LoggedInAdminTemplate>
  );
};

export default Users;
