import React, { useState } from 'react';
import Button from '@/elements/Button';
import { CheckboxGroup } from '@/elements/Form';
import { TextInput, FinalForm } from '@/elements/Form';
import arrayMutators from 'final-form-arrays';
import { ErrorBox } from '@/elements/ErrorBox/style';
import { getCognitoFieldValue } from '@/utils/getCognitoFieldValue';
import { Auth, API } from 'aws-amplify';
import { SelectList } from '@/elements/Form';
import { Spacer } from '@/elements/Spacer/style';
import { CheckboxGroupClients } from '@/elements/Form';
import { PhotoUploadField } from '@/elements/Form';
import { s3Upload } from '@/utils/awsLib';
import { isUserEditProfileNotAdmin } from '@/utils/isUserAdmin';

export const EditUser = (props) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  const { user, setActiveView, setUpdated, organizations, loginUser } = props;

  const onSubmit = async (values) => {
    const {
      position,
      profileImage,
      name,
      phoneNumber,
      userGroup,
      areas,
      organization,
      organizationClient,
    } = values;

    if (submitting) {
      return;
    }

    setSubmitting(true);

    let image = getCognitoFieldValue('custom:profileImage', user.Attributes);

    if (profileImage) {
      image = await s3Upload('userPhotos', profileImage);
    }

    const dataToSend = {
      username: user.Username,
      name,
      phoneNumber,
      userGroup,
      profileImage: image,
      position,
      areas: '',
    };

    if (areas && userGroup === 'client') {
      dataToSend.areas = JSON.stringify(areas ? areas : []);
    }

    if (
      organization &&
      organization.length > 0 &&
      (userGroup === 'sales' ||
        userGroup === 'observer' ||
        userGroup === 'partner')
    ) {
      dataToSend.organization = organization.join();
    }

    if (userGroup === 'client') {
      dataToSend.organization = organizationClient;
    }

    if (userGroup === 'admin' || userGroup === 'p-c' || userGroup === 'PM') {
      dataToSend.areas = '';
      dataToSend.organization = '';
    }

    let apiName = 'AdminQueries';
    let path = '/updateUserAttributes';
    let pathAddToGroup = '/addUserToGroup';
    let pathRemoveUserFromGroup = '/removeUserFromGroup';
    let headers = {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    };
    let myInit = {
      headers,
      body: dataToSend,
    };
    let addToGroupData = {
      headers,
      body: {
        username: user.Username,
        groupname: 'Admins',
      },
    };

    let removeEditorGroupData = {
      headers,
      body: {
        username: user.Username,
        groupname: 'Editors',
      }
    };

    try {
      await API.post(apiName, path, myInit);

      if (userGroup === 'admin' || userGroup === 'PM' || userGroup === 'p-c' || userGroup === 'sales') {
        await API.post(apiName, pathAddToGroup, addToGroupData);
        
        // remove user from other group
        await API.post(apiName, pathRemoveUserFromGroup, removeEditorGroupData);
      }

      setSubmitting(false);
      setUpdated(true);
      setActiveView('table');
    } catch (error) {
      setSubmitting(false);

      if (error.response?.status === 400) {
        setError(error.response.data.message);
      } else {
        setError(
          'An unknown error occured. Please contact the site administrator.'
        );
      }
    }
  };

  return (
    <>
      {error && <ErrorBox>{error}</ErrorBox>}
      <FinalForm
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
          resetAreas: (args, state, utils) => {
            utils.changeValue(state, 'areas', () => []);
          },
        }}
        initialValues={{
          name: getCognitoFieldValue('custom:name', user.Attributes),
          phoneNumber: getCognitoFieldValue(
            'custom:phoneNumber',
            user.Attributes
          ),
          email: getCognitoFieldValue('email', user.Attributes),
          userGroup: getCognitoFieldValue('custom:userGroup', user.Attributes),
          position: getCognitoFieldValue('custom:position', user.Attributes),
          organization: getCognitoFieldValue(
            'custom:organization',
            user.Attributes
          ).split(','),
          organizationClient:
            getCognitoFieldValue('custom:organization', user.Attributes).split(
              ','
            )[0] === 'null'
              ? undefined
              : getCognitoFieldValue(
                  'custom:organization',
                  user.Attributes
                ).split(',')[0],
          areas: getCognitoFieldValue('custom:areas', user.Attributes)
            ? JSON.parse(getCognitoFieldValue('custom:areas', user.Attributes))
            : [],
        }}
        render={({ form, handleSubmit, values, valid }) => {
          return (
            <form onSubmit={handleSubmit}>
              <PhotoUploadField
                name="profileImage"
                component="input"
                label="User Photo"
                defaultImage={getCognitoFieldValue(
                  'custom:profileImage',
                  user.Attributes
                )}
              />
              <TextInput
                name="name"
                component="input"
                type="text"
                value={values.name}
                validationType="required"
                label="Name"
                showAppend
              />
              <TextInput
                name="email"
                component="input"
                type="text"
                disabled
                value={values.email}
                validationType="required"
                label="Email Address"
                showAppend
              />
              <TextInput
                name="position"
                label="Position Title"
                component="input"
                type="text"
                validationType="required"
                placeholder="Project Manager"
                value={values.position}
              />
              <TextInput
                name="phoneNumber"
                component="input"
                type="text"
                value={values.phoneNumber}
                validationType="required"
                label="Phone Number"
                showAppend
              />
              <SelectList
                name="userGroup"
                label="User Type"
                validationType="required"
                component="select"
                value={values.userGroup}
              >
                <option disabled value="">
                  Select a user type
                </option>
                <option value="client">Client</option>
                {!isUserEditProfileNotAdmin(loginUser) && (
                  <>
                  <option value="sales">Sales</option>
                  <option value="p-c">P&amp;C</option>
                  <option value="partner">Partner</option>
                  <option value="admin">Admin</option>
                  <option value="PM">PM</option>
                  </>
                )}
              </SelectList>

              {organizations &&
                values.userGroup &&
                values.userGroup !== 'admin' &&
                values.userGroup !== 'PM' &&
                values.userGroup !== 'p-c' &&
                values.userGroup !== 'client' && (
                  <CheckboxGroupClients
                    name="organization"
                    label="Associated Client/s"
                    options={organizations.map((x) => ({
                      label: x.name,
                      value: x.id,
                    }))}
                  />
                )}

              {organizations &&
                values.userGroup &&
                values.userGroup === 'client' && (
                  <SelectList
                    name="organizationClient"
                    label="Client"
                    validationType="required"
                    component="select"
                    selectOnChange={form.mutators.resetAreas}
                  >
                    <option disabled value="">
                      Select client
                    </option>
                    {organizations.map((x) => (
                      <option value={x.id} key={x.id}>
                        {x.name}
                      </option>
                    ))}
                  </SelectList>
                )}
              {organizations &&
                values.organizationClient &&
                values.userGroup === 'client' &&
                (organizations.filter(
                  (x) => x.id === values.organizationClient
                )[0].areas[0] === '' ? (
                  <div>
                    Selected client has no Department/Area
                    <Spacer desktop={15} />
                  </div>
                ) : (
                  <CheckboxGroup
                    name="areas"
                    label="Select Department/Area"
                    validationType="requiredArray"
                    value={values.areas}
                    options={
                      organizations.filter(
                        (x) => x.id === values.organizationClient
                      )[0].areas
                    }
                  />
                ))}
              <Button disabled={submitting || !valid}>Update</Button>
            </form>
          );
        }}
      ></FinalForm>
    </>
  );
};
