export const getCognitoFieldValue = (fieldName, userData) => {
  const data = userData.filter((x) => x.Name && x.Name === fieldName);

  if (!data) {
    return '';
  }

  if (!data[0]?.Value) {
    return '';
  }

  return data[0].Value || '';
};
