import React, { useEffect, useState } from 'react';
import {
  Wrap,
  List,
  Ul,
  SearchBox,
  SearchWrapper,
  MobileSliderWrapper,
  CloseButton,
} from './style';
import Type from '@/elements/Type';
import Icons from '@/elements/Icons';
import { InputWrap } from '@/elements/Form/style';

const SidebarFilter = (props) => {
  const {
    data,
    classify,
    onFilter,
    title,
    mobileTitle,
    withSearch,
    status,
    isOpen,
    setIsOpen,
  } = props;

  const [keys, setKeys] = useState({});
  const [selectedKey, setSelectedKey] = useState('All');

  let filterNameValue = {};
  if (status) {
    status.map((x) => (filterNameValue[x.name] = x.value));
  }

  const handleOnClick = (key) => {
    setSelectedKey(key);
    setIsOpen(false);

    const filtered =
      key === 'All'
        ? data
        : data.filter((item) =>
            key === 'new'
              ? !item['viewed']
                ? item[classify] === key
                : null
              : item[classify] === key
          );
    onFilter(filtered, key);
  };

  useEffect(() => {
    let obj = {};
    if (status) {
      status.map((x) => (obj[x.name] = 0));
    }
    data.map((item) => {
      const classValue = item[classify];
      
      return typeof obj[classValue] !== 'undefined'
        ? (obj[classValue] =
            classValue === 'new' && status
              ? item.viewed ? obj[classValue] + 0 : obj[classValue] + 1
              : obj[classValue] + 1)
        : (obj[classValue] = 1);
    });
    setKeys(obj);

    if(obj.new > 0) {
      setSelectedKey('new');
      const filtered =
            data.filter((item) =>
                !item['viewed']
                  ? item[classify] === 'new'
                  : null
            );
      onFilter(filtered, 'new');
    } else {
      setSelectedKey('All');
    }
  }, [data, classify, status, onFilter]);

  const handleSearch = (e) => {
    const keyword = e.target.value;
    const filtered = data.filter((item) =>
      Object.values(item).find(
        (x) =>
          x && x.toString().toLowerCase().indexOf(keyword.toLowerCase()) > -1
      )
    );
    onFilter(filtered);
  };

  return (
    <Wrap>
      {title && <Type.H6 hasShadow>{title}</Type.H6>}
      <Ul mobileOnly>
        <List active={true}>
          <span>{selectedKey}</span>{' '}
          <span>
            [{selectedKey === 'All' ? data.length : keys[selectedKey]}]
          </span>
        </List>
      </Ul>
      <MobileSliderWrapper isOpen={isOpen}>
        <CloseButton
          onClick={() => {
            setIsOpen(false);
          }}
        />
        {mobileTitle && (
          <Type.H6 className="hidden-desktop" hasShadow>
            {mobileTitle}
          </Type.H6>
        )}
        {withSearch && (
          <SearchWrapper>
            <InputWrap>
              <SearchBox className={`input-text `}>
                <Icons.Search />
                <input
                  type={`text`}
                  placeholder={`Search`}
                  onChange={handleSearch}
                />
              </SearchBox>
            </InputWrap>
          </SearchWrapper>
        )}
        <Ul>
          <List
            active={selectedKey === 'All'}
            onClick={() => handleOnClick('All')}
          >
            <span>All Candidates</span> <span>[{data.length}]</span>
          </List>
          {Object.keys(keys).map((name) => {
            return (
              <List
                active={selectedKey === name}
                // disabled={keys[name] === 0}
                key={name}
                title={name}
                onClick={() => handleOnClick(name)}
              >
                <span>{status ? filterNameValue[name] : name}</span>
                <span>[{keys[name]}]</span>
              </List>
            );
          })}
        </Ul>
      </MobileSliderWrapper>
    </Wrap>
  );
};

export default SidebarFilter;
