import styled, { css } from 'styled-components';
import { colour, breakpoint } from '@/styles/variables';
import toggleIcon from '@/static/images/toggleIcon.png';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-shrink: 0;
`;

export const List = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
  text-transform: capitalize;
  color: ${({ active }) => (active ? colour.blue : colour.charcoal)};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      display: none;
    `};
`;

export const SearchWrapper = styled.div`
  @media (max-width: ${breakpoint.max.medium}) {
    display: none;
  }
`;

export const Ul = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  width: 100%;
  flex-direction: column;

  ${({ mobileOnly }) =>
    mobileOnly &&
    css`
      display: none;
      @media (max-width: ${breakpoint.max.medium}) {
        display: block;

        li {
          display: inline-block;
          span:first-child {
            margin-right: 5px;
          }
        }
      }
    `}
`;

export const SearchBox = styled.div`
  position: relative;
  margin-top: 10px;
  input {
    padding-left: 40px;
  }
  svg {
    position: absolute;
    z-index: 2;
    top: 24px;
    left: 15px;
  }
`;

export const MobileSliderWrapper = styled.div`
  @media (max-width: ${breakpoint.max.medium}) {
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(100%);
    transition: transform 0.3s;
    background-color: white;
    width: calc(100% - 40px);
    height: 100vh;
    z-index: 19;
    padding: 20px;

    ${({ isOpen }) =>
      isOpen &&
      css`
        transform: translateX(0);
      `}
  }
`;

export const FilterWithMobileToggle = styled.div`
  display: flex;
  justify-content: space-between;

  .content {
    flex: 0 0 100%;
    max-width: 100%;
    @media (max-width: ${breakpoint.max.medium}) {
      flex: 0 0 calc(100% - 80px);
      max-width: calc(100% - 80px);
    }
  }

  .toggle {
    display: none;
    @media (max-width: ${breakpoint.max.medium}) {
      display: block;
      text-align: right;
      flex: 0 0 60px;
      max-width: 60px;
    }
  }
`;

export const CloseButton = styled.div`
  height: 15px;
  width: 15px;
  margin-bottom: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.707' height='15.707' viewBox='0 0 15.707 15.707'%3E%3Cg id='Group_222' data-name='Group 222' transform='translate(-685.646 -196.646)'%3E%3Cline id='Line_56' data-name='Line 56' x2='15' y2='15' transform='translate(686 197)' fill='none' stroke='%23363537' stroke-width='1'/%3E%3Cline id='Line_57' data-name='Line 57' x2='15' y2='15' transform='translate(701 197) rotate(90)' fill='none' stroke='%23363537' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");
  cursor: pointer;
  display: none;

  @media (max-width: ${breakpoint.max.medium}) {
    display: block;
  }
`;

export const ToggleFilterButton = styled.div`
  height: 50px;
  width: 50px;
  border: 1px solid ${colour.grey};
  background-image: url(${toggleIcon});
  background-size: 28px;
  margin-right: 0;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
`;
