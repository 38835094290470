import React, { useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import Modal from '@/elements/Modal';
import { Button } from '@/elements/Button/style';
import { Table } from 'evergreen-ui';
import {
  TableRow,
  TableCell,
  TableHead,
  HeaderCell,
} from '@/components/Table/style';
import { ViewLink } from '@/components/InvoiceTable/style';
import { LinksContainer } from '@/pages/app/Organization/style';
import { Spacer } from '@/elements/Spacer/style';
import { ErrorBox } from '@/elements/ErrorBox/style';
import { DatePickerInput } from '@/elements/Form';
import { Toggle } from '@/elements/Form';
import {
  PhotoUploadFieldWrap,
  ButtonGroupContainer,
} from '@/elements/Form/style';
import { TextInput, getValidator, FinalForm, FileInput } from '@/elements/Form';
import { formatDateForHumans } from '@/utils/dates';
import { Separator } from '../style';

export const ClientTasks = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [error, setError] = useState('');
  const [errorAdd, setErrorAdd] = useState('');

  const [selectedItem, setSelectedItem] = useState({ name: '', index: 0 });

  return (
    <PhotoUploadFieldWrap>
      <label className="label">{`${props.label}${
        props.validationType && props.validationType.indexOf('required') > -1
          ? '*'
          : ''
      }`}</label>
      <Spacer desktop={10} />
      <FieldArray
        name="type"
        validate={(value) => getValidator(props.validationType, value)}
        {...props}
      >
        {({ fields }) => {
          const handleDelete = () => {
            fields.remove(selectedItem.index);
          };

          const handleRemove = (filename, values) => {
            if (values.document && values.document.indexOf('"name":""') < 0) {
              const taskItem = {
                taskName: values.taskName,
                dueDate: values.dueDate,
                taskApprover: values.approved ? values.taskApprover : '',
                approved: values.approved
              };
              const documentItem = {
                title: values.title
              }
              let arr = JSON.parse(values.document).filter(
                (obj) => obj.name !== filename
              );
              arr = arr.length ? arr : [{ name: '' }];
              documentItem.document = JSON.stringify(arr);

              taskItem.documents = [documentItem];
              //console.log(taskItem);
              //fields.update(selectedItem.index, taskItem);
            }
          };

          const handleEdit = (values) => {
            const {
              taskName,
              dueDate,
              taskApprover,
              approved,
              approvalDate,
              document, documentUrl, title
            } = values;
            setError('');

            const taskItem = {
              taskName,
              dueDate: formatDateForHumans(new Date(dueDate), 'yyyy-MM-dd'),
              taskApprover: approved ? taskApprover : '',
              approved: approved,
              documents: null
            };

            const documentItem = {
              title,
            };
            
            if (document) {
              documentItem.document = JSON.parse(document);
            }

            if (documentUrl) {
              documentItem.document = [
                {
                  name: 'url-document--1',
                  serverFileName: title,
                  folder: 'projects/documents',
                  url: documentUrl,
                  type: 'application/pdf',
                  uploadDate: JSON.stringify(new Date()),
                },
              ];
            }

            if (approved) {
              taskItem.approvalDate = formatDateForHumans(
                new Date(approvalDate),
                'yyyy-MM-dd'
              );
            }

            if(document || documentUrl) {
              taskItem.documents = [];
              taskItem.documents = [documentItem];
            }

            fields.update(selectedItem.index, taskItem);
            setIsModalOpen(false); console.log(taskItem);
          };
          const handleAdd = (values) => {
            const {
              taskName,
              dueDate,
              taskApprover,
              approved,
              approvalDate,
              document, documentUrl, title
            } = values;
            setErrorAdd('');

            const taskItem = {
              taskName,
              dueDate,
              taskApprover: approved ? taskApprover : '',
              approved: approved ? true : false,
              documents: null
            };

            const documentItem = {
              title,
            }

            if (approved) {
              taskItem.approvalDate = formatDateForHumans(
                new Date(approvalDate),
                'yyyy-MM-dd'
              );
            }

            if (document) {
              documentItem.document = JSON.parse(document);
            }

            if (documentUrl) {
              documentItem.document = [
                {
                  name: 'url-document--1',
                  serverFileName: title,
                  folder: 'projects/documents',
                  url: documentUrl,
                  type: 'application/pdf',
                  uploadDate: JSON.stringify(new Date()),
                },
              ];
            }

            if(document || documentUrl) {
              taskItem.documents = [];
              taskItem.documents.push(documentItem);
            }
            
            fields.push(taskItem);
            setIsAddModalOpen(false);
          };
          return (
            <>
              {fields.length !== 0 && (
                <>
                  <Table>
                    <TableHead paddingX={0}>
                      <HeaderCell>Title</HeaderCell>
                      <HeaderCell>Due Date</HeaderCell>
                      <HeaderCell
                        flexBasis={200}
                        flexShrink={0}
                        flexGrow={0}
                        paddingX={0}
                      ></HeaderCell>
                    </TableHead>
                    <Table.Body height={'auto'}>
                      {fields.map((x, i) => {
                        return (
                          <TableRow key={i} height={'auto'} variation={'admin'}>
                            <TableCell>{fields.value[i].taskName}</TableCell>
                            <TableCell>
                              {formatDateForHumans(
                                new Date(fields.value[i].dueDate)
                              )}
                            </TableCell>
                            <TableCell
                              flexBasis={200}
                              flexShrink={0}
                              flexGrow={0}
                              paddingX={0}
                            >
                              <LinksContainer>
                                <ViewLink
                                  onClick={() => {
                                    setIsModalOpen(true);
                                    setSelectedItem({
                                      ...fields.value[i],
                                      index: i,
                                    });
                                  }}
                                >
                                  Edit
                                </ViewLink>
                                <ViewLink
                                  onClick={() => {
                                    setIsDeleteModalOpen(true);
                                    setSelectedItem({
                                      ...fields.value[i],
                                      index: i,
                                    });
                                  }}
                                >
                                  Delete
                                </ViewLink>
                              </LinksContainer>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </Table.Body>
                  </Table>
                  <Spacer desktop={10} />
                </>
              )}
              {fields.length === 0 && 'No tasks have been created '}
              <Button
                simple
                onClick={(e) => {
                  e.preventDefault();
                  setIsAddModalOpen(true);
                }}
              >
                + Add Task
              </Button>

              <Modal
                title="Add Task"
                isShown={isAddModalOpen}
                handleModalState={setIsAddModalOpen}
                type="plain"
                alignment="center"
                shouldCloseOnEscapePress={false}
                shouldCloseOnOverlayClick={false}
              >
                <FinalForm
                  onSubmit={handleAdd}
                  render={({ handleSubmit, values }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        {errorAdd && (
                          <ErrorBox slimPadding>{errorAdd}</ErrorBox>
                        )}
                        <TextInput
                          name="taskName"
                          component="input"
                          value={values.taskName}
                          type="text"
                          validationType="required"
                          label="Task"
                          placeholder="What is the task name?"
                        />

                        <DatePickerInput
                          name="dueDate"
                          type="text"
                          dateFormat="dd-MM-yyyy"
                          value={values.dueDate}
                          label="Due Date"
                          validationType={'required'}
                          placeholder="DD/MM/YYYY"
                          maxDate={values.endDate}
                          showAppend
                        />
                        <Toggle
                          name="approved"
                          type="checkbox"
                          label="Approved?"
                        />
                        <Spacer desktop={10} />
                        {values.approved && (
                          <TextInput
                            name="taskApprover"
                            component="input"
                            value={values.taskApprover}
                            type="text"
                            label="Task Approver"
                            placeholder="Who Approved the Task?"
                          />
                        )}

                        <TextInput
                          name="title"
                          component="input"
                          value={values.title}
                          type="text"
                          label="Title of Document"
                          placeholder="Enter the title of the document"
                        />
                        
                        <TextInput
                          name="documentUrl"
                          component="input"
                          value={values.documentUrl}
                          type="url"
                          label="URL"
                          placeholder="Enter the URL of the document"
                          disabled={values.document ? 'disabled' : undefined}
                        />
                        <Separator>OR</Separator>
                        <FileInput
                          name="document"
                          single={true}
                          value={values.document}
                          handleRemove={(filename) =>
                            handleRemove(filename, values)
                          }
                          component="input"
                          label="Upload Document"
                          folder="projects/documents"
                          disabled={values.documentUrl ? 'disabled' : undefined}
                        />
                        <ButtonGroupContainer justifyContent="flex-end">
                          <Button>Add</Button>
                          <Button
                            simple
                            onClick={(e) => {
                              e.preventDefault();
                              setIsAddModalOpen(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </ButtonGroupContainer>
                      </form>
                    );
                  }}
                ></FinalForm>
              </Modal>

              <Modal
                title="Edit Task"
                isShown={isModalOpen}
                handleModalState={setIsModalOpen}
                type="plain"
                alignment="center"
                shouldCloseOnEscapePress={false}
                shouldCloseOnOverlayClick={false}
              >
                <FinalForm
                  onSubmit={handleEdit}
                  initialValues={{
                    taskName: selectedItem.taskName,
                    dueDate: new Date(selectedItem.dueDate),
                    approved: selectedItem.approved,
                    taskApprover: selectedItem.taskApprover,
                    document: selectedItem.documents && selectedItem.documents[0] && selectedItem.documents[0].document &&
                      selectedItem.documents[0].document[0].name !== 'url-document--1'
                        ? JSON.stringify(selectedItem.documents[0].document)
                        : null,
                    title: selectedItem.documents && selectedItem.documents[0] && selectedItem.documents[0].document ? selectedItem.documents[0].title : '',
                    documentUrl: selectedItem.documents && selectedItem.documents[0] && selectedItem.documents[0].document &&
                      selectedItem.documents[0].document[0].name === 'url-document--1'
                        ? selectedItem.documents[0].document[0]?.url
                        : null,
                    approvalDate: selectedItem.approvalDate
                      ? new Date(selectedItem.approvalDate)
                      : null,
                  }}
                  render={({ handleSubmit, values }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        {error && <ErrorBox slimPadding>{error}</ErrorBox>}
                        <TextInput
                          name="taskName"
                          component="input"
                          value={values.taskName}
                          type="text"
                          validationType="required"
                          label="Task"
                          placeholder="What is the task name?"
                        />
                        <DatePickerInput
                          name="dueDate"
                          type="text"
                          dateFormat="dd-MM-yyyy"
                          value={values.dueDate}
                          label="Due Date"
                          validationType={'required'}
                          placeholder="DD/MM/YYYY"
                          maxDate={values.endDate}
                          showAppend
                        />
                        <Toggle
                          name="approved"
                          type="checkbox"
                          label="Approved?"
                        />
                        <Spacer desktop={10} />
                        {values.approved && (
                          <TextInput
                            name="taskApprover"
                            component="input"
                            value={values.taskApprover}
                            type="text"
                            label="Task Approver"
                            placeholder="Who Approved the Task?"
                          />
                        )}
                        {values.approved && (
                          <DatePickerInput
                            name="approvalDate"
                            type="text"
                            dateFormat="dd-MM-yyyy"
                            value={values.approvalDate}
                            label="Approval Date"
                            validationType={'required'}
                            placeholder="DD/MM/YYYY"
                            showAppend
                          />
                        )}
                        <TextInput
                          name="title"
                          component="input"
                          value={values.title}
                          type="text"
                          label="Title of Document"
                          placeholder="Enter the title of the document"
                        />

                        <TextInput
                          name="documentUrl"
                          component="input"
                          value={values.documentUrl}
                          type="url"
                          label="URL"
                          placeholder="Enter the URL of the document"
                          disabled={values.document ? 'disabled' : undefined}
                        />
                        <Separator>OR</Separator>
                        <FileInput
                          name="document"
                          single={true}
                          value={values.document}
                          handleRemove={(filename) =>
                            handleRemove(filename, values)
                          }
                          component="input"
                          label="Upload Document"
                          folder="projects/documents"
                          disabled={values.documentUrl ? 'disabled' : undefined}
                        />
                        <ButtonGroupContainer justifyContent="flex-end">
                          <Button>Update</Button>
                          <Button
                            simple
                            onClick={(e) => {
                              e.preventDefault();
                              setIsModalOpen(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </ButtonGroupContainer>
                      </form>
                    );
                  }}
                ></FinalForm>
              </Modal>

              <Modal
                title="Delete Task"
                isShown={isDeleteModalOpen}
                handleModalState={setIsDeleteModalOpen}
                handleAction={() => handleDelete()}
                type={'action'}
                alignment={'center'}
                buttonText="Yes"
                shouldCloseOnEscapePress={false}
                shouldCloseOnOverlayClick={false}
              >
                <p>Are you sure you want to delete {selectedItem.taskName}?</p>
              </Modal>
            </>
          );
        }}
      </FieldArray>
    </PhotoUploadFieldWrap>
  );
};
