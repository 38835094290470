import React, { useState } from 'react';
import db from '@/utils/database';
import { s3Upload } from '@/utils/awsLib';
import Button from '@/elements/Button';
import { TextInput, FinalForm } from '@/elements/Form';
import arrayMutators from 'final-form-arrays';
import { CheckboxGroup } from '@/elements/Form';
import { PhotoUploadField } from '@/elements/Form';
import { ErrorBox } from '@/elements/ErrorBox/style';
import Spacer from '@/elements/Spacer';
import { SelectList } from '@/elements/Form';
import { Toggle } from '@/elements/Form';
import { FormRowConsultant } from './style';
import { DatePickerInput } from '@/elements/Form';
import { formatDateForHumans } from '@/utils/dates';

export const EditConsultant = (props) => {
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const { setActiveView, consultant, organizations, roles } = props;

  const onSubmit = async (values) => {
    const {
      name,
      photoImage,
      organization,
      areas,
      startDate,
      inContract,
      endDate,
      role,
    } = values;

    if (submitting) {
      return;
    }

    if (!photoImage && !consultant.consultantImage) {
      setError('Please provide a consultant photo.');
      return;
    }

    setSubmitting(true);

    const consultantImageNew = photoImage
      ? await s3Upload('organizationLogos', photoImage)
      : consultant.consultantImage;

    const consultantData = {
      name,
      consultantImage: consultantImageNew,
      inContract: inContract ? inContract : false,
      areas: [''],
    };

    if (inContract) {
      consultantData.role = role;
      consultantData.organization = { id: organization };
      consultantData.startDate = formatDateForHumans(
        new Date(startDate),
        'yyyy-MM-dd'
      );
      consultantData.endDate = formatDateForHumans(
        new Date(endDate),
        'yyyy-MM-dd'
      );
    } else {
      consultantData.role = '-';
      consultantData.organization = {
        id: '83f8df16-c47b-4d11-918c-67e6d8b5aa30',
      };
      consultantData.startDate = '-';
      consultantData.endDate = '-';
    }

    if (areas && areas.length !== 0) {
      consultantData.areas = areas;
    }

    try {
      await db.update('consultant', consultantData, consultant.id).then(() => {
        setSubmitting(false);
        setActiveView('table');
      });
    } catch (e) {
      setSubmitting(false);
      setError(
        typeof error === 'string'
          ? error
          : error.message ||
              'An unexpected error occurred. Please contact the site administrator.'
      );
    }
  };

  const validateData = (value) => {
    if (!!!value || value === '-') {
      return '';
    }

    return value;
  };

  return (
    <>
      {error && (
        <ErrorBox>
          <>{error}</>
        </ErrorBox>
      )}
      <FinalForm
        onSubmit={onSubmit}
        initialValues={{
          inContract: validateData(consultant.inContract),
          name: consultant.name,
          startDate: validateData(consultant.startDate),
          endDate: validateData(consultant.endDate),
          organization: validateData(consultant.organization?.id),
          areas: validateData(consultant.areas),
          role: validateData(consultant.role),
        }}
        mutators={{
          ...arrayMutators,
          resetAreas: (args, state, utils) => {
            utils.changeValue(state, 'areas', () => []);
          },
        }}
        render={({ form, handleSubmit, values, valid }) => {
          return (
            <form onSubmit={handleSubmit}>
              <PhotoUploadField
                name="photoImage"
                component="input"
                label="Consultant Photo"
                defaultImage={consultant.consultantImage}
              />

              <TextInput
                name="name"
                component="input"
                type="text"
                validationType="required"
                label="Consultant"
                value={values.name}
                showAppend
              />
              <FormRowConsultant>
                <div className="toggle">
                  <Toggle
                    name="inContract"
                    type="checkbox"
                    label="In contract?"
                  />
                </div>
                <div className="date">
                  <DatePickerInput
                    name="startDate"
                    type="text"
                    dateFormat="dd-MM-yyyy"
                    value={values.startDate}
                    label="Dates (from - to)"
                    validationType={values.inContract ? 'required' : ''}
                    placeholder="DD/MM/YYYY"
                    maxDate={values.endDate}
                    disabled={!values.inContract ? 'disabled' : ''}
                    showAppend
                  />
                  <DatePickerInput
                    name="endDate"
                    type="text"
                    dateFormat="dd-MM-yyyy"
                    value={values.endDate}
                    validationType={values.inContract ? 'required' : ''}
                    placeholder="DD/MM/YYYY"
                    minDate={values.startDate}
                    disabled={!values.inContract ? 'disabled' : ''}
                    showAppend
                  />
                </div>
              </FormRowConsultant>

              {organizations && (
                <SelectList
                  name="organization"
                  label="Client"
                  validationType={values.inContract ? 'required' : ''}
                  component="select"
                  disabled={!values.inContract ? 'disabled' : ''}
                  value={values.organization}
                  selectOnChange={form.mutators.resetAreas}
                >
                  <option disabled value="">
                    Select client
                  </option>
                  {organizations.map((x) => (
                    <option value={x.id} key={x.id}>
                      {x.name}
                    </option>
                  ))}
                </SelectList>
              )}

              {organizations &&
                values.organization &&
                (organizations.filter((x) => x.id === values.organization)[0]
                  .areas[0] === '' ? (
                  <div>
                    Selected client has no Department/Area
                    <Spacer desktop={15} />
                  </div>
                ) : (
                  <CheckboxGroup
                    name="areas"
                    label="Select Department/Area"
                    validationType={values.inContract ? 'requiredArray' : ''}
                    disabled={!values.inContract ? 'disabled' : ''}
                    options={
                      organizations.filter(
                        (x) => x.id === values.organization
                      )[0].areas
                    }
                    value={values.areas}
                  />
                ))}

              {roles && (
                <SelectList
                  name="role"
                  label="Role"
                  validationType={values.inContract ? 'required' : ''}
                  component="select"
                  disabled={!values.inContract ? 'disabled' : ''}
                  value={values.role}
                >
                  <option disabled value="">
                    Select role
                  </option>
                  {roles.map((x) => (
                    <option value={x.name} key={x.id}>
                      {x.name}
                    </option>
                  ))}
                </SelectList>
              )}

              <Spacer desktop={15} />

              <Button disabled={submitting || !valid}>Done</Button>
            </form>
          );
        }}
      ></FinalForm>
    </>
  );
};
