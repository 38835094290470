import { Table } from 'evergreen-ui';
import { colour, font, breakpoint } from '@/styles/variables';
import styled, { css } from 'styled-components';

const HiddenMobile = () => css`
  @media (max-width: ${breakpoint.max.medium}) {
    display: none;
  }
`;

export const HeaderCell = styled(Table.TextHeaderCell)`
  background-color: transparent;
  font-family: ${font.primary};
  flex: ${({ width }) => width && `0 0 ${width}`};

  ${({ hiddenmobile }) => hiddenmobile === 'true' && HiddenMobile};

  * {
    font-family: ${font.primary};
    font-weight: 800;
    color: ${colour.grey};
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
  }
`;

export const TableHead = styled(Table.Head)`
  background-color: transparent;
  border-bottom: 0;
`;

export const TableCell = styled(Table.TextCell)`
  font-family: ${font.primary};
  flex: ${({ width }) => width && `0 0 ${width}`};

  ${({ hiddenmobile }) => hiddenmobile === 'true' && HiddenMobile};

  * {
    font-family: ${font.primary};
    color: ${colour.charcoal};
    font-size: 14px;
    line-height: 16px;
  }
`;

export const TableRow = styled(Table.Row)`
  border-bottom: ${({ variation }) =>
    variation === 'admin' ? '0' : `1px solid ${colour.grey}`};

  ${({ variation }) =>
    variation === 'admin' &&
    css`
      padding: 24px 0;
      &:nth-child(even) {
      }
      &:nth-child(odd) {
        background-color: ${colour.lightGrey};
      }
    `};
`;
