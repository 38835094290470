import styled from 'styled-components';
import { colour } from '@/styles/variables';

export const ErrorBox = styled.div`
  border: 1px solid ${colour.red};
  border-radius: 5px;
  background-color: ${colour.red};
  color: white;
  padding: 5px;
  font-weight: 700;
  margin: ${({ slimPadding }) => (slimPadding ? '15px 0 0' : '15px auto 20px')};
`;
