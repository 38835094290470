import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import PublicTemplate from '@/templates/public';
import Type from '@/elements/Type';
import Button from '@/elements/Button';
import { FinalForm } from '@/elements/Form';
import { TextInput } from '@/elements/Form';
import logo from '@/static/images/logo.svg';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { ErrorBox } from '@/elements/ErrorBox/style';

const LoginTopElements = styled.div`
  img {
    width: 90px;
    margin: 0 auto 20px;
  }

  p {
    font-size: 14px;
    line-height: 16px;
    margin: 10px auto 30px;
  }
`;

const ForgotPassword = (props) => {
  const [error, setError] = useState(null);
  const { history } = props;

  const onSubmit = async (values) => {
    const { email } = values;
    try {
      await Auth.forgotPassword(email);
      history.push(
        `/forgot-password-confirmation?email=${encodeURIComponent(email)}`
      );
    } catch (error) {
      if (error.code === 'InvalidParameterException') {
        setError(
          'Cannot reset password for the user as there is no registered/verified email.'
        );
        return;
      }
      setError(
        typeof error === 'string'
          ? error
          : error.message ||
              'An unexpected error occurred. Please try again later'
      );
    }
  };

  return (
    <PublicTemplate>
      <LoginTopElements>
        <Link to="/">
          <img src={logo} alt="appscore logo" />
        </Link>
        <Type.H6>Forgot Password</Type.H6>
        <Type.P>
          Please enter your email address and we will send you a confirmation
          code.
        </Type.P>
      </LoginTopElements>
      {error && <ErrorBox>{error}</ErrorBox>}
      <FinalForm
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, form }) => (
          <form onSubmit={handleSubmit}>
            <TextInput
              name="email"
              component="input"
              type="text"
              validationType="required"
              label="Email Address"
              showAppend
            />
            <Button disabled={submitting} type="submit">
              Send
            </Button>
          </form>
        )}
      ></FinalForm>
    </PublicTemplate>
  );
};

export default withRouter(ForgotPassword);
