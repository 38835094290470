import React, { useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { getValidator } from '..';
import Modal from '@/elements/Modal';
import { FinalForm } from '..';
import { ButtonGroupContainer, PhotoUploadFieldWrap } from '../style';
import { Button } from '@/elements/Button/style';
import { TextInput } from '..';
import { Table } from 'evergreen-ui';
import { TableRow, TableCell } from '@/components/Table/style';
import { ViewLink } from '@/components/InvoiceTable/style';
import { LinksContainer } from '@/pages/app/Organization/style';
import { Spacer } from '@/elements/Spacer/style';
import { ErrorBox } from '@/elements/ErrorBox/style';

export const OrgAreasInput = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [error, setError] = useState('');
  const [errorAdd, setErrorAdd] = useState('');

  const [selectedArea, setSelectedArea] = useState({ name: '', index: 0 });

  return (
    <PhotoUploadFieldWrap>
      <label className="label">{`${props.label}${
        props.validationType && props.validationType.indexOf('required') > -1
          ? '*'
          : ''
      }`}</label>
      <Spacer desktop={10} />
      <FieldArray
        name="type"
        validate={(value) => getValidator(props.validationType, value)}
        {...props}
      >
        {({ fields }) => {
          const handleDelete = () => {
            fields.remove(selectedArea.index);
          };

          const handleEdit = (values) => {
            const { name } = values;
            setError('');

            if (
              fields.value &&
              fields.value.indexOf(name) > -1 &&
              name !== selectedArea.name
            ) {
              setError('Department/Area already exists');
              return;
            }

            fields.update(selectedArea.index, name);
            setIsModalOpen(false);
          };
          const handleAdd = (values) => {
            const { addName } = values;
            setErrorAdd('');

            if (fields.value && fields.value.indexOf(addName) > -1) {
              setErrorAdd('Department/Area already exists');
              return;
            }

            fields.push(addName);
            setIsAddModalOpen(false);
          };
          return (
            <>
              {fields.length !== 0 && (
                <>
                  <Table>
                    <Table.Body height={'auto'}>
                      {fields.map((x, i) => {
                        return (
                          <TableRow key={i} height={'auto'} variation={'admin'}>
                            <TableCell>
                              {fields.value[i]}
                              <input type="hidden" />
                            </TableCell>
                            <TableCell
                              flexBasis={200}
                              flexShrink={0}
                              flexGrow={0}
                              paddingX={0}
                            >
                              <LinksContainer>
                                <ViewLink
                                  onClick={() => {
                                    setIsModalOpen(true);
                                    setSelectedArea({
                                      name: fields.value[i],
                                      index: i,
                                    });
                                  }}
                                >
                                  Edit
                                </ViewLink>
                                <ViewLink
                                  onClick={() => {
                                    setIsDeleteModalOpen(true);
                                    setSelectedArea({
                                      name: fields.value[i],
                                      index: i,
                                    });
                                  }}
                                >
                                  Delete
                                </ViewLink>
                              </LinksContainer>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </Table.Body>
                  </Table>
                  <Spacer desktop={10} />
                </>
              )}
              {fields.length === 0 && 'No Department/Area have been created '}
              <Button
                simple
                onClick={(e) => {
                  e.preventDefault();
                  setIsAddModalOpen(true);
                }}
              >
                + Add Department/Area
              </Button>

              <Modal
                title="Add Client Department/Area"
                isShown={isAddModalOpen}
                handleModalState={setIsAddModalOpen}
                type="plain"
                alignment="center"
                shouldCloseOnEscapePress={false}
                shouldCloseOnOverlayClick={false}
              >
                <FinalForm
                  onSubmit={handleAdd}
                  render={({ handleSubmit, values }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        {errorAdd && (
                          <ErrorBox slimPadding>{errorAdd}</ErrorBox>
                        )}
                        <TextInput
                          name="addName"
                          component="input"
                          value={values.name}
                          type="text"
                          validationType="required"
                          placeholder="What is the Department/Area?"
                        />
                        <ButtonGroupContainer justifyContent="flex-end">
                          <Button>Add</Button>
                          <Button
                            simple
                            onClick={(e) => {
                              e.preventDefault();
                              setIsAddModalOpen(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </ButtonGroupContainer>
                      </form>
                    );
                  }}
                ></FinalForm>
              </Modal>

              <Modal
                title="Edit Client Department/Area"
                isShown={isModalOpen}
                handleModalState={setIsModalOpen}
                type="plain"
                alignment="center"
                shouldCloseOnEscapePress={false}
                shouldCloseOnOverlayClick={false}
              >
                <FinalForm
                  onSubmit={handleEdit}
                  initialValues={{
                    name: selectedArea.name,
                  }}
                  render={({ handleSubmit, values }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        {error && <ErrorBox slimPadding>{error}</ErrorBox>}
                        <TextInput
                          name="name"
                          component="input"
                          value={values.name}
                          type="text"
                          validationType="required"
                          placeholder="What is the Department/Area?"
                        />
                        <ButtonGroupContainer justifyContent="flex-end">
                          <Button>Update</Button>
                          <Button
                            simple
                            onClick={(e) => {
                              e.preventDefault();
                              setIsModalOpen(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </ButtonGroupContainer>
                      </form>
                    );
                  }}
                ></FinalForm>
              </Modal>

              <Modal
                title="Delete Department/Area"
                isShown={isDeleteModalOpen}
                handleModalState={setIsDeleteModalOpen}
                handleAction={() => handleDelete()}
                type={'action'}
                alignment={'center'}
                buttonText="Yes"
                shouldCloseOnEscapePress={false}
                shouldCloseOnOverlayClick={false}
              >
                <p>Are you sure you want to delete {selectedArea.name}?</p>
              </Modal>
            </>
          );
        }}
      </FieldArray>
    </PhotoUploadFieldWrap>
  );
};
