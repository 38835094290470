import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// amplify config
import Amplify from '@aws-amplify/core';
import awsconfig from './aws-exports';

// amplify-Custom-ui config
import * as aws_amplify_react from 'aws-amplify-react';
import AmplifyCustomUi from 'aws-amplify-react-custom-ui';
import { DataStore } from '@aws-amplify/datastore';

Amplify.configure(awsconfig);
DataStore.configure(awsconfig);

AmplifyCustomUi.configure(aws_amplify_react);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
