const bps = {
  small: 576,
  medium: 768,
  large: 992,
  larger: 1024,
  xlarge: 1200,
  wide: 1440,
};

export const font = {
  primary: 'Gilroy',
};

export const breakpoint = {
  unitless: {
    ...bps,
  },
  min: {
    small: `${bps.small}px`,
    medium: `${bps.medium}px`,
    large: `${bps.large}px`,
    larger: `${bps.larger}px`,
    xlarge: `${bps.xlarge}px`,
    wide: `${bps.wide}px`,
  },
  max: {
    small: `${bps.small - 1}px`,
    medium: `${bps.medium - 1}px`,
    large: `${bps.large - 1}px`,
    larger: `${bps.larger - 1}px`,
    xlarge: `${bps.xlarge - 1}px`,
    wide: `${bps.wide - 1}px`,
  },
};

export const colour = {
  white: "#FFFFFF",
  grey: "#B1B1B1",
  charcoal: "#363537",
  black: "#000000",
  lightGrey: "#f1f3f4",
  lightGreyOpacity: '#36353733',

  lighterBlue: '#D1F4FF',
  lightBlue: '#41CDFC',
  blue: '#00ADE8',
  midBlue: '#069FE9',
  darkBlue: '#021942',

  lighterGreen: '#D7F0E3',
  lightGreen: '#49D696',
  green: '#2CB673',
  midGreen: '#26A069',
  darkGreen: '#0D3322',

  pink: '#FF42B0',
  darkPink: '#E10185',
  purple: '#440142',

  orange: '#FFB400',
  darkOrange: '#FF7E00',
  brown: '#3F332A',

  red: '#E84855',
};

export const defaultShadow = '6px 6px 6px rgba(54, 53, 55, 0.1)';

export const gradient = {
  blue:
    'linear-gradient(135deg, rgba(65,205,252,1) 0%, rgba(65,205,252,1) 25%, rgba(6,159,233,1) 60%, rgba(6,159,233,1) 100%)',
  orange:
    'linear-gradient(135deg, rgba(255,180,0,1) 0%, rgba(255,180,0,1) 25%, rgba(255,126,0,1) 60%, rgba(255,126,0,1) 100%)',
  charcoal:
    'linear-gradient(135deg, rgba(54,53,55,1) 0%, rgba(48,57,68,1) 71%, rgba(48,57,68,1) 100%)',
  charcoal2:
    'linear-gradient(90deg, rgba(54,53,55,1) 0%, rgba(54,53,55,1) 25%, rgba(48,57,68,1) 60%, rgba(48,57,68,1) 100%)',
  pink:
    'linear-gradient(135deg, rgba(255,66,176,1) 0%, rgba(255,66,176,1) 25%, rgba(225,1,133,1) 60%, rgba(225,1,133,1) 100%)',
  green:
    'linear-gradient(#49d696 0%, #49d696 17.2%, #2cb673 59.6%, #26a069 100%);',
};

export const layout = {
  width: '1225px',
};

export const trans = {
  easeOutExpo: 'cubic-bezier(1,.01,.38,.99)',
};

export const boxShadow = {
  boxShadowLightHeader: '0 20px 20px rgba(54, 53, 55, 0.02)',
  boxShadowHeader: '0px 1px 0px #36353734',
}