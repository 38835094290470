import styled, { css } from 'styled-components';

export const ModalWrap = styled.div`
  div[role='dialog'] {
    padding: 15px 20px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: ${({ alignment, type }) =>
    alignment === 'center' || type === 'action' ? 'center' : 'space-between'};

  svg {
    cursor: pointer;
  }

  ${({ isTall }) =>
    isTall &&
    css`
      position: absolute;
      top: 15px;
      width: calc(100% - 30px);
      left: 15px;
      padding-bottom: 15px;
      background: white;
      z-index: 5;
    `}
`;

export const ModalBody = styled.div`
  text-align: ${({ alignment }) => alignment};

  ${({ isTall }) =>
    isTall &&
    css`
      padding-bottom: 40px;
      padding-top: 40px;
    `}
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    &:last-child {
      margin-left: 15px;
    }
  }

  ${({ isTall }) =>
    isTall &&
    css`
      position: absolute;
      bottom: 15px;
      right: 15px;
      z-index: 5;
      width: calc(100% - 30px);
      background: white;
      padding-top: 15px;
    `}
`;
