import React from 'react';
import Type from '@/elements/Type';
import { Spacer } from '@/elements/Spacer/style';
import { Col, Row } from 'react-styled-flexboxgrid';
import { ClientTaskList } from '@/components/TaskListCard';
import { CompletedTaskList } from '@/components/TaskListCard';
import RiskTable from '@/components/RiskTable';
import InvoiceTable from '@/components/InvoiceTable';
import DocumentListing from '@/components/DocumentListing';
import StepProgress from '@/components/StepProgress';
import { TasksContainer, TasksOuterContainer, StatusBar } from './style';
import ProjectAvatar from '@/components/ProfileAvatar/ProjectAvatar';
import { colour } from '@/styles/variables';

const ProjectBlock = (props) => {
  const {
    id,
    name,
    phase,
    clientTasks,
    risks,
    invoices,
    documents,
    changeRequests,
    pointOfContact,
    clientPointOfContact,
    status,
  } = props;
  const phases = [
    'SOW',
    'Design',
    'Development',
    'Beta',
    'UAT',
    'Release',
    'Warranty',
  ];

  const getStatusColour = () => {
    if (status === 'On Track') {
      return colour.green;
    }

    if (status === 'Okay') {
      return colour.orange;
    }

    if (status === 'Delayed') {
      return colour.red;
    }
  };

  // combine documents between client task and normal documents
  // getting client tasks 
  
  let fullDocuments = [];
  if(documents && documents.length >0) {
    if(documents.length > 1) {
      fullDocuments = [...documents];
    } else {
      if(documents.length === 1) {
        if(documents[0].document) {
          fullDocuments = [...documents];
        }
      }
    }
  }
  
  if(clientTasks && clientTasks.length > 0) {
    clientTasks.forEach(task => {
      if(task.documents && task.documents.length > 0) {
        fullDocuments.push(task.documents[0])
      }
    });
  }

  return (
    <>
      <Row>
        <Col xs={12} style={{display: 'flex', alignItems: 'center'}}>
          <Type.H6>{name}</Type.H6>
          <StatusBar statusColor={getStatusColour()}/>
          <Type.P8 style={{marginLeft: '5px'}} color={getStatusColour()}>Project Status</Type.P8>
        </Col>
      </Row>
      <Spacer desktop={30} />
      <Row>
        <Col xs={12}>
          <StepProgress
            direction="horizontal"
            currentStep={phases.indexOf(phase)}
            steps={phases}
            setActiveStep={() => null}
          ></StepProgress>
          <Spacer desktop={40} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <TasksOuterContainer>
            <TasksContainer>
              <div className="client-task">
                <ClientTaskList
                  title={'Client Tasks'}
                  projectId={id}
                  clientTasks={clientTasks.length > 1 ? clientTasks : clientTasks.length === 1 ? clientTasks[0].taskName !== '' ? clientTasks : null : null}
                />
              </div>
              <div className="completed-task">
                <CompletedTaskList
                  title={'Completed Tasks'}
                  completedTasks={clientTasks?.filter((x) => x.approved)}
                />
              </div>
            </TasksContainer>
          </TasksOuterContainer>
          <Spacer desktop={60} mobile={40} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <RiskTable title={'Key Risks'} risks={risks.length > 1 ? risks : risks.length === 1 ? risks[0].title !== '' ? risks : null : null} />
          <Spacer desktop={60} mobile={40} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <InvoiceTable title={'Invoices'} invoices={invoices.length > 1 ? invoices : invoices.length === 1 ? invoices[0].number ? invoices : null : null} />
          <Spacer desktop={60} mobile={40} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <DocumentListing
            title={'Project Documents'}
            documents={fullDocuments}
            emptyMessage={'There are no documents associated with the project.'}
          />
          <Spacer desktop={60} mobile={40} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <DocumentListing
            title={'Change Requests'}
            documents={changeRequests.length > 1 ? changeRequests : changeRequests.length === 1 ? changeRequests[0].document ? changeRequests : null : null}
            emptyMessage={'You have not submitted any change requests.'}
          />
          <Spacer desktop={60} mobile={40} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          {pointOfContact && (
            <ProjectAvatar {...pointOfContact} heading="Point of Contact" />
          )}
          <Spacer desktop={0} mobile={40} />
        </Col>
        <Col xs={12} sm={6}>
          {clientPointOfContact && (
            <ProjectAvatar
              {...clientPointOfContact}
              heading="Client Point of Contact"
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default ProjectBlock;
