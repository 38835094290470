import styled from 'styled-components';
import { colour } from '@/styles/variables';

export const ProfileWrapper = styled.div`
  display: block;
  align-items: center;
  padding: 15px;
  background-color: #fff;
  border: 1px solid ${colour.lightGreyOpacity};
  border-radius: 10px;
`;

export const AvatarWrapper = styled.div`
  position: relative;
  margin-bottom: 10px;
`;
export const NewDot = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: ${colour.blue};
  height: 12px;
  width: 12px;
  z-index: 2;
`;

export const ProfileInfoWrapper = styled.div`
  .consultant {
    &-name {
      font-size: 16px;
      margin-bottom: 4px;
      line-height: 20px;
    }
    &-time {
      font-size: 14px;
      line-height: 17px;
    }
    &-position {
      font-size: 12px;
      line-height: 17px;
      color: #b1b1b1;
    }
  }
`;
