import styled from "styled-components";
import { breakpoint } from "@/styles/variables";

export const Spacer = styled.div`
  display: block;
  width: 100%;
  height: ${props =>
    props.mobile ? `${props.mobile}px` : `${props.desktop}px`};
  @media (min-width: ${breakpoint.min.medium}) {
    height: ${props =>
      props.desktop ? `${props.desktop}px` : `${props.mobile}px`};
  }
`;
