import styled from 'styled-components';

export const LinksContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  * {
    width: auto;
    display: inline-block;
    cursor: pointer;
    margin-left: 10px;
  }
`;

export const ContactFields = styled.div`
  .form-label {
    flex: 0 0 100%;
    max-width: 100%;
    font-size: 12px;
  }

  .form-row {
    display: flex;
    margin: 10px -10px 0;
  }

  .form-col {
    margin-left: 10px;
    margin-right: 10px;
    width: 100%;

    label {
      font-size: 11px;
    }
  }
`;

export const Separator = styled.div`
  position: relative;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 15px;
  overflow: hidden;
  color: #363537;
  font-size: 13px;

  &::after {
    left: 20px;
    margin-right: -50%;
    background-color: #363537;
    opacity: 0.25;
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 95%;
  }
`;
