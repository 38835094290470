import styled from 'styled-components';
import { colour } from '@/styles/variables';

export const StatusWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #fff;
  border: 1px solid ${colour.lightGreyOpacity};
  border-radius: 10px;

  .icon {
    max-width: 22.5px;
    margin-bottom: 10px;
  }

  h6 {
    font-size: 16px;
    line-height: 1.1;
    margin-bottom: 5px;
  }

  p {
    font-size: 12px;
    line-height: 14px;
  }

  svg {
    color: ${colour.charcoal};
  }
`;

export const StatusContainer = styled.div`
  p{
    color: ${({ statusColor }) => statusColor && statusColor};
    font-weight: 600;
  }
  margin-top: 15px;
`;

export const StatusDot = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: ${({ statusColor }) => statusColor && statusColor};
  margin-bottom: 5px;

  p {
    font-size: 11px;
    line-height: 13px;
  }
`;

export const DropdownIconTrigger = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
`;
