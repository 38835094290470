import styled from "styled-components";
import { colour } from "@/styles/variables";

export const Wrap = styled.div`
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity:0;
  }
  .label {
    display: grid;
    text-align: center;
    color: ${colour.grey};
    padding: 20px;
    margin: auto;
    b {
      display: block;
      span {
        color: ${colour.blue};
      }
    }
  }
  .flex {
    display: flex;
  }
  .file {
    &.label{
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }
    &-wrap{
      @media(max-width: 767px){
        flex-direction: column;
      }
    }
    &-input {
      margin-right: 20px;
      flex-shrink: 0;
      border: 1px dashed ${colour.grey};
      border-radius: 10px;
      display: flex;
      cursor: pointer;
      position: relative;
      transition: border .3s;
      &:hover{
        border-color: ${colour.blue};
      }
      @media(max-width: 767px){
        margin-right: 0;
        margin-bottom: 15px;
      }
    }
    &-progress {
      position: relative;
      overflow: hidden;
      border: 1px solid ${colour.lighterBlue};
      border-radius: 10px;
      transition: border-color 0.3s;
      margin-bottom: 10px;
      &.successful {
        .file-progress-bg {
          opacity: 0;
        }
      }
      &-bg {
        background: ${colour.lighterBlue};
        position: absolute;
        width: 0%;
        height: 100%;
        transition: 0.3s;
      }
      button {
        padding: 0;
        margin-left: 10px;
        svg{margin-right:0}
        &:disabled{
          cursor: not-allowed;
        }
      }
      .label {
        position: relative;
        color: ${colour.black};
        display: flex;
        /* flex-direction: column; */
        text-align: left;
        padding: 20px 10px 20px 20px;
        b {
          white-space: nowrap;
          max-width: 165px;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
      }
      p {
        margin: 0;
        span {
          display: inline-block;
          width: 0%;
          height: 2px;
          border-radius: 2px;
          background: ${colour.blue};
          transition: 0.3s;
        }
      }
    }
  }
 
`;
