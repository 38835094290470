import React, { useState, useContext } from 'react';
import Type from '@/elements/Type';
import { Spacer } from '@/elements/Spacer/style';
import db from '@/utils/database';
import { withRouter } from 'react-router-dom';
import Button from '@/elements/Button';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import Icons from '@/elements/Icons';
import Step from '@/behaviour/Step';
import { fetchEmails } from '@/utils/fetchEmails';
import { colour } from '@/styles/variables';
import InfoCard from '@/components/InfoCard';
import { AuthContext } from '@/context/auth';
import { createNotification } from '@/utils/createNotification';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isValidDate } from '@/utils/dates';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useDraggableInPortal from '../../../utils/useDraggableInPortal';
import {
  TextInput,
  FinalForm,
  SearchInput,
  CheckboxGroup,
  DatePickerInput,
  SelectList,
  WysiwygInput,
} from '@/elements/Form';
import { CheckBoxWrap } from '@/elements/Form/style';
import styled from 'styled-components';
import ProfilePdfTemplate, { ProfilePdfContainer } from './ProfilePdfTemplate';
import { generatePdf } from '@/utils/generatePdf';
import { sendEmail } from '@/utils/sendEmail';

const educationObj = {
  institute: '',
  accolade: '',
  course: '',
  completion: '',
};
const experienceObj = {
  role: '',
  tenureStart: '',
  tenureEnd: '',
  company: '',
  responsibilities: '',
};

const combineSkills = (skills, customSkills) => {
  if (skills) {
    let filteredSkills = skills.filter((entry) => {
      return entry.trim() !== '';
    });
    const skillArr =
      filteredSkills && filteredSkills[0] !== '' ? filteredSkills : [];
    // remove <ul> and <li> and <p> tag from customSkillArr
    const customSkillArr =
      customSkills && customSkills !== '' ? customSkills : '';

    return Array.from(new Set([...skillArr, customSkillArr]));
  } else {
    return [];
  }
};

export const hasEducations = (educations) => {
  return (
    educations &&
    educations.every((edu) => {
      const values = Object.values(edu);
      return values.length && values.some((val) => val !== '');
    })
  );
};

export const hasResponsibilities = (value) => {
  if (value) {
    var htmlObject = document.createElement('div');
    htmlObject.innerHTML = value;
    return htmlObject.textContent.length > 1;
  }
  return false;
};

const SuccessToaster = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileForm = (props) => {
  const [submitting, setSubmitting] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [profileDataOnSubmit, setProfileDataOnSubmit] = useState(undefined);
  const { user } = useContext(AuthContext);
  const renderDraggable = useDraggableInPortal();

  const {
    setActiveStep,
    activeStep,
    profileId,
    reqId,
    setShouldBlockNavigation,
    profile,
    setProfile,
    setSaveDraft,
    activeOrganization,
    skills,
    educations,
    experiences,
    roles,
    setEducations,
    setExperiences,
    requirement,
  } = props;

  const maxStep = 5;

  const pushNotification = async (action) => {
    const pandcEmails = await fetchEmails('p-c');
    const clientEmails = await fetchEmails(
      'client',
      activeOrganization,
      requirement.areas
    );
    const partnerEmails = await fetchEmails('partner', activeOrganization);
    const salesEmails = await fetchEmails('sales', activeOrganization);

    const emails = []
      .concat(partnerEmails, salesEmails, pandcEmails, clientEmails)
      .filter((email) => email !== user.attributes.email);

    emails.map((email) =>
      createNotification(
        `<b>${profile.name}</b>'s profile has been ${action}d`,
        '/profiles?reqId=' + reqId + '&orgId=' + activeOrganization,
        email
      )
    );
  };

  const freezeStepButtonMomentarily = () => {
    setIsAnimating(true);

    setTimeout(() => {
      setIsAnimating(false);
    }, 500);
  };

  const sendEmailToClient = async () => {
    const clientEmails = await fetchEmails(
      'client',
      activeOrganization,
      requirement.areas
    );

    const dom = document.querySelector('#profileHtml');
    let attachment;

    if (dom) {
      const main = dom.cloneNode(true);
      var tmp = document.createElement('body');
      tmp.appendChild(main);
      const html = tmp.innerHTML;
      const base64Pdf = await generatePdf(html);

      attachment = [
        {
          filename: `Appscore - ${requirement.title} - ${profile.name}.pdf`,
          path: base64Pdf,
        },
      ];
    }

    let subject = 'New Profile Created';

    let body = `<p><b>${profile.name}</b>'s profile has been added for the position <b>${requirement.title}</b>. <a href="${window.location.origin}/profiles?reqId=${requirement.id}" target="_blank">Click here to view position.</a></p>`;

    return sendEmail(clientEmails, subject, body, attachment);
  };

  const isEmptyFirst = (array) => {
    if (!array) {
      return [''];
    }

    if (array && !array[0]) {
      return [''];
    }

    let cleanArray = [];

    if (Array.isArray(array)) {
      array.forEach((element) => {
        if (element && element !== undefined) {
          cleanArray.push(element);
        }
      });
    } else {
      return [''];
    }

    return cleanArray;
  };

  const handleSaveDraft = async () => {
    setShouldBlockNavigation(false);
    setSubmitting(true);
    //setIsReview(false);

    let payload = { ...profile };

    const skills = combineSkills(payload['skills'], payload['customSkills']);
    payload['yearsOfExperience'] = parseInt(payload['yearsOfExperience']);
    payload['interviewDate'] = '';
    payload['proposedInterviewDates'] = [''];
    payload['photo'] = '';
    payload['idealStartDate'] = '';
    payload['startDate'] = '';
    payload['status'] = 'draft';
    payload['customSkills'] = payload['customSkills']
      ? payload['customSkills']
      : '';
    payload['domains'] = payload['domains']
      ? isEmptyFirst(payload['domains'])
      : [''];
    payload['certifications'] = payload['certifications']
      ? isEmptyFirst(payload['certifications'])
      : [''];
    payload['skills'] =
      skills && skills.length > 0 && skills[0].length > 0 ? skills : [''];
    payload['consultingRequirement'] = { id: reqId };
    payload['summary'] = profile.summary ? profile.summary : '';
    payload['educations'] = payload['educations']
      ? payload['educations']
      : [educationObj];
    payload['experiences'] = payload['experiences']
      ? payload['experiences']
      : [experienceObj];
    console.log(payload);
    if (profileId) {
      // if update, get profile again
      const res = await db.search('profile', profileId, 'id');
      let currentProfile = { ...res[0] };

      // overwrite the profile with the new one
      payload['_version'] = currentProfile._version;
      payload['_lastChangedAt'] = currentProfile._lastChangedAt;

      await db.update('profile', payload, profileId);
    } else {
      await db.add('profile', payload);
    }

    setSubmitting(false);
    toast(
      <SuccessToaster>
        <Icons.Tick
          style={{
            color: colour.green,
            width: '30px',
            height: '30px',
            marginLeft: '15px',
            marginRight: '20px',
          }}
        />
        <div>
          <Type.P8 bold>Draft Saved!</Type.P8>
          <Type.FieldLabel>
            {payload['name']} has been saved as a draft.
          </Type.FieldLabel>
        </div>
      </SuccessToaster>,
      {
        position: toast.POSITION.TOP_RIGHT,
        className: 'success-bar',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        closeButton: true,
      }
    );
    setSaveDraft(true);
    props.history.push('/profiles?reqId=' + reqId);
  };
  const handleSaveProfile = async () => {
    setShouldBlockNavigation(false);
    setSubmitting(true);
    //setIsReview(false);
    let payload = { ...profile };
    const skills = combineSkills(payload['skills'], payload['customSkills']);
    payload['yearsOfExperience'] = parseInt(payload['yearsOfExperience']);
    payload['interviewDate'] = '';
    payload['proposedInterviewDates'] = [''];
    payload['photo'] = '';
    payload['idealStartDate'] = '';
    payload['startDate'] = '';
    payload['status'] = payload['status']
      ? payload['status'] === 'draft'
        ? 'new'
        : payload['status']
      : 'new';
    payload['customSkills'] = payload['customSkills']
      ? payload['customSkills']
      : '';
    payload['domains'] = payload['domains']
      ? isEmptyFirst(payload['domains'])
      : [''];
    payload['certifications'] = payload['certifications']
      ? isEmptyFirst(payload['certifications'])
      : [''];
    payload['skills'] =
      skills && skills.length > 0 && skills[0].length > 0 ? skills : [''];
    payload['consultingRequirement'] = { id: reqId };
    payload['summary'] = profile.summary ? profile.summary : '';
    payload['educations'] = payload['educations']
      ? payload['educations']
      : [educationObj];
    payload['experiences'] = payload['experiences']
      ? payload['experiences']
      : [experienceObj];

    if (profileId) {
      // if update, get profile again
      const res = await db.search('profile', profileId, 'id');
      let currentProfile = { ...res[0] };

      // overwrite the profile with the new one
      payload['_version'] = currentProfile._version;
      payload['_lastChangedAt'] = currentProfile._lastChangedAt;

      await db.update('profile', payload, profileId);
      pushNotification('update');
    } else {
      setProfileDataOnSubmit(payload);
      await db.add('profile', payload);
      pushNotification('create');
      await sendEmailToClient();
    }

    setSubmitting(false);
    props.history.push('/profiles?reqId=' + reqId);
  };

  const makeOnDragEndFunction = (fields, form) => (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    fields.move(result.source.index, result.destination.index);
    const values = form.getState().values;
    form.restart(values);
    setProfile({ ...profile, ...values });
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    background: 'white',
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => {
    const container = document.querySelector('#droppableContainer');
    return {
      background: isDraggingOver ? colour.lightGrey : 'white',
      marginBottom: 10,
      height: isDraggingOver
        ? container
          ? container.clientHeight
          : '100vh'
        : '100%',
    };
  };

  return (
    <Wrap>
      {/* {JSON.stringify(profile)} */}
      <ToastContainer style={{ top: '6.2rem' }} />
      {/* Step 1 */}
      <Step active={activeStep === 0}>
        <Type.H4>Add Candidate - Step 1</Type.H4>
        <Type.P>All fields are mandatory unless stated otherwise.</Type.P>
        <hr />
        <Type.H6>Overview</Type.H6>
        <FinalForm
          initialValues={{
            ...profile,
            domains: profile.domains ? profile.domains : [''],
          }}
          onSubmit={(values) => {
            setProfile({ ...profile, ...values });
            if (!values.isSave) {
              if (values.isReview && values.isReview === true) {
                setActiveStep(maxStep);
              } else {
                setActiveStep(activeStep + 1);
              }
            } else {
              setActiveStep(parseInt(values.jumpStep));
            }

            setShouldBlockNavigation(true);
            setSaveDraft(false);
            freezeStepButtonMomentarily();
          }}
          mutators={{
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
            ...arrayMutators,
          }}
          render={({
            handleSubmit,
            form: {
              mutators: { push, pop },
            },
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              {/* {JSON.stringify(values)} */}
              <TextInput
                name="name"
                value={values.name}
                component="input"
                type="text"
                validationType="required"
                label="Consultant's name"
                placeholder="Enter name"
                showAppend
              />

              <SelectList
                name="yearsOfExperience"
                label="Years of experience"
                validationType="required"
                component="select"
                value={values.yearsOfExperience}
              >
                <option disabled value="">
                  0
                </option>
                {Array(30)
                  .fill(0)
                  .map((x, i) => (
                    <option key={i + 1 + 'y'} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
              </SelectList>

              {/*<TextArea
                  name="summary"
                  value={values.summary}
                  label="Summary"
                  //validationType="isExceed-600"
                  component="textarea"
                  //maxLength="600"
                  placeholder="What team and work environment will our consultant will be working in."
                />*/}
              <WysiwygInput
                name="summary"
                value={values.summary}
                initialValue={profile.summary}
                label="Summary"
                //validationType="isExceed-600"
                component="textarea"
                //maxLength="600"
                placeholder="What team and work environment will our consultant will be working in."
              />

              <FieldArray name="domains">
                {({ fields }) =>
                  fields.map((x, i) => (
                    <div key={x + i}>
                      {fields.length > 1 && (
                        <Button
                          style={{ marginTop: 32, float: 'right' }}
                          colour={colour.red}
                          simple
                          type="button"
                          onClick={() => fields.remove(i)}
                        >
                          <Icons.Minus />
                        </Button>
                      )}
                      <TextInput
                        name={x}
                        value={fields.value[i]}
                        component="input"
                        type="text"
                        label="Domain Experience"
                        placeholder="Enter a domain"
                        showAppend
                      />
                    </div>
                  ))
                }
              </FieldArray>

              <Button
                simple
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  push('domains', '');
                }}
                style={{ display: 'block' }}
              >
                + Add Another
              </Button>

              <Button
                type="submit"
                hidden
                className={`${isAnimating ? 'disabled' : ''} save`}
                style={{ display: 'none' }}
                onClick={(i) => {
                  values.isSave = true;
                  values.jumpStep = i.currentTarget.getAttribute('step');
                }}
              />
              <SubmitButton
                type="submit"
                withIcon
                className={`${isAnimating ? 'disabled' : ''}`}
                onClick={() => {
                  values.isReview = false;
                  values.isSave = false;
                }}
              >
                Next <Icons.ArrowRight />
              </SubmitButton>
              <ReviewButton
                type="submit"
                onClick={() => {
                  values.isReview = true;
                  values.isSave = false;
                }}
              >
                Review
              </ReviewButton>
            </form>
          )}
        ></FinalForm>
      </Step>

      {/* Step 2 */}
      <Step active={activeStep === 1}>
        <Type.H4>Add Candidate - Step 2</Type.H4>
        <Type.P>All fields are mandatory unless stated otherwise.</Type.P>
        <hr />
        <FinalForm
          onSubmit={(values) => {
            // console.log(values);
            setProfile({
              ...profile,
              ...values,
              customSkills: values.customSkills ? values.customSkills : '',
            });
            if (!values.isSave) {
              if (values.isReview && values.isReview === true) {
                setActiveStep(maxStep);
              } else {
                setActiveStep(activeStep + 1);
              }
            } else {
              setActiveStep(parseInt(values.jumpStep));
            }
            setShouldBlockNavigation(true);
            setSaveDraft(false);
            freezeStepButtonMomentarily();
          }}
          initialValues={{
            skills: profile.skills ? profile.skills : [''],
            customSkills: profile.customSkills ? profile.customSkills : '',
          }}
          mutators={{
            ...arrayMutators,
          }}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit}>
              {/* {JSON.stringify(values)} */}
              {skills.length > 0 ? (
                <CheckboxGroup
                  name="skills"
                  value={values.skills}
                  // sort alphabetically
                  options={skills
                    .map((x) => x.name)
                    .sort((a, b) =>
                      a.toUpperCase() > b.toUpperCase() ? 1 : -1
                    )}
                  label="Skills / Tech (Optional)"
                />
              ) : (
                <>
                  There are no skills set up in this position yet.
                  <Spacer desktop={20} />
                </>
              )}
              <WysiwygInput
                name="customSkills"
                value={values.customSkills}
                initialValue={profile.customSkills}
                label="Custom Skills / Tech (Optional)"
                //validationType="isExceed-600"
                component="textarea"
                //maxLength="600"
                placeholder="Enter skills separated by comma"
              />
              {/*<TextInput
                name="customSkills"
                value={values.customSkills}
                component="input"
                type="text"
                label="Custom Skills / Tech (Optional)"
                placeholder="Enter skills separated by comma"
                showAppend
              />*/}
              <Button
                type="submit"
                hidden
                className="save"
                style={{ display: 'none' }}
                onClick={(i) => {
                  values.isSave = true;
                  values.jumpStep = i.currentTarget.getAttribute('step');
                }}
              />
              <BackButton
                type="button"
                className={`${isAnimating ? 'disabled' : ''} backStep`}
                step={activeStep - 1}
                withIcon
                onClick={(i) => {
                  setSaveDraft(false);
                  setActiveStep(parseInt(i.currentTarget.getAttribute('step')));
                  freezeStepButtonMomentarily();
                }}
              >
                <Icons.ArrowLeftBlue /> Back
              </BackButton>
              <SubmitButton
                type="submit"
                withIcon
                className={`${isAnimating ? 'disabled' : ''}`}
                onClick={() => {
                  values.isReview = false;
                  values.isSave = false;
                }}
              >
                Next <Icons.ArrowRight />
              </SubmitButton>
              <ReviewButton
                type="submit"
                onClick={() => {
                  values.isReview = true;
                }}
              >
                Review
              </ReviewButton>
            </form>
          )}
        ></FinalForm>
      </Step>

      {/* Step 3 */}
      <Step active={activeStep === 2}>
        <Type.H4>Add Candidate - Step 3</Type.H4>
        <Type.P>All fields are mandatory unless stated otherwise.</Type.P>
        <hr />
        <FinalForm
          onSubmit={(values) => {
            let payload = { ...values };
            payload.educations.map((x) => {
              return x.completion ? x.completion : x;
            });
            setEducations(payload);
            setProfile({ ...profile, ...payload });
            if (!values.isSave) {
              if (values.isReview && values.isReview === true) {
                setActiveStep(maxStep);
              } else {
                setActiveStep(activeStep + 1);
              }
            } else {
              setActiveStep(parseInt(values.jumpStep));
            }
            setShouldBlockNavigation(true);
            setSaveDraft(false);
            freezeStepButtonMomentarily();
          }}
          initialValues={educations}
          mutators={{
            ...arrayMutators,
          }}
          render={({
            handleSubmit,
            values,
            form: {
              mutators: { push, pop },
            },
          }) => (
            <form onSubmit={handleSubmit}>
              {/* {JSON.stringify(values)} */}
              <FieldArray name="educations">
                {({ fields }) =>
                  fields.map((x, i) => (
                    <div key={x + i}>
                      {/* {JSON.stringify(fields, x)} */}
                      <Type.H6>
                        Education {fields.length > 1 ? i + 1 : ''}
                        {i > 0 && (
                          <Button
                            colour={colour.red}
                            simple
                            type="button"
                            onClick={() => fields.remove(i)}
                          >
                            <Icons.Minus />
                          </Button>
                        )}
                      </Type.H6>
                      <TextInput
                        name={`${x}.institute`}
                        value={fields.value[i].institute}
                        component="input"
                        type="text"
                        label="Institute"
                        placeholder="University, college or school"
                        showAppend
                      />
                      <TextInput
                        name={`${x}.accolade`}
                        value={fields.value[i].accolade}
                        component="input"
                        type="text"
                        label="Accolade"
                        placeholder="E.g. BA, BSc, MA, MSc"
                        showAppend
                      />
                      <TextInput
                        name={`${x}.course`}
                        value={fields.value[i].course}
                        component="input"
                        type="text"
                        label="Course / Subject"
                        placeholder="E.g. Computer Science"
                        showAppend
                      />
                      <TextInput
                        name={`${x}.completion`}
                        value={fields.value[i].completion}
                        component="input"
                        type="text"
                        validationType="isNumber"
                        label="Completion"
                        placeholder="Year"
                        showAppend
                      />
                    </div>
                  ))
                }
              </FieldArray>
              {hasEducations(values.educations) && (
                <Button
                  simple
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    push('educations', educationObj);
                  }}
                  style={{ display: 'block' }}
                >
                  + Add Another
                </Button>
              )}
              <Button
                type="submit"
                hidden
                className="save"
                style={{ display: 'none' }}
                onClick={(i) => {
                  values.isSave = true;
                  values.jumpStep = i.currentTarget.getAttribute('step');
                }}
              />
              <BackButton
                type="button"
                className={`${isAnimating ? 'disabled' : ''} backStep`}
                step={activeStep - 1}
                withIcon
                onClick={(i) => {
                  setSaveDraft(false);
                  setActiveStep(parseInt(i.currentTarget.getAttribute('step')));
                  freezeStepButtonMomentarily();
                }}
              >
                <Icons.ArrowLeftBlue /> Back
              </BackButton>
              <SubmitButton
                type="submit"
                withIcon
                className={`${isAnimating ? 'disabled' : ''}`}
                onClick={() => {
                  values.isReview = false;
                  values.isSave = false;
                }}
              >
                Next <Icons.ArrowRight />
              </SubmitButton>
              <ReviewButton
                type="submit"
                onClick={() => {
                  values.isReview = true;
                  values.isSave = false;
                }}
              >
                Review
              </ReviewButton>
            </form>
          )}
        ></FinalForm>
      </Step>

      {/* Step 4 */}
      <Step active={activeStep === 3}>
        <Type.H4>Add Candidate - Step 4</Type.H4>
        <Type.P>All fields are mandatory unless stated otherwise.</Type.P>
        <hr />
        <FinalForm
          onSubmit={(values) => {
            let payload = { ...values };
            //check responsibilities field
            payload.experiences.map((x) => {
              return hasResponsibilities(x.responsibilities)
                ? x
                : (x.responsibilities = '');
            });
            setExperiences(payload);
            setProfile({ ...profile, ...payload });
            if (!values.isSave) {
              if (values.isReview && values.isReview === true) {
                setActiveStep(maxStep);
              } else {
                setActiveStep(activeStep + 1);
              }
            } else {
              setActiveStep(parseInt(values.jumpStep));
            }
            setShouldBlockNavigation(true);
            setSaveDraft(false);
            freezeStepButtonMomentarily();
          }}
          initialValues={experiences}
          mutators={{
            ...arrayMutators,
          }}
          render={({
            handleSubmit,
            values,
            form: {
              mutators: { push, pop, update },
            },
            form,
          }) => (
            <form onSubmit={handleSubmit}>
              {/* {JSON.stringify(values)} */}
              <FieldArray name="experiences">
                {({ fields }) => (
                  <DragDropContext
                    onDragEnd={makeOnDragEndFunction(fields, form)}
                  >
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          id="droppableContainer"
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {fields.map((x, i) => (
                            <Draggable
                              key={i}
                              draggableId={'draggable-' + i}
                              index={i}
                            >
                              {renderDraggable((provided, snapshot) => (
                                <ExpBlock
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <Type.H6>
                                    Experience {fields.length > 1 ? i + 1 : ''}
                                    {i > 0 && (
                                      <Button
                                        simple
                                        type="button"
                                        colour={colour.red}
                                        onClick={() => fields.remove(i)}
                                      >
                                        <Icons.Minus />
                                      </Button>
                                    )}
                                  </Type.H6>
                                  <div className="exp-block">
                                    <SearchInput
                                      name={`${x}.role`}
                                      value={fields.value[i].role}
                                      component="input"
                                      type="text"
                                      //validationType="required"
                                      label="Role"
                                      placeholder="Role"
                                      options={roles}
                                      optionKey="name"
                                      showAppend
                                    />

                                    <div className="tenure">
                                      <DatePickerInput
                                        name={`${x}.tenureStart`}
                                        value={
                                          isValidDate(
                                            fields.value[i].tenureStart
                                          )
                                            ? fields.value[i].tenureStart
                                            : ''
                                        }
                                        type="text"
                                        showMonthYearPicker
                                        showFullMonthYearPicker
                                        dateFormat="MM/yyyy"
                                        label="Tenure (from - to)"
                                        //validationType="required"
                                        placeholder="MM/YYYY"
                                        maxDate={
                                          isValidDate(fields.value[i].tenureEnd)
                                            ? fields.value[i].tenureEnd
                                            : ''
                                        }
                                        showAppend
                                      />
                                      {fields.value[i].tenureEnd !==
                                      'Present' ? (
                                        <DatePickerInput
                                          name={`${x}.tenureEnd`}
                                          value={
                                            isValidDate(
                                              fields.value[i].tenureEnd
                                            )
                                              ? fields.value[i].tenureEnd
                                              : ''
                                          }
                                          type="text"
                                          showMonthYearPicker
                                          showFullMonthYearPicker
                                          dateFormat="MM/yyyy"
                                          //validationType="required"
                                          placeholder="MM/YYYY"
                                          //minDate={isValidDate(fields.value[i].tenureStart) ? fields.value[i].tenureStart : ''}
                                          showAppend
                                        />
                                      ) : (
                                        <TextInput
                                          name={`${x}.tenureEnd`}
                                          value={'Present'}
                                          component="input"
                                          type="text"
                                          //validationType="required"
                                          disabled
                                          showAppend
                                        />
                                      )}
                                    </div>
                                    <CheckBoxWrap
                                      style={{
                                        marginTop: 0,
                                        marginBottom: 15,
                                        padding: 0,
                                        display: 'block',
                                      }}
                                    >
                                      <div className="input-checkbox ">
                                        <input
                                          type="checkbox"
                                          id="check-xx"
                                          onChange={(e) => {
                                            let payload = {
                                              ...fields.value[i],
                                              tenureEnd: e.target.checked
                                                ? 'Present'
                                                : '',
                                            };
                                            update('experiences', i, payload);
                                          }}
                                          checked={
                                            fields.value[i].tenureEnd ===
                                            'Present'
                                          }
                                        />
                                        <span className="square"></span>
                                        <span className="label">Present</span>
                                      </div>
                                    </CheckBoxWrap>
                                    <TextInput
                                      name={`${x}.company`}
                                      value={fields.value[i].company}
                                      component="input"
                                      type="text"
                                      //validationType="required"
                                      label="Company"
                                      placeholder="Company"
                                      showAppend
                                    />
                                    <WysiwygInput
                                      name={`${x}.responsibilities`}
                                      value={fields.value[i].responsibilities}
                                      initialValue={
                                        profile.experiences
                                          ? profile.experiences[i]
                                            ? profile.experiences[i]
                                                .responsibilities
                                            : ''
                                          : ''
                                      }
                                      label="Responsibilities"
                                      component="textarea"
                                      placeholder="Describe responsibilities"
                                    />
                                  </div>
                                </ExpBlock>
                              ))}
                            </Draggable>
                          ))}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
              </FieldArray>
              <Button
                simple
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  push('experiences', experienceObj);
                }}
                style={{ display: 'block' }}
              >
                + Add Another
              </Button>
              <Button
                type="submit"
                hidden
                className="save"
                style={{ display: 'none' }}
                onClick={(i) => {
                  values.isSave = true;
                  values.jumpStep = i.currentTarget.getAttribute('step');
                }}
              />
              <BackButton
                type="button"
                className={`${isAnimating ? 'disabled' : ''} backStep`}
                step={activeStep - 1}
                withIcon
                onClick={(i) => {
                  setSaveDraft(false);
                  setActiveStep(parseInt(i.currentTarget.getAttribute('step')));
                  freezeStepButtonMomentarily();
                }}
              >
                <Icons.ArrowLeftBlue /> Back
              </BackButton>
              <SubmitButton
                type="submit"
                withIcon
                className={`${isAnimating ? 'disabled' : ''}`}
                onClick={() => {
                  values.isReview = false;
                  values.isSave = false;
                }}
              >
                Next <Icons.ArrowRight />
              </SubmitButton>
              <ReviewButton
                type="submit"
                onClick={() => {
                  values.isReview = true;
                  values.isSave = false;
                }}
              >
                Review
              </ReviewButton>
            </form>
          )}
        ></FinalForm>
      </Step>

      {/* Step 5 */}
      <Step active={activeStep === 4}>
        <Type.H4>Add Candidate - Step 5</Type.H4>
        <Type.P>All fields are mandatory unless stated otherwise.</Type.P>
        <hr />
        <Type.H6>Certifications</Type.H6>
        <FinalForm
          initialValues={{
            ...profile,
            certifications: profile.certifications
              ? profile.certifications
              : [''],
          }}
          onSubmit={(values) => {
            setProfile({ ...profile, ...values });
            if (!values.isSave) {
              if (values.isReview && values.isReview === true) {
                setActiveStep(maxStep);
              } else {
                setActiveStep(activeStep + 1);
              }
            } else {
              setActiveStep(parseInt(values.jumpStep));
            }
            setShouldBlockNavigation(true);
            setSaveDraft(false);
            freezeStepButtonMomentarily();
          }}
          mutators={{
            ...arrayMutators,
          }}
          render={({
            handleSubmit,
            form: {
              mutators: { push, pop },
            },
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              {/* {JSON.stringify(values)} */}

              <FieldArray name="certifications">
                {({ fields }) =>
                  fields.map((x, i) => (
                    <div key={x + i}>
                      {fields.length > 1 && (
                        <Button
                          style={{ marginTop: 19, float: 'right' }}
                          colour={colour.red}
                          simple
                          type="button"
                          onClick={() => fields.remove(i)}
                        >
                          <Icons.Minus />
                        </Button>
                      )}
                      <TextInput
                        name={x}
                        value={fields.value[i]}
                        component="input"
                        type="text"
                        label=""
                        placeholder="Enter a certification"
                        showAppend
                      />
                    </div>
                  ))
                }
              </FieldArray>

              <Button
                simple
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  push('certifications', '');
                }}
                style={{ display: 'block' }}
              >
                + Add Another
              </Button>
              <Button
                type="submit"
                hidden
                className="save"
                style={{ display: 'none' }}
                onClick={(i) => {
                  values.isSave = true;
                  values.jumpStep = i.currentTarget.getAttribute('step');
                }}
              />
              <BackButton
                type="button"
                className={`${isAnimating ? 'disabled' : ''} backStep`}
                step={activeStep - 1}
                withIcon
                onClick={(i) => {
                  setSaveDraft(false);
                  setActiveStep(parseInt(i.currentTarget.getAttribute('step')));
                  freezeStepButtonMomentarily();
                }}
              >
                <Icons.ArrowLeftBlue /> Back
              </BackButton>
              <SubmitButton
                type="submit"
                withIcon
                className={`${isAnimating ? 'disabled' : ''}`}
                onClick={() => {
                  values.isReview = false;
                  values.isSave = false;
                }}
              >
                Next <Icons.ArrowRight />
              </SubmitButton>
              <ReviewButton
                type="submit"
                onClick={() => {
                  values.isReview = true;
                  values.isSave = false;
                }}
              >
                Review
              </ReviewButton>
            </form>
          )}
        ></FinalForm>
      </Step>

      {/* Step 6 */}
      <Step active={activeStep === 5}>
        {activeStep === 5 && profileDataOnSubmit && (
          <ProfilePdfContainer id="profileHtml">
            <ProfilePdfTemplate
              profile={profileDataOnSubmit}
              positionName={requirement.title}
            />
          </ProfilePdfContainer>
        )}
        <Type.H4>Add Candidate - Step 6</Type.H4>
        <Type.P>Is this information correct?</Type.P>
        <hr />
        <InfoCard
          title="Overview"
          handleEditClick={(e) => setActiveStep(0)}
          items={[
            { title: "Consultant's name", content: profile.name },
            {
              title: 'years of Experience',
              content: profile.yearsOfExperience,
            },
            {
              title: 'Summary',
              content: profile.summary,
            },
            {
              title: 'Domain Experience',
              content: profile.domains && profile.domains.join(', '),
            },
          ]}
        />
        <br />
        {combineSkills(profile.skills, profile.customSkills).length > 0 && (
          <InfoCard
            title="Skills"
            handleEditClick={(e) => setActiveStep(1)}
            items={[
              {
                title: 'Skills',
                content: combineSkills(
                  profile.skills,
                  profile.customSkills
                ).join(', '),
              },
            ]}
          />
        )}
        <br />
        {hasEducations(profile.educations) && (
          <InfoCard
            title="Education"
            handleEditClick={(e) => setActiveStep(2)}
            educations={profile.educations}
          />
        )}
        <br />
        {profile.experiences && profile.experiences.length > 0 && (
          <InfoCard
            title="Experiences"
            handleEditClick={(e) => setActiveStep(3)}
            experiences={profile.experiences}
          />
        )}
        <br />
        {profile.certifications &&
          profile.certifications.length > 0 &&
          profile.certifications[0] !== '' &&
          !profile.certifications[0] && (
            <InfoCard
              title="Certifications"
              handleEditClick={(e) => setActiveStep(4)}
              items={[
                {
                  title: 'Certifications',
                  content: profile.certifications
                    ? profile.certifications.join(', ')
                    : '',
                },
              ]}
            />
          )}

        <Button
          type="submit"
          hidden
          className="save"
          style={{ display: 'none' }}
          onClick={(i) => {
            setActiveStep(parseInt(i.currentTarget.getAttribute('step')));
          }}
        />
        <BackButton
          type="button"
          className={`${isAnimating ? 'disabled' : ''} backStep`}
          step={activeStep - 1}
          withIcon
          onClick={(i) => {
            setSaveDraft(false);
            setActiveStep(parseInt(i.currentTarget.getAttribute('step')));
            freezeStepButtonMomentarily();
          }}
        >
          <Icons.ArrowLeftBlue /> Back
        </BackButton>
        <SubmitButton
          type="submit"
          withIcon
          disabled={submitting}
          onClick={handleSaveProfile}
        >
          Publish <Icons.ArrowRight />
        </SubmitButton>
        {profile['status'] === 'draft' ||
        profile['status'] === 'new' ||
        !profile['status'] ? (
          <ReviewButton
            type="submit"
            disabled={submitting}
            onClick={handleSaveDraft}
          >
            Save as draft
          </ReviewButton>
        ) : (
          ''
        )}
      </Step>

      <Spacer desktop={20} />
    </Wrap>
  );
};

export default withRouter(ProfileForm);

const SubmitButton = styled(Button)`
  margin-top: 30px;
  float: right;
`;

const BackButton = styled(Button)`
  margin-top: 30px;
  position: absolute;
  left: 0;
  box-shadow: none;
  background: none;
  color: ${colour.blue};
`;

const ReviewButton = styled(Button)`
  margin-top: 30px;
  float: right;
  background-color: transparent;
  color: ${colour.blue};
  margin-right: 20px;
  border-color: ${colour.blue};
`;

const Wrap = styled.div`
  max-width: 640px;
  margin-bottom: 100px;
`;

const ExpBlock = styled.div`
  margin: 0 -10px;
  padding: 10px;
  h6 {
    margin-bottom: 10px;
    display: flex;
  }
  .tenure {
    display: flex;
    align-items: flex-end;
    & > div:first-child {
      margin-right: 20px;
    }
  }
`;
