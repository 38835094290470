import React, { useState } from 'react';
import {
  RiskTableWrapper,
  StatusText,
  ViewLink,
  RiskModalContainer,
  RiskModalTitle,
  RiskItemDescContainer,
  RiskModalRow,
  TableTitleContainer,
  FilterButton,
} from './style';
import { TableHead, HeaderCell, TableCell, TableRow } from '../Table/style';
import { Table, Button, SelectMenu } from 'evergreen-ui';
import Type from '@/elements/Type';
import { formatDateForHumans } from '@/utils/dates';
import Modal from '@/elements/Modal';
import { colour } from '@/styles/variables';
import Icons from '@/elements/Icons';
import Spacer from '@/elements/Spacer';
import { capitalize } from 'lodash';

const InvoiceTable = (props) => {
  const { title, invoices } = props;

  const renderStatus = (status) => {
    if (status === 'Past due') {
      return (
        <StatusText>
          <span style={{ color: colour.red }}>{status.replace('-', ' ')}</span>
        </StatusText>
      );
    } else if (status === 'Paid') {
      return (
        <StatusText>
          <span style={{ color: colour.green }}>{status}</span>
        </StatusText>
      );
    } else {
      return (
        <StatusText>
          <span>{status}</span>
        </StatusText>
      );
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeInvoiceIndex, setActInvoiceIndex] = useState(0);

  const [activeFilter, setActiveFilter] = useState('all');

  if (!invoices) {
    return (
      <RiskTableWrapper>
        <Type.H6>{title}</Type.H6>
        <Spacer desktop={10} />
        <Type.P8>There are no invoices associated with the project.</Type.P8>
      </RiskTableWrapper>
    );
  }

  const allFilters = invoices
    .map((item) => item.status)
    .filter((value, index, self) => self.indexOf(value) === index);

  const activeInvoice = invoices[activeInvoiceIndex];

  return (
    <RiskTableWrapper>
      <TableTitleContainer>
        <Type.H6 style={{ marginRight: '10px' }}>{title}</Type.H6>
        <SelectMenu
          title="Filter:"
          hasFilter={false}
          options={[
            { label: 'All', value: 'all' },
            ...allFilters.map((label) => ({
              label: capitalize(label.replace('-', ' ')),
              value: label,
            })),
          ]}
          selected={activeFilter}
          onSelect={(item) => setActiveFilter(item.value)}
          closeOnSelect
        >
          <Button appearance="minimal" height={16}>
            <FilterButton>
              {activeFilter.replace('-', ' ')}
              <Icons.ChevronDown />
            </FilterButton>
          </Button>
        </SelectMenu>
      </TableTitleContainer>
      <Spacer desktop={10} />
      <Table>
        <TableHead paddingX={0}>
          <HeaderCell
            flexBasis={'33%'}
            flexShrink={0}
            flexGrow={0}
            paddingX={0}
          >
            Date
          </HeaderCell>
          <HeaderCell hiddenmobile="true">Invoice #</HeaderCell>
          <HeaderCell>Amount</HeaderCell>
          <HeaderCell>Status</HeaderCell>
          <HeaderCell
            flexBasis={50}
            flexShrink={0}
            flexGrow={0}
            paddingX={0}
          ></HeaderCell>
        </TableHead>
        <Table.Body>
          {invoices
            .filter((i) => {
              if (activeFilter === 'all') {
                return true;
              }
              return activeFilter === i.status;
            })
            .map((invoice, index) => (
              <TableRow
                key={index}
                isSelectable
                onSelect={() => {
                  setIsModalOpen(true);
                  setActInvoiceIndex(index);
                }}
              >
                <TableCell
                  flexBasis={'33%'}
                  flexShrink={0}
                  flexGrow={0}
                  paddingX={0}
                >
                  {formatDateForHumans(new Date(invoice.dueDate))}
                </TableCell>
                <TableCell hiddenmobile="true">{invoice.number}</TableCell>
                <TableCell>${invoice.amount}</TableCell>
                <TableCell>
                  <b>{renderStatus(invoice.status)}</b>
                </TableCell>
                <TableCell
                  flexBasis={50}
                  flexShrink={0}
                  flexGrow={0}
                  paddingX={0}
                >
                  <ViewLink>
                    <span className="hidden-mobile">View</span>
                    <Icons.ChevronRight />
                  </ViewLink>
                </TableCell>
              </TableRow>
            ))}
        </Table.Body>
      </Table>
      <Modal
        title="Invoice"
        isShown={isModalOpen}
        handleModalState={setIsModalOpen}
        noCancel={true}
      >
        <RiskModalContainer>
          <RiskModalTitle>
            <Type.H6>#{activeInvoice.number}</Type.H6>
            <Type.P>
              {formatDateForHumans(new Date(activeInvoice.dueDate))}
            </Type.P>
          </RiskModalTitle>
          <RiskModalRow>
            <RiskItemDescContainer>
              <Type.P className="title">Amount</Type.P>${activeInvoice.amount}
            </RiskItemDescContainer>
            <RiskItemDescContainer>
              <Type.P className="title">Status</Type.P>
              <Type.P>
                <b>{renderStatus(activeInvoice.status)}</b>
              </Type.P>
            </RiskItemDescContainer>
          </RiskModalRow>
          {activeInvoice.invoice && activeInvoice.invoice.length !== 0 && (
            <RiskItemDescContainer>
              <Type.P8 className="title">Invoice</Type.P8>
              {activeInvoice.invoice[0]?.name !== '' ? (
                activeInvoice.invoice.map((x, i) => (
                  <Type.P8 key={i}>
                    <a href={x.url} target="_blank" rel="noopener noreferrer">
                      <Icons.Download
                        style={{ verticalAlign: 'middle', marginRight: 10 }}
                      />
                      {x.name}
                    </a>
                  </Type.P8>
                ))
              ) : (
                <Type.P8>Nil</Type.P8>
              )}
            </RiskItemDescContainer>
          )}
        </RiskModalContainer>
      </Modal>
    </RiskTableWrapper>
  );
};

export default InvoiceTable;
