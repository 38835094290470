import React, { useState } from 'react';
import Button from '@/elements/Button';
import { TextInput, FinalForm } from '@/elements/Form';
import arrayMutators from 'final-form-arrays';
import { CheckboxGroup } from '@/elements/Form';
import { ErrorBox } from '@/elements/ErrorBox/style';
import { SelectList } from '@/elements/Form';
import { API, Auth } from 'aws-amplify';
import { generatePassword } from '@/utils/passwordGenerator';
import { Spacer } from '@/elements/Spacer/style';
import { CheckboxGroupClients } from '@/elements/Form';
import { PhotoUploadField } from '@/elements/Form';
import { s3Upload } from '@/utils/awsLib';
import { isUserEditProfileNotAdmin } from '@/utils/isUserAdmin';

export const AddUser = (props) => {
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const { setActiveView, setUpdated, organizations, loginUser } = props;

  const onSubmit = async (values) => {
    const {
      profileImage,
      name,
      email,
      areas,
      userGroup,
      organization,
      organizationClient,
      phoneNumber,
      position,
    } = values;

    if (submitting) {
      return;
    }

    setSubmitting(true);
    setError('');

    let image = undefined;

    if (profileImage) {
      image = await s3Upload('userPhotos', profileImage);
    }

    const dataToSend = {
      username: email.toLowerCase(),
      name,
      phoneNumber,
      organization: '',
      userGroup,
      areas: '',
      position,
      profileImage: image,
      temPpassword: generatePassword(),
    };

    if (areas && userGroup === 'client') {
      dataToSend.areas = JSON.stringify(areas ? areas : []);
    }

    if (
      organization &&
      organization.length > 0 &&
      (userGroup === 'sales' ||
        userGroup === 'observer' ||
        userGroup === 'partner')
    ) {
      dataToSend.organization = organization.join();
    }

    if (userGroup === 'client') {
      dataToSend.organization = organizationClient;
    }

    let apiName = 'AdminQueries';
    let path = '/createUser';
    let pathAddToGroup = '/addUserToGroup';
    let headers = {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    };
    let myInit = {
      headers,
      body: dataToSend,
    };
    let addToGroupData = {
      headers,
      body: {
        username: email.toLowerCase(),
        groupname: 'Admins',
      },
    };

    try {
      await API.post(apiName, path, myInit);

      if (userGroup === 'admin' || userGroup === 'PM' || userGroup === 'p-c' || userGroup === 'sales') {
        await API.post(apiName, pathAddToGroup, addToGroupData);
      }
      setSubmitting(false);
      setUpdated(true);
      setActiveView('table');
    } catch (error) {
      setSubmitting(false);
      if (error.response.status === 400) {
        setError(error.response.data.message);
      } else {
        setError(
          'An unknown error occured. Please contact the site administrator.'
        );
      }
    }
  };

  return (
    <>
      {error && (
        <ErrorBox>
          <>{error}</>
        </ErrorBox>
      )}
      <FinalForm
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
          resetAreas: (args, state, utils) => {
            utils.changeValue(state, 'areas', () => []);
          },
        }}
        render={({ form, handleSubmit, values, valid }) => {
          return (
            <form onSubmit={handleSubmit}>
              <PhotoUploadField
                name="profileImage"
                component="input"
                label="User Photo"
                defaultImage={values.profileImage}
              />
              <TextInput
                name="name"
                label="Name"
                component="input"
                type="text"
                validationType="required"
                placeholder="John Doe"
              />
              <TextInput
                name="email"
                label="Email Address"
                component="input"
                type="text"
                validationType="required"
                placeholder="John@example.com"
              />
              <TextInput
                name="position"
                label="Position Title"
                component="input"
                type="text"
                validationType="required"
                placeholder="Project Manager"
              />
              <TextInput
                name="phoneNumber"
                label="Phone Number"
                component="input"
                type="text"
                validationType="required"
                placeholder="0411111111"
              />
              <SelectList
                name="userGroup"
                label="User Type"
                validationType="required"
                component="select"
              >
                <option disabled value="">
                  Select a user type
                </option>
                <option value="client">Client</option>

                {!isUserEditProfileNotAdmin(loginUser) && (
                  <>
                  <option value="sales">Sales</option>
                  <option value="p-c">P&amp;C</option>
                  <option value="partner">Partner</option>
                  <option value="admin">Admin</option>
                  <option value="PM">PM</option>
                  </>
                )}
              </SelectList>

              {organizations &&
                values.userGroup &&
                values.userGroup !== 'admin' &&
                values.userGroup !== 'PM' &&
                values.userGroup !== 'p-c' &&
                values.userGroup !== 'client' && (
                  <CheckboxGroupClients
                    name="organization"
                    label="Associated Client/s"
                    options={organizations.map((x) => ({
                      label: x.name,
                      value: x.id,
                    }))}
                  />
                )}

              {organizations &&
                values.userGroup &&
                values.userGroup === 'client' && (
                  <SelectList
                    name="organizationClient"
                    label="Client"
                    validationType="required"
                    component="select"
                    selectOnChange={() => {
                      form.mutators.resetAreas();
                    }}
                  >
                    <option disabled value="">
                      Select client
                    </option>
                    {organizations.map((x) => (
                      <option value={x.id} key={x.id}>
                        {x.name}
                      </option>
                    ))}
                  </SelectList>
                )}
              {organizations &&
                values.organizationClient &&
                values.userGroup === 'client' &&
                (organizations.filter(
                  (x) => x.id === values.organizationClient
                )[0].areas[0] === '' ? (
                  <div>
                    Selected client has no Department/Area
                    <Spacer desktop={15} />
                  </div>
                ) : (
                  <CheckboxGroup
                    name="areas"
                    label="Select Department/Area"
                    validationType="requiredArray"
                    value={values.areas}
                    options={
                      organizations.filter(
                        (x) => x.id === values.organizationClient
                      )[0].areas
                    }
                  />
                ))}
              <Button disabled={submitting || !valid}>Add</Button>
            </form>
          );
        }}
      ></FinalForm>
    </>
  );
};
