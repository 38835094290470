import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import PublicTemplate from '@/templates/public';
import Type from '@/elements/Type';
import Button from '@/elements/Button';
import { FinalForm } from '@/elements/Form';
import { TextInput } from '@/elements/Form';
import logo from '@/static/images/logo.svg';
import styled from 'styled-components';
import { ErrorBox } from '@/elements/ErrorBox/style';
import { withRouter } from 'react-router-dom';

const LoginTopElements = styled.div`
  img {
    width: 90px;
    margin: 0 auto 20px;
  }

  p {
    font-size: 14px;
    line-height: 16px;
    margin: 10px auto 30px;
  }
`;

const SignUpConfirmation = (props) => {
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const { history, location } = props;

  const changeState = (type, event) => {
    const { changeAuthState } = props;
    changeAuthState(type, event);
  };

  const onSubmit = (values) => {
    const { email, confirmationCode } = values;
    let { from } = location.state || { from: { pathname: '/' } };

    Auth.confirmSignUp(email, confirmationCode)
      .then((user) => {
        setSubmitting(true);
        if (
          user.challengeName === 'SMS_MFA' ||
          user.challengeName === 'SOFTWARE_TOKEN_MFA'
        ) {
          changeState('confirmSignIn', user);
        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          changeState('requireNewPassword', user);
        } else if (user.challengeName === 'MFA_SETUP') {
          changeState('TOTPSetup', user);
        } else {
          changeState('signedIn', user);
          history.replace(from);
          // window.location.reload();
        }
      })
      .catch((err) => {
        if (err.code === 'UserNotConfirmedException') {
          changeState('confirmSignUp');
        } else if (err.code === 'PasswordResetRequiredException') {
          changeState('requireNewPassword');
        } else {
          setError(err.message);
        }
        setSubmitting(false);
      });
  };

  return (
    <PublicTemplate>
      <LoginTopElements>
        <img src={logo} alt="appscore logo" />
        <Type.H6>Confirm Signup</Type.H6>
        <Type.P>Put the code sent to you email</Type.P>
      </LoginTopElements>
      {error && <ErrorBox>{error}</ErrorBox>}
      <FinalForm
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <TextInput
              name="email"
              component="input"
              type="text"
              validationType="required"
              label="Email Address"
              showAppend
            />
            <TextInput
              name="confirmationCode"
              component="input"
              type="text"
              validationType="required"
              className="mt-0"
              label="Confirmation Code"
              showAppend
            />
            <Button disabled={submitting} type="submit">
              Confirm
            </Button>
          </form>
        )}
      ></FinalForm>
    </PublicTemplate>
  );
};

export default withRouter(SignUpConfirmation);
