import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import PublicTemplate from '@/templates/public';
import Type from '@/elements/Type';
import Button from '@/elements/Button';
import { FinalForm } from '@/elements/Form';
import { TextInput } from '@/elements/Form';
import logo from '@/static/images/logo.svg';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import queryString from 'query-string';
import { ErrorBox } from '@/elements/ErrorBox/style';

const LoginTopElements = styled.div`
  img {
    width: 90px;
    margin: 0 auto 20px;
  }

  p {
    font-size: 14px;
    line-height: 16px;
    margin: 10px auto 30px;
  }
`;

const ForgotPasswordConfirmation = (props) => {
  const [queryFormValues, setQueryFormValues] = useState(null);
  const [error, setError] = useState(null);
  const { history } = props;

  useEffect(() => {
    if (!!!queryFormValues) {
      setQueryFormValues(queryString.parse(window.location.search));
    }
  }, [queryFormValues]);

  const onSubmit = async (values) => {
    const { email, code, password } = values;

    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      history.push(`/`);
    } catch (error) {
      setError(
        typeof error === 'string'
          ? error
          : error.message ||
              'An unexpected error occurred. Please try again later'
      );
    }
  };

  return (
    <PublicTemplate>
      <LoginTopElements>
        <Link to="/">
          <img src={logo} alt="appscore logo" />
        </Link>
        <Type.H6>Forgot Password</Type.H6>
        <Type.P>
          Put your email, confirmation code and new password to finish the
          password reset.
        </Type.P>
      </LoginTopElements>
      {error && <ErrorBox>{error}</ErrorBox>}
      <FinalForm
        onSubmit={onSubmit}
        initialValues={queryFormValues}
        render={({ handleSubmit, submitting, values, form }) => (
          <form onSubmit={handleSubmit}>
            <TextInput
              name="email"
              component="input"
              type="text"
              validationType="required"
              label="Email Address"
              value={values.email}
              showAppend
            />
            <TextInput
              name="code"
              component="input"
              type="text"
              validationType="required"
              label="Code"
              showAppend
            />
            <TextInput
              name="password"
              component="input"
              type="password"
              validationType="required"
              label="New Password"
              showAppend
            />
            <Button disabled={submitting} type="submit">
              Send
            </Button>
          </form>
        )}
      ></FinalForm>
    </PublicTemplate>
  );
};

export default withRouter(ForgotPasswordConfirmation);
