import React from 'react';
import { StatusWrapper, DropdownIconTrigger, ButtonsContainer } from './style';
import Button from '@/elements/Button';
import Type from '@/elements/Type';
import Icons from '@/elements/Icons';
import { colour } from '@/styles/variables';

const ConsultingRequirementCard = (props) => {
  const {
    title,
    description,
    status,
    viewed,
    nextStatus,
    handleRejectClick,
    handleNextStatusClick,
    handleContinueEditClick,
    handleUndoClick,
    onClick,
  } = props;

  const getStatusColor = () =>{
    if (status === 'new' || status === 'shortlist') return viewed? colour.grey : colour.blue
    else if (status === 'rejected') return colour.red
    else if (status === 'draft') return colour.blue
    else return colour.green

  }

  return (
    <StatusWrapper>
      <div className="card-body" onClick={onClick}>
        <Type.P className="status-text" color={getStatusColor()}>{viewed && status === 'new'? 'Viewed' : (status === 'shortlist' ? 'Interviewing' : (status === 'rejected' ? 'Declined' : status))}</Type.P>
        <Type.H6>{title}</Type.H6>
        <Type.P>{description}</Type.P>

        <DropdownIconTrigger>
          <Icons.ChevronRight />
        </DropdownIconTrigger>
      </div>
      {
        status === 'draft' ? <ButtonsContainer>
          <Button colour={colour.blue} simple onClick={handleContinueEditClick}>
            <Icons.EditBlue style={{width: '15px', height: '15px', color: colour.blue}}/>
            Continue Editing
          </Button>
        </ButtonsContainer> : 
        <ButtonsContainer>
          {handleNextStatusClick && nextStatus && (
            <Button colour={colour.green} simple onClick={handleNextStatusClick}>
              <Icons.Tick/>
              {nextStatus === 'Shortlist' ? 'Request Interview' : nextStatus}
            </Button>
          )}
          {handleRejectClick && nextStatus && (
            <Button colour={colour.red} simple onClick={handleRejectClick}>
              <Icons.Cross />
              Decline
            </Button>
          )}
          {!nextStatus && (
            <Button colour={colour.blue} simple onClick={handleUndoClick}>
              <Icons.Undo />
              Undo
            </Button>
          )}
        </ButtonsContainer>
      }
    </StatusWrapper>
  );
};

export default ConsultingRequirementCard;
