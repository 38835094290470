import React, { useState, useEffect, useContext } from 'react';
import LoggedInTemplate from '@/templates/loggedIn';
import Type from '@/elements/Type';
import Button from '@/elements/Button';
import styled from 'styled-components';
import { Col, Row, Grid } from 'react-styled-flexboxgrid';
import ActionCard from '@/components/ActionCard';
import SidebarFilter from '@/components/SidebarFilter';
import AddProfile from '@/pages/app/Profile/AddProfile';
import db from '@/utils/database';
import Modal from '@/elements/Modal';
import { formatDateForHumans, newDate } from '@/utils/dates';
import { fetchEmails } from '@/utils/fetchEmails';
import { sendEmail } from '@/utils/sendEmail';
import { AuthContext } from '@/context/auth';
import {
  isUserProfileGranted,
  isUserEditProfile,
  isUserProfileCreateGranted,
} from '@/utils/isUserAdmin';
import { colour } from '@/styles/variables';
import {
  RequirementDetailModal,
  SendEmailModal,
  ProfileDetailModal,
} from './Modals';

import {
  useRouteMatch,
  withRouter,
  Route,
  Switch,
  Link,
  Redirect,
} from 'react-router-dom';
import {
  ToggleFilterButton,
  FilterWithMobileToggle,
} from '@/components/SidebarFilter/style';
import { isConsultingClient } from '@/utils/clientTypeHelpers';
import ProfilePdfTemplate, { ProfilePdfContainer } from './ProfilePdfTemplate';
import { generatePdf } from '@/utils/generatePdf';
import { Hub } from 'aws-amplify';
import { DataStore } from '@aws-amplify/datastore';
import { Profile } from '@/models';

let STATUS = [
  { name: 'new', value: 'New', action: 'Undo' },
  { name: 'shortlist', value: 'Interviewing', action: 'Shortlist' },
  { name: 'accepted', value: 'Accepted', action: 'Accept' },
  { name: 'rejected', value: 'Declined', action: 'Reject' },
  { name: 'draft', value: 'Draft', action: 'Draft' },
];

const getStatus = (name, order, attr) => {
  const curIndex = STATUS.findIndex((x) => x.name === name);
  if (order === 'prev' && curIndex > 0)
    return STATUS[curIndex - 1][attr ? attr : 'value'];
  else if (order === 'next' && curIndex < STATUS.length - 1)
    return STATUS[curIndex + 1][attr ? attr : 'value'];
  else return curIndex > -1 ? STATUS[curIndex][attr ? attr : 'value'] : '';
};

const GoTo = styled(Link)`
  margin: 40px 0 30px 0;
  display: inline-block;
`;

const activeOrganization = localStorage.getItem('organization');

async function listProfiles(filteredProfilesAndSet, reqId) {
  const profiles = await db.getAll('profile');
  const req = await db.search('consultingRequirement', reqId, 'id');
  filteredProfilesAndSet(profiles, req);
}

const Profiles = (props) => {
  const { user, organization } = useContext(AuthContext);

  let { path } = useRouteMatch();
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showSendEmailDialog, setShowSendEmailDialog] = useState(false);
  const [showProfileDialog, setShowProfileDialog] = useState(false);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [requirement, setRequirement] = useState({});
  const reqId = new URLSearchParams(props.location.search).get('reqId');
  const [targetProfile, setTargetProfile] = useState();

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    if (!user || !reqId) {
      return;
    }
    const filteredProfilesAndSet = async (profs, req) => {
      if (isUserEditProfile(user)) {
        profs = profs.filter(
          (x) =>
            x['consultingRequirement'] &&
            x['consultingRequirement']['id'] === reqId
        );
      } else {
        profs = profs.filter(
          (x) =>
            x['consultingRequirement'] &&
            x['consultingRequirement']['id'] === reqId &&
            x['status'] !== 'draft'
        );
      }

      setRequirement(req[0]);
      setProfiles(profs ? profs : []);
      setFiltered(profs ? profs : []);
    };

    const listenerData = (data) => {
      if (data.payload.event === 'ready') {
        listProfiles(filteredProfilesAndSet, reqId);
      }
    };
    Hub.listen('datastore', listenerData);

    listProfiles(filteredProfilesAndSet, reqId);

    const subscription = DataStore.observe(Profile).subscribe((msg) => {
      listProfiles(filteredProfilesAndSet, reqId);
    });

    const handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';

      if (condition === 'online') {
        listProfiles(filteredProfilesAndSet, reqId);
      }
    };

    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      subscription.unsubscribe();
      Hub.remove('datastore', listenerData);

      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, reqId]);

  const setProfileStatus = async (profile, reject, reset) => {
    let payload = {};
    if (reject) {
      payload['status'] = 'rejected';
    } else if (reset) {
      payload['status'] = 'new';
      if (profile.consultant && profile.consultant !== 'null') {
        await db.delete('consultant', profile.consultant);
        await db.update('profile', { consultant: 'null' }, profile.id);
      }
    } else {
      const nextStatus = getStatus(profile.status, 'next', 'name');
      payload['status'] = nextStatus;
    }
    let tempProfile = { ...profile };
    tempProfile.status = payload['status'];
    setTargetProfile(tempProfile); // update targetProfile
    return await db.update('profile', payload, profile.id);
  };

  const addConsultant = async (profile) => {
    let payload = {
      name: profile.name,
      consultantImage: '',
      organization: { id: activeOrganization },
      areas: requirement.areas,
      startDate: '',
      endDate: '',
      role: requirement.title,
      inContract: true,
    };
    const consultant = await db.add('consultant', payload);
    await db.update('profile', { consultant: consultant.id }, profile.id);
  };

  const handleSetProfileStatus = async (profile, reject, reset) => {
    const nextStatus = getStatus(profile.status, 'next', 'name');
    setTargetProfile(profile);
    if (nextStatus === 'accepted' && profile.status !== 'accepted') {
      await addConsultant(profile);
      emailNotifyAccepReject(profile, 'Accepted');
    }
    if (nextStatus === 'shortlist' && !reject && !reset) {
      setShowSendEmailDialog(true);
    } else {
      setProfileStatus(profile, reject, reset);
    }
  };

  const handleRejectStatus = async (profile) => {
    setTargetProfile(profile);
    setProfileStatus(profile, true, false);

    emailNotifyAccepReject(profile, 'Declined');
  };

  const emailNotify = async (profile, reject, reset, sendSelf) => {
    if (!targetProfile) return;

    const pandcEmails = await fetchEmails('p-c');
    const partnerEmails = await fetchEmails('partner', activeOrganization);
    const salesEmails = await fetchEmails('sales', activeOrganization);
    let emails = [].concat(partnerEmails, salesEmails, pandcEmails);

    if (sendSelf) emails.push(user.attributes.email);

    const dom = document.querySelector('#profileHtml');
    let attachment;

    if (dom) {
      const main = dom.cloneNode(true);
      var tmp = document.createElement('body');
      tmp.appendChild(main);
      const html = tmp.innerHTML;
      const base64Pdf = await generatePdf(html);

      attachment = [
        {
          filename: `Appscore - ${requirement.title} - ${profile.name}.pdf`,
          path: base64Pdf,
        },
      ];
    }

    let subject = 'Profile Status Update';

    let body = `<p>The user <b>${user.attributes['custom:name']} (${organization.name})</b> has shortlisted the profile belonging to <b>${profile.name}</b> for the position <b>${requirement.title}</b>. Please follow up with the candidate and the client to arrange an interview.</p>`;

    sendEmail(emails, subject, body, attachment);
  };

  const emailNotifyAccepReject = async (profile, status) => {
    if (!targetProfile) return;

    const pandcEmails = await fetchEmails('p-c');

    const subject = 'Profile Status Update';
    const body = `<p><b>${profile.name}</b>'s profile status has changed to <b>${status}</b>.<br/>Client: <b>${organization.name}</b><br/>Job Role: <b>${requirement.title}</b></p>`;

    sendEmail(pandcEmails, subject, body);
  };

  const sortNewFirst = (list) => {
    return [
      ...list.filter((x) => x.status === 'new' && !x.viewed),
      ...list.filter((x) => x.status === 'new' && x.viewed),
      ...list.filter((x) => x.status !== 'new'),
    ];
  };

  if (!isConsultingClient(organization) && organization) {
    return <Redirect push to="/projects" />;
  }

  const ProfileCard = (x) => (
    <Col key={x.id} xs={12} sm={6} md={4}>
      <div style={{ margin: '15px 0' }}>
        <ActionCard
          title={x.name}
          description={`${x.yearsOfExperience} ${
            x.yearsOfExperience <= 1 ? 'year' : 'years'
          } of experience`}
          status={x.status}
          viewed={x.viewed}
          nextStatus={
            (x.status === 'accepted' || x.status === 'rejected') &&
            user.attributes['custom:userGroup'] !== 'partner'
              ? null
              : getStatus(x.status, 'next', 'action')
          }
          handleNextStatusClick={
            (isUserProfileGranted(user) || x.status === 'new') &&
            user.attributes['custom:userGroup'] !== 'partner'
              ? async () => {
                  await handleSetProfileStatus(x);
                }
              : null
          }
          handleRejectClick={
            user.attributes['custom:userGroup'] !== 'partner'
              ? () => {
                  handleRejectStatus(x);
                }
              : null
          }
          handleUndoClick={
            user.attributes['custom:userGroup'] !== 'partner'
              ? async () => {
                  await handleSetProfileStatus(x, false, true);
                }
              : null
          }
          handleContinueEditClick={() => {
            if (x.status === 'draft') {
              props.history.push(
                '/profiles/add?reqId=' + reqId + '&id=' + x.id
              );
            }
          }}
          onClick={() => {
            if (x.status === 'draft') {
              props.history.push(
                '/profiles/add?reqId=' + reqId + '&id=' + x.id
              );
            } else {
              setTargetProfile(x);
              db.update('profile', { viewed: true }, x.id);
              setShowProfileDialog(true);
            }
          }}
          // to={`/profiles/view?id=${x.id}&reqId=${reqId}`}
        />
      </div>
    </Col>
  );

  return (
    <Switch>
      <Route exact path={path}>
        <LoggedInTemplate>
          <Grid>
            <GoTo to="/requirements"> {`< Back to Dashboard`} </GoTo>
            <Modal
              title="Are you sure you wish to cancel?"
              intent="danger"
              buttonText="Yes, I'm sure"
              isShown={showCancelDialog}
              handleModalState={setShowCancelDialog}
              handleAction={() => props.history.push('/requirements')}
              shouldCloseOnEscapePress={false}
              shouldCloseOnOverlayClick={false}
            >
              <p>All entered information will be lost</p>
            </Modal>
            <ProfileDetailModal
              profile={targetProfile}
              isShown={showProfileDialog}
              user={user}
              history={props.history}
              reqId={reqId}
              requirementTitle={requirement.title || '-'}
              handleModalState={setShowProfileDialog}
              nextStatus={
                targetProfile
                  ? (targetProfile.status === 'accepted' ||
                      targetProfile.status === 'rejected') &&
                    user.attributes['custom:userGroup'] !== 'partner'
                    ? null
                    : getStatus(targetProfile.status, 'next', 'action')
                  : null
              }
              handleNextStatusClick={
                targetProfile
                  ? (isUserProfileGranted(user) ||
                      targetProfile.status === 'new') &&
                    user.attributes['custom:userGroup'] !== 'partner'
                    ? async () => {
                        await handleSetProfileStatus(targetProfile);
                      }
                    : null
                  : null
              }
              handleRejectClick={
                user
                  ? user.attributes['custom:userGroup'] !== 'partner'
                    ? () => {
                        handleRejectStatus(targetProfile);
                      }
                    : null
                  : null
              }
              handleUndoClick={
                user
                  ? user.attributes['custom:userGroup'] !== 'partner'
                    ? async () => {
                        await handleSetProfileStatus(
                          targetProfile,
                          false,
                          true
                        );
                      }
                    : null
                  : null
              }
            />
            <SendEmailModal
              profile={targetProfile}
              isShown={showSendEmailDialog}
              handleModalState={setShowSendEmailDialog}
              handleAction={(sendMeCopy) => {
                setProfileStatus(targetProfile, false, false);
                emailNotify(targetProfile, false, false, sendMeCopy);
              }}
            />
            <Row>
              <Col xs={12} sm={4} md={3}>
                <FilterWithMobileToggle>
                  <div className="content">
                    {requirement && Object.keys(requirement).length > 0 && (
                      <>
                        <RequirementDetailModal
                          data={requirement}
                          isShown={showDetailsDialog}
                          handleModalState={setShowDetailsDialog}
                        ></RequirementDetailModal>

                        <Type.H6>{requirement.title}</Type.H6>
                        <Type.P8>{`${formatDateForHumans(
                          newDate(requirement.idealStartDate),
                          'dd LLLL'
                        )} • ${
                          requirement.duration
                            ? requirement.duration + ' Months • '
                            : ''
                        } ${requirement.numberOfPositions} Positions`}</Type.P8>
                        <Button
                          onClick={() => setShowDetailsDialog(true)}
                          style={{ padding: '5px 0 0 0', fontSize: 14 }}
                          color={colour.blue}
                          simple
                        >
                          more details
                        </Button>
                      </>
                    )}
                    <SidebarFilter
                      withSearch
                      classify="status"
                      mobileTitle="Profiles Flow"
                      data={profiles}
                      status={
                        isUserEditProfile(user)
                          ? STATUS
                          : STATUS.filter((e) => e.name !== 'draft')
                      }
                      onFilter={setFiltered}
                      isOpen={isFilterOpen}
                      setIsOpen={setIsFilterOpen}
                    />
                  </div>
                  <div className="toggle">
                    <ToggleFilterButton
                      onClick={() => {
                        setIsFilterOpen(true);
                      }}
                    />
                  </div>
                </FilterWithMobileToggle>
                {isUserProfileCreateGranted(user) && (
                  <Button
                    to={`/profiles/add?reqId=${reqId}`}
                    style={{
                      display: 'block',
                      marginTop: 20,
                      minWidth: 'unset',
                    }}
                    className={'hidden-mobile'}
                  >
                    Add Candidate
                  </Button>
                )}
              </Col>
              <Col xs={12} sm={8} md={9}>
                <Row>
                  {filtered.length > 0 ? (
                    sortNewFirst(filtered).map((x) => {
                      if (x.status === 'draft') {
                        if (isUserEditProfile(user)) {
                          return ProfileCard(x);
                        } else {
                          return null;
                        }
                      } else {
                        return ProfileCard(x);
                      }
                    })
                  ) : (
                    <Col xs={12}>
                      <p>Click "Add Candidate" to create new profiles</p>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Grid>
          {targetProfile && (
            <ProfilePdfContainer id="profileHtml">
              <ProfilePdfTemplate profile={targetProfile} />
            </ProfilePdfContainer>
          )}
        </LoggedInTemplate>
      </Route>
      <Route path={`/profiles/add`}>
        <AddProfile />
      </Route>
    </Switch>
  );
};

export default withRouter(Profiles);
