import styled, { css } from 'styled-components';
import { colour, breakpoint } from '@/styles/variables';

export const StepsWrap = styled.div`
  padding-bottom: 20px;
  @media (max-width: 767px) {
    &.horizontal {
      ul {
        width: 100%;
      }
      li {
        &:not(:last-child) {
          width: 100%;
        }
        &:after {
          width: 100%;
        }
        &:first-child {
          & > div {
            align-items: flex-start;
            span {
              margin-left: 0;
            }
          }
        }
        &:last-child {
          & > div {
            align-items: flex-end;
            span {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  @media (max-width: ${breakpoint.max.larger}) {
    &.horizontal {
      overflow: auto;
      padding-bottom: 30px;
      margin: 0 -30px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
`;

export const StepsList = styled.ul`
  display: inline-flex;
  align-items: flex-start;
  padding: 0;
  list-style: none;
  flex-direction: ${({ direction }) =>
    direction === 'vertical' ? 'column' : 'row'};

  ${({ direction }) =>
    direction === 'horizontal' &&
    css`
      width: 100%;
      @media (max-width: ${breakpoint.max.larger}) {
        min-width: 670px;
      }
    `};
`;

export const Step = styled.li`
  position: relative;
  display: flex;
  align-items: ${({ direction }) =>
    direction === 'vertical' ? 'flex-start' : 'center'};
  flex-direction: ${({ direction }) =>
    direction === 'vertical' ? 'column' : 'row'};

  width: calc(100% / 6 - 9px);

  &:last-child {
    width: 34px;
  }
  & > div {
    display: flex;
    flex-direction: ${({ direction }) =>
      direction === 'vertical' ? 'row' : 'column'};
    justify-content: space-between;
    align-items: center;
  }
  p {
    position: ${({ direction }) =>
      direction === 'vertical' ? 'static' : 'absolute'};
    bottom: -30px;
    font-weight: 900;
    margin: ${({ direction }) =>
      direction === 'vertical' ? '0 5px' : '5px 0'};
    font-size: 14px;
  }
  color: ${({ status }) =>
    status === 'active'
      ? colour.blue
      : status === 'done'
      ? colour.charcoal
      : colour.grey};
  svg {
    width: 24px;
    height: 24px;
    margin: ${({ direction }) => (direction === 'vertical' ? '5px' : '0 5px')};
  }
  &:after {
    content: '';
    background: ${({ specialLine, status }) =>
      specialLine === 'toBlue'
        ? `linear-gradient(to right, ${colour.charcoal}, ${colour.blue})`
        : specialLine === 'fromBlue'
        ? `linear-gradient(to right,${colour.blue}, ${colour.grey})`
        : status === 'done'
        ? colour.charcoal
        : colour.grey};
    height: 2px;
    display: block;
    width: calc(100% - 34px);
    margin-top: 0;
    ${({ direction }) =>
      direction === 'vertical' &&
      css`
        height: 32px;
        width: 2px;
        margin-left: 16px;
        background: ${({ specialLine, status }) =>
          specialLine === 'toBlue'
            ? `linear-gradient( ${colour.charcoal}, ${colour.blue})`
            : specialLine === 'fromBlue'
            ? `linear-gradient(${colour.blue}, ${colour.grey})`
            : status === 'done'
            ? colour.charcoal
            : colour.grey};
      `}
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
`;

export const Circle = styled.span`
  width: 24px;
  height: 24px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border: 4px solid ${colour.grey};
    border-radius: 50%;
  }
`;
