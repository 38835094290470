import React, { useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import Modal from '@/elements/Modal';
import { Button } from '@/elements/Button/style';
import { Table } from 'evergreen-ui';
import {
  TableRow,
  TableCell,
  TableHead,
  HeaderCell,
} from '@/components/Table/style';
import { ViewLink } from '@/components/InvoiceTable/style';
import { LinksContainer } from '@/pages/app/Organization/style';
import { Spacer } from '@/elements/Spacer/style';
import { ErrorBox } from '@/elements/ErrorBox/style';
import { DatePickerInput } from '@/elements/Form';
import {
  PhotoUploadFieldWrap,
  ButtonGroupContainer,
} from '@/elements/Form/style';
import {
  TextInput,
  getValidator,
  FinalForm,
  FileInput,
  SelectList,
} from '@/elements/Form';
import { formatDateForHumans } from '@/utils/dates';

export const Invoices = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [error, setError] = useState('');
  const [errorAdd, setErrorAdd] = useState('');

  const [selectedItem, setSelectedItem] = useState({ name: '', index: 0 });

  return (
    <PhotoUploadFieldWrap>
      <label className="label">{`${props.label}${
        props.validationType && props.validationType.indexOf('required') > -1
          ? '*'
          : ''
      }`}</label>
      <Spacer desktop={10} />
      <FieldArray
        name="type"
        validate={(value) => getValidator(props.validationType, value)}
        {...props}
      >
        {({ fields }) => {
          const handleDelete = () => {
            fields.remove(selectedItem.index);
          };

          const handleRemove = (filename, values) => {
            if (values.invoice && values.invoice.indexOf('"name":""') < 0) {
              let payload = values;
              let arr = JSON.parse(values.invoice).filter(
                (obj) => obj.name !== filename
              );
              arr = arr.length ? arr : [{ name: '' }];
              payload['invoice'] = JSON.stringify(arr);
              fields.update(selectedItem.index, payload);
            }
          };

          const handleEdit = (values) => {
            const { number, dueDate, amount, status, invoice } = values;
            setError('');

            const invoiceItem = {
              number,
              dueDate: formatDateForHumans(new Date(dueDate), 'yyyy-MM-dd'),
              amount,
              status,
              invoice: invoice ? JSON.parse(invoice) : undefined,
            };

            fields.update(selectedItem.index, invoiceItem);
            setIsModalOpen(false);
          };
          const handleAdd = (values) => {
            const { number, dueDate, amount, status, invoice } = values;
            setErrorAdd('');

            const invoiceItem = {
              number,
              dueDate: formatDateForHumans(new Date(dueDate), 'yyyy-MM-dd'),
              amount,
              status,
              invoice: invoice ? JSON.parse(invoice) : null,
            };

            fields.push(invoiceItem);
            setIsAddModalOpen(false);
          };
          return (
            <>
              {fields.length !== 0 && (
                <>
                  <Table>
                    <TableHead paddingX={0}>
                      <HeaderCell>Invoice #</HeaderCell>
                      <HeaderCell>Due Date</HeaderCell>
                      <HeaderCell>Amount</HeaderCell>
                      <HeaderCell>Status</HeaderCell>
                      <HeaderCell
                        flexBasis={200}
                        flexShrink={0}
                        flexGrow={0}
                        paddingX={0}
                      ></HeaderCell>
                    </TableHead>
                    <Table.Body height={'auto'}>
                      {fields.map((x, i) => {
                        return (
                          <TableRow key={i} height={'auto'} variation={'admin'}>
                            <TableCell>{fields.value[i].number}</TableCell>
                            <TableCell>
                              {formatDateForHumans(
                                new Date(fields.value[i].dueDate)
                              )}
                            </TableCell>
                            <TableCell>${fields.value[i].amount}</TableCell>
                            <TableCell>{fields.value[i].status}</TableCell>
                            <TableCell
                              flexBasis={200}
                              flexShrink={0}
                              flexGrow={0}
                              paddingX={0}
                            >
                              <LinksContainer>
                                <ViewLink
                                  onClick={() => {
                                    setIsModalOpen(true);
                                    setSelectedItem({
                                      ...fields.value[i],
                                      index: i,
                                    });
                                  }}
                                >
                                  Edit
                                </ViewLink>
                                <ViewLink
                                  onClick={() => {
                                    setIsDeleteModalOpen(true);
                                    setSelectedItem({
                                      ...fields.value[i],
                                      index: i,
                                    });
                                  }}
                                >
                                  Delete
                                </ViewLink>
                              </LinksContainer>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </Table.Body>
                  </Table>
                  <Spacer desktop={10} />
                </>
              )}
              {fields.length === 0 && 'No invoices have been created '}
              <Button
                simple
                onClick={(e) => {
                  e.preventDefault();
                  setIsAddModalOpen(true);
                }}
              >
                + Add Invoice
              </Button>

              <Modal
                title="Add Invoice"
                isShown={isAddModalOpen}
                handleModalState={setIsAddModalOpen}
                type="plain"
                alignment="center"
                shouldCloseOnEscapePress={false}
                shouldCloseOnOverlayClick={false}
              >
                <FinalForm
                  onSubmit={handleAdd}
                  render={({ handleSubmit, values }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        {errorAdd && (
                          <ErrorBox slimPadding>{errorAdd}</ErrorBox>
                        )}
                        <DatePickerInput
                          name="dueDate"
                          type="text"
                          dateFormat="dd-MM-yyyy"
                          value={values.dueDate}
                          label="Due Date"
                          validationType={'required'}
                          placeholder="DD/MM/YYYY"
                          maxDate={values.endDate}
                          showAppend
                        />
                        <TextInput
                          name="number"
                          component="input"
                          value={values.number}
                          type="text"
                          validationType="required"
                          label="Invoice Number"
                          placeholder="Enter the invoice number"
                        />
                        <TextInput
                          name="amount"
                          component="input"
                          value={values.amount}
                          type="number"
                          validationType="isNumber,required"
                          label="Amount ($)"
                          placeholder="0"
                        />

                        <SelectList
                          name="status"
                          label="Status"
                          validationType="required"
                          component="select"
                          value={values.status}
                        >
                          <option disabled value="">
                            Select a status
                          </option>
                          <option value="Due">Due</option>
                          <option value="Paid">Paid</option>
                          <option value="Past due">Past due</option>
                        </SelectList>

                        <FileInput
                          name="invoice"
                          value={values.invoice}
                          handleRemove={(filename) =>
                            handleRemove(filename, values)
                          }
                          component="input"
                          label="Invoice"
                          folder="projects/invoices"
                        />

                        <ButtonGroupContainer justifyContent="flex-end">
                          <Button>Add</Button>
                          <Button
                            simple
                            onClick={(e) => {
                              e.preventDefault();
                              setIsAddModalOpen(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </ButtonGroupContainer>
                      </form>
                    );
                  }}
                ></FinalForm>
              </Modal>

              <Modal
                title="Edit Invoice"
                isShown={isModalOpen}
                handleModalState={setIsModalOpen}
                type="plain"
                alignment="center"
                shouldCloseOnEscapePress={false}
                shouldCloseOnOverlayClick={false}
              >
                <FinalForm
                  onSubmit={handleEdit}
                  initialValues={{
                    number: selectedItem.number,
                    dueDate: selectedItem.dueDate,
                    status: selectedItem.status,
                    amount: selectedItem.amount,
                    invoice: JSON.stringify(selectedItem.invoice),
                  }}
                  render={({ handleSubmit, values }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        {error && <ErrorBox slimPadding>{error}</ErrorBox>}
                        <DatePickerInput
                          name="dueDate"
                          type="text"
                          dateFormat="dd-MM-yyyy"
                          value={values.dueDate}
                          label="Due Date"
                          validationType={'required'}
                          placeholder="DD/MM/YYYY"
                          maxDate={values.endDate}
                          showAppend
                        />
                        <TextInput
                          name="number"
                          component="input"
                          value={values.number}
                          type="text"
                          validationType="required"
                          label="Invoice Number"
                          placeholder="Enter the invoice number"
                        />
                        <TextInput
                          name="amount"
                          component="input"
                          value={values.amount}
                          type="number"
                          validationType="isNumber,required"
                          label="Amount ($)"
                          placeholder="0"
                        />

                        <SelectList
                          name="status"
                          label="Status"
                          validationType="required"
                          component="select"
                          value={values.status}
                        >
                          <option disabled value="">
                            Select a status
                          </option>
                          <option value="Due">Due</option>
                          <option value="Paid">Paid</option>
                          <option value="Past due">Past due</option>
                        </SelectList>

                        <FileInput
                          name="invoice"
                          value={values.invoice}
                          handleRemove={(filename) =>
                            handleRemove(filename, values)
                          }
                          component="input"
                          label="Invoice"
                          folder="projects/invoices"
                        />
                        <ButtonGroupContainer justifyContent="flex-end">
                          <Button>Update</Button>
                          <Button
                            simple
                            onClick={(e) => {
                              e.preventDefault();
                              setIsModalOpen(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </ButtonGroupContainer>
                      </form>
                    );
                  }}
                ></FinalForm>
              </Modal>

              <Modal
                title="Delete Invoice"
                isShown={isDeleteModalOpen}
                handleModalState={setIsDeleteModalOpen}
                handleAction={() => handleDelete()}
                type={'action'}
                alignment={'center'}
                buttonText="Yes"
                shouldCloseOnEscapePress={false}
                shouldCloseOnOverlayClick={false}
              >
                <p>
                  Are you sure you want to delete invoice #{selectedItem.number}
                  ?
                </p>
              </Modal>
            </>
          );
        }}
      </FieldArray>
    </PhotoUploadFieldWrap>
  );
};
