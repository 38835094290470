import React, { useState } from 'react';
import {
  InfoCardWrapper,
  InfoCardTitleContainer,
  InfoCardItem,
  InfoCardEditContainer,
  CollapseContainer,
} from './style';
import Type from '@/elements/Type';
import { Spacer } from '@/elements/Spacer/style';
import { formatDateForHumans, newDate } from '@/utils/dates';

const InfoCard = (props) => {
  const { title, items, educations, experiences, handleEditClick } = props;

  const [experiencesCollapsed, setExperiencesCollapsed] = useState(false);
  const [educationsCollapsed, setEducationsCollapsed] = useState(false);

  const renderEducation = (education, hasSpacer) => (
    <>
      {hasSpacer && <Spacer desktop={20} />}
      {education.institute && (
        <InfoCardItem>
          <Type.P className="info-title">Institute</Type.P>
          <Type.P className="info-content">{education.institute}</Type.P>
        </InfoCardItem>
      )}
      {education.accolade && (
        <InfoCardItem>
          <Type.P className="info-title">Accolade</Type.P>
          <Type.P className="info-content">{education.accolade}</Type.P>
        </InfoCardItem>
      )}
      {education.course && (
        <InfoCardItem>
          <Type.P className="info-title">Course / Subject</Type.P>
          <Type.P className="info-content">{education.course}</Type.P>
        </InfoCardItem>
      )}
      {education.completion && (
        <InfoCardItem>
          <Type.P className="info-title">Completion</Type.P>
          <Type.P className="info-content">{education.completion}</Type.P>
        </InfoCardItem>
      )}
    </>
  );

  const renderExperience = (experience, hasSpacer) => (
    <>
      {hasSpacer && <Spacer desktop={20} />}
      {experience.role && (
        <InfoCardItem>
          <Type.P className="info-title">Role</Type.P>
          <Type.P className="info-content">{experience.role}</Type.P>
        </InfoCardItem>
      )}
      {(experience.tenureStart || experience.tenureEnd) && (
        <InfoCardItem>
          <Type.P className="info-title">Tenure (from - to)</Type.P>
          <Type.P className="info-content">
            {formatDateForHumans(newDate(experience.tenureStart))}
            {' - '}
            {experience.tenureEnd === 'Present'
              ? 'Present'
              : formatDateForHumans(newDate(experience.tenureEnd))}
          </Type.P>
        </InfoCardItem>
      )}
      {experience.company && (
        <InfoCardItem>
          <Type.P className="info-title">Company</Type.P>
          <Type.P className="info-content">{experience.company}</Type.P>
        </InfoCardItem>
      )}
      {experience.completion && (
        <InfoCardItem>
          <Type.P className="info-title">Completion</Type.P>
          <Type.P className="info-content">{experience.completion}</Type.P>
        </InfoCardItem>
      )}
      {experience.responsibilities && (
        <InfoCardItem>
          <Type.P className="info-title">Responsibilities</Type.P>
          <Type.P className="info-content">
            <span
              className="wysiwyg"
              dangerouslySetInnerHTML={{ __html: experience.responsibilities }}
            ></span>
          </Type.P>
        </InfoCardItem>
      )}
    </>
  );

  return (
    <>
      <InfoCardTitleContainer>
        <Type.H6>{title}</Type.H6>
      </InfoCardTitleContainer>
      <InfoCardWrapper>
        <InfoCardEditContainer>
          <button onClick={handleEditClick}>Edit</button>
        </InfoCardEditContainer>
        {items &&
          items.map(
            (item, index) =>
              item &&
              item.content && (
                <InfoCardItem key={index}>
                  <Type.P className="info-title">{item.title}</Type.P>
                  <Type.P className="info-content">
                    <span
                      className="wysiwyg"
                      dangerouslySetInnerHTML={{ __html: item.content }}
                    ></span>
                  </Type.P>
                </InfoCardItem>
              )
          )}
        {educations && (
          <>
            {renderEducation(educations[0], false)}
            {educations.length > 1 && (
              <>
                {!educationsCollapsed && (
                  <button
                    className="collapse-btn"
                    onClick={() => setEducationsCollapsed(true)}
                  >
                    + {educations.length - 1} more
                  </button>
                )}
                <CollapseContainer isOpen={educationsCollapsed}>
                  {educations.slice(1).map((education, i) => (
                    <div key={i}>{renderEducation(education, true)}</div>
                  ))}
                </CollapseContainer>
              </>
            )}
          </>
        )}
        {experiences && (
          <>
            {renderExperience(experiences[0], false)}
            {experiences.length > 1 && (
              <>
                {!experiencesCollapsed && (
                  <button
                    className="collapse-btn"
                    onClick={() => setExperiencesCollapsed(true)}
                  >
                    + {experiences.length - 1} more
                  </button>
                )}
                <CollapseContainer isOpen={experiencesCollapsed}>
                  {experiences.slice(1).map((experience, i) => (
                    <div key={i}>{renderExperience(experience, true)}</div>
                  ))}
                </CollapseContainer>
              </>
            )}
          </>
        )}
      </InfoCardWrapper>
    </>
  );
};

export default InfoCard;
