import styled from 'styled-components';
import { breakpoint } from '@/styles/variables';

export const ProjectCardsContainer = styled.div`
  @media (max-width: ${breakpoint.max.larger}) {
    display: flex;
    overflow: auto;
    margin-left: -30px;
    margin-right: -30px;
    padding: 0 30px;
  }
`;

export const TasksOuterContainer = styled.div`
  @media (max-width: ${breakpoint.max.medium}) {
    margin: 0 -30px;
    padding: 0 30px;
    overflow: auto;
  }
`;

export const StatusBar = styled.div`
  background-color: ${({ statusColor }) => statusColor && statusColor};
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
  display: inline-block;
  margin-left: 18px;
`;

export const TasksContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${breakpoint.max.medium}) {
    min-width: 566px;
  }

  .client-task {
    flex: 0 0 calc((100% * (7 / 12)) - 16px);
    max-width: calc((100% * (7 / 12)) - 16px);

    @media (max-width: ${breakpoint.max.medium}) {
      flex: 0 0 300px;
      max-width: 300px;
    }
  }
  .completed-task {
    flex: 0 0 calc((100% * (5 / 12)) - 16px);
    max-width: calc((100% * (5 / 12)) - 16px);

    @media (max-width: ${breakpoint.max.medium}) {
      flex: 0 0 250px;
      max-width: 250px;
    }
  }
`;
