import React from 'react';
import {
  ModalWrap,
  ModalHeader,
  ModalButtonContainer,
  ModalBody,
} from './style';
import { Dialog } from 'evergreen-ui';
import Icons from '@/elements/Icons';
import Type from '@/elements/Type';
import Button from '@/elements/Button';

import { colour } from '@/styles/variables';

const Modal = (props) => {
  const {
    isShown,
    handleModalState,
    handleAction,
    type,
    buttonText,
    alignment,
    intent,
    shouldCloseOnOverlayClick,
    shouldCloseOnEscapePress,
    isTall,
    noCancel,
    closeFromExternal
  } = props;

  return (
    <ModalWrap>
      <Dialog
        isShown={isShown}
        onCloseComplete={() => handleModalState(false)}
        hasFooter={false}
        hasHeader={false}
        containerProps={{ padding: 15 }}
        shouldCloseOnEscapePress={shouldCloseOnEscapePress}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        preventBodyScrolling
        topOffset={isTall ? '5vmin' : undefined}
      >
        {({ close }) => (
          <>
           {closeFromExternal && closeFromExternal(close)}
            <ModalHeader alignment={alignment} isTall={isTall}>
              <Type.H6>{props.title}</Type.H6>
              {type !== 'action' && type !== 'plain' && (
                <Icons.CrossSelf onClick={close} />
              )}
            </ModalHeader>
            <ModalBody alignment={alignment} isTall={isTall}>
              {props.children}
            </ModalBody>
            {type === 'action' && (
              <ModalButtonContainer isTall={isTall}>
                {!noCancel && (
                  <Button
                    simple
                    onClick={close}
                    style={{
                      color: intent === 'danger' ? colour.charcoal : '',
                    }}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  className={intent}
                  onClick={() => {
                    handleAction && handleAction();
                    close();
                  }}
                >
                  {buttonText || 'Done'}
                </Button>
              </ModalButtonContainer>
            )}
          </>
        )}
      </Dialog>
    </ModalWrap>
  );
};

export default Modal;
