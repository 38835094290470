import React, { useState } from 'react';
import {
  StatusWrapper,
  //StatusContainer,
  //StatusDot,
  DropdownIconTrigger,
} from './style';
import Icons from '@/elements/Icons';
import Type from '@/elements/Type';
import { Position, Menu, Popover, Button } from 'evergreen-ui';
import { colour } from '@/styles/variables';
import { Redirect } from 'react-router-dom';
import {
  RequirementDetailModal
} from '../../pages/app/Profile/Modals';
import ButtonPopup from '@/elements/Button';

const ConsultingRequirementCard = (props) => {
  const {
    title,
    //icon,
    description,
    statusColor,
    //statusText,
    to,
    handleEdit,
    handleDelete,
    requirement
  } = props;
  //const Icon = Icons[icon];
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [redirect, setRedirect] = useState(false);

  if(redirect) {
    return <Redirect push to={to} />;
  }

  const iconType = (value) => {
    let title = value.toLowerCase();
    if(title.includes('devops engineer')) {
      return <Icons.DevOpsEn className="icon" />
    } else if(title.includes('cloud secuirity engineer')) {
      return <Icons.CloudSecurityEn className="icon" />
    } else if(title.includes('mobile developer')) {
      return <Icons.MobileDev className="icon" />
    } else if(title.includes('android developer')) {
      return <Icons.AndroidDev className="icon" style={{height: '22.5px'}} />
    } else if(title.includes('backend developer') || title.includes('back end developer') || title.includes('back-end developer') ) {
      return <Icons.BackEndDev className="icon" />
    } else if(title.includes('frontend developer') || title.includes('front end developer') || title.includes('front-end developer')) {
      return <Icons.FrontEndDev className="icon" />
    } else if(title.includes('full stack developer') || title.includes('fullstack developer') || title.includes('full-stack developer')) {
      return <Icons.FullStackDev className="icon" />
    } else if(title.includes('ios developer')) {
      return <Icons.iOSDev className="icon" />
    } else if(title.includes('react developer') || title.includes('react native developer')) {
      return <Icons.ReactDev className="icon" />
    } else if(title.includes('uxui designer') || title.includes('ux/ui designer')) {
      return <Icons.UXUIDesigner className="icon" />
    } else if(title.includes('ui designer')) {
      return <Icons.UIDesigner className="icon" />
    } else if(title.includes('ux designer')) {
      return <Icons.UXDesigner className="icon" />
    } else {
      return <Icons.DefaultRoleIcon className="icon" />
    }
  }

  return (
    <div>
    <StatusWrapper style={{cursor: 'pointer'}} onClick={() => {setRedirect(true)}}>
        {iconType(title)}
        <Type.H6>{title}</Type.H6>
        <Type.P>{description}</Type.P>
        {/* <StatusContainer statusColor={colour[statusColor]}>
          <StatusDot statusColor={colour[statusColor]} /> 
          <Type.P>{statusText}</Type.P>
        </StatusContainer>*/}
        <ButtonPopup
          onClick={(e) => {e.stopPropagation();setShowDetailsDialog(true)}}
          style={{ padding: '5px 0 0 0', width: 'fit-content', fontSize: 12, fontFamily: "'Gilroy', sans-serif" }}
          color={colour[statusColor]}
          simple
        >
          more details
        </ButtonPopup>
      <DropdownIconTrigger onClick={(e) => e.stopPropagation()}>
        <Popover
          position={Position.BOTTOM}
          content={({ close }) => (
            <Menu>
              <Menu.Group>
                <Menu.Item
                  onSelect={() => {
                    handleEdit();
                    close();
                  }}
                >
                  Edit
                </Menu.Item>
                <Menu.Item
                  onSelect={() => {
                    handleDelete();
                    close();
                  }}
                >
                  Delete
                </Menu.Item>
              </Menu.Group>
            </Menu>
          )}
        >
          <Button appearance="minimal" height={10}>
            <Icons.ThreeDotsSelf />
          </Button>
        </Popover>
      </DropdownIconTrigger>
    </StatusWrapper>
    <RequirementDetailModal
      data={requirement}
      isShown={showDetailsDialog}
      handleModalState={setShowDetailsDialog}
    ></RequirementDetailModal>
    </div>
  );
};

export default ConsultingRequirementCard;
