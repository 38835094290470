import React from 'react';
import { ReactComponent as ArrowRight } from '@/static/icons/arrowRight.svg';
import { ReactComponent as Tick } from '@/static/icons/tick.svg';
import { ReactComponent as TickFilled } from '@/static/icons/tickFilled.svg';
import { ReactComponent as TickThickFilled } from '@/static/icons/tickThickFilled.svg';
import { ReactComponent as AlertFilled } from '@/static/icons/alertFilled.svg';
import { ReactComponent as Cross } from '@/static/icons/cross.svg';
import { ReactComponent as CrossSelf } from '@/static/icons/crossSelf.svg';
import { ReactComponent as ThreeDotsSelf } from '@/static/icons/threeDotsSelf.svg';
import { ReactComponent as ThreeDotsFilled } from '@/static/icons/threeDotsFilled.svg';
import { ReactComponent as ChevronRight } from '@/static/icons/chevronRight.svg';
import { ReactComponent as ChevronDown } from '@/static/icons/chevronDown.svg';
import { ReactComponent as Calendar } from '@/static/icons/calendar.svg';
import { ReactComponent as CalendarSelf } from '@/static/icons/calendarSelf.svg';
import { ReactComponent as Prototype } from '@/static/icons/prototype.svg';
import { ReactComponent as Search } from '@/static/icons/search.svg';
import { ReactComponent as Pause } from '@/static/icons/pause.svg';
import { ReactComponent as Minus } from '@/static/icons/minus.svg';
import { ReactComponent as Download } from '@/static/icons/download.svg';
import { ReactComponent as Powerpoint } from '@/static/icons/powerpoint_icon.svg';
import { ReactComponent as Image } from '@/static/icons/image_icon.svg';
import { ReactComponent as PDF } from '@/static/icons/pdf_icon.svg';
import { ReactComponent as Keynote } from '@/static/icons/keynote_icon.svg';
import { ReactComponent as Excel } from '@/static/icons/excel_icon.svg';
import { ReactComponent as Link } from '@/static/icons/link_icon.svg';
import { ReactComponent as Word } from '@/static/icons/word_icon.svg';
import { ReactComponent as Edit } from '@/static/icons/edit_icon.svg';
import { ReactComponent as BackArrow } from '@/static/icons/back_arrow.svg';
import { ReactComponent as Undo } from '@/static/icons/undo_icon.svg';
import { ReactComponent as ArrowLeftBlue } from '@/static/icons/arrow-down.svg';
import { ReactComponent as EditBlue } from '@/static/icons/edit_blue.svg';
import { ReactComponent as LinkBlue } from '@/static/icons/link_blue.svg';

// icons for roles
import { ReactComponent as DevOpsEn } from '@/static/icons/devops_engineer.svg';
import { ReactComponent as CloudSecurityEn } from '@/static/icons/cloud_secuirity_engineer.svg';
import { ReactComponent as MobileDev } from '@/static/icons/mobile_developer.svg';
import { ReactComponent as AndroidDev } from '@/static/icons/android_developer.svg';
import { ReactComponent as BackEndDev } from '@/static/icons/backend_developer.svg';
import { ReactComponent as FrontEndDev } from '@/static/icons/frontend_developer.svg';
import { ReactComponent as FullStackDev } from '@/static/icons/full_stack_developer.svg';
import { ReactComponent as iOSDev } from '@/static/icons/ios_developer.svg';
import { ReactComponent as ReactDev } from '@/static/icons/react_developer.svg';
import { ReactComponent as UIDesigner } from '@/static/icons/ui_designer.svg';
import { ReactComponent as UXDesigner } from '@/static/icons/ux_designer.svg';
import { ReactComponent as UXUIDesigner } from '@/static/icons/uxui_designer.svg';
import { ReactComponent as DefaultRoleIcon } from '@/static/icons/default_icon.svg';

const Icon = ({ icon, ...props }) => {
  const IconComponent = icon;
  return <IconComponent {...props} />;
};

const Icons = {
  ArrowRight: (props) => <Icon icon={ArrowRight} {...props} />,
  Tick: (props) => <Icon icon={Tick} {...props} />,
  TickThickFilled: (props) => <Icon icon={TickThickFilled} {...props} />,
  TickFilled: (props) => <Icon icon={TickFilled} {...props} />,
  AlertFilled: (props) => <Icon icon={AlertFilled} {...props} />,
  Cross: (props) => <Icon icon={Cross} {...props} />,
  CrossSelf: (props) => <Icon icon={CrossSelf} {...props} />,
  ThreeDotsFilled: (props) => <Icon icon={ThreeDotsFilled} {...props} />,
  ThreeDotsSelf: (props) => <Icon icon={ThreeDotsSelf} {...props} />,
  ChevronRight: (props) => <Icon icon={ChevronRight} {...props} />,
  ChevronDown: (props) => <Icon icon={ChevronDown} {...props} />,
  Calendar: (props) => <Icon icon={Calendar} {...props} />,
  CalendarSelf: (props) => <Icon icon={CalendarSelf} {...props} />,
  Prototype: (props) => <Icon icon={Prototype} {...props} />,
  Search: (props) => <Icon icon={Search} {...props} />,
  Pause: (props) => <Icon icon={Pause} {...props} />,
  Minus: (props) => <Icon icon={Minus} {...props} />,
  Powerpoint: (props) => <Icon icon={Powerpoint} {...props} />,
  Image: (props) => <Icon icon={Image} {...props} />,
  PDF: (props) => <Icon icon={PDF} {...props} />,
  Keynote: (props) => <Icon icon={Keynote} {...props} />,
  Excel: (props) => <Icon icon={Excel} {...props} />,
  Link: (props) => <Icon icon={Link} {...props} />,
  Download: (props) => <Icon icon={Download} {...props} />,
  Word: (props) => <Icon icon={Word} {...props} />,
  Edit: (props) => <Icon icon={Edit} {...props} />,
  BackArrow: (props) => <Icon icon={BackArrow} {...props} />,
  Undo: (props) => <Icon icon={Undo} {...props} />,
  ArrowLeftBlue: (props) => <Icon icon={ArrowLeftBlue} {...props} />,
  EditBlue: (props) => <Icon icon={EditBlue} {...props} />,
  LinkBlue: (props) => <Icon icon={LinkBlue} {...props} />,
  // icons for roles
  DevOpsEn: (props) => <Icon icon={DevOpsEn} {...props} />,
  CloudSecurityEn: (props) => <Icon icon={CloudSecurityEn} {...props} />,
  MobileDev: (props) => <Icon icon={MobileDev} {...props} />,
  AndroidDev: (props) => <Icon icon={AndroidDev} {...props} />,
  BackEndDev: (props) => <Icon icon={BackEndDev} {...props} />,
  FrontEndDev: (props) => <Icon icon={FrontEndDev} {...props} />,
  FullStackDev: (props) => <Icon icon={FullStackDev} {...props} />,
  iOSDev: (props) => <Icon icon={iOSDev} {...props} />,
  ReactDev: (props) => <Icon icon={ReactDev} {...props} />,
  UIDesigner: (props) => <Icon icon={UIDesigner} {...props} />,
  UXDesigner: (props) => <Icon icon={UXDesigner} {...props} />,
  UXUIDesigner: (props) => <Icon icon={UXUIDesigner} {...props} />,
  DefaultRoleIcon: (props) => <Icon icon={DefaultRoleIcon} {...props} />,
};

export default Icons;
