import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import PublicTemplate from '@/templates/public';
import Type from '@/elements/Type';
import Button from '@/elements/Button';
import { FinalForm } from '@/elements/Form';
import { TextInput } from '@/elements/Form';
import logo from '@/static/images/logo.svg';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ErrorBox } from '@/elements/ErrorBox/style';
import { colour } from '@/styles/variables';

const LoginTopElements = styled.div`
  img {
    width: 90px;
    margin: 0 auto 20px;
  }

  p {
    font-size: 14px;
    line-height: 16px;
    margin: 10px auto 30px;
  }
`;

const LoginLinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    font-weight: 600;
    color: ${colour.blue};
  }
`;

const SignIn = (props) => {
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const [errorNewPass, setErrorNewPass] = useState(null);
  const [submittingNewPass, setSubmittingNewPass] = useState(false);

  const [errorMfa, setErrorMfa] = useState(null);
  const [submittingMfa, setSubmittingMfa] = useState(false);
  const [isRequireNewPassword, setIsRequireNewPassword] = useState(false);
  const [isMfaConfirmation, setIsMfaConfirmation] = useState(false);

  const [user, setUser] = useState();

  const changeState = (type, event) => {
    const { changeAuthState } = props;
    changeAuthState(type, event);
  };

  const onSubmit = (values) => {
    const { email, password } = values;

    setSubmitting(true);

    Auth.signIn(email.toLowerCase(), password)
      .then((user) => {
        setSubmitting(false);
        if (
          user.challengeName === 'SMS_MFA' ||
          user.challengeName === 'SOFTWARE_TOKEN_MFA'
        ) {
          setIsRequireNewPassword(false);
          setIsMfaConfirmation(true);
          setUser(user);
        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setIsMfaConfirmation(false);
          setIsRequireNewPassword(true);
          setUser(user);
        } else if (user.challengeName === 'MFA_SETUP') {
          changeState('TOTPSetup', user);
        } else {
          changeState('signedIn', user);
          // window.location.reload();
        }
      })
      .catch((err) => {
        if (err.code === 'UserNotConfirmedException') {
          changeState('confirmSignUp');
        } else if (err.code === 'PasswordResetRequiredException') {
          changeState('requireNewPassword');
        } else {
          setError(err.message);
        }
        setSubmitting(false);
      });
  };

  const onRequireNewPassSubmit = async (values) => {
    const { newPassword } = values;

    setSubmittingNewPass(true);

    await Auth.completeNewPassword(user, newPassword)
      .then((user) => {
        setSubmittingNewPass(false);
        if (
          user.challengeName === 'SMS_MFA' ||
          user.challengeName === 'SOFTWARE_TOKEN_MFA'
        ) {
          setIsRequireNewPassword(false);
          setIsMfaConfirmation(true);
          setUser(user);
        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setIsMfaConfirmation(false);
          setIsRequireNewPassword(true);
          setUser(user);
        } else if (user.challengeName === 'MFA_SETUP') {
          changeState('TOTPSetup', user);
        } else {
          changeState('signedIn', user);
          // window.location.reload();
        }
      })
      .catch((err) => {
        if (err.code === 'UserNotConfirmedException') {
          changeState('confirmSignUp');
        } else if (err.code === 'PasswordResetRequiredException') {
          changeState('requireNewPassword');
        } else {
          setErrorNewPass(err.message);
        }
        setSubmittingNewPass(false);
      });
  };

  const onMfaSubmit = async (values) => {
    const { mfaCode } = values;

    setSubmittingMfa(true);

    await Auth.confirmSignIn(user, mfaCode)
      .then((user) => {
        setSubmittingMfa(false);
        if (
          user.challengeName === 'SMS_MFA' ||
          user.challengeName === 'SOFTWARE_TOKEN_MFA'
        ) {
          setIsRequireNewPassword(false);
          setIsMfaConfirmation(true);
          setUser(user);
        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setIsMfaConfirmation(false);
          setIsRequireNewPassword(true);
          setUser(user);
        } else if (user.challengeName === 'MFA_SETUP') {
          changeState('TOTPSetup', user);
        } else {
          changeState('signedIn', user);
          // window.location.reload();
        }
      })
      .catch((err) => {
        if (err.code === 'UserNotConfirmedException') {
          changeState('confirmSignUp');
        } else if (err.code === 'PasswordResetRequiredException') {
          changeState('requireNewPassword');
        } else {
          setErrorMfa(err.message);
        }
        setSubmittingMfa(false);
      });
  };

  return (
    <PublicTemplate>
      <LoginTopElements>
        <Link to="/">
          <img src={logo} alt="appscore logo" />
        </Link>
        <Type.H6>Sign In</Type.H6>
        {isRequireNewPassword && <Type.P>Please set your new password.</Type.P>}
        {isMfaConfirmation && (
          <Type.P>Please enter verification code sent via SMS.</Type.P>
        )}
        {!isRequireNewPassword && !isMfaConfirmation && (
          <Type.P>Use your company account.</Type.P>
        )}
      </LoginTopElements>
      {isRequireNewPassword && (
        <>
          {errorNewPass && <ErrorBox>{errorNewPass}</ErrorBox>}
          <FinalForm
            onSubmit={onRequireNewPassSubmit}
            validate={(values) => {
              const errors = {};
              if (values.confirmPasswordPassword !== values.newPassword) {
                errors.confirmPasswordPassword = 'Must match';
              }
              return errors;
            }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <TextInput
                  name="newPassword"
                  component="input"
                  type="password"
                  validationType="required"
                  label="New Password"
                  showAppend
                />
                <TextInput
                  name="confirmPasswordPassword"
                  component="input"
                  type="password"
                  validationType="required"
                  label="Confirm New Password"
                  showAppend
                />
                <Button disabled={submittingNewPass} type="submit">
                  Done
                </Button>
              </form>
            )}
          ></FinalForm>
        </>
      )}
      {isMfaConfirmation && (
        <>
          {errorMfa && <ErrorBox>{errorMfa}</ErrorBox>}
          <FinalForm
            onSubmit={onMfaSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <TextInput
                  name="mfaCode"
                  component="input"
                  type="text"
                  validationType="required"
                  label="Verification code"
                  showAppend
                />
                <Button disabled={submittingMfa} type="submit">
                  Submit
                </Button>
              </form>
            )}
          ></FinalForm>
        </>
      )}
      {!isRequireNewPassword && !isMfaConfirmation && (
        <>
          {error && <ErrorBox>{error}</ErrorBox>}
          <FinalForm
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <TextInput
                  name="email"
                  component="input"
                  type="text"
                  validationType="required"
                  label="Email Address"
                  showAppend
                />
                <TextInput
                  name="password"
                  component="input"
                  type="password"
                  validationType="required"
                  className="mt-0"
                  label="Password"
                  showAppend
                />
                <LoginLinksContainer>
                  <Link to="/forgot-password">Forgot password?</Link>
                  <Button disabled={submitting} type="submit">
                    Sign In
                  </Button>
                </LoginLinksContainer>
              </form>
            )}
          ></FinalForm>
        </>
      )}
    </PublicTemplate>
  );
};

export default SignIn;
