import React, { useState, useEffect, useRef } from 'react';
import { Form, Field } from 'react-final-form';
import Icon from '@/elements/Icons';
import {
  InputWrap,
  SelectWrap,
  CheckBoxWrap,
  FormWrap,
  InputGroupWrap,
  FileInputWrap,
  CheckboxGroupWrap,
  SearchInputWrap,
  PhotoUploaded,
  PhotoUploadFieldWrap,
  ToggleWrap,
  MultipleChoiceWrap,
} from './style';
import Uploader from '@/components/Uploader';
import defaultPic from '@/static/images/default-pic.jpg';
import { FieldArray } from 'react-final-form-arrays';
import DatePicker from 'react-datepicker';
import './datepicker.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const getValidator = (type, value) => {
  let re;

  const validType = type && type.indexOf(',') ? type.split(',')[0] : type;
  const checkRequired = () =>
    type && type.indexOf('required') > -1
      ? value
        ? undefined
        : 'Required'
      : undefined;

  switch (true) {
    case validType === 'requiredArray':
      return !value || !value.length ? 'Required' : undefined;
    case validType === 'isNumber':
      return value
        ? isNaN(value)
          ? 'Please enter number'
          : null
        : checkRequired();
    case validType === 'isEmail':
      re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return value
        ? !re.test(value)
          ? 'Please enter a valid email'
          : null
        : checkRequired();
    case validType === 'isName':
      re = /^[A-Za-z\s]+$/;
      return value
        ? !re.test(value)
          ? 'Please enter a valid value'
          : null
        : checkRequired();
    case /^isExceed/.test(validType):
      return value
        ? value.length > validType.split('-').pop()
          ? 'Exceeded word limit'
          : null
        : checkRequired();
    case validType === 'hasTextNote':
      var htmlObject = document.createElement('div');
      htmlObject.innerHTML = value;
      // console.log(htmlObject.textContent.length > 1)
      return htmlObject.textContent.length > 1 ? null : 'Required';
    default:
      return checkRequired();
  }
};

export const shouldHighlight = (meta, props) => {
  return (
    meta.touched &&
    props.validationType &&
    (props.validationType.indexOf('required') > -1 ? true : props.value)
  );
};

export const FinalForm = (props) => (
  <FormWrap>
    <Form {...props} />
  </FormWrap>
);

export const HiddenInput = (props) => (
  <InputWrap>
    <Field {...props}>
      {({ input }) => (
        <div className="gCaptcha-field">
          <input {...input} value={props.value} />
        </div>
      )}
    </Field>
  </InputWrap>
);

export const FileInput = (props) => (
  <FileInputWrap>
    <Field
      {...props}
      validate={(value) => getValidator(props.validationType, value)}
    >
      {({ input, meta }) => (
        <div
          className={`input-file ${
            meta.touched ? (meta.invalid ? 'invalid' : 'valid') : ''
          } ${props.disabled ? 'disabled' : ''}`}
        >
          <label>{`${props.label}${
            props.validationType &&
            props.validationType.indexOf('required') > -1
              ? '*'
              : ''
          }`}</label>
          <Uploader
            input={input}
            label="Max 100MB"
            folder={props.folder}
            value={props.value}
            single={props.single}
            handleRemove={props.handleRemove}
          ></Uploader>
        </div>
      )}
    </Field>
  </FileInputWrap>
);

export const PhotoUploadField = ({ name, defaultImage, ...props }) => (
  <PhotoUploadFieldWrap>
    <Field name={name}>
      {({ input: { value, onChange, ...input }, meta }) => {
        const handleChange = ({ target }) => {
          onChange(target.files[0]); // instead of the default target.value

          const output = document.getElementById(name);
          const reader = new FileReader();
          const file = target.files[0];
          if (!file) {
            return;
          }
          if (!file.type) {
            return;
          }
          if (!file.type.match('image.*')) {
            return;
          }
          reader.addEventListener('load', (event) => {
            output.src = event.target.result;
          });
          reader.readAsDataURL(file);
        };

        return (
          <div
            className={`input-file ${
              meta.touched ? (meta.invalid ? 'invalid' : 'valid') : ''
            }`}
          >
            <label>{`${props.label}${props.validationType ? '*' : ''}`}</label>
            <PhotoUploaded>
              <img
                src={defaultImage ? defaultImage : defaultPic}
                id={name}
                alt=""
              />
            </PhotoUploaded>
            <input
              type="file"
              id={`${name}-fileupload`}
              {...input}
              {...props}
              className="hidden"
              onChange={handleChange}
              accept=".jpg, .jpeg, .png"
            />
            <label htmlFor={`${name}-fileupload`} className="file-upload-label">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.712"
                height="15"
                viewBox="0 0 19.712 15"
              >
                <g id="camera-1" transform="translate(0.005 -2.875)">
                  <circle
                    id="Ellipse_77"
                    data-name="Ellipse 77"
                    cx="3.079"
                    cy="3.079"
                    r="3.079"
                    transform="translate(9.03 8.211)"
                    fill="#00ADE8"
                  />
                  <path
                    id="Path_6531"
                    data-name="Path 6531"
                    d="M17.6,5.588h-1.08a.438.438,0,0,1-.395-.284c-.507-1.071-1.032-2.179-2.173-2.179h-4.1c-.879,0-1.343.639-2.183,1.8-.315.433-.419.664-.693.664H2.049C.355,5.588,0,6.58,0,7.413v8.528A1.85,1.85,0,0,0,2.1,17.9H17.6a1.85,1.85,0,0,0,2.1-1.961V7.413C19.7,6.58,19.339,5.588,17.6,5.588Zm-.775,5.747a4.721,4.721,0,1,1-4.721-4.721,4.721,4.721,0,0,1,4.721,4.721ZM4.1,8.667A1.026,1.026,0,1,1,3.078,7.64,1.026,1.026,0,0,1,4.1,8.667Z"
                    transform="translate(0 -0.045)"
                    fill="#00ADE8"
                  />
                  <path
                    id="Path_6532"
                    data-name="Path 6532"
                    d="M2.41,4.709l2.463.013h0a.41.41,0,0,0,.41-.41V4.106A1.231,1.231,0,0,0,4.052,2.875H3.231A1.231,1.231,0,0,0,2,4.106V4.3A.41.41,0,0,0,2.41,4.709Z"
                    transform="translate(-0.359)"
                    fill="#00ADE8"
                  />
                </g>
              </svg>
              Upload
            </label>
          </div>
        );
      }}
    </Field>
  </PhotoUploadFieldWrap>
);

export const DatePickerInput = (props) => {
  const { validationType, type, placeholder, label, minDate, maxDate } = props;
  const startRef = useRef();

  const value = props.value && !isNaN(new Date(props.value)) ? props.value : '';
  const onKeyDown = (e) => {
    if (e.keyCode === 9 || e.which === 9) {
      startRef.current.setOpen(false);
    }
  };
  return (
    <InputWrap>
      <Field
        validate={(value) => getValidator(validationType, value)}
        type={type}
        {...props}
        value={value}
      >
        {({ input, meta }) => (
          <div
            className={`input-text ${
              shouldHighlight(meta, props)
                ? meta.invalid
                  ? 'invalid'
                  : 'valid'
                : ''
            }`}
          >
            <label className={`${meta.error && meta.touched ? 'error' : ''}`}>
              {label ? (
                `${label}${
                  validationType && validationType.indexOf('required') > -1
                    ? '*'
                    : ''
                }`
              ) : (
                <>
                  <br />
                </>
              )}
            </label>
            <DatePicker
              selected={value && new Date(value)}
              className={meta.active || meta.dirty ? 'active' : ''}
              placeholderText={placeholder}
              minDate={new Date(minDate)}
              maxDate={new Date(maxDate)}
              autoComplete={'off'}
              ref={startRef}
              onKeyDown={onKeyDown}
              {...props}
              {...input}
              value={value}
            >
              {props.children}
            </DatePicker>
            {meta.error && meta.touched && (
              <small className="error">{meta.error}</small>
            )}
            {props.showAppend && (
              <div className="append">
                {shouldHighlight(meta, props) ? (
                  meta.invalid ? (
                    <Icon.AlertFilled />
                  ) : (
                    <Icon.TickFilled />
                  )
                ) : (
                  ''
                )}
              </div>
            )}
          </div>
        )}
      </Field>
    </InputWrap>
  );
};

const calcState = (value) => {
  if (value) {
    const contentBlock = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  } else {
    EditorState.createEmpty();
  }
};

export const WysiwygInput = (props) => {
  let initialValue = props.initialValue;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const handleOnChange = (editorState, input) => {
    setEditorState(editorState);
    input.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  useEffect(() => {
    setEditorState(calcState(initialValue));
  }, [initialValue]);

  return (
    <InputWrap>
      <Field
        validate={(value) => getValidator(props.validationType, value)}
        type={props.type}
        {...props}
      >
        {({ input, meta }) => (
          <div
            className={`input-text ${
              meta.active || meta.dirty ? 'active' : ''
            } ${
              shouldHighlight(meta, props)
                ? meta.invalid
                  ? 'invalid'
                  : 'valid'
                : ''
            }`}
          >
            <label>{`${props.label}${
              props.validationType &&
              props.validationType.indexOf('required') > -1
                ? '*'
                : ''
            }`}</label>
            {meta.error && meta.touched && (
              <small className="error">{meta.error}</small>
            )}

            <Editor
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              toolbar={{
                options: ['inline', 'list'],
                inline: {
                  inDropdown: false,
                  options: ['bold', 'italic', 'underline'],
                },
              }}
              onBlur={input.onBlur}
              onFocus={input.onFocus}
              stripPastedStyles={true}
              onEditorStateChange={(editorState) =>
                handleOnChange(editorState, input)
              }
            />
          </div>
        )}
      </Field>
    </InputWrap>
  );
};

export const SearchInput = (props) => {
  const { options, optionKey, value } = props;
  const [showList, setShowList] = useState(false);
  const filtered = options.filter(
    (x) => value && x[optionKey].toLowerCase().indexOf(value.toLowerCase()) > -1
  );
  return (
    <InputWrap>
      <Field
        validate={(value) => getValidator(props.validationType, value)}
        type={props.type}
        {...props}
      >
        {({ input, meta }) => (
          <>
            <div
              className={`input-text ${
                shouldHighlight(meta, props)
                  ? meta.invalid
                    ? 'invalid'
                    : 'valid'
                  : ''
              }`}
            >
              <input
                {...input}
                onChange={(e) => {
                  input.onChange(e);
                  setShowList(true);
                }}
                value={props.value}
                type={props.type}
                className={meta.active || meta.dirty ? 'active' : ''}
                placeholder={props.placeholder}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 27 || e.key === 'Esc' || e.key === 'Escape') {
                    setShowList(false);
                  }
                }}
              />
              {props.label && (
                <>
                  <label
                    className={`${meta.error && meta.touched ? 'error' : ''}`}
                  >{`${props.label}${
                    props.validationType &&
                    props.validationType.indexOf('required') > -1
                      ? '*'
                      : ''
                  }`}</label>
                  {meta.error && meta.touched && (
                    <small className="error">{meta.error}</small>
                  )}
                  {props.showAppend && (
                    <div className="append">
                      {shouldHighlight(meta, props) ? (
                        meta.invalid ? (
                          <Icon.AlertFilled />
                        ) : (
                          <Icon.TickFilled />
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            <SearchInputWrap>
              {filtered.length > 0 && showList && (
                <ul>
                  {filtered.map((x) => (
                    <li
                      onClick={() => {
                        input.onChange(x[optionKey]);
                        setShowList(false);
                      }}
                      key={x.id}
                    >
                      {x[optionKey]}
                    </li>
                  ))}
                </ul>
              )}
            </SearchInputWrap>
          </>
        )}
      </Field>
    </InputWrap>
  );
};

export const TextInput = (props) => {
  return (
    <InputWrap>
      <Field
        validate={(value) => getValidator(props.validationType, value)}
        type={props.type}
        {...props}
      >
        {({ input, meta }) => (
          <div
            className={`input-text ${
              shouldHighlight(meta, props)
                ? meta.invalid
                  ? 'invalid'
                  : 'valid'
                : ''
            }`}
          >
            <input
              {...input}
              //value={props.value}
              type={props.type}
              className={meta.active || meta.dirty ? 'active' : ''}
              placeholder={props.placeholder}
              disabled={props.disabled ? 'disabled' : undefined}
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault();
              }}
            />
            {props.label && (
              <>
                <label
                  className={`${meta.error && meta.touched ? 'error' : ''}`}
                >{`${props.label}${
                  props.validationType &&
                  props.validationType.indexOf('required') > -1
                    ? '*'
                    : ''
                }`}</label>
                {meta.error && meta.touched && (
                  <small className="error">{meta.error}</small>
                )}
                {props.showAppend && (
                  <div className="append">
                    {shouldHighlight(meta, props) ? (
                      meta.invalid ? (
                        <Icon.AlertFilled />
                      ) : (
                        <Icon.TickFilled />
                      )
                    ) : (
                      ''
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </Field>
    </InputWrap>
  );
};

export const TextArea = (props) => {
  const [count, setCount] = useState(0);
  return (
    <InputWrap>
      <Field
        validate={(value) => {
          setCount(value ? value.length : 0);
          return getValidator(props.validationType, value);
        }}
        type={props.type}
        {...props}
      >
        {({ input, meta }) => (
          <div
            className={`input-text ${
              shouldHighlight(meta, props)
                ? meta.invalid
                  ? 'invalid'
                  : 'valid'
                : ''
            }`}
          >
            <textarea
              {...input}
              type={props.type}
              className={meta.active || meta.dirty ? 'active' : ''}
              placeholder={props.placeholder}
            />

            <label>{`${props.label}${
              props.validationType &&
              props.validationType.indexOf('required') > -1
                ? '*'
                : ''
            }`}</label>
            {meta.error && meta.touched && (
              <small className="error">{meta.error}</small>
            )}
            {props.maxLength && (
              <b className="append">
                {count}/{props.maxLength}
              </b>
            )}
          </div>
        )}
      </Field>
    </InputWrap>
  );
};

export const Checkbox = (props) => (
  <CheckBoxWrap style={props.style}>
    <Field
      type="checkbox"
      validate={(value) => getValidator(props.validationType, value)}
      {...props}
    >
      {({ input, meta }) => (
        <div
          className={`input-checkbox ${
            meta.touched ? (meta.invalid ? 'invalid' : 'valid') : ''
          }`}
        >
          <input
            {...input}
            onChange={(e) => {
              input.onChange(e);
              props.onChange && props.onChange(e);
            }}
            id={'check-' + props.name}
            type="checkbox"
            className={meta.active || meta.dirty ? 'active' : ''}
          />
          <span className="square"></span>
          <span
            htmlFor={'check-' + props.name}
            className="label"
            dangerouslySetInnerHTML={{
              __html: `${props.label}${props.required ? '*' : ''}`,
            }}
          ></span>
          {meta.error && meta.touched && (
            <small className="error">{meta.error}</small>
          )}
        </div>
      )}
    </Field>
  </CheckBoxWrap>
);

export const MultipleChoice = (props) => (
  <MultipleChoiceWrap>
    <label className="label">{`${props.label}${
      props.validationType && props.validationType.indexOf('required') > -1
        ? '*'
        : ''
    }`}</label>
    <Field
      type="radio"
      validate={(value) => getValidator(props.validationType, value)}
      {...props}
    >
      {({ input, meta }) => (
        <div
          className={`input-radio ${
            meta.touched ? (meta.invalid ? 'invalid' : 'valid') : ''
          }`}
        >
          {props.options.map((x, i) => {
            return (
              <span key={i}>
                <input
                  {...input}
                  type="radio"
                  name={props.name}
                  value={x}
                  checked={
                    !meta.touched && input.value === x ? 'checked' : undefined
                  }
                  id={'multiple-choice-' + props.name + i}
                />
                <label htmlFor={'multiple-choice-' + props.name + i}>{x}</label>
              </span>
            );
          })}
          {meta.error && meta.touched && (
            <small className="error">{meta.error}</small>
          )}
        </div>
      )}
    </Field>
  </MultipleChoiceWrap>
);

export const Toggle = (props) => (
  <ToggleWrap>
    <label className="label">{`${props.label}${
      props.validationType && props.validationType.indexOf('required') > -1
        ? '*'
        : ''
    }`}</label>
    <Field
      type="checkbox"
      validate={(value) => getValidator(props.validationType, value)}
      {...props}
    >
      {({ input, meta }) => (
        <div
          className={`${
            meta.touched ? (meta.invalid ? 'invalid' : 'valid') : ''
          }`}
        >
          <label
            className={`switch ${
              meta.touched ? (meta.invalid ? 'invalid' : 'valid') : ''
            }`}
          >
            <input
              {...input}
              type="checkbox"
              className={meta.active || meta.dirty ? 'active' : ''}
              id={'check-' + props.name}
            />
            <span
              className="slider round"
              htmlFor={'check-' + props.name}
            ></span>
          </label>
          {meta.error && meta.touched && (
            <small className="error">{meta.error}</small>
          )}
        </div>
      )}
    </Field>
  </ToggleWrap>
);

export const CheckboxGroup = (props) => {
  return (
    <CheckboxGroupWrap>
      <label className="label">{`${props.label}${
        props.validationType && props.validationType.indexOf('required') > -1
          ? '*'
          : ''
      }`}</label>
      <FieldArray
        name="type"
        validate={(value) => getValidator(props.validationType, value)}
        {...props}
      >
        {({ fields, options, meta }) => {
          return (
            <>
              {options.map((option, i) => {
                const checkboxId = `check-${props.name}-${i}`;

                const isChecked =
                  (!meta.touched && fields.value?.includes(option)) ||
                  props.checked
                    ? true
                    : undefined;

                const toggle = (event, option) => {
                  if (event.target.checked) {
                    fields.push(option);
                  } else {
                    const index = fields.value.indexOf(option);
                    fields.remove(index);
                  }
                };

                return (
                  <CheckBoxWrap
                    key={i}
                    className={`${props.disabled ? 'disabled' : ''}`}
                  >
                    <div
                      className={`input-checkbox ${
                        meta.touched ? (meta.invalid ? 'invalid' : 'valid') : ''
                      }`}
                    >
                      <input
                        id={checkboxId}
                        type="checkbox"
                        key={option}
                        checked={isChecked}
                        disabled={props.disabled}
                        onChange={(event) => toggle(event, option)}
                      />
                      <span className="square"></span>
                      <span
                        htmlFor={checkboxId}
                        className={`label`}
                        dangerouslySetInnerHTML={{
                          __html: option,
                        }}
                      ></span>
                    </div>
                  </CheckBoxWrap>
                );
              })}
              {meta.error && meta.touched && (
                <small className="error">{meta.error}</small>
              )}
            </>
          );
        }}
      </FieldArray>
    </CheckboxGroupWrap>
  );
};

export const CheckboxGroupClients = (props) => {
  return (
    <CheckboxGroupWrap>
      <label className="label">{`${props.label}${
        props.validationType && props.validationType.indexOf('required') > -1
          ? '*'
          : ''
      }`}</label>
      <FieldArray
        name="type"
        validate={(value) => getValidator(props.validationType, value)}
        {...props}
      >
        {({ fields, options, meta }) => {
          return (
            <>
              {options.map((option, i) => {
                const toggle = (event, option) => {
                  if (event.target.checked) {
                    fields.push(option);
                  } else {
                    const index = fields.value.indexOf(option);
                    fields.remove(index);
                  }
                };

                const checkboxId = `check-${props.name}-${i}`;
                const isChecked =
                  !meta.touched && fields.value?.includes(option.value)
                    ? true
                    : undefined;

                return (
                  <CheckBoxWrap key={i}>
                    <div
                      className={`input-checkbox ${
                        meta.touched ? (meta.invalid ? 'invalid' : 'valid') : ''
                      }`}
                    >
                      <input
                        id={checkboxId}
                        type="checkbox"
                        key={option.value}
                        checked={isChecked}
                        onChange={(event) => toggle(event, option.value)}
                      />
                      <span className="square"></span>
                      <span
                        htmlFor={checkboxId}
                        className="label"
                        dangerouslySetInnerHTML={{
                          __html: option.label,
                        }}
                      ></span>
                    </div>
                  </CheckBoxWrap>
                );
              })}
              {meta.error && meta.touched && (
                <small className="error">{meta.error}</small>
              )}
            </>
          );
        }}
      </FieldArray>
    </CheckboxGroupWrap>
  );
};

export const SelectList = (props) => (
  <SelectWrap>
    <Field
      validate={(value) => getValidator(props.validationType, value)}
      type={props.type}
      {...props}
    >
      {({ input, meta }) => (
        <div
          className={`input-text ${
            meta.touched ? (meta.invalid ? 'invalid' : 'valid') : ''
          } ${props.disabled ? 'disabled' : undefined}`}
        >
          <select
            {...input}
            type={props.type}
            disabled={props.disabled}
            className={meta.active || meta.dirty ? 'active' : ''}
            onChange={(e) => {
              input.onChange(e);
              props.selectOnChange && props.selectOnChange(e);
            }}
          >
            {props.children}
          </select>

          <label>{`${props.label}${
            props.validationType &&
            props.validationType.indexOf('required') > -1
              ? '*'
              : ''
          }`}</label>
          {meta.error && meta.touched && (
            <small className="error">{meta.error}</small>
          )}
        </div>
      )}
    </Field>
  </SelectWrap>
);

export const InputGroup = (props) => (
  <InputGroupWrap>{props.children}</InputGroupWrap>
);
