import styled, { css } from 'styled-components';
import { colour } from '@/styles/variables';

export const FormWrap = styled.div``;

export const InputWrap = styled.div`
  width: 100%;
  padding: 0;

  .input-text {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 5px;

    .append {
      position: absolute;
      right: 10px;
      top: 34px;
      z-index: 1;
    }

    .rdw-editor {
      &-wrapper {
        background: ${colour.lightGrey};
        border-radius: 10px;
        margin: 10px 0;
        width: 100%;
        cursor: initial;
      }
      &-toolbar {
        background: #fff;
        border-radius: 10px 10px 0 0;
      }
      &-main {
        height: 150px;
        padding: 0 15px;
      }
    }

    .react-datepicker {
      &-wrapper {
        width: 100%;
      }
      &__month-container {
        float:none;
      }
      &__input-container {
        display: flex;
        &:before {
          content: url('data:image/svg+xml; utf8, <svg height="13" viewBox="0 0 13 13" width="13" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><rect height="10.667" rx="1.5" width="12" x=".5" y="1.833"/><path d="m.5 5.833h12"/><path d="m3.401 3.256v-2.756"/><path d="m8.914 3.256v-2.756"/></g></svg>');
          position: absolute;
          top: 25px;
          left: 14px;
          z-index: 2;
        }
        input {
          padding-left: 35px;
        }
      }
      &-year-header {
        padding: 8px;
      }
      &__month-text {
        padding: 5px;
      }
      &-popper {
        z-index: 2;
      }
    }

    &.active {
      .rdw-editor-wrapper {
        border: 1px solid ${colour.blue};
      }
    }

    &.invalid {
      border-color: ${colour.red};
      .append {
        color: ${colour.red};
      }
      input,
      textarea,
      select {
        color: ${colour.red};
        border-color: ${colour.red};
        box-shadow: 0 0 15px rgba(232, 72, 85, 0.1);
      }
      .rdw-editor-wrapper {
        border: 1px solid ${colour.red};
        box-shadow: 0 0 15px rgba(232, 72, 85, 0.1);
      }
    }

    &.valid {
      border-color: ${colour.lightGreen};
      .append {
        color: ${colour.lightGreen};
      }
      input,
      textarea,
      select {
        border-color: ${colour.lightGreen};
        box-shadow: 0 0 15px rgba(3, 173, 232, 0.1);
      }
      .rdw-editor-wrapper {
        border: 1px solid ${colour.lightGreen};
        box-shadow: 0 0 15px rgba(3, 173, 232, 0.1);
      }
    }

    input,
    textarea,
    select {
      &:disabled {
        background-color: #ddd;
      }
    }
  }

  label {
    font-size: 12px;
    transition: all 0.3s;
    text-align: left;
    width: 100%;
  }

  small {
    color: #575757;
    padding: 5px 0 5px 0;
    position: absolute;
    top: -5px;
    right: 0px;
    font-weight: 900;
    &.error {
      color: ${colour.red};
      line-height: 1.3;
    }
  }

  input,
  textarea,
  select {
    border: none;
    border: 1px solid transparent;
    background: ${colour.lightGrey};
    border-radius: 10px;
    position: relative;
    z-index: 1;
    margin: 10px 0;
    height: 40px;
    width: 100%;
    order: 2;
    font-size: 14px;
    line-height: 30px;
    padding: 0px 15px 0 15px;
    font-family: 'Gilroy', sans-serif;
    color: ${colour.black};
    &::placeholder {
      color: ${colour.grey};
    }
    &:focus,
    &.active {
      outline: none;
      border-color: ${colour.blue};
      ~ label {
        &.error {
          color: ${colour.red};
        }
      }
    }
  }

  textarea {
    padding-top: 15px;
    min-height: 150px;
    -ms-overflow-style: none;
  }
`;

export const CheckBoxWrap = styled.label`
  color: #575757;
  transition: all 0.3s;
  text-align: left;
  cursor: pointer;
  width: 100%;
  padding: 4px 12px;

  &.disabled {
    pointer-events: none;
    cursor: normal;
    opacity: 0.8;
  }

  .input-checkbox {
    display: flex;
    align-items: flex-start;

    &:hover {
      .square {
        box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
  .square {
    border: 1px solid transparent;
    background: ${colour.lightGrey};
    width: 18px;
    height: 18px;
    border-radius: 3px;
    margin-right: 10px;
    transition: all 0.3s;
    display: flex;
    flex-shrink: 0;
    position: relative;
  }
  .invalid {
    .square {
      border-color: ${colour.red};
    }
  }
  .label {
    transition: all 0.3s;
    width: 100%;
    line-height: 1.5;
    font-size: 12px;
    a {
      color: ${colour.grey};
    }
  }
  input {
    display: none;
    &:checked {
      & ~ .square {
        border: 1px solid ${colour.blue};
        &:before {
          content: "";
          width: 85%;
          margin-left: 9%;
          position: absolute;
          height: 100%;
          background: no-repeat center / 67% url('data:image/svg+xml,<svg enable-background="new 0 0 9.4 6.7" viewBox="0 0 9.4 6.7" xmlns="http://www.w3.org/2000/svg"><path d="m.7 3.5 2.6 2.5 5.4-5.3" fill="none" stroke="%2303ade8" stroke-linecap="round"/></svg>');
        }
      }
      & ~ .label {
        /* color: ${colour.grey}; */
      }
    }
    &:disabled{
      & ~ .square {
        opacity: .3;
        cursor: default;
      }
    }
  }
  .error {
    color: ${colour.red};
  }
`;

export const SelectWrap = styled(InputWrap)`
  select {
    appearance: none;
    background-image: url('data:image/svg+xml,<svg enable-background="new 0 0 10.7 6.1" viewBox="0 0 10.7 6.1" xmlns="http://www.w3.org/2000/svg"><path d="m.4.4 5 5 5-5" fill="none" stroke="%23363537"/></svg>');
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 10px;
    &::-ms-expand {
      display: none;
    }
    &:required:invalid {
      color: ${colour.grey};
    }
    option {
      color: #000;
    }
    &:disabled {
      pointer-events: none;
      background-color: #ddd;
    }
  }
`;
export const SearchInputWrap = styled.div`
  ul {
    padding: 0;
    background: #fff;
    border-radius: 10px;
    padding: 10px 15px;
    list-style: none;
    box-shadow: 0 0 30px rgba(54, 53, 55, 0.11);
    position: absolute;
    z-index: 3;
  }
  li {
    padding: 10px 0;
    cursor: pointer;
  }
`;
export const FileInputWrap = styled.div`
  .input-file {
    text-align: left;
    &.invalid {
      .file-input {
        border-color: ${colour.red};
      }
    }
    & > label {
      font-size: 12px;
      display: block;
      margin-bottom: 10px;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
`;
export const CheckboxGroupWrap = styled.div`
  text-align: left;
  margin: 10px 0;
  & > .label {
    font-size: 12px;
    display: block;
    margin-bottom: 10px;
  }

  & > small {
    display: block;
    color: ${colour.red};
    flex: 0 0 100%;
    max-width: 100%;
  }
  label:not(.label) {
    padding: 4px 0;
    margin: 0px 10px 10px 0;
    display: inline-flex;
    width: auto;
  }
  /* .input-checkbox{
    display: inline-flex;
  } */
`;

export const ToggleWrap = styled.div`
  text-align: left;
  & > .label {
    font-size: 12px;
    display: block;
    margin-bottom: 10px;
  }

  & > small {
    display: block;
    color: ${colour.red};
    flex: 0 0 100%;
    max-width: 100%;
  }
  & > label:not(.label) {
    padding: 4px 0;
    margin: 0px 10px 10px 0;
    display: inline-flex;
    width: auto;
  }
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f1f3f4;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: #b1b1b1;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    &:before {
      background-color: ${({ theme }) => theme.colour.primary};
    }
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

export const ButtonGroupContainer = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};

  button {
    &:first-child {
      margin-left: 15px;
      order: 2;
    }
    &:nth-child(2) {
      order: 1;
    }
  }

  ${({ isTall }) =>
    isTall &&
    css`
      position: absolute;
      bottom: 15px;
      right: 15px;
      z-index: 5;
      width: calc(100% - 30px);
      background: white;
      padding-top: 15px;
    `}
`;

export const InputGroupWrap = styled.div`
  display: flex;
  position: relative;
  input {
    border-radius: 60px;
  }
  button {
    box-shadow: none;
    border-radius: 60px;
    height: 60px;
    margin-top: 15px;
    margin-bottom: 5px;
    position: absolute;
    right: 0;
    z-index: 1;
  }
`;

export const PhotoUploaded = styled.div`
  margin-top: 5px;
  height: 125px;
  width: 125px;
  background-color: ${colour.lightGrey};

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const PhotoUploadFieldWrap = styled.div`
  margin: 15px 0;
  position: relative;

  label {
    transition: all 0.3s;
    width: 100%;
    line-height: 1.5;
    font-size: 12px;
    a {
      color: ${colour.grey};
    }
  }

  small.error {
    display: block;
    margin-top: 5px;
    color: ${colour.red};
  }

  .hidden {
    display: none;
  }

  .file-upload-label {
    background-color: white;
    border: 1px solid ${({ theme }) => theme.colour.primary};
    color: ${({ theme }) => theme.colour.primary};
    padding: 10px;
    font-weight: 800;
    font-size: 16px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 100px;
    width: 100px;
    border-radius: 10px;
    text-align: center;

    svg {
      margin-right: 7px;
      margin-bottom: -1px;
    }
  }
`;

export const MultipleChoiceWrap = styled.div`
  margin: 0 0 15px;
  text-align: left;

  & > .label {
    font-size: 12px;
    display: block;
    margin-bottom: 10px;
  }

  .input-radio {
    display: flex;
    flex-direction: row;
    width: 100%;
    input[type='radio'] {
      display: none;
    }
    > span {
      display: inline-block;
    }
    label {
      flex: 1 1 auto;
      border: solid 1px #b1b1b1;
      color: #b1b1b1;
      border-radius: 10px;
      cursor: pointer;
      display: block;
      padding: 10px 15px;
      margin-right: 15px;
    }
    *:checked + label {
      border-color: ${({ theme }) => theme.colour.primary};
      color: ${({ theme }) => theme.colour.primary};
    }
  }
`;
