import styled from 'styled-components';
import { colour, font } from '@/styles/variables';

export const InfoCardItem = styled.div`
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  .wysiwyg{
    ul, ol{
      padding: 0 0 0 20px;
    }
    p{
      margin-top: 0;
    }
  }
  .info {
    &-title {
      font-size: 14px;
      line-height: 1;
      margin-bottom: 5px;
    }
    &-content {
      font-weight: 600;
    }
  }
`;
export const InfoCardWrapper = styled.div`
  position: relative;
  padding: 20px;
  background-color: ${colour.lightGrey};
  border-radius: 10px;

  .collapse-btn {
    font-size: 16px;
    font-weight: 600;
    color: ${colour.blue};
    background-color: transparent;
    padding: 0;
    border: 0;
    font-family: ${font.primary};
    cursor: pointer;
  }
`;

export const InfoCardTitleContainer = styled.div`
  margin-bottom: 10px;
`;

export const InfoCardEditContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  button {
    color: ${colour.blue};
    cursor: pointer;
    background: transparent;
    border: 0;
    padding: 0;
    font-size: 14px;
    line-height: 1;
  }
`;

export const CollapseContainer = styled.div`
  height: ${({ isOpen }) => (isOpen ? 'auto' : 0)};
  overflow: hidden;
`;
