import React, { useState, useEffect, useContext } from 'react';
import LoggedInTemplate from '@/templates/loggedIn';
import Type from '@/elements/Type';
import Button from '@/elements/Button';
import Loader from '@/elements/Loader';
import AddRequirement from './AddRequirement';
import { Col, Row, Grid } from 'react-styled-flexboxgrid';
import ConsultingRequirementCard from '@/components/ConsultingRequirementCard';
import {
  useRouteMatch,
  withRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import db from '@/utils/database';
import { formatDateForHumans } from '@/utils/dates';
import { s3RemoveFile } from '@/utils/awsLib';
import Modal from '@/elements/Modal';
import { AuthContext } from '@/context/auth';
import { DataStore } from '@aws-amplify/datastore';
import { Hub } from 'aws-amplify';
import { ConsultingRequirement } from '@/models';
import { Spacer } from '@/elements/Spacer/style';
import { isConsultingClient } from '@/utils/clientTypeHelpers';

async function getRequirements(setFilteredRequirements) {
  try {
    const requirements = await db.getAll('consultingRequirement');

    setFilteredRequirements(requirements);
  } catch (error) {
    console.warn(error + 'requirements');
  }
}

const Requirement = (props) => {
  const { user, areas, organization } = useContext(AuthContext);
  let { path } = useRouteMatch();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [target, setTarget] = useState();
  const [requirements, setRequirements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initing, setIniting] = useState(false);
  const activeOrganization = localStorage.getItem('organization');

  useEffect(() => {
    if (!user) {
      return;
    }

    const setFilteredRequirements = (data) => {
      const filterRequirements = data
        .filter((x) => {
          if (!x.organization) {
            return false;
          }

          if (!activeOrganization) {
            return false;
          }

          return x.organization?.id === activeOrganization;
        })
        .filter((x) => {
          if (
            !areas ||
            !user.attributes['custom:organization'] ||
            user.attributes['custom:organization'] === 'null'
          ) {
            return true;
          }

          return areas.filter((area) => x.areas.includes(area)).length > 0;
        });

      setRequirements(filterRequirements);
      setLoading(false);
    };

    getRequirements(setFilteredRequirements);

    const listenerData = (capsule) => {
      const {
        payload: { event },
      } = capsule;

      if (event === 'outboxStatus' || event === 'ready') {
        setIniting(false);
      } else {
        setIniting(requirements.length > 0);
      }
      if (event === 'ready') {
        getRequirements(setFilteredRequirements);
      }
    };
    Hub.listen('datastore', listenerData);

    const subscription = DataStore.observe(ConsultingRequirement).subscribe(
      (msg) => {
        getRequirements(setFilteredRequirements);
      }
    );

    const handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';

      if (condition === 'online') {
        getRequirements(setFilteredRequirements);
      }
    };

    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      subscription.unsubscribe();
      Hub.remove('datastore', listenerData);

      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, areas, activeOrganization]);

  const handleDelete = async (id) => {
    const req = requirements.filter((x) => x.id === id);
    let profs = await db.getAll('profile');
    profs = profs.filter(
      (x) => x.consultingRequirement && x.consultingRequirement.id === id
    );

    //delete all profiles asoicate with the requirement
    profs.map((x) => db.delete('profile', x.id));
    //delete all job deccription files
    req.jobDescriptionFile &&
      req.jobDescriptionFile.map((x) => {
        const filename = `${x.folder}/${x.serverFileName}`;
        return s3RemoveFile(filename)
          .then((result) => {
            console.log(result);
          })
          .catch((err) => console.log(err));
      });
    //delete the requirement
    await db.delete('consultingRequirement', id);
  };

  if (!isConsultingClient(organization) && organization) {
    return <Redirect push to="/projects" />;
  }

  return (
    <Switch>
      <Route exact path={path}>
        <LoggedInTemplate hasTabs hasYPadding>
          <Modal
            title="Are you sure you wish to delete?"
            intent="danger"
            type="action"
            buttonText="Yes, I'm sure"
            isShown={showDeleteDialog}
            handleModalState={setShowDeleteDialog}
            handleAction={() => handleDelete(target.id)}
            shouldCloseOnEscapePress={false}
            shouldCloseOnOverlayClick={false}
          >
            <p>
              All profiles and job descriptions files assoiate with this
              requirement will be deleted.
            </p>
          </Modal>

          {loading || initing ? (
            <Loader overlay />
          ) : (
            <Grid>
              <Row between="xs" middle="xs">
                <Col xs={12} sm={6}>
                  <Type.H6 hasShadow>Position Requirements</Type.H6>
                </Col>
                <Col>
                  <Button floatingButtonMobile href={`${path}/add`}>
                    Add New
                  </Button>
                </Col>
              </Row>
              <Spacer desktop={20} />
              <Row>
                {requirements.length > 0 ? (
                  requirements.map((x) => (
                    <Col
                      key={x.id}
                      xs={12}
                      sm={4}
                      md={3}
                      style={{ marginBottom: 20 }}
                    >
                      <ConsultingRequirementCard
                        title={x.title}
                        description={`${formatDateForHumans(
                          new Date(x.idealStartDate),
                          'dd LLLL'
                        )} • ${x.duration ? x.duration + ' Months • ' : ''} ${
                          x.numberOfPositions
                        } Positions`}
                        statusText="Checking Bench"
                        statusColor="blue"
                        icon="Prototype"
                        to={`/profiles?reqId=${x.id}`}
                        handleEdit={() =>
                          props.history.push(`/requirements/add?id=${x.id}`)
                        }
                        handleDelete={() => {
                          setTarget(x);
                          setShowDeleteDialog(true);
                        }}
                        requirement={x}
                      />
                    </Col>
                  ))
                ) : (
                  <Col xs={12}>
                    {!loading && (
                      <Type.P8>
                        Please click the "Add New" button to create a new
                        position
                      </Type.P8>
                    )}
                  </Col>
                )}
              </Row>
            </Grid>
          )}
        </LoggedInTemplate>
      </Route>
      <Route path={`${path}/add`}>
        <AddRequirement />
      </Route>
    </Switch>
  );
};

export default withRouter(Requirement);
