import styled from 'styled-components';
import { breakpoint, colour } from '@/styles/variables';

export const Divider = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  width: 100%;
  opacity: 0.1;
  background-color: ${colour.charcoal};
  margin: ${({ desktop, mobile }) =>
    mobile ? `${mobile}px auto` : `${desktop}px auto`};

  @media (min-width: ${breakpoint.min.medium}) {
    margin: ${({ desktop, mobile }) =>
      desktop ? `${desktop}px auto` : `${mobile}px auto`};
  }
`;
