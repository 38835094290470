import styled from 'styled-components';
import { colour } from '@/styles/variables';

export const SuccessBox = styled.div`
  border: 1px solid ${colour.green};
  border-radius: 5px;
  background-color: ${colour.green};
  color: white;
  padding: 5px;
  font-weight: 700;
  margin: 15px auto 20px;
`;
