import React from 'react';
import * as Style from './style';

const Button = (props) =>
  props.href ? (
    <Style.ButtonLink {...props} />
  ) : props.to ? (
    <Style.ButtonRouteLink {...props} />
  ) : (
    <Style.Button {...props} />
  );

export default Button;
