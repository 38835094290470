/**
 * calculate the time difference
 * @param {Date} startTime
 * @param {Date} endTime
 * @returns {Error|string}
 * @constructor
 */
export const TimeDiff = (
  startTime,
  endTime,
  futureText,
  pastText,
  tenseFromStart = false
) => {
  startTime = new Date(startTime);

  endTime = endTime ? new Date(endTime) : new Date();

  const startTimeInMS = startTime.getTime();

  const endTimeInMS = endTime.getTime();

  if (!startTimeInMS) return new Error('Invalid start date');

  if (!endTimeInMS) return new Error('Invalid end date');

  /**
   * time difference in milliseconds
   * @type {number}
   */
  let timeDifference = endTimeInMS - startTimeInMS;

  const tense = timeDifference > 0 ? pastText : '';
  const future = timeDifference < 0 ? futureText : '';

  timeDifference = timeDifference > 0 ? timeDifference : -timeDifference;

  /**
   * time difference in seconds
   * @type {number}
   */
  const secondsAgo = timeDifference / 1000;

  /**
   * time difference in minutes
   * @type {number}
   */
  const minutesAgo = secondsAgo / 60;

  /**
   * time difference in hours
   * @type {number}
   */
  const hoursAgo = minutesAgo / 60;

  /**
   * time difference in days
   * @type {number}
   */
  const daysAgo = hoursAgo / 24;

  /**
   * time difference in months
   * @type {number}
   */
  const monthsAgo = daysAgo / 30;

  /**
   * time difference in years
   * @type {number}
   */
  const yearsAgo = monthsAgo / 12;

  if (monthsAgo >= 12) {
    const year = Math.round(yearsAgo);
    if (year === 1) {
      return `${tenseFromStart ? tense : ''}${future}${year} Year${
        tenseFromStart ? '' : tense
      }`;
    } else {
      return `${tenseFromStart ? tense : ''}${future}${year} Years${
        tenseFromStart ? '' : tense
      }`;
    }
  } else if (daysAgo >= 30) {
    const month = Math.round(monthsAgo);
    if (month === 1) {
      return `${tenseFromStart ? tense : ''}${future}${month} Month${
        tenseFromStart ? '' : tense
      }`;
    } else {
      return `${tenseFromStart ? tense : ''}${future}${month} Months${
        tenseFromStart ? '' : tense
      }`;
    }
  } else if (hoursAgo >= 24) {
    const day = Math.round(daysAgo);
    if (day === 1) {
      return `${tenseFromStart ? tense : ''}${future}${day} Day${
        tenseFromStart ? '' : tense
      }`;
    } else {
      return `${tenseFromStart ? tense : ''}${future}${day} Days${
        tenseFromStart ? '' : tense
      }`;
    }
  } else if (minutesAgo >= 60) {
    const hour = Math.round(hoursAgo);
    if (hour === 1) {
      return `${tenseFromStart ? tense : ''}${future}${hour} Hour${
        tenseFromStart ? '' : tense
      }`;
    } else {
      return `${tenseFromStart ? tense : ''}${future}${hour} Hours${
        tenseFromStart ? '' : tense
      }`;
    }
  } else if (secondsAgo >= 60) {
    const minute = Math.round(minutesAgo);
    if (minute === 1) {
      return `${tenseFromStart ? tense : ''}${future}${minute} Minute${
        tenseFromStart ? '' : tense
      }`;
    } else {
      return `${tenseFromStart ? tense : ''}${future}${minute} Minutes${
        tenseFromStart ? '' : tense
      }`;
    }
  } else {
    const second = Math.round(secondsAgo);
    if (second === 1) {
      return `${tenseFromStart ? tense : ''}A moment${
        tenseFromStart ? '' : tense
      }`;
    } else {
      return `${tenseFromStart ? tense : ''}A moment${
        tenseFromStart ? '' : tense
      }`;
    }
  }
};
