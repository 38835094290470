import React, { useState, useEffect } from 'react';
import {
  ProfileWrapper,
  AvatarWrapper,
  NewDot,
  ProfileInfoWrapper,
} from './style';
import { Avatar } from 'evergreen-ui';
import Type from '@/elements/Type';
import { TimeDiff } from '@/utils/timeDiff';
import { datesIsTomorrow } from '@/utils/dates';

const ProfileAvatar = (props) => {
  const { consultantImage, name, role, startDate, endDate } = props;

  const timeText = {
    futureText: 'Starts in ',
    pastText: ' Left',
  };

  const [started, setStarted] = useState(false);

  const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

  useEffect(() => {
    if (startDate && endDate) {
      const now = new Date();
      const startD = new Date(startDate);
      const hasStarted = now > startD && !datesAreOnSameDay(now, startD);
      setStarted(hasStarted);
    }
  }, [startDate, endDate]);

  const getTime = (start, end) => {
    const now = new Date();
    const startDate = new Date(start);
    const endDate = new Date(end);

    if (datesAreOnSameDay(now, endDate)) {
      return 'Ends Today';
    }

    if (datesAreOnSameDay(now, startDate)) {
      return 'Starts Today';
    }

    if (datesIsTomorrow(now, startDate)) {
      return 'Starts Tomorrow';
    }

    if (datesIsTomorrow(now, endDate)) {
      return 'Ends Tomorrow';
    }

    if (endDate < now) {
      return 'Expired';
    }

    return started
      ? TimeDiff(now, endDate, timeText.futureText, timeText.pastText)
      : TimeDiff(startDate, now, timeText.futureText, timeText.pastText);
  };

  return (
    <ProfileWrapper>
      <AvatarWrapper>
        {!started && <NewDot />}
        <Avatar src={consultantImage} name={name} size={55} />
      </AvatarWrapper>
      <ProfileInfoWrapper>
        <Type.H6 className="consultant-name">{name}</Type.H6>
        <Type.P className="consultant-time">
          {startDate && endDate && getTime(startDate, endDate)}
          {(!startDate || !endDate) && 'TBC'}
        </Type.P>
        <Type.P className="consultant-position">{role}</Type.P>
      </ProfileInfoWrapper>
    </ProfileWrapper>
  );
};

export default ProfileAvatar;
