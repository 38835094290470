import db from './database';

/**
 * Creates a notification
 *
 * sample usage:
 * createNotification('A <b>new profile</b> has been added.', '/consultants',  'email@gmail.com');
 *
 * @param {*} content - content of the notification
 * @param {*} url - relative url of the notification
 * @param {*} for - email address of the user to be notified
 */
export const createNotification = async (content, url, user) => {
  const payload = {
    content,
    url,
    for: user,
    seen: false,
    date: JSON.stringify(new Date()),
  };

  try {
    await db.add('notification', payload);
  } catch (e) {
    console.warn('Something went wrong.');
  }
};
