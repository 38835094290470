import React, { useState, useEffect } from 'react';
import LoggedInTemplate from '@/templates/loggedIn';
import { Col, Row, Grid } from 'react-styled-flexboxgrid';
import Type from '@/elements/Type';
import ProjectBlock from '@/pages/app/Projects/ProjectBlock';
import ProjectCard from '@/components/ProjectCard';
import { Spacer } from '@/elements/Spacer/style';
import { orderBy } from 'lodash';
import db from '@/utils/database';
import { DataStore } from '@aws-amplify/datastore';
import { Project } from '@/models';
import { useContext } from 'react';
import { AuthContext } from '@/context/auth';
import { Hub } from 'aws-amplify';
import { ProjectCardsContainer } from './style';
import Loader from '@/elements/Loader';
import { isProjectClient } from '@/utils/clientTypeHelpers';
import { Redirect } from 'react-router-dom';

async function listProjects(filterProjectsAndSet) {
  const projects = await db.getAll('project');
  filterProjectsAndSet(projects);
}

const Projects = (props) => {
  const [activeProject, setActiveProject] = useState(0);

  const [projects, setProjects] = useState();
  const [loading, setLoading] = useState(true);

  const { user, areas, organization } = useContext(AuthContext);
  const activeOrganization = localStorage.getItem('organization');

  useEffect(() => {
    if (!user) {
      return;
    }
    const filterProjectsAndSet = (data) => {
      const filteredProjects = data
        .filter((x) => {
          if (!x.organization) {
            return false;
          }
          if (!x.organization?.id || !x.organization?.id === '-') {
            return false;
          }

          if (!activeOrganization) {
            return false;
          }

          return x.organization?.id === activeOrganization;
        })
        .filter((x) => {
          if (!areas || !user.attributes['custom:organization']) {
            return true;
          }

          return areas.filter((area) => x.areas.includes(area)).length > 0;
        });

      setProjects(
        orderBy(
          filteredProjects,
          [(consultant) => consultant.name?.toLowerCase()],
          ['asc']
        )
      );
      setLoading(false);
    };

    listProjects(filterProjectsAndSet);

    const listenerData = (data) => {
      if (data.payload.event === 'ready') {
        listProjects(filterProjectsAndSet);
      }
    };
    Hub.listen('datastore', listenerData);

    const subscription = DataStore.observe(Project).subscribe((msg) => {
      listProjects(filterProjectsAndSet);
    });

    const handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';

      if (condition === 'online') {
        listProjects(filterProjectsAndSet);
      }
    };

    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      subscription.unsubscribe();
      Hub.remove('datastore', listenerData);

      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, [user, activeOrganization, areas]);

  if (loading) return <Loader overlay />;

  if (projects && projects.length === 0) {
    return (
      <LoggedInTemplate hasTabs hasYPadding>
        <Grid>
          <Row>
            <Col xs={12}>
              <Type.H6>Your Projects</Type.H6>
              <Spacer desktop={30} />
              <Type.P8>There are currently no active projects.</Type.P8>
            </Col>
          </Row>
        </Grid>
      </LoggedInTemplate>
    );
  }

  if (!isProjectClient(organization) && organization) {
    return <Redirect push to="/requirements" />;
  }

  return (
    <LoggedInTemplate hasTabs hasYPadding>
      <Grid>
        <Row>
          <Col xs={12} md={3}>
            <Type.H6>Your Projects</Type.H6>
            <Spacer desktop={30} />
            <ProjectCardsContainer>
              {projects &&
                projects.map((project, index) => (
                  <div key={index}>
                    <ProjectCard
                      isActive={activeProject === index}
                      title={project.name}
                      description={project.description}
                      statusText={project.status}
                      key={index}
                      handleClick={() => {
                        setActiveProject(index);
                      }}
                    />
                    <Spacer desktop={20} />
                  </div>
                ))}
            </ProjectCardsContainer>
          </Col>
          <Col xs={12} md={9}>
            {projects && <ProjectBlock {...projects[activeProject]} />}
          </Col>
        </Row>
      </Grid>
    </LoggedInTemplate>
  );
};

export default Projects;
