import styled, {css} from "styled-components";
import { colour } from "@/styles/variables";

export const Loader = styled.div`
  color:${props=> props.color? props.color : colour.blue};
  font-weight: bold;
  svg{
    width: 80px;
    height: 80px;
  }
  ${props=>props.overlay && css`
    position: fixed;
    width: 100%;
    height: 100%;
    left:0;
    top:0;
    z-index:3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255,.5);
  `}
`;
