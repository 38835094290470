import React, { useState, useEffect, useContext } from 'react';
import Type from '@/elements/Type';
import { Spacer } from '@/elements/Spacer/style';
import db from '@/utils/database';
import { withRouter } from 'react-router-dom';
import Button from '@/elements/Button';
import InfoCard from '@/components/InfoCard';
import arrayMutators from 'final-form-arrays';
import Icons from '@/elements/Icons';
import Step from '@/behaviour/Step';
import { AuthContext } from '@/context/auth';
import { formatDateForHumans } from '@/utils/dates';
import { fetchEmails } from '@/utils/fetchEmails';
import { createNotification } from '@/utils/createNotification';
import { colour } from '@/styles/variables';

import {
  TextInput,
  FinalForm,
  FileInput,
  CheckboxGroup,
  TextArea,
  DatePickerInput,
  SelectList,
} from '@/elements/Form';
import styled from 'styled-components';
import { isUserEditProfile } from '@/utils/isUserAdmin';

const initFile = [
  {
    name: '',
    url: '',
    type: '',
    uploadDate: '',
    folder: '',
    serverFileName: '',
  },
];

const BackButton = styled(Button)`
  margin-top: 30px;
  position: absolute;
  left: 0;
  box-shadow: none;
  background: none;
  color: ${colour.blue};
`;

const combineJobTitle = (title, customTitle) =>
  title === 'Not Available' ? customTitle : title;

const getJobTitle = (title, roles) =>
  roles.filter((x) => !x.archived).some((x) => x.name === title)
    ? { title: title }
    : { title: 'Not Available', customTitle: title };

const RequirementForm = (props) => {
  const { user, areas } = useContext(AuthContext);
  const [requirement, setRequirement] = useState({});
  const [skills, setSkills] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [roles, setRoles] = useState([]);
  const [activeAreas, setActiveAreas] = useState(areas);

  const { setActiveStep, activeStep, requirementId, setShouldBlockNavigation } = props;

  const organization = localStorage.getItem('organization');
  useEffect(() => {
    if (!user) {
      return;
    }
    const fetchData = async () => {
      const skills = await db.getAll('skill');
      setSkills(skills);
      setRoles(await db.getAll('role'));
      if (isUserEditProfile(user)) {
        const orgObj = await db.search('organization', organization, 'id');
        setActiveAreas(orgObj[0].areas);
      }
      if (requirementId) {
        const res = await db.search(
          'consultingRequirement',
          requirementId,
          'id'
        );
        let payload = { ...res[0], ...getJobTitle(res[0].title, roles) };
        payload['jobDescriptionFile'] = JSON.stringify(
          payload['jobDescriptionFile']
        );
        payload['skills'] =
          payload['skills'][0] === '' ? [] : payload['skills'];
        setRequirement(payload);
        setSkills(skills.filter((x) => x.roles.indexOf(payload['title']) > -1));
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requirementId, organization, user]);

  const handleSaveRequirment = async () => {
    setShouldBlockNavigation(false);
    setSubmitting(true);
    let payload = { ...requirement };
    payload['numberOfPositions'] = parseInt(payload['numberOfPositions']);
    payload['organization'] = organization
      ? { id: organization }
      : { id: localStorage.getItem('organization') };
    payload['projectDetails'] = payload['projectDetails']
      ? payload['projectDetails']
      : '';
    payload['duration'] = payload['duration'] ? payload['duration'] : '';
    payload['title'] = combineJobTitle(
      payload['title'],
      payload['customTitle']
    );
    payload['team'] = payload['team'] ? payload['team'] : '';
    payload['skills'] =
      payload['skills'] && payload['skills'].length ? payload['skills'] : [''];
    payload['areas'] =
      payload['areas'] && payload['areas'].length ? payload['areas'] : [''];
    payload['idealStartDate'] =
      typeof requirement.idealStartDate === 'string'
        ? requirement.idealStartDate
        : requirement.idealStartDate.toISOString();
    payload['jobDescriptionFile'] = payload['jobDescriptionFile']
      ? Object.values(JSON.parse(payload['jobDescriptionFile']))
      : initFile;

    if (requirementId) {
      // if update, get profile again
      const res = await db.search('consultingRequirement', requirementId, 'id');
      let currentRequirement = { ...res[0] };

      // overwrite the profile with the new one
      payload['_version'] = currentRequirement._version;
      payload['_lastChangedAt'] = currentRequirement._lastChangedAt;

      const response = await db.update(
        'consultingRequirement',
        payload,
        requirementId
      );
      pushNotification('update', response);
    } else {
      const response = await db.add('consultingRequirement', payload);
      pushNotification('create', response);
    }
    setSubmitting(false);
    props.history.push('/requirements');
  };

  const pushNotification = async (action, requirement) => {
    const pandcEmails = await fetchEmails('p-c');
    const clientEmails = await fetchEmails(
      'client',
      organization,
      requirement.areas
    );
    const partnerEmails = await fetchEmails('partner', organization);
    const salesEmails = await fetchEmails('sales', organization);
    const emails = []
      .concat(partnerEmails, salesEmails, pandcEmails, clientEmails)
      .filter((email) => email !== user.attributes.email);
    emails.map((email) =>
      createNotification(
        `<b>${requirement.title}</b> position has been ${action}d`,
        '/profiles?reqId=' +
          requirement.id +
          '&orgId=' +
          localStorage.getItem('organization'),
        email
      )
    );
  };

  const handleRemoveJobDescription = (filename) => {
    if (
      requirement.jobDescriptionFile &&
      requirement.jobDescriptionFile.indexOf('"name":""') < 0
    ) {
      let payload = requirement;
      let arr = JSON.parse(requirement.jobDescriptionFile).filter(
        (obj) => obj.name !== filename
      );
      arr = arr.length ? arr : initFile;
      payload['jobDescriptionFile'] = JSON.stringify(arr);
      setRequirement(payload);
      if (requirementId) {
        db.update(
          'consultingRequirement',
          { jobDescriptionFile: arr },
          requirementId
        );
      }
    }
  };

  return (
    <Wrap>
      {/* {JSON.stringify(requirement)} */}
      {/* Step 1 */}
      <Step active={activeStep === 0}>
        <Type.H4>New Position Requirement - Step 1</Type.H4>
        <Type.P>All fields are mandatory unless stated otherwise.</Type.P>
        <hr />
        <Type.H6>Overview</Type.H6>
        <FinalForm
          initialValues={{
            ...requirement,
            // duration: "",
            idealStartDate:
              typeof requirement.idealStartDate === 'string' &&
              requirement.idealStartDate !== ''
                ? new Date(requirement.idealStartDate)
                : requirement.idealStartDate,
          }}
          onSubmit={(values) => {
            setShouldBlockNavigation(true);
            setRequirement({ ...requirement, ...values });
            if(!values.isSave) {
              setActiveStep(activeStep + 1);
            } else {
              setActiveStep(parseInt(values.jumpStep));
            }

            setSkills(
              skills.filter((x) => x.roles.indexOf(values['title']) > -1)
            );
          }}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit}>
              {/* {JSON.stringify(values)} */}
              <SelectList
                name="title"
                label="Job Title"
                validationType="required"
                component="select"
                value={values.title}
              >
                <option disabled selected value="">
                  Select a job title
                </option>
                {/* sort roles alphabetically */}
                {roles
                  .filter((x) => !x.archived)
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((x) => (
                    <option key={x.id} value={x.name}>
                      {x.name}
                    </option>
                  ))}
                <option value="Not Available">Not Available</option>
              </SelectList>

              {values.title === 'Not Available' && (
                <TextInput
                  name="customTitle"
                  value={values.customTitle}
                  component="input"
                  type="text"
                  validationType="required"
                  label="Custom Job Title"
                  placeholder="Enter a job title"
                  showAppend
                />
              )}

              <TextInput
                name="numberOfPositions"
                value={values.numberOfPositions}
                component="input"
                type="number"
                validationType="isNumber,required"
                label="Number of Positions"
                placeholder="Enter number"
                showAppend
              />

              <DatePickerInput
                name="idealStartDate"
                value={values.idealStartDate}
                type="text"
                label="Ideal Start Date"
                validationType="required"
                placeholder="DD / MM / YYYY"
                showAppend
              />

              <TextInput
                name="duration"
                value={values.duration}
                component="input"
                type="number"
                validationType="isNumber"
                label="Duration (Months)"
                placeholder="Enter number"
                showAppend
              />

              <FileInput
                name="jobDescriptionFile"
                value={values.jobDescriptionFile}
                handleRemove={handleRemoveJobDescription}
                component="input"
                label="Job Description"
                folder="jobDescription"
              />
              <Button type="submit" hidden className='save' style={{display: 'none'}} onClick={(i) => {values.isSave = true; values.jumpStep = i.currentTarget.getAttribute('step');}}/>
              <BackButton type="button" className='backStep' step={activeStep - 1} withIcon onClick={(i) => {setActiveStep(parseInt(i.currentTarget.getAttribute('step')))}}>
                <Icons.ArrowLeftBlue /> Back
              </BackButton>
              <SubmitButton type="submit" withIcon onClick={() => {
                values.isSave = false;
              }}>
                Next <Icons.ArrowRight />
              </SubmitButton>
            </form>
          )}
        ></FinalForm>
      </Step>

      {/* Step 2 */}
      <Step active={activeStep === 1}>
        <Type.H4>New Position Requirement - Step 2</Type.H4>
        <Type.P>All fields are mandatory unless stated otherwise.</Type.P>
        <hr />
        <Type.H6>Details</Type.H6>
        <FinalForm
          onSubmit={(values) => {
            setShouldBlockNavigation(true);
            setRequirement({
              ...requirement,
              ...values,
              skills:
                requirement.title === 'Not Available'
                  ? values.skills && typeof values.skills === 'string'
                    ? values.skills.split(',')
                    : []
                  : values.skills,
            });
            if(!values.isSave) {
              setActiveStep(activeStep + 1);
            } else {
              setActiveStep(parseInt(values.jumpStep));
            }
          }}
          initialValues={{
            ...requirement,
            areas:
              activeAreas && activeAreas.length === 1 && activeAreas[0] !== ''
                ? activeAreas
                : requirement.areas,
          }}
          mutators={{
            ...arrayMutators,
          }}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit}>
              {/* {JSON.stringify(values)} */}
              <TextArea
                name="projectDetails"
                value={values.projectDetails}
                label="Project"
                validationType="isExceed-800"
                component="textarea"
                maxLength="800"
                placeholder="Provide Details of the project the consultant will be working on."
              />
              <TextArea
                name="team"
                value={values.team}
                label="Team"
                validationType="isExceed-600"
                component="textarea"
                maxLength="600"
                placeholder="What team and work environment will our consultant will be working in."
              />
              {requirement.title === 'Not Available' ? (
                <TextInput
                  name="skills"
                  value={values.skills}
                  component="input"
                  type="text"
                  label="Skills / Tech (Optional)"
                  placeholder="Enter skills separated by comma"
                  showAppend
                />
              ) : (
                <>
                  {skills.length > 0 && (
                    <CheckboxGroup
                      name="skills"
                      value={values.skills}
                      // sort alphabetically
                      options={skills
                        .map((x) => x.name)
                        .sort((a, b) =>
                          a.toUpperCase() > b.toUpperCase() ? 1 : -1
                        )}
                      label="Skills / Tech (Optional)"
                    />
                  )}
                </>
              )}
              {activeAreas && activeAreas.length > 0 && activeAreas[0] !== '' && (
                <CheckboxGroup
                  name="areas"
                  value={values.areas}
                  validationType="requiredArray"
                  // sort alphabetically
                  options={[...activeAreas].sort()}
                  label="Departments/Areas"
                  checked={activeAreas.length === 1}
                  disabled={activeAreas.length === 1}
                />
              )}
              <Button type="submit" hidden className='save' style={{display: 'none'}} onClick={(i) => {values.isSave = true; values.jumpStep = i.currentTarget.getAttribute('step');}}/>
              <BackButton type="button" className='backStep' step={activeStep - 1} withIcon onClick={(i) => {setActiveStep(parseInt(i.currentTarget.getAttribute('step')))}}>
                <Icons.ArrowLeftBlue /> Back
              </BackButton>
              <SubmitButton type="submit" withIcon onClick={() => {
                values.isSave = false;
              }}>
                Next <Icons.ArrowRight />
              </SubmitButton>
            </form>
          )}
        ></FinalForm>
      </Step>

      <Step active={activeStep === 2}>
        <Type.H4>New Position Requirement - Step 3</Type.H4>
        <Type.P>Is this information correct?</Type.P>
        <hr />

        <InfoCard
          title="Overview"
          handleEditClick={(e) => setActiveStep(0)}
          items={[
            {
              title: 'Job Title',
              content: combineJobTitle(
                requirement.title,
                requirement.customTitle
              ),
            },
            {
              title: 'Number of Positions',
              content: requirement.numberOfPositions,
            },
            {
              title: 'Ideal Start Date',
              content: formatDateForHumans(requirement.idealStartDate),
            },
            { title: 'Duration (Months)', content: requirement.duration },
            {
              title: 'Job Description',
              content:
                requirement.jobDescriptionFile &&
                typeof requirement.jobDescriptionFile === 'string' &&
                requirement.jobDescriptionFile.indexOf('"name":""') < 0
                  ? JSON.parse(requirement.jobDescriptionFile)
                      .map((x) => x.name)
                      .join(', ')
                  : '',
            },
          ]}
        />
        <br />
        {
          <InfoCard
            title="Details"
            handleEditClick={(e) => setActiveStep(1)}
            items={[
              { title: 'Project', content: requirement.projectDetails },
              { title: 'Team', content: requirement.team },
              {
                title: 'Skills',
                content: requirement.skills
                  ? (requirement.skills.filter(function(entry) { return /\S/.test(entry); }).join(', '))
                  : '',
              },
              {
                title: 'Departments/Areas',
                content: requirement.areas
                  ? (requirement.areas.filter(function(entry) { return /\S/.test(entry); }).join(', '))
                  : '-',
              },
            ]}
          />
        }
        <Button type="submit" hidden className='save' style={{display: 'none'}} onClick={(i) => {setActiveStep(parseInt(i.currentTarget.getAttribute('step')));}}/>
        <BackButton type="button" className='backStep' step={activeStep - 1} withIcon onClick={(i) => setActiveStep(parseInt(i.currentTarget.getAttribute('step')))}>
          <Icons.ArrowLeftBlue /> Back
        </BackButton>
        <SubmitButton
          type="submit"
          withIcon
          disabled={submitting}
          onClick={handleSaveRequirment}
        >
          Publish <Icons.ArrowRight />
        </SubmitButton>
      </Step>

      <Spacer desktop={20} />
    </Wrap>
  );
};

export default withRouter(RequirementForm);

const SubmitButton = styled(Button)`
  margin-top: 30px;
  float: right;
`;

const Wrap = styled.div`
  max-width: 640px;
  margin-bottom: 100px;
  h6 {
    margin-bottom: 10px;
  }
`;
