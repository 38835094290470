import React from 'react';
import Card from '@/elements/Card';
import { Link } from 'react-router-dom';
import { LayoutContainer, LayoutInnerContainer, FooterListing } from './style';

const PublicTemplate = (props) => {
  return (
    <LayoutContainer {...props}>
      <LayoutInnerContainer {...props}>
        <Card>{props.children}</Card>
        <FooterListing>
          <li>
            <Link to={'/'}>Login</Link>
          </li>
          <li>
            <Link to={'/help'}>Help?</Link>
          </li>
          <li>
            <Link to={'/privacy'}>Privacy</Link>
          </li>
          <li>
            <Link to={'/terms'}>Terms</Link>
          </li>
        </FooterListing>
      </LayoutInnerContainer>
    </LayoutContainer>
  );
};

export default PublicTemplate;
