import styled, { css } from 'styled-components';
import { colour } from '@/styles/variables';

export const ModalWrap = styled.div`
  div[role='dialog'] {
    padding: 15px 20px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: ${({ alignment, type }) =>
    alignment === 'center' || type === 'action' ? 'center' : 'space-between'};

  svg {
    cursor: pointer;
  }

  ${({ isTall }) =>
    isTall &&
    css`
      position: absolute;
      top: 15px;
      width: calc(100% - 30px);
      left: 15px;
      padding-bottom: 15px;
      background: white;
      z-index: 5;
    `}
`;

export const ModalBody = styled.div`
  text-align: ${({ alignment }) => alignment};

  ${({ isTall }) =>
    isTall &&
    css`
      padding-bottom: 40px;
      padding-top: 40px;
    `}
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    &:last-child {
      margin-left: 15px;
    }
  }

  ${({ isTall }) =>
    isTall &&
    css`
      position: absolute;
      bottom: 15px;
      right: 15px;
      z-index: 5;
      width: calc(100% - 30px);
      background: white;
      padding-top: 15px;
    `}
`;

export const HeaderWrap = styled.div`
  text-align: center;
  margin: 0 auto;
`;

export const RoleTitle = styled.div`
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 100px;
    height: 1px;
    background-color: ${colour.charcoal};
    position: absolute;
    top: 50%;
  }
  &:before {
    left: -110px;
  }
  &:after {
    right: -110px;
  }
`;
