import React, { useState } from 'react';
import db from '@/utils/database';
import { s3Upload } from '@/utils/awsLib';
import Button from '@/elements/Button';
import { TextInput, FinalForm } from '@/elements/Form';
import arrayMutators from 'final-form-arrays';
import { CheckboxGroup } from '@/elements/Form';
import { PhotoUploadField } from '@/elements/Form';
import { ErrorBox } from '@/elements/ErrorBox/style';
import Type from '@/elements/Type';
import { OrgAreasInput } from '@/elements/Form/Fields/OrgAreasInput';
import Spacer from '@/elements/Spacer';

export const AddOrganization = (props) => {
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const { setActiveView } = props;

  const onSubmit = async (values) => {
    const { name, type, photoImage, areas } = values;

    if (submitting) {
      return;
    }

    if (!photoImage) {
      setError('Please provide a client logo.');
      return;
    }

    setSubmitting(true);

    const logoImage = await s3Upload('organizationLogos', photoImage);

    const orgData = {
      name,
      type,
      logoImage,
      areas: [''],
    };

    if (areas && areas.length !== 0) {
      orgData.areas = areas;
    }

    try {
      await db.add('organization', orgData).then(() => {
        setSubmitting(false);
        setActiveView('table');
      });
    } catch (e) {
      setSubmitting(false);
      setError(
        typeof error === 'string'
          ? error
          : error.message ||
              'An unexpected error occurred. Please contact the site administrator.'
      );
    }
  };

  return (
    <>
      {error && (
        <ErrorBox>
          <>{error}</>
        </ErrorBox>
      )}
      <FinalForm
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit, values, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Type.H6>Overview</Type.H6>
              <PhotoUploadField
                name="photoImage"
                component="input"
                label="Client logo*"
                defaultImage={values.logoImage}
              />

              <TextInput
                name="name"
                component="input"
                type="text"
                validationType="required"
                label="Client"
                showAppend
              />

              <CheckboxGroup
                name="type"
                validationType="requiredArray"
                label="Type of client"
                options={['Project', 'Consulting']}
              />

              <OrgAreasInput name="areas" type="text" label="Departments/Areas" />

              <Spacer desktop={15} />

              <Button disabled={submitting}>Done</Button>
            </form>
          );
        }}
      ></FinalForm>
    </>
  );
};
