import React from 'react';
import { CardWrapper } from './style';

const Card = (props) => {
  const { children } = props;

  return <CardWrapper {...props}>{children}</CardWrapper>;
};

export default Card;
