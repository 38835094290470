import React from 'react';
import {
  StatusWrapper,
  StatusContainer,
  StatusBar,
} from './style';
//import Icons from '@/elements/Icons';
import Type from '@/elements/Type';
import { colour } from '@/styles/variables';

const ConsultingRequirementCard = (props) => {
  const { isActive, title, icon, description, statusText, handleClick } = props;

  const getStatusColour = () => {
    if (statusText === 'On Track') {
      return colour.green;
    }

    if (statusText === 'Okay') {
      return colour.orange;
    }

    if (statusText === 'Delayed') {
      return colour.red;
    }
  };

  return (
    <StatusWrapper className={isActive ? 'active':''} isActive={isActive} onClick={handleClick}>
      {icon && <img src={icon} className="icon" alt={title} />}
      <Type.H6>{title}</Type.H6>
      <Type.P>{description}</Type.P>
      <StatusContainer statusColor={getStatusColour()}>
        <StatusBar statusColor={getStatusColour()} />
      </StatusContainer>
    </StatusWrapper>
  );
};

export default ConsultingRequirementCard;
