import styled from 'styled-components';
import { colour, breakpoint } from '@/styles/variables';

export const StatusWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px 30px 15px 15px;
  background-color: #fff;
  border: 1px solid ${colour.lightGreyOpacity};
  border-radius: 10px;
  cursor: pointer;

  &.active {
    color: ${colour.white};
    background-color: ${colour.blue};
    border: none;

    h6, p {
      color: ${colour.white};
    }
  }

  .icon {
    max-width: 22.5px;
    margin-bottom: 10px;
  }

  h6 {
    font-size: 16px;
    line-height: 1.1;
    margin-bottom: 5px;
  }

  p {
    font-size: 12px;
    line-height: 14px;
  }

  @media (max-width: ${breakpoint.max.larger}) {
    min-width: 200px;
    margin-right: 20px;
    margin-bottom: 30px;
  }
`;

export const StatusContainer = styled.div`
  color: ${({ statusColor }) => statusColor && statusColor};
  position: absolute;
  right: 15px;
  top: 15px;
  height: calc(100% - 30px);
  width: 10px;

  p {
    color: ${({ statusColor }) => statusColor && statusColor};
    font-weight: 700;
  }
`;

export const StatusDot = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: ${({ statusColor }) => statusColor && statusColor};
  margin-bottom: 5px;

  p {
    font-size: 11px;
    line-height: 13px;
  }
`;

export const StatusBar = styled.div`
  background-color: ${({ statusColor }) => statusColor && statusColor};
  height: 100%;
  width: 10px;
  border-radius: 5px;
  border: 1px solid #FFFFFF;
`;

export const DropdownIconTrigger = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  opacity: ${({ isActive }) => (isActive ? '1' : '0.5')};
`;
