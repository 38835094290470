import React, { useState, useEffect } from 'react';
import db from '@/utils/database';
import LoggedInAdminTemplate from '@/templates/loggedInAdmin';
import { Table } from 'evergreen-ui';
import {
  TableHead,
  HeaderCell,
  TableRow,
  TableCell,
} from '@/components/Table/style';
import { ViewLink } from '@/components/InvoiceTable/style';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import Button from '@/elements/Button';
import { DataStore } from '@aws-amplify/datastore';
import { Hub } from 'aws-amplify';
import { Consultant } from '@/models';
import Modal from '@/elements/Modal';
import { AddConsultant } from './AddConsultant';
import { LinksContainer, ProfilePhoto } from './style';
import Type from '@/elements/Type';
import { Spacer } from '@/elements/Spacer/style';
import { orderBy } from 'lodash';
import { colour } from '@/styles/variables';
import { Divider } from '@/elements/Divider/style';
import { withRouter } from 'react-router-dom';
import { EditConsultant } from './EditConsultant';
import { ProfileWithText, ClientLogo } from '../Organization/style';
import { TimeDiff } from '@/utils/timeDiff';
import { useContext } from 'react';
import { AuthContext } from '@/context/auth';
import defaultAvatar from '@/static/images/default-avatar.svg';
import { datesAreOnSameDay, datesIsTomorrow } from '@/utils/dates';

async function listConsultants(setConsultants) {
  const consultants = await db.getAll('consultant');
  setConsultants(
    orderBy(
      consultants,
      [(consultant) => consultant.name.toLowerCase()],
      ['asc']
    )
  );
}

async function listOrganizationsRoles(setOrganizations, setRoles) {
  const organizations = await db.getAll('organization');
  const roles = await db.getAll('role');
  setOrganizations(
    orderBy(organizations, [(org) => org.name?.toLowerCase()], ['asc'])
  );
  setRoles(orderBy(roles, [(role) => role.name?.toLowerCase()], ['asc']));
}

const ListConsultant = (props) => {
  const [consultants, setConsultants] = useState([]);

  const [activeView, setActiveView] = useState('table');

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedConsultant, setSelectedConsultant] = useState({});

  const [roles, setRoles] = useState([]);
  const [organizations, setOrganizations] = useState([]);

  const { user } = useContext(AuthContext);

  async function handleDelete(id) {
    const toDelete = await DataStore.query(Consultant, id);
    await DataStore.delete(toDelete);
    setShowDeleteModal(false);
  }

  useEffect(() => {
    if (!user) {
      return;
    }

    listConsultants(setConsultants);

    const listenerData = (data) => {
      if (data.payload.event === 'ready') {
        listConsultants(setConsultants);
      }
    };
    Hub.listen('datastore', listenerData);

    const subscription = DataStore.observe(Consultant).subscribe((msg) => {
      listConsultants(setConsultants);
    });

    const handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';

      if (condition === 'online') {
        listConsultants(setConsultants);
      }
    };

    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      subscription.unsubscribe();
      Hub.remove('datastore', listenerData);

      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, [user]);

  useEffect(() => {
    if (!user) {
      return;
    }

    listOrganizationsRoles(setOrganizations, setRoles);
  }, [user]);

  const getDateText = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return 'TBC';
    }

    const dateNow = new Date();
    const consultantStartDate = new Date(startDate);
    const consultantEndDate = new Date(endDate);

    if (datesAreOnSameDay(dateNow, consultantStartDate)) {
      return 'Starts Today';
    }

    if (datesAreOnSameDay(dateNow, consultantEndDate)) {
      return 'Ends Today';
    }

    if (datesIsTomorrow(dateNow, consultantStartDate)) {
      return 'Starts Tomorrow';
    }

    if (datesIsTomorrow(dateNow, consultantEndDate)) {
      return 'Ends Tomorrow';
    }

    if (consultantEndDate < dateNow) {
      return 'Expired';
    }

    if (consultantStartDate > dateNow) {
      return TimeDiff(
        consultantStartDate,
        dateNow,
        'Starts ',
        'Expires ',
        true
      );
    }

    return TimeDiff(dateNow, consultantEndDate, 'Starts ', 'Expires ', true);
  };

  return (
    <LoggedInAdminTemplate hasTabs>
      <Grid>
        <Row>
          {activeView === 'add' && (
            <Col xsOffset={2} xs={8}>
              <Button
                simple
                withArrow
                colour={colour.charcoal}
                onClick={() => setActiveView('table')}
              >
                Back to consultants
              </Button>
              <Spacer desktop={20} />
              <Type.H4>Add Consultant</Type.H4>
              <Divider desktop={20} />
              <AddConsultant
                roles={roles}
                organizations={organizations}
                setActiveView={setActiveView}
              />
            </Col>
          )}
          {activeView === 'edit' && (
            <Col xsOffset={2} xs={8}>
              <Button
                simple
                withArrow
                colour={colour.charcoal}
                onClick={() => setActiveView('table')}
              >
                Back to clients
              </Button>
              <Spacer desktop={20} />
              <Type.H4>Edit Consultant</Type.H4>
              <Divider desktop={20} />
              <EditConsultant
                setActiveView={setActiveView}
                roles={roles}
                organizations={organizations}
                consultant={selectedConsultant}
              />
            </Col>
          )}
          {activeView === 'table' && (
            <>
              <Col xs={3}>
                <Button
                  width={'100%'}
                  border
                  onClick={() => setActiveView('add')}
                >
                  Add Consultant
                </Button>
              </Col>
              <Col xs={9}>
                <Row>
                  {activeView === 'table' && (
                    <>
                      <Col xs={12}>
                        <Type.H4>Consultants</Type.H4>
                        <Spacer desktop={30} />
                      </Col>
                      <Col xs={12}>
                        {!!consultants && (
                          <Table>
                            <TableHead paddingX={0}>
                              <HeaderCell></HeaderCell>
                              <HeaderCell>Role</HeaderCell>
                              <HeaderCell>Client</HeaderCell>
                              <HeaderCell>Contract</HeaderCell>
                              <HeaderCell
                                flexBasis={200}
                                flexShrink={0}
                                flexGrow={0}
                                paddingX={0}
                              ></HeaderCell>
                            </TableHead>
                            <Table.Body height={'auto'}>
                              {consultants.map((x, index) => (
                                <TableRow
                                  key={index}
                                  height={'auto'}
                                  variation={'admin'}
                                >
                                  <TableCell>
                                    <ProfileWithText>
                                      <ProfilePhoto
                                        src={
                                          x.consultantImage &&
                                          x.consultantImage !== ''
                                            ? x.consultantImage.replace(
                                                /%20/g,
                                                '+'
                                              )
                                            : defaultAvatar
                                        }
                                        alt={x.name}
                                      />
                                      <span>{x.name}</span>
                                    </ProfileWithText>
                                  </TableCell>
                                  <TableCell>{x.role}</TableCell>
                                  <TableCell>
                                    {x.organization?.id && x.inContract ? (
                                      <ClientLogo
                                        src={x.organization?.logoImage?.replace(
                                          /%20/g,
                                          '+'
                                        )}
                                        alt={x.organization?.name}
                                      />
                                    ) : (
                                      '-'
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {x.inContract
                                      ? `Yes (${getDateText(
                                          x.startDate,
                                          x.endDate
                                        )})`
                                      : 'No'}
                                  </TableCell>
                                  <TableCell
                                    flexBasis={200}
                                    flexShrink={0}
                                    flexGrow={0}
                                    paddingX={0}
                                  >
                                    <LinksContainer>
                                      <ViewLink
                                        onClick={() => {
                                          setActiveView('edit');
                                          setSelectedConsultant(x);
                                        }}
                                      >
                                        Edit
                                      </ViewLink>
                                      <ViewLink
                                        onClick={() => {
                                          setShowDeleteModal(true);
                                          setSelectedConsultant(x);
                                        }}
                                      >
                                        Delete
                                      </ViewLink>
                                    </LinksContainer>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </Table.Body>
                          </Table>
                        )}

                        <Modal
                          title="Delete Consultant"
                          isShown={showDeleteModal}
                          intent="danger"
                          handleModalState={setShowDeleteModal}
                          handleAction={() =>
                            handleDelete(selectedConsultant.id)
                          }
                          type={'action'}
                          alignment={'center'}
                          buttonText="Yes"
                          shouldCloseOnEscapePress={false}
                          shouldCloseOnOverlayClick={false}
                        >
                          <p>
                            Are you sure you want to delete{' '}
                            {selectedConsultant.name}?
                          </p>
                        </Modal>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            </>
          )}
        </Row>
      </Grid>
    </LoggedInAdminTemplate>
  );
};

export default withRouter(ListConsultant);
