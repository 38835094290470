import styled, { css } from 'styled-components';
import { colour, breakpoint } from '@/styles/variables';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  position: relative;
`;

export const NotificationToggleContainer = styled.div`
  cursor: pointer;
`;

export const NotificationContentContainer = styled.div`
  position: absolute;
  top: 55px;
  right: 0;
  transform: translateX(200%);
  transition: opacity 0.3s;
  opacity: 0;
  background-color: white;
  width: 315px;
  max-width: calc(100vw - 100px);
  height: calc(100vh - 132px);
  z-index: 1200;
  padding: 20px;
  box-shadow: 0px 0px 30px rgba(54, 53, 55, 0.11);
  border-radius: 10px;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateX(0);
      opacity: 1;
    `}

@media (max-width: ${breakpoint.max.medium}) {
  position: fixed;
  width: 100vw;
  height: calc(100vh - 80px);
  max-width: calc(100% - 40px);
  transform: translateY(100%);
  transition: transform 0.3s;
  opacity: 1;
  box-shadow: none;
  top: 80px;
  border-radius: 0;

${({ isOpen }) =>
  isOpen &&
  css`
    transform: translateY(0);
  `}
}
`;

export const NotificationCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 10px;
  font-weight: 600;
  line-height: 14;
  width: 20px;
  height: 20px;
  background-color: ${colour.red};
  border-radius: 50%;
  box-shadow: 0 0 20px rgba(149, 20, 30, 0.3);
  position: absolute;
  left: -15px;
  cursor: pointer;
`;

export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const NotifsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 50px);
  overflow: auto;

  @media (max-width: ${breakpoint.max.medium}) {
    max-height: calc(100% - 85px);
  }
`;

export const NotifsItem = styled.div`
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 20px;
  opacity: ${({ seen }) => (seen ? 0.5 : 1)};
  transition: opacity 0.3s;

  &:last-child {
    margin-bottom: 0;
  }

  .col-a {
    width: 100%;
    cursor: pointer;

    > p {
      margin: 0;
      padding-right: 30px;
    }
  }
  .col-b {
    position: absolute;
    top: 0;
    right: 0;

    .notif-dot {
      border: 0;
      height: 8px;
      width: 8px;
      background-color: ${colour.blue};
      cursor: pointer;
      border: 0;
      padding: 0;
      border-radius: 50%;
    }
  }

  .date {
    display: block;
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
    color: ${({ seen }) => (seen ? colour.charcoal : colour.blue)};
  }
`;
