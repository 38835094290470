import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { breakpoint } from '@/styles/variables';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  height: 80px;
`;

export const MenuToggleContainer = styled.div``;

export const MenuOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  transition: opacity 0.3s;
  opacity: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1199;
  background-color: rgba(67, 90, 111, 0.7);

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateX(0);
      opacity: 1;
    `}
`;

export const MenuSlideContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  transition: transform 0.3s;
  background-color: white;
  width: 315px;
  max-width: calc(100vw - 100px);
  height: 100vh;
  z-index: 1200;
  padding: 20px;
  box-shadow: 0px 0px 30px rgba(54, 53, 55, 0.11);

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateX(0);
    `}
`;

export const LogoContainer = styled(Link)`
  width: 120px;
  color: ${({ theme }) => theme.colour.primary};
`;

export const LeftElements = styled.div`
  display: flex;
  align-items: center;
`;

export const RightElements = styled.div`
  display: flex;
  align-items: center;

  .active-client {
    margin-left: 15px;
    max-width: 300px;

    @media (max-width: ${breakpoint.max.medium}) {
      text-align: center;
      max-width: 70px;
    }
  }
`;

export const SlideInMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  a,
  button {
    background: transparent;
    border: 0;
    margin-bottom: 15px;
    display: block;
    width: auto;
    font-size: 14px;
    font-family: 'Gilroy', sans-serif;
  }

  a {
    font-weight: 700;
  }

  button {
    width: auto;
    display: flex;
    padding: 0;
    cursor: pointer;
    outline: none;
  }
`;

export const CloseButton = styled.div`
  height: 15px;
  width: 15px;
  margin-bottom: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.707' height='15.707' viewBox='0 0 15.707 15.707'%3E%3Cg id='Group_222' data-name='Group 222' transform='translate(-685.646 -196.646)'%3E%3Cline id='Line_56' data-name='Line 56' x2='15' y2='15' transform='translate(686 197)' fill='none' stroke='%23363537' stroke-width='1'/%3E%3Cline id='Line_57' data-name='Line 57' x2='15' y2='15' transform='translate(701 197) rotate(90)' fill='none' stroke='%23363537' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");
  cursor: pointer;
`;
