import React, { useState, useContext } from 'react';
import Button from '@/elements/Button';
import { TextInput, FinalForm } from '@/elements/Form';
import arrayMutators from 'final-form-arrays';
import { ErrorBox } from '@/elements/ErrorBox/style';
import { Auth } from 'aws-amplify';
import LoggedInManageAccount from '@/templates/loggedInManageAccount';
import { AuthContext } from '@/context/auth';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import Type from '@/elements/Type';
import { Spacer } from '@/elements/Spacer/style';
import { SuccessBox } from '@/elements/SuccessBox/style';
import Divider from '@/elements/Divider';

export const ManageAccount = (props) => {
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const [submittingPassword, setSubmittingPassword] = useState(false);
  const [successPassword, setSuccessPassword] = useState(false);
  const [errorPassword, setErrorPassword] = useState('');

  const { user, handleDataRefresh } = useContext(AuthContext);

  const onSubmit = async (values) => {
    try {
      const { name, phoneNumber } = values;

      setSubmitting(true);
      setSuccess(false);
      setError('');

      await Auth.updateUserAttributes(await Auth.currentAuthenticatedUser(), {
        'custom:name': name,
        'custom:phoneNumber': phoneNumber,
      }).catch((error) => {
        setSubmitting(false);
        setError(
          typeof error === 'string'
            ? error
            : error.message ||
                'An unexpected error occurred. Please contact the site administrator.'
        );

        return;
      });

      setSubmitting(false);
      setSuccess(true);
      handleDataRefresh();
    } catch (e) {
      setSubmitting(false);
      setError(
        typeof error === 'string'
          ? error
          : error.message ||
              'An unexpected error occurred. Please contact the site administrator.'
      );
    }
  };

  const handleChangePassword = async (values) => {
    try {
      const { oldPassword, newPassword } = values;

      setSubmittingPassword(true);
      setSuccessPassword(false);
      setErrorPassword('');

      await Auth.changePassword(
        await Auth.currentAuthenticatedUser(),
        oldPassword,
        newPassword
      )
        .then(() => {
          setSubmittingPassword(false);
          setSuccessPassword(true);
        })
        .catch((error) => {
          setSubmittingPassword(false);
          setErrorPassword(
            typeof error === 'string'
              ? error
              : error.message ||
                  'An unexpected error occurred. Please contact the site administrator.'
          );
        });
    } catch (e) {
      setSubmittingPassword(false);
      setErrorPassword(
        typeof error === 'string'
          ? error
          : error.message ||
              'An unexpected error occurred. Please contact the site administrator.'
      );
    }
  };

  return (
    <LoggedInManageAccount hasYPadding>
      <Grid>
        <Row>
          <Col xs={12} md={8} mdOffset={2}>
            <Type.H3>Manage Account</Type.H3>
            <Divider desktop={20} />
            <Type.H5>Details</Type.H5>
            <Spacer desktop={20} />
            {error && <ErrorBox>{error}</ErrorBox>}
            {success && <SuccessBox>Successfully updated details.</SuccessBox>}
            <FinalForm
              onSubmit={onSubmit}
              mutators={{
                ...arrayMutators,
              }}
              initialValues={{
                name: user ? user.attributes['custom:name'] : '',
                phoneNumber: user ? user.attributes['custom:phoneNumber'] : '',
                email: user ? user.attributes['email'] : '',
              }}
              render={({ handleSubmit, values }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <TextInput
                      name="name"
                      component="input"
                      type="text"
                      value={values.name}
                      validationType="required"
                      label="Name"
                      showAppend
                    />
                    <TextInput
                      name="email"
                      component="input"
                      type="text"
                      disabled
                      value={values.email}
                      validationType="required"
                      label="Email Address"
                      showAppend
                    />
                    <TextInput
                      name="phoneNumber"
                      component="input"
                      type="text"
                      value={values.phoneNumber}
                      validationType="required"
                      label="Phone Number"
                      showAppend
                    />

                    <Button disabled={submitting}>Update</Button>
                  </form>
                );
              }}
            ></FinalForm>
            <Spacer desktop={40} />
            <Type.H5>Change Password</Type.H5>
            <Spacer desktop={20} />
            {errorPassword && <ErrorBox>{errorPassword}</ErrorBox>}
            {successPassword && (
              <SuccessBox>Successfully changed password.</SuccessBox>
            )}
            <FinalForm
              onSubmit={handleChangePassword}
              initialValues={{
                name: user && user.attributes['custom:name'],
                phoneNumber: user && user.attributes['custom:phoneNumber'],
                email: user && user.attributes['email'],
              }}
              validate={(values) => {
                const errors = {};
                if (values.confirmPasswordPassword !== values.newPassword) {
                  errors.confirmPasswordPassword = 'Must match';
                }
                return errors;
              }}
              render={({ handleSubmit, values }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <TextInput
                      name="oldPassword"
                      component="input"
                      type="password"
                      validationType="required"
                      label="Old Password"
                      showAppend
                    />
                    <TextInput
                      name="newPassword"
                      component="input"
                      type="password"
                      validationType="required"
                      label="New Password"
                      showAppend
                    />
                    <TextInput
                      name="confirmPasswordPassword"
                      component="input"
                      type="password"
                      validationType="required"
                      label="Confirm New Password"
                      showAppend
                    />

                    <Button disabled={submittingPassword}>
                      Change Password
                    </Button>
                  </form>
                );
              }}
            ></FinalForm>
          </Col>
        </Row>
      </Grid>
    </LoggedInManageAccount>
  );
};
