import React from 'react';
import {
  DocumentListingWrapper,
  DocumentItemWrapper,
  DocumentItemsWrapper,
  DocumentItemInfo,
} from './style';
import Icons from '@/elements/Icons';
import Type from '@/elements/Type';
import { formatDateForHumans } from '@/utils/dates';
import { Spacer } from '@/elements/Spacer/style';

const DocumentItem = (props) => {
  const { title } = props;
  const { url, type, name, uploadDate } = props.document[0]; //only one file per document

  const IMAGE_TYPE = [
    'image/svg+xml',
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/tiff',
    'image/webp',
  ];
  const PDF_TYPE = ['application/pdf'];
  const EXCEL_TYPE = [
    'application/vnd.ms-excel',
    '	application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
  ];
  const WORD_TYPE = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];
  const PPT_TYPE = [
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ];
  const KEYNOTE_TYPE = [
    'application/keynote',
    'application/x-iWork-keynote-sffkey',
  ];

  const isImage = (type) => {
    return IMAGE_TYPE.find((x) => x.indexOf(type) > -1);
  };
  const isPdf = (type) => {
    return PDF_TYPE.find((x) => x.indexOf(type) > -1);
  };
  const isExcel = (type) => {
    return EXCEL_TYPE.find((x) => x.indexOf(type) > -1);
  };
  const isWord = (type) => {
    return WORD_TYPE.find((x) => x.indexOf(type) > -1);
  };
  const isPpt = (type) => {
    return PPT_TYPE.find((x) => x.indexOf(type) > -1);
  };
  const isKeynote = (type) => {
    return KEYNOTE_TYPE.find((x) => x.indexOf(type) > -1);
  };

  const renderFileIcon = (type, name) => {
    if (name === 'url-document--1') {
      return <Icons.Link />;
    }
    if (!type) {
      return <Icons.Keynote />;
    } else if (isImage(type)) {
      return <Icons.Image />;
    } else if (isPdf(type)) {
      return <Icons.PDF />;
    } else if (isExcel(type)) {
      return <Icons.Excel />;
    } else if (isWord(type)) {
      return <Icons.Word />;
    } else if (isPpt(type)) {
      return <Icons.Powerpoint />;
    } else if (isKeynote(type)) {
      return <Icons.Keynote />;
    } else {
      return <Icons.Tick />;
    }
  };

  return (
    <DocumentItemWrapper href={url} target="_blank">
      <div className="inner-container">
        {renderFileIcon(type, name)}
        <DocumentItemInfo>
          <Type.H6>{title}</Type.H6>
          {uploadDate && (
            <Type.P>
              {formatDateForHumans(
                new Date(uploadDate.replace('"', '').replace('"', ''))
              )}
            </Type.P>
          )}
        </DocumentItemInfo>
      </div>
    </DocumentItemWrapper>
  );
};

const DocumentListing = (props) => {
  const { title, documents, emptyMessage } = props;

  return (
    <DocumentListingWrapper>
      <Type.H6>{title}</Type.H6>
      <Spacer desktop={10} />
      <DocumentItemsWrapper count={documents?.length}>
        {documents ? (
          documents.map((document, index) => (
            <DocumentItem {...document} key={index} />
          ))
        ) : (
          <Type.P8>{emptyMessage}</Type.P8>
        )}
      </DocumentItemsWrapper>
    </DocumentListingWrapper>
  );
};

export default DocumentListing;
