import React, { useState, useRef, useEffect } from "react";
import { Wrap } from "./style";
import Button from "@/elements/Button";
import { colour } from "@/styles/variables";
import Icon from "@/elements/Icons";
import { s3UploadFile, s3RemoveFile } from "@/utils/awsLib";

const initFile = [
  {
    name: "",
    url: "",
    type: "",
    uploadDate: "",
    folder: "",
    serverFileName: "",
  },
];

const Uploader = (props) => {
  const { name, folder, input, value } = props;

  const inputEl = useRef(null);
  const [progress, setProgress] = useState({});
  const [eta, setEta] = useState({});
  const [files, setFiles] = useState({});

  const folderPath = folder ? folder : "temp";

  let startTime;
  const handleOnProgress = (prog, file) => {
    // console.log(prog);
    var now = new Date().getTime();
    var elapsedtime = (now - startTime) / 1000;
    var etaSecs = (prog.total / prog.loaded) * elapsedtime - elapsedtime;
    // console.log(startTime, elapsedtime, etaSecs);
    let etaObj = { ...eta };
    etaObj[file.name] = Math.round(etaSecs);
    setEta({ ...etaObj });
    let progressObj = { ...progress };
    progressObj[file.name] = Math.round((prog.loaded / prog.total) * 100) / 100;
    setProgress({ ...progressObj });
  };

  const handleOnChange = async (e) => {
    startTime = new Date().getTime();
    const file = e.target.files[0];
    if (!file) return;
    let obj = { ...files };
    obj[file.name] = file;
    setFiles(obj);

    //upload to s3 and update files state
    const fileRes = await s3UploadFile(folderPath, file, handleOnProgress);
    obj[file.name] = { ...file, ...fileRes };
    setFiles({ ...files, ...obj });

    //update the form from parent
    const inputElm = inputEl.current;
    setNativeValue(inputElm, JSON.stringify(Object.values(obj)));
    var evt = new Event("input");
    inputElm.dispatchEvent(evt);
    input.onChange(evt);
  };

  const handleFileRemove = async (e, file) => {
    e.preventDefault();
    const filename = `${file.folder}/${file.serverFileName}`;
    s3RemoveFile(filename)
      .then((result) => {
        // console.log(result);
        let obj = { ...files };
        delete obj[file.name];
        // console.log(obj);
        setFiles(obj);

        obj = Object.keys(obj).length ? obj : initFile;

        const inputElm = inputEl.current;
        setNativeValue(inputElm, null);
        var evt = new Event("input");
        inputElm.dispatchEvent(evt);
        input.onChange(evt);

        props.handleRemove(file.name);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    //restore the view if value is supplied
    // console.log(value)
    if (value && value !== 'null' && value.indexOf('"name":""') < 0) {
      const json = JSON.parse(value);
      let files = {};
      if (Array.isArray(json)) {
        const arr = JSON.parse(value);
        arr.map((x) => (files[x.name] = x));
      } else {
        files = JSON.parse(value);
      }
      // console.log(value, files);
      const progs = (value) => {
        let obj = {};
        Object.values(files).map((x) => (obj[x.name] = value));
        return obj;
      };
      setFiles(files);
      setEta(progs(0));
      setProgress(progs(1));
    }
  }, [value]);

  const canUpload = (props.single && !Object.values(files).length) || !props.single
  return (
    <Wrap>
      <div className="file-wrap flex">
          <div style={{display:canUpload? 'block' : 'none'}}>
            <div className="file-input">
              <label className="label" htmlFor={name ? name : "file-upload"}>
                <div>
                  <b>
                    Drop file here or <span>browse</span>
                  </b>
                  <br />
                  {props.label}
                </div>
              </label>
              <input type="hidden" ref={inputEl} />
              <input
                onFocus={input.onFocus}
                onBlur={input.onBlur}
                id={name ? name : "file-upload"}
                type="file"
                onChange={handleOnChange}
              />
            </div>
          </div>
        
        <div>
          {Object.values(files).map((x) => (
            <div
              className={`file-progress ${
                progress[x.name] === 1 ? "successful" : ""
              }`}
              key={x.name}
            >
              <div
                className={`file-progress-bg`}
                style={{ width: progress[x.name] * 100 + "%" }}
              >
                <span></span>
              </div>
              <div className="label file">
                <div>
                  <b>
                    {progress[x.name] === 1 ? "Uploaded" : "Uploading"} {x.name}
                  </b>
                  <small>
                    {progress[x.name] ? progress[x.name] * 100 : 0}% •{" "}
                    {eta[x.name]} Seconds left
                  </small>
                  <p>
                    <span
                      style={{ width: progress[x.name] * 100 + "%" }}
                    ></span>
                  </p>
                </div>
                <div className="flex">
                  {/* {progress[x.name]<1 && 
                        <Button style={{color: colour.grey}} simple><Icon.Pause /></Button>
                      } */}
                  <Button
                    disabled={progress[x.name] < 1}
                    onClick={(e) => handleFileRemove(e, x)}
                    style={{ color: colour.red }}
                    simple
                  >
                    <Icon.Cross width={20} height={20} />
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Wrap>
  );
};

export default Uploader;

const setNativeValue = (element, value) => {
  const valueSetter = Object.getOwnPropertyDescriptor(element, "value").set;
  const prototype = Object.getPrototypeOf(element);
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(
    prototype,
    "value"
  ).set;

  if (valueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter.call(element, value);
  } else {
    valueSetter.call(element, value);
  }
};
