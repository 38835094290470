export const isProjectClient = (organization) => {
  if (!organization) {
    return false;
  }

  return organization.type?.indexOf('Project') > -1;
};

export const isConsultingClient = (organization) => {
  if (!organization) {
    return false;
  }

  return organization.type?.indexOf('Consulting') > -1;
};
