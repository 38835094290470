export const isUserAdmin = (user) => {
  if (!user) {
    return false;
  }

  const userGroup = user.attributes['custom:userGroup'];

  if (userGroup === 'admin' || userGroup === 'PM') {
    return true;
  }

  if (!userGroup) {
    return true;
  }
};

export const isUserAddClient = (user) => {
  if (!user) {
    return false;
  }

  const userGroup = user.attributes['custom:userGroup'];

  if (
    userGroup === 'admin' ||
    userGroup === 'PM' ||
    userGroup === 'sales' ||
    userGroup === 'p-c'
  ) {
    return true;
  }

  if (!userGroup) {
    return true;
  }
};

export const isUserEditProfile = (user) => {
  if (!user) {
    return false;
  }

  const userGroup = user.attributes['custom:userGroup'];

  if (
    userGroup === 'admin' ||
    userGroup === 'PM' ||
    userGroup === 'p-c' ||
    userGroup === 'sales'
  ) {
    return true;
  }

  if (!userGroup) {
    return true;
  }
};

export const isUserEditProfileNotAdmin = (user) => {
  if (!user) {
    return false;
  }

  const userGroup = user.attributes['custom:userGroup'];

  if (userGroup === 'p-c' || userGroup === 'sales') {
    return true;
  }

  if (!userGroup) {
    return true;
  }
};

export const isUserProfileCreateGranted = (user) => {
  if (!user) {
    return false;
  }

  const userGroup = user.attributes['custom:userGroup'];

  if (
    userGroup === 'admin' ||
    userGroup === 'PM' ||
    userGroup === 'p-c' ||
    userGroup === 'sales'
  ) {
    return true;
  }

  if (!userGroup) {
    return true;
  }
};

export const isUserProfileGranted = (user) => {
  if (!user) {
    return false;
  }

  const userGroup = user.attributes['custom:userGroup'];

  if (
    userGroup === 'admin' ||
    userGroup === 'client' ||
    userGroup === 'PM' ||
    userGroup === 'p-c' ||
    userGroup === 'sales'
  ) {
    return true;
  }

  if (!userGroup) {
    return true;
  }
};

export const isUserTypeOf = (user, type) => {
  if (!user) {
    return false;
  }

  const userGroup = user.attributes['custom:userGroup'];

  if (userGroup === type) {
    return true;
  }
};

export const isUserMultiClient = (user) => {
  if (!user) {
    return false;
  }

  const userGroup = user.attributes['custom:userGroup'];

  if (
    userGroup === 'admin' ||
    userGroup === 'PM' ||
    userGroup === 'observer' ||
    userGroup === 'partner' ||
    userGroup === 'sales' ||
    userGroup === 'p-c'
  ) {
    return true;
  }

  if (!userGroup) {
    return true;
  }
};
