import { API, Auth } from 'aws-amplify';

/**
 * Fetch Emails from Cognito
 *
 * sample usage:
 * fetchEmails('p-c');
 * fetchEmails('sales', 'orgId-orgId');
 *
 * @param {*} userType - user type ex. p-c, sales, observer etc.
 * @param {*} orgId - (Optional) if not provided, the emails won't be filtered by org Id
 * @param {*} areas - (Optional) if not provided, the emails won't be filtered by areas
 */
export const fetchEmails = async (userType, orgId = undefined, areas = undefined) => {
  let apiName = 'AdminQueries';
  let path = '/fetchEmails';
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `${(await Auth.currentSession())
      .getAccessToken()
      .getJwtToken()}`,
  };
  let data = {
    userType: userType,
  };
  if (orgId) {
    data.orgId = orgId;
  }
  if (areas) {
    data.areas = JSON.stringify(areas);
  }

  let payload = {
    headers: headers,
    body: data,
  };

  const resposne = await API.post(apiName, path, payload);

  return resposne
};
