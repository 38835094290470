import React, { Component } from 'react';
import PublicTemplate from '@/templates/public';
import Type from '@/elements/Type';
import { Spacer } from '@/elements/Spacer/style';

class Help extends Component {
  render() {
    return (
      <PublicTemplate>
        <Type.H6>Help</Type.H6>
        <Spacer desktop={20} />
        <Type.P>-</Type.P>
      </PublicTemplate>
    );
  }
}

export default Help;
