import Modal from '@/elements/Modal';
import React from 'react';
import Type from '@/elements/Type';
import styled from 'styled-components';
import { colour } from '@/styles/variables';
import { formatDateForHumans } from '@/utils/dates';

const StyledWrap = styled.div`
  .title {
    color: ${colour.grey};
    margin: 10px 0 0px 0;
    text-transform: uppercase;
  }
`;

export const ConsultantDetailModal = (props) => {
  const { consultant, isShown, handleModalState } = props;
  return consultant ? (
    <Modal
      title={consultant.name}
      isShown={isShown}
      handleModalState={handleModalState}
    >
      <StyledWrap>
        <Type.P8 className="title">Client</Type.P8>
        <Type.P8>{consultant.organization.name ? consultant.organization.name : 'Nil'}</Type.P8>
        <Type.P8 className="title">Role</Type.P8>
        <Type.P8>{consultant.role ? consultant.role : 'Nil'}</Type.P8>
        <Type.P8 className="title">Departments/Areas</Type.P8>
        <Type.P8>
          {consultant.areas[0] !== '' ? consultant.areas.join(', ') : 'Nil'}
        </Type.P8>
        <Type.P8 className="title">In Contract</Type.P8>
        <Type.P8>{consultant.inContract ? 'Yes' : 'No'}</Type.P8>
        <Type.P8 className="title">Start Date</Type.P8>
        <Type.P8>{consultant.startDate ? formatDateForHumans(new Date(consultant.startDate)) : 'Nil'}</Type.P8>
        <Type.P8 className="title">End Date</Type.P8>
        <Type.P8>{consultant.endDate ? formatDateForHumans(new Date(consultant.endDate)) : 'Nil'}</Type.P8>
      </StyledWrap>
    </Modal>
  ) : null;
};
