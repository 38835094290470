import React, { useContext } from 'react';
import amplifyCustomUi from 'aws-amplify-react-custom-ui';
import Header from '@/components/Header';
import { TabWrap, TabHeader } from '@/elements/Tabs/style';
import { TabLink } from '@/elements/TabLink';
import { AuthContext } from '@/context/auth';
import { isUserEditProfile } from '@/utils/isUserAdmin';
import { Redirect } from 'react-router-dom';
import { NavContainer, ContentArea } from './style';

const LoggedInAdminTemplate = (props) => {
  const { user } = useContext(AuthContext);

  if (!!!user) {
    return null;
  }

  return isUserEditProfile(user) ? (
    <>
      <NavContainer hasTab={props.hasTabs}>
        <Header />
        {props.hasTabs && (
          <nav>
            <TabWrap>
              <TabHeader>
                <TabLink to="/clients">Clients</TabLink>
                <TabLink to="/projects-admin">Projects</TabLink>
                <TabLink to="/consultants-admin">Consultants</TabLink>
                <TabLink to="/skills">Skills</TabLink>
              </TabHeader>
            </TabWrap>
          </nav>
        )}
      </NavContainer>
      <ContentArea>{props.children}</ContentArea>
    </>
  ) : (
    <Redirect push to="/" />
  );
};

export default amplifyCustomUi.withAuthenticator(LoggedInAdminTemplate);
