export const generatePdf = async (html) => {
  const data = {
    html,
    format: 'a4',
    margin: {
      top: '0.65cm',
      left: '0cm',
      right: '0cm',
      bottom: '0.6cm',
    },
    expires: 86400,
  };
  const response = await fetch(
    'https://v3k0tbzqmg.execute-api.ap-southeast-2.amazonaws.com/development/pdf',
    {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data),
    }
  );

  return response.text();
};
