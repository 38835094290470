import React from 'react';
import Projects from '@/pages/app/Projects/Projects';
import Requirements from '@/pages/app/Requirement/Requirements';
import Consultants from '@/pages/app/Consultants/Consultants';
import NoteExample from '@/pages/app/NoteExample';
import Admin from '@/pages/app/Admin';
import Profiles from '@/pages/app/Profile/Profiles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Help from './pages/public/Help';
import Terms from './pages/public/Terms';
import Privacy from './pages/public/Privacy';
import ListOrganization from './pages/app/Organization/ListOrganization';
import { RoleAdmin } from './pages/app/Role/Role';
import { SkillAdmin } from './pages/app/Skill/Skill';
import ForgotPassword from './pages/auth/forgotPassword';
import ForgotPasswordConfirmation from './pages/auth/forgotPasswordConfirmation';
import Users from './pages/app/Users/Users';
import { ManageAccount } from './pages/app/Users/ManageAccount';
import ListConsultant from './pages/app/ConsultantsAdmin/ListConsultant';
import ListProject from './pages/app/ProjectsAdmin/ListProject';

export const Routes = () => {
  return (
    <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/forgot-password" children={() => <ForgotPassword />} />
          <Route path="/help" children={() => <Help />} />
          <Route path="/terms" children={() => <Terms />} />
          <Route path="/privacy" children={() => <Privacy />} />
          <Route
            path="/forgot-password-confirmation"
            children={() => <ForgotPasswordConfirmation />}
          />

          <Route path="/users" children={() => <Users />} />
          <Route path="/requirements" children={() => <Requirements />} />
          <Route path="/consultants" children={() => <Consultants />} />
          <Route path="/projects" children={() => <Projects />} />
          <Route path="/example" children={() => <NoteExample />} />
          <Route path="/admin" children={() => <Admin />} />
          <Route path="/clients" children={() => <ListOrganization />} />
          <Route path="/roles" children={() => <RoleAdmin />} />
          <Route path="/skills" children={() => <SkillAdmin />} />
          <Route path="/profiles" children={() => <Profiles />} />
          <Route
            path="/consultants-admin"
            children={() => <ListConsultant />}
          />
          <Route path="/projects-admin" children={() => <ListProject />} />
          <Route path="/manage-account" children={() => <ManageAccount />} />
          <Route
            exact
            path="/"
            children={() => <Redirect push to="/requirements" />}
          />
        </Switch>
      </div>
    </Router>
  );
};
