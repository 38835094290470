import styled, { css } from 'styled-components';
import { colour } from '../../styles/variables';
import { breakpoint } from '../../styles/variables';
import { Link } from 'react-router-dom';

const ButtonStyle = css`
  display: inline-flex;
  justify-content: ${(props) => (props.withIcon ? 'space-between' : 'center')};
  text-decoration: none;
  outline: none;
  text-align: center;
  font-size: 100%;
  font-weight: 800;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 15px 25px;
  font-family: 'Gilroy', sans-serif;
  box-shadow: 0px 5px 20px rgba(3, 173, 232, 0.15);
  line-height: 1;
  cursor: pointer;
  min-width: 130px;
  white-space: nowrap;
  transition: 0.5s;
  user-select: none;
  background: ${({ theme }) => theme && theme.colour.primary};
  color: ${colour.white};
  width: ${({ width }) => width && width};

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: ${breakpoint.max.medium}) {
    transition: transform 1s ease;
  }

  &.disabled,
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: normal;
  }

  &.danger {
    background: ${colour.red};
    box-shadow: 0px 5px 20px rgba(232, 3, 3, 0.15);
  }

  ${({ floatingButtonMobile }) =>
    floatingButtonMobile &&
    css`
      @media (max-width: ${breakpoint.max.medium}) {
        position: fixed;
        bottom: 20px;
        right: 2rem;
        z-index: 5;
        min-width: unset;
        border-radius: 27px;
      }
    `}
`;

const ButtonBorderStyle = css`
  border-color: ${({ theme }) => theme && theme.colour.primary};
  color: ${({ theme }) => theme && theme.colour.primary};
  background: transparent;
`;

const ButtonSimpleStyle = css`
  display: inline-flex;
  align-items: center;
  appearance: none;
  background: none;
  border: 0;
  outline: none;
  cursor: pointer;
  transition: 0.5s;
  color: ${({ theme }) => theme && theme.colour.primary};
  color: ${(props) => props.colour};
  svg {
    margin-right: 5px;
  }
  &.disabled,
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: normal;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const ButtonWithArrow = css`
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.061' height='10.707' viewBox='0 0 6.061 10.707'%3E%3Cpath id='Path_6478' data-name='Path 6478' d='M108.352,1026.755l-5,5,5,5' transform='translate(-102.645 -1026.402)' fill='none' stroke='currentColor' stroke-width='1'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 15px;
  font-size: 14px;
`;

const ButtonCenter = css`
  padding-right: 25px !important;
`;

const ButtonVerticalCenter = css`
  display: flex;
  align-items: center;
`;

const ButtonMini = css`
  padding: 10px !important;
  min-width: auto;
`;

export const ButtonLink = styled.a`
  ${ButtonStyle};
`;

export const ButtonRouteLink = styled(Link)`
  ${ButtonStyle};
`;

export const Button = styled.button`
  ${(props) => (props.simple ? ButtonSimpleStyle : ButtonStyle)};
  ${(props) => props.border && ButtonBorderStyle};
  ${(props) => props.withArrow && ButtonWithArrow};
  ${(props) => props.withIcon && ButtonVerticalCenter};
  ${(props) => !props.withIcon && ButtonCenter};
  ${(props) => props.mini && ButtonMini};
`;
