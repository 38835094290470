import { DataStore, Predicates } from '@aws-amplify/datastore';
import {
  Consultant,
  Skill,
  Organization,
  ConsultingRequirement,
  Role,
  Profile,
  Project,
  Notification,
} from '@/models';

const getModel = (model) => {
  switch (model) {
    case 'skill':
      return Skill;
    case 'consultant':
      return Consultant;
    case 'organization':
      return Organization;
    case 'consultingRequirement':
      return ConsultingRequirement;
    case 'role':
      return Role;
    case 'profile':
      return Profile;
    case 'project':
      return Project;
    case 'notification':
      return Notification;
    default:
      return;
  }
};

//singleton
const db = {
  getAll: async (model) => {
    try {
      return await DataStore.query(getModel(model), Predicates.ALL);
    } catch (error) {
      console.warn(error);
      return [];
    }
  },
  add: async (model, item) => {
    try {
      const Model = getModel(model);
      return await DataStore.save(new Model(item));
    } catch (error) {
      console.warn(error);
    }
  },
  update: async (model, item, id) => {
    try {
      const Model = getModel(model);
      const original = await DataStore.query(Model, id);
      return await DataStore.save(
        Model.copyOf(original, (updated) => {
          for (const key in item) {
            updated[key] = item[key];
          }
        })
      );
    } catch (error) {
      console.warn(error);
    }
  },
  delete: async (model, id) => {
    try {
      const toDelete = await DataStore.query(getModel(model), id);
      return await DataStore.delete(toDelete);
    } catch (error) {
      console.warn(error);
    }
  },
  search: async (model, item, key) => {
    try {
      return await DataStore.query(getModel(model), (c) =>
        c[key ? key : 'name']('contains', item)
      );
    } catch (error) {
      console.warn(error);
    }
  },
  observe: async (model, id, callback) =>
    await DataStore.observe(getModel(model), id).subscribe((msg) =>
      callback(msg)
    ),
  clear: async () => await DataStore.clear(),
  restartSync: async () => {
    await DataStore.stop();
    await DataStore.start();
  },
};

export default db;
