import React, { useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import Modal from '@/elements/Modal';
import { Button } from '@/elements/Button/style';
import { Table } from 'evergreen-ui';
import {
  TableRow,
  TableCell,
  TableHead,
  HeaderCell,
} from '@/components/Table/style';
import { ViewLink } from '@/components/InvoiceTable/style';
import { LinksContainer } from '@/pages/app/Organization/style';
import { Spacer } from '@/elements/Spacer/style';
import { ErrorBox } from '@/elements/ErrorBox/style';
import {
  PhotoUploadFieldWrap,
  ButtonGroupContainer,
} from '@/elements/Form/style';
import { TextInput, getValidator, FinalForm, FileInput } from '@/elements/Form';
import { Separator } from '../style';

export const Documents = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [error, setError] = useState('');
  const [errorAdd, setErrorAdd] = useState('');

  const [selectedItem, setSelectedItem] = useState({ name: '', index: 0 });

  return (
    <PhotoUploadFieldWrap>
      <label className="label">{`${props.label}${
        props.validationType && props.validationType.indexOf('required') > -1
          ? '*'
          : ''
      }`}</label>
      <Spacer desktop={10} />
      <FieldArray
        name="type"
        validate={(value) => getValidator(props.validationType, value)}
        {...props}
      >
        {({ fields }) => {
          const handleDelete = () => {
            fields.remove(selectedItem.index);
          };

          const handleRemove = (filename, values) => {
            if (values.document && values.document.indexOf('"name":""') < 0) {
              let payload = values;
              let arr = JSON.parse(values.document).filter(
                (obj) => obj.name !== filename
              );
              arr = arr.length ? arr : [{ name: '' }];
              payload['document'] = JSON.stringify(arr);
              fields.update(selectedItem.index, payload);
            }
          };

          const handleEdit = (values) => {
            const { title, document, documentUrl } = values;
            setError('');

            if (!documentUrl && !document) {
              setError('Please provide a document or a document URL.');
              return;
            }

            const documentItem = {
              title,
            };

            if (document) {
              documentItem.document = JSON.parse(document);
            }

            if (documentUrl) {
              documentItem.document = [
                {
                  name: 'url-document--1',
                  serverFileName: title,
                  folder: 'projects/documents',
                  url: documentUrl,
                  type: 'application/pdf',
                  uploadDate: JSON.stringify(new Date()),
                },
              ];
            }

            fields.update(selectedItem.index, documentItem);
            setIsModalOpen(false);
          };
          const handleAdd = (values) => {
            const { title, document, documentUrl } = values;
            setErrorAdd('');

            if (!documentUrl && !document) {
              setErrorAdd('Please provide a document or a document URL.');
              return;
            }

            const documentItem = {
              title,
            };

            if (document) {
              documentItem.document = JSON.parse(document);
            }

            if (documentUrl) {
              documentItem.document = [
                {
                  name: 'url-document--1',
                  serverFileName: title,
                  folder: 'projects/documents',
                  url: documentUrl,
                  type: 'application/pdf',
                  uploadDate: JSON.stringify(new Date()),
                },
              ];
            }

            fields.push(documentItem);
            setIsAddModalOpen(false);
          };
          return (
            <>
              {fields.length !== 0 && (
                <>
                  <Table>
                    <TableHead paddingX={0}>
                      <HeaderCell>Title</HeaderCell>
                      <HeaderCell
                        flexBasis={200}
                        flexShrink={0}
                        flexGrow={0}
                        paddingX={0}
                      ></HeaderCell>
                    </TableHead>
                    <Table.Body height={'auto'}>
                      {fields.map((x, i) => {
                        return (
                          <TableRow key={i} height={'auto'} variation={'admin'}>
                            <TableCell>{fields.value[i].title}</TableCell>
                            <TableCell
                              flexBasis={200}
                              flexShrink={0}
                              flexGrow={0}
                              paddingX={0}
                            >
                              <LinksContainer>
                                <ViewLink
                                  onClick={() => {
                                    setIsModalOpen(true);
                                    setSelectedItem({
                                      ...fields.value[i],
                                      index: i,
                                    });
                                  }}
                                >
                                  Edit
                                </ViewLink>
                                <ViewLink
                                  onClick={() => {
                                    setIsDeleteModalOpen(true);
                                    setSelectedItem({
                                      ...fields.value[i],
                                      index: i,
                                    });
                                  }}
                                >
                                  Delete
                                </ViewLink>
                              </LinksContainer>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </Table.Body>
                  </Table>
                  <Spacer desktop={10} />
                </>
              )}
              {fields.length === 0 && 'No documents have been created '}
              <Button
                simple
                onClick={(e) => {
                  e.preventDefault();
                  setIsAddModalOpen(true);
                }}
              >
                + Add document
              </Button>

              <Modal
                title="Add document"
                isShown={isAddModalOpen}
                handleModalState={setIsAddModalOpen}
                type="plain"
                alignment="center"
                shouldCloseOnEscapePress={false}
                shouldCloseOnOverlayClick={false}
              >
                <FinalForm
                  onSubmit={handleAdd}
                  render={({ handleSubmit, values }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        {errorAdd && (
                          <ErrorBox>{errorAdd}</ErrorBox>
                        )}

                        <TextInput
                          name="title"
                          component="input"
                          value={values.title}
                          type="text"
                          validationType="required"
                          label="Title of Document"
                          placeholder="Enter the title of the document"
                        />
                        
                        <TextInput
                          name="documentUrl"
                          component="input"
                          value={values.documentUrl}
                          type="url"
                          label="URL"
                          placeholder="Enter the URL of the document"
                          disabled={values.document ? 'disabled' : undefined}
                        />
                        <Separator>OR</Separator>
                        <FileInput
                          name="document"
                          single={true}
                          value={values.document}
                          handleRemove={(filename) =>
                            handleRemove(filename, values)
                          }
                          component="input"
                          label="Upload Document"
                          folder="projects/documents"
                          disabled={values.documentUrl ? 'disabled' : undefined}
                        />

                        <ButtonGroupContainer justifyContent="flex-end">
                          <Button>Add</Button>
                          <Button
                            simple
                            onClick={(e) => {
                              e.preventDefault();
                              setIsAddModalOpen(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </ButtonGroupContainer>
                      </form>
                    );
                  }}
                ></FinalForm>
              </Modal>
                
              <Modal
                title="Edit Document"
                isShown={isModalOpen}
                handleModalState={setIsModalOpen}
                type="plain"
                alignment="center"
                shouldCloseOnEscapePress={false}
                shouldCloseOnOverlayClick={false}
              >
                <FinalForm
                  onSubmit={handleEdit}
                  initialValues={{
                    title: selectedItem.title,
                    document:
                      selectedItem.document &&
                      selectedItem.document[0].name !== 'url-document--1'
                        ? JSON.stringify(selectedItem.document)
                        : null,
                    documentUrl:
                      selectedItem.document &&
                      selectedItem.document[0].name === 'url-document--1'
                        ? selectedItem.document[0]?.url
                        : null,
                  }}
                  render={({ handleSubmit, values }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        {error && <ErrorBox>{error}</ErrorBox>}
                        <TextInput
                          name="title"
                          component="input"
                          value={values.title}
                          type="text"
                          label="Title of Document"
                          placeholder="Enter the title of the document"
                        />

                        <TextInput
                          name="documentUrl"
                          component="input"
                          value={values.documentUrl}
                          type="url"
                          label="URL"
                          placeholder="Enter the URL of the document"
                          disabled={values.document ? 'disabled' : undefined}
                        />
                        <Separator>OR</Separator>
                        <FileInput
                          name="document"
                          single={true}
                          value={values.document}
                          handleRemove={(filename) =>
                            handleRemove(filename, values)
                          }
                          component="input"
                          label="Upload Document"
                          folder="projects/documents"
                          disabled={values.documentUrl ? 'disabled' : undefined}
                        />

                        <ButtonGroupContainer justifyContent="flex-end">
                          <Button>Update</Button>
                          <Button
                            simple
                            onClick={(e) => {
                              e.preventDefault();
                              setIsModalOpen(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </ButtonGroupContainer>
                      </form>
                    );
                  }}
                ></FinalForm>
              </Modal>

              <Modal
                title="Delete Document"
                isShown={isDeleteModalOpen}
                handleModalState={setIsDeleteModalOpen}
                handleAction={() => handleDelete()}
                type={'action'}
                alignment={'center'}
                buttonText="Yes"
                shouldCloseOnEscapePress={false}
                shouldCloseOnOverlayClick={false}
              >
                <p>Are you sure you want to delete {selectedItem.title}?</p>
              </Modal>
            </>
          );
        }}
      </FieldArray>
    </PhotoUploadFieldWrap>
  );
};
