import React, { useContext } from 'react';
import amplifyCustomUi from 'aws-amplify-react-custom-ui';
import Header from '@/components/Header';
import { TabWrap, TabHeader } from '@/elements/Tabs/style';
import { TabLink } from '@/elements/TabLink';
import { NavContainer, ContentWrapper } from './style';
import { AuthContext } from '@/context/auth';
import { Redirect } from 'react-router-dom';
import { isConsultingClient, isProjectClient } from '@/utils/clientTypeHelpers';

const LoggedInTemplate = (props) => {
  const { user, organization } = useContext(AuthContext);

  if (!user) {
    return null;
  }

  if (!localStorage.getItem('organization')) {
    return <Redirect push to="/clients" />;
  }

  return (
    <>
      {!props.noNav && (
        <NavContainer hasTab={props.hasTabs}>
          <Header />
          {props.hasTabs && (
            <nav>
              <TabWrap>
                <TabHeader>
                  {isConsultingClient(organization) && (
                    <>
                      <TabLink to="/requirements">Positions</TabLink>
                      <TabLink to="/consultants">Consultants</TabLink>
                    </>
                  )}
                  {isProjectClient(organization) && (
                    <TabLink to="/projects">Projects</TabLink>
                  )}
                </TabHeader>
              </TabWrap>
            </nav>
          )}
        </NavContainer>
      )}
      <ContentWrapper hasYPadding={props.hasYPadding}>
        {props.children}
      </ContentWrapper>
    </>
  );
};

export default amplifyCustomUi.withAuthenticator(LoggedInTemplate);
