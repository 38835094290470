import React, { useState, useEffect } from 'react';
import SidebarFilter from '@/components/SidebarFilter';
import ProfileAvatar from '@/components/ProfileAvatar';
import LoggedInTemplate from '@/templates/loggedIn';
import { Col, Row, Grid } from 'react-styled-flexboxgrid';
import db from '@/utils/database';
import { DataStore } from '@aws-amplify/datastore';
import { Hub } from 'aws-amplify';
import { Consultant } from '@/models';
import { useContext } from 'react';
import { AuthContext } from '@/context/auth';
import { orderBy } from 'lodash';
import { Loader } from '@/elements/Loader/style';
import {
  FilterWithMobileToggle,
  ToggleFilterButton,
} from '@/components/SidebarFilter/style';
import { ConsultantDetailModal } from './Modals';
import { isConsultingClient } from '@/utils/clientTypeHelpers';
import { Redirect } from 'react-router-dom';
import { datesAreOnSameDay } from '@/utils/dates';

async function listConsultants(filteredConsultantsAndSet) {
  const consultants = await db.getAll('consultant');

  filteredConsultantsAndSet(consultants);
}

const Consultants = (props) => {
  const [consultants, setConsultants] = useState();
  const [targetConsultant, setTargetConsultants] = useState();
  const [showConsultantDialog, setShowConsultantDialog] = useState(false);
  const [filteredConsultants, setFilteredConsultants] = useState();
  const [loading, setLoading] = useState(true);

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const { user, areas, organization } = useContext(AuthContext);
  const activeOrganization = localStorage.getItem('organization');

  useEffect(() => {
    if (!user) {
      return;
    }
    const filteredConsultantsAndSet = (data) => {
      const filterConsultants = data
        .filter((x) => {
          if (!x.inContract) {
            return false;
          }
          if (!x.organization) {
            return false;
          }
          if (!x.organization?.id || !x.organization?.id === '-') {
            return false;
          }

          if (!activeOrganization) {
            return false;
          }

          return x.organization?.id === activeOrganization;
        })
        .filter((x) => {
          if (!areas || !user.attributes['custom:organization']) {
            return true;
          }

          return areas.filter((area) => x.areas.includes(area)).length > 0;
        });

      setConsultants(
        orderBy(
          filterConsultants,
          [(consultant) => consultant.name?.toLowerCase()],
          ['asc']
        )
      );
      setFilteredConsultants(
        orderBy(
          filterConsultants,
          [(consultant) => consultant.name?.toLowerCase()],
          ['asc']
        )
      );
      setLoading(false);
    };

    listConsultants(filteredConsultantsAndSet);

    const listenerData = (data) => {
      if (data.payload.event === 'ready') {
        listConsultants(filteredConsultantsAndSet);
      }
    };
    Hub.listen('datastore', listenerData);

    const subscription = DataStore.observe(Consultant).subscribe((msg) => {
      listConsultants(filteredConsultantsAndSet);
    });

    const handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';

      if (condition === 'online') {
        listConsultants(filteredConsultantsAndSet);
      }
    };

    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      subscription.unsubscribe();
      Hub.remove('datastore', listenerData);

      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (!isConsultingClient(organization) && organization) {
    return <Redirect push to="/projects" />;
  }

  return (
    <LoggedInTemplate hasTabs hasYPadding>
      {loading && <Loader overlay />}
      <ConsultantDetailModal
        consultant={targetConsultant}
        isShown={showConsultantDialog}
        user={user}
        history={props.history}
        handleModalState={setShowConsultantDialog}
      />
      <Grid>
        <Row>
          <Col xs={12} sm={4} md={3}>
            <FilterWithMobileToggle>
              <div className="content">
                {consultants && (
                  <SidebarFilter
                    classify="role"
                    title="Current Consultants"
                    mobileTitle="Filter Consultants By"
                    data={consultants}
                    onFilter={setFilteredConsultants}
                    isOpen={isFilterOpen}
                    setIsOpen={setIsFilterOpen}
                  />
                )}
              </div>
              <div className="toggle">
                <ToggleFilterButton
                  onClick={() => {
                    setIsFilterOpen(true);
                  }}
                />
              </div>
            </FilterWithMobileToggle>
          </Col>
          <Col xs={12} sm={8} md={9}>
            {consultants && consultants.length === 0 && (
              <p>There are currently no consultants in contract.</p>
            )}
            {filteredConsultants && (
              <Row>
                {filteredConsultants &&
                  filteredConsultants
                    .filter((x) => {
                      if (!x.startDate || !x.endDate) {
                        return true;
                      }
                      const dateNow = new Date();
                      const consultantStartDate = new Date(x.startDate);
                      return (
                        consultantStartDate > dateNow ||
                        datesAreOnSameDay(dateNow, consultantStartDate)
                      );
                    })
                    .map((x) => (
                      <Col xs={12} sm={6} md={4} key={`new-${x.id}`}>
                        <div
                          style={{ cursor: 'pointer', margin: '15px 0' }}
                          onClick={() => {
                            setTargetConsultants(x);
                            setShowConsultantDialog(true);
                          }}
                        >
                          <ProfileAvatar {...x} />
                        </div>
                      </Col>
                    ))}
                <Col xs={12}></Col>
                {filteredConsultants &&
                  filteredConsultants
                    .filter((x) => {
                      if (!x.startDate || !x.endDate) {
                        return false;
                      }
                      const dateNow = new Date();
                      const consultantStartDate = new Date(x.startDate);
                      return (
                        consultantStartDate < dateNow &&
                        !datesAreOnSameDay(dateNow, consultantStartDate)
                      );
                    })
                    .map((x) => (
                      <Col xs={12} sm={6} md={4} key={x.id}>
                        <div
                          style={{ cursor: 'pointer', margin: '15px 0' }}
                          onClick={() => {
                            setTargetConsultants(x);
                            setShowConsultantDialog(true);
                          }}
                        >
                          <ProfileAvatar {...x} />
                        </div>
                      </Col>
                    ))}
              </Row>
            )}
          </Col>
          {/* <button onClick={handleSubmit}>add</button> */}
        </Row>
      </Grid>
    </LoggedInTemplate>
  );
};

export default Consultants;
