import { Storage } from 'aws-amplify';

export const s3Upload = async function (folder, file) {
  const filename = `${Date.now()}-${file.name}`;

  const stored = await Storage.put(`${folder}/${filename}`, file, {
    contentType: file.type,
    progressCallback(progress) {
    },
  });

  const imageUrl = await Storage.get(stored.key);

  return imageUrl.split('?')[0];
};

export const s3UploadFile = async function (folder, file, onProgress) {
  const filename = `${Date.now()}-${file.name}`;

  const stored = await Storage.put(`${folder}/${filename}`, file, {
    contentType: file.type,
    progressCallback(progress) {
      onProgress(progress, file);
    },
  });

  const imageUrl = await Storage.get(stored.key);

  return {
    name: file.name,
    serverFileName: filename,
    folder: folder,
    url: imageUrl.split('?')[0],
    type: file.type,
    uploadDate: new Date().toISOString(),
  };
};


export const s3RemoveFile = async(filename) => {
  return await Storage.remove(filename);
}
