import styled from 'styled-components';

export const Wrapper = styled.div`
  animation-duration: 500ms;
  animation-fill-mode: both;
  animation-name: ${(props) =>
    props.animated ? (props.active ? 'fadeInRight' : 'fadeOutLeft') : ''};
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(5%, 0, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes fadeOutLeft {
    from {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    to {
      opacity: 0;
      transform: translate3d(-5%, 0, 0);
    }
  }
`;
