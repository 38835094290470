import styled, { css } from 'styled-components';
import { colour, breakpoint, boxShadow } from '@/styles/variables';

export const ContentArea = styled.div`
  padding: 30px 0;
`;

export const NavContainer = styled.div`
  box-shadow: ${boxShadow.boxShadowHeader};
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background: #fff;
  z-index: 19;
`;

export const LayoutContainer = styled.div`
  min-height: calc(100vh - 100px);
  padding: 50px 0;
  display: flex;
  align-items: center;
`;

export const LayoutInnerContainer = styled.div`
  width: ${({ wide }) => (wide ? '900px' : '430px')};
  margin: 0 auto;
  text-align: center;
  padding: 0 15px;

  @media (max-width: ${breakpoint.max.medium}) {
    padding: 0 25px;
  }
`;

export const FooterListing = styled.ul`
  padding: 0;
  margin: 30px 0 0;
  list-style-type: none;
  text-align: right;

  li {
    display: inline-block;
    margin-left: 30px;

    a {
      color: ${colour.charcoal};
      text-decoration: none;
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

export const ContentWrapper = styled.div`
  ${({ hasYPadding }) =>
    hasYPadding &&
    css`
      padding: 40px 0;

      @media (max-width: ${breakpoint.max.medium}) {
        padding: 20px 0;
      }
    `}
`;
