import React, { useEffect } from 'react';
import Login from '@/pages/auth/Login';
import amplifyCustomUi from 'aws-amplify-react-custom-ui';
import { ThemeProvider } from 'styled-components';
import SignUpConfirmation from './pages/auth/SignupConfirmation';
import useAmplifyAuth from './utils/useAmplifyAuth';
import { AuthProvider } from './context/auth';
import { Routes } from './Routes';
import { Auth, DataStore } from 'aws-amplify';

const App = () => {
  amplifyCustomUi.setSignIn(Login);
  amplifyCustomUi.setConfirmSignUp(SignUpConfirmation);

  const {
    state: { user, organization, areas },
    handleSignout,
    handleDataRefresh,
    handleSetOrganization,
  } = useAmplifyAuth();

  const theme = {
    flexboxgrid: {
      // Defaults
      gridSize: 12, // columns
      gutterWidth: 2, // rem
      outerMargin: 2, // rem
      mediaQuery: 'only screen',
      container: {
        sm: 46, // rem
        md: 61, // rem
        lg: 76, // rem
      },
      breakpoints: {
        xs: 0, // em
        sm: 48, // em
        md: 64, // em
        lg: 75, // em
      },
    },
    colour: {
      primary: '#00ADE8',
    },
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    const refreshToken = async () => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = cognitoUser.signInUserSession;
        cognitoUser.refreshSession(
          currentSession.refreshToken,
          (err, session) => {}
        );
      } catch (e) {
        console.warn(e);
      }
    };
    const refreshData = async () => {
      try {
        await DataStore.start();
      } catch (e) {
        console.warn(e);
      }
    };
    refreshData();
    refreshToken();
    const interval = setInterval(() => {
      refreshToken();
      refreshData();
    }, 60000);
    return () => clearInterval(interval);
  }, [user]);

  return (
    <AuthProvider
      value={{
        user,
        organization,
        areas,
        handleSignout,
        handleDataRefresh,
        handleSetOrganization,
      }}
    >
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
