import React, { useState, useContext, useEffect } from "react";
import db from '@/utils/database';
import ProfileForm from "@/pages/app/Profile/ProfileForm";
import StepProgress from "@/components/StepProgress";
import LoggedInTemplate from "@/templates/loggedIn";
import { Col, Row, Grid } from 'react-styled-flexboxgrid';
import styled from "styled-components";
import { withRouter, Redirect, Prompt } from 'react-router-dom';
import Modal from '@/elements/Modal';
import { AuthContext } from "@/context/auth";
import { isUserProfileGranted } from '@/utils/isUserAdmin';

const educationObj = {
  institute: '',
  accolade: '',
  course: '',
  completion: '',
};
const experienceObj = {
  role: '',
  tenureStart: '',
  tenureEnd: '',
  company: '',
  responsibilities: '',
};

const AddProfile = (props) => {
  const { user } = useContext(AuthContext);

  const [activeStep, setActiveStep] = useState(0)
  const profileId = new URLSearchParams(props.location.search).get('id')
  const reqId = new URLSearchParams(props.location.search).get('reqId')
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const [hasSaveDraft, setSaveDraft] = useState(false)
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [profile, setProfile] = useState({summary: ''});
  
  const [skills, setSkills] = useState([]);
  const [requirement, setRequirement] = useState({});
  const [roles, setRoles] = useState([]);
  const [educations, setEducations] = useState({
    educations: [educationObj],
  });
  const [experiences, setExperiences] = useState({
    experiences: [experienceObj],
  });
  const activeOrganization = localStorage.getItem('organization');

  useEffect(() => {
    const fetchData = async () => {
      const req = await db.search('consultingRequirement', reqId, 'id');
      const skills = await db.getAll('skill');
      // console.log(skills);
      setSkills(skills.filter((x) => x.roles.indexOf(req[0]['title']) > -1));
      setRoles(await db.getAll('role'));
      setRequirement(req[0]);
      if (profileId) {
        const res = await db.search('profile', profileId, 'id');
        let payload = { ...res[0] };
        payload['customSkills'] = payload['skills']
          ? payload['skills']
            .filter((x) => skills.map((y) => y.name).indexOf(x) < 0)
            .toString()
          : '';
        payload['skills'] =
          payload['skills'] && payload['skills'][0] === ''
            ? []
            : payload['skills'].filter(
              (x) => skills.map((y) => y.name).indexOf(x) > -1
            );
        payload['domains'] = payload['domains']
          ? payload['domains'][0] === ''
            ? ['']
            : payload['domains']
          : [''];
        payload['certifications'] = payload['certifications']
          ? payload['certifications'][0] === ''
            ? ['']
            : payload['certifications']
          : [''];
        payload['summary'] =
          payload['summary'] ? payload['summary'] : '' ;

        if(payload['educations']) {
          setEducations({ educations: payload['educations'] });
        }
        
        if(payload['experiences']) {
          setExperiences({
            experiences: payload['experiences'].map((x) => {
              return {
                ...x,
                tenureStart: x.tenureStart? new Date(x.tenureStart) : "",
                tenureEnd:
                  x.tenureEnd !== 'Present' ? x.tenureEnd ? new Date(x.tenureEnd): "" : 'Present',
              };
            }),
          });
        }
        
        // console.log(payload, skills);
        setProfile(payload);
        setSkills(
          skills.filter(
            (x) =>
              x.roles.indexOf(payload['consultingRequirement']['title']) > -1
          )
        );
      }
    };
    fetchData();
  }, [profileId, reqId, activeOrganization, user, setProfile]);

  return (
    <LoggedInTemplate>
      {!isUserProfileGranted(user) ? <Redirect push to="/requirements" />
      :  
      <Wrap fluid>
        <Prompt
          when={shouldBlockNavigation}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        <Row className="row">
          <Col xs={3} className="sidebar">
          <div className="sticky">
            <span onClick={() => {
              if(!hasSaveDraft) {
                setShouldBlockNavigation(false);
                setShowCancelDialog( true );
              } else {
                props.history.push(`/profiles?reqId=${reqId}`)}
              }
            } className="back" >{`< Cancel`}</span>
            <StepProgress
              direction="vertical"
              currentStep={activeStep}
              steps={[
                'Overview',
                'Skills',
                'Education',
                'Experience',
                'Certifications',
                'Review'
              ]}
              setActiveStep={(i) => {
                if(i < activeStep) {
                  document.getElementsByClassName("backStep")[0].setAttribute('step', i);
                  document.getElementsByClassName("backStep")[0].click();
                } else {
                  document.getElementsByClassName("save")[0].setAttribute('step', i);
                  document.getElementsByClassName("save")[0].click();
                }
              }}
            ></StepProgress>
          </div>
          </Col>
          <Col xs={9} className="main" >
            <ProfileForm skills={skills} requirement={requirement} roles={roles} educations={educations} experiences={experiences} activeOrganization={activeOrganization} profile={profile} setSaveDraft={(i) => setSaveDraft(i)} setProfile={setProfile} setShouldBlockNavigation={setShouldBlockNavigation} profileId={profileId} reqId={reqId} activeStep={activeStep} setActiveStep={setActiveStep} refreshProfiles={props.refreshProfiles} setSkills={setSkills} setRequirement={setRequirement} setRoles={setRoles} setEducations={setEducations} setExperiences={setExperiences} />
          </Col>
        </Row>
      </Wrap>
      }
      <Modal
        title="Are you sure you wish to cancel?"
        intent="danger"
        type="action"
        buttonText="Yes, I'm sure"
        isShown={showCancelDialog}
        handleModalState={setShowCancelDialog}
        handleAction={() => {props.history.push(`/profiles?reqId=${reqId}`)}}
        shouldCloseOnEscapePress={false}
        shouldCloseOnOverlayClick={false}
      >
        <p>
        All entered information will be lost
        </p>
      </Modal>
    </LoggedInTemplate>
  );
};

export default withRouter(AddProfile);

const Wrap = styled(Grid)`
  background: linear-gradient(to right, #f1f3f4 50%, #fff 50%);
  .row{
    min-height: 100vh;
  }
  .sidebar{
    background: #f1f3f4;
    padding-top: 30px;
  }
  .main{
    background: #FFF;
    padding: 30px;
    position: relative;
  }
  .back{
    display: block;
    margin: 15px 0 50px 0;
    cursor: pointer;
  }
  .sticky{
    position: sticky;
    top: 125px;
  }
`
