import React, { useEffect, useState } from 'react';
import * as Styled from './style';

const Step = (props) => {
  const { active, animated = true, children } = props;
  const [render, setRender] = useState(active);

  useEffect(() => {
    if (active) setTimeout(() => setRender(true), 500);
  }, [active]);

  const onAnimationEnd = () => {
    if (!active) setRender(false);
  };

  return render ? (
    <Styled.Wrapper
      animated={animated}
      onAnimationEnd={onAnimationEnd}
      active={active}
    >
      {children}
    </Styled.Wrapper>
  ) : null;
};

export default Step;
