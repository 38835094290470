import React from 'react';
import { ProfileWrapper, AvatarWrapper, ProfileInfoWrapper } from './style';
import { Avatar } from 'evergreen-ui';
import Type from '@/elements/Type';
import { Spacer } from '@/elements/Spacer/style';

const ProjectAvatar = (props) => {
  const { heading, name, title, email, phone, profileImage } = props;

  return (
    <>
      <Type.H6>{heading}</Type.H6>
      <Spacer desktop={15} />
      <ProfileWrapper>
        <AvatarWrapper>
          <Avatar src={profileImage} name={name} size={55} />
        </AvatarWrapper>
        <ProfileInfoWrapper>
          {name && <Type.H6 className="consultant-name">{name}</Type.H6>}
          {title && <Type.P className="consultant-time">{title}</Type.P>}
          <Type.P className="consultant-position">
            {email} / {phone}
          </Type.P>
        </ProfileInfoWrapper>
      </ProfileWrapper>
    </>
  );
};

export default ProjectAvatar;
