import React, { useState, useEffect } from 'react';
import db from '@/utils/database';
import LoggedInAdminTemplate from '@/templates/loggedInAdmin';
import { Table } from 'evergreen-ui';
import {
  TableHead,
  HeaderCell,
  TableRow,
  TableCell,
} from '@/components/Table/style';
import { ViewLink } from '@/components/InvoiceTable/style';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import Button from '@/elements/Button';
import { DataStore } from '@aws-amplify/datastore';
import { Hub, Auth, API } from 'aws-amplify';
import { Project } from '@/models';
import Modal from '@/elements/Modal';
import { AddProject } from './AddProject';
import { LinksContainer } from './style';
import Type from '@/elements/Type';
import { Spacer } from '@/elements/Spacer/style';
import { orderBy } from 'lodash';
import { colour } from '@/styles/variables';
import { Divider } from '@/elements/Divider/style';
import { withRouter } from 'react-router-dom';
import { EditProject } from './EditProject';
import { ClientLogo, ProfileWithText } from '../Organization/style';
import { useContext } from 'react';
import { AuthContext } from '@/context/auth';

async function listProjects(setProjects) {
  const projects = await db.getAll('project');
  setProjects(
    orderBy(projects, [(project) => project.name.toLowerCase()], ['asc'])
  );
}

async function listOrganizations(setOrganizations) {
  const organizations = await db.getAll('organization');
  setOrganizations(
    orderBy(organizations, [(org) => org.name?.toLowerCase()], ['asc'])
  );
}

async function listUsers(setUsers) {
  let apiName = 'AdminQueries';
  let path = '/listUsers';
  let myInit = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };

  const { ...rest } = await API.get(apiName, path, myInit);

  setUsers(rest.Users.filter((x) => x.Enabled));
}

const ListConsultant = (props) => {
  const [projects, setProjects] = useState([]);

  const [activeView, setActiveView] = useState('table');

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedProject, setSelectedProject] = useState({});

  const [organizations, setOrganizations] = useState([]);

  const [users, setUsers] = useState([]);

  const { user } = useContext(AuthContext);

  async function handleDelete(id) {
    await db.delete('project', id);
    setShowDeleteModal(false);
  }

  useEffect(() => {
    if (!user) {
      return;
    }
    listProjects(setProjects);
    listUsers(setUsers);
    listOrganizations(setOrganizations);

    const listenerData = (data) => {
      if (data.payload.event === 'ready') {
        listOrganizations(setOrganizations);
      }
    };
    Hub.listen('datastore', listenerData);

    const subscription = DataStore.observe(Project).subscribe((msg) => {
      listProjects(setProjects);
    });

    const handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';

      if (condition === 'online') {
        listProjects(setProjects);
        listUsers(setUsers);
        listOrganizations(setOrganizations);
      }
    };

    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      subscription.unsubscribe();
      Hub.remove('datastore', listenerData);

      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, [user]);

  return (
    <LoggedInAdminTemplate hasTabs>
      <Grid>
        <Row>
          {activeView === 'add' && (
            <Col xsOffset={2} xs={8}>
              <Button
                simple
                withArrow
                colour={colour.charcoal}
                onClick={() => setActiveView('table')}
              >
                Back to projects
              </Button>
              <Spacer desktop={20} />
              <Type.H4>Add Project</Type.H4>
              <Divider desktop={20} />
              <AddProject
                organizations={organizations}
                users={users}
                setActiveView={setActiveView}
              />
            </Col>
          )}
          {activeView === 'edit' && (
            <Col xsOffset={2} xs={8}>
              <Button
                simple
                withArrow
                colour={colour.charcoal}
                onClick={() => setActiveView('table')}
              >
                Back to projects
              </Button>
              <Spacer desktop={20} />
              <Type.H4>Edit Project</Type.H4>
              <Divider desktop={20} />
              <EditProject
                setActiveView={setActiveView}
                organizations={organizations}
                users={users}
                project={selectedProject}
              />
            </Col>
          )}
          {activeView === 'table' && (
            <>
              <Col xs={3}>
                <Button
                  width={'100%'}
                  border
                  onClick={() => setActiveView('add')}
                >
                  Add Project
                </Button>
              </Col>
              <Col xs={9}>
                <Row>
                  {activeView === 'table' && (
                    <>
                      <Col xs={12}>
                        <Type.H4>Projects</Type.H4>
                        <Spacer desktop={30} />
                      </Col>
                      <Col xs={12}>
                        {!!projects && projects.length > 0 ? (
                          <Table>
                            <TableHead paddingX={0}>
                              <HeaderCell>Title</HeaderCell>
                              <HeaderCell>Phase</HeaderCell>
                              <HeaderCell>Contact</HeaderCell>
                              <HeaderCell>Client</HeaderCell>
                              <HeaderCell
                                flexBasis={200}
                                flexShrink={0}
                                flexGrow={0}
                                paddingX={0}
                              ></HeaderCell>
                            </TableHead>
                            <Table.Body height={'auto'}>
                              {projects.map((x, index) => (
                                <TableRow
                                  key={index}
                                  height={'auto'}
                                  variation={'admin'}
                                >
                                  <TableCell>{x.name}</TableCell>
                                  <TableCell>{x.phase}</TableCell>
                                  <TableCell>{x.pointOfContact.name}</TableCell>
                                  <TableCell>
                                    {x.organization?.id ? (
                                      <ProfileWithText>
                                        <ClientLogo
                                          src={x.organization?.logoImage?.replace(
                                            /%20/g,
                                            '+'
                                          )}
                                          alt={x.organization?.name}
                                        />
                                        <span>{x.organization?.name}</span>
                                      </ProfileWithText>
                                    ) : (
                                      '-'
                                    )}
                                  </TableCell>
                                  <TableCell
                                    flexBasis={200}
                                    flexShrink={0}
                                    flexGrow={0}
                                    paddingX={0}
                                  >
                                    <LinksContainer>
                                      <ViewLink
                                        onClick={() => {
                                          setActiveView('edit');
                                          setSelectedProject(x);
                                        }}
                                      >
                                        Edit
                                      </ViewLink>
                                      <ViewLink
                                        onClick={() => {
                                          setShowDeleteModal(true);
                                          setSelectedProject(x);
                                        }}
                                      >
                                        Delete
                                      </ViewLink>
                                    </LinksContainer>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </Table.Body>
                          </Table>
                        ) : (
                          <Type.P8>There are currently no projects.</Type.P8>
                        )}

                        <Modal
                          title="Delete Project"
                          isShown={showDeleteModal}
                          handleModalState={setShowDeleteModal}
                          handleAction={() => handleDelete(selectedProject.id)}
                          type={'action'}
                          alignment={'center'}
                          buttonText="Yes"
                          shouldCloseOnEscapePress={false}
                          shouldCloseOnOverlayClick={false}
                        >
                          <p>
                            Are you sure you want to delete{' '}
                            {selectedProject.name}?
                          </p>
                        </Modal>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            </>
          )}
        </Row>
      </Grid>
    </LoggedInAdminTemplate>
  );
};

export default withRouter(ListConsultant);
