import { useReducer, useState, useEffect } from 'react';
import { Auth, DataStore, Hub } from 'aws-amplify';
import db from './database';

async function getOrganization(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const organization = await db.search('organization', id, 'id');

      if (organization && organization.length > 0) {
        resolve(organization[0]);
      } else {
        resolve(null);
      }
    } catch (error) {
      resolve(null);
    }
  });
}

const amplifyAuthReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_USER_DATA_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case 'FETCH_USER_DATA_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        user: action.payload.user,
        organization: action.payload.organization,
        areas: action.payload.areas,
      };
    case 'FETCH_USER_DATA_FAILURE':
      return { ...state, isLoading: false, isError: true };
    case 'RESET_USER_DATA':
      return { ...state, user: null };
    case 'SET_ORGANIZATION':
      return {
        ...state,
        organization: action.payload.organization,
      };
    default:
      throw new Error();
  }
};

const useAmplifyAuth = () => {
  const initialState = {
    isLoading: true,
    isError: false,
    user: null,
    organization: null,
    areas: null,
  };

  const [state, dispatch] = useReducer(amplifyAuthReducer, initialState);
  const [triggerFetch, setTriggerFetch] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const fetchUserData = async () => {
      if (isMounted) {
        dispatch({ type: 'FETCH_USER_DATA_INIT' });
      }
      try {
        if (isMounted) {
          try {
            const data = await Auth.currentAuthenticatedUser({
              bypassCache: true,
            });

            let organization = 'null';
            let areas = null;
            let organizationData = null;

            if (
              !!data.attributes['custom:organization'] &&
              data.attributes['custom:organization'] !== 'null' &&
              !localStorage.getItem('organization')
            ) {
              organization = data.attributes['custom:organization'].split(',');

              if (organization.length === 1) {
                localStorage.setItem(
                  'organization',
                  data.attributes['custom:organization']
                );
              }
            }

            if (localStorage.getItem('organization')) {
              organizationData = await getOrganization(
                localStorage.getItem('organization')
              );
            }

            if (!!data.attributes['custom:areas']) {
              areas = JSON.parse(data.attributes['custom:areas']);
            }

            if (data) {
              dispatch({
                type: 'FETCH_USER_DATA_SUCCESS',
                payload: {
                  user: data,
                  organization: organizationData,
                  areas,
                },
              });
            }
          } catch (error) {
            return;
          }
        }
      } catch (error) {
        if (isMounted) {
          dispatch({ type: 'FETCH_USER_DATA_FAILURE' });
        }
      }
    };

    const HubListener = () => {
      Hub.listen('auth', (data) => {
        const { payload } = data;
        onAuthEvent(payload);
      });
    };
    const onAuthEvent = (payload) => {
      switch (payload.event) {
        case 'signIn':
          if (isMounted) {
            setTriggerFetch(true);
          }
          break;
        case 'signOut':
          if (isMounted) {
            DataStore.clear();
          }
          break;
        default:
          return;
      }
    };
    HubListener();
    fetchUserData();
    return () => {
      Hub.remove('auth');
      isMounted = false;
    };
  }, [triggerFetch]);
  const handleSignout = async () => {
    try {
      await Auth.signOut();
      setTriggerFetch(false);
      dispatch({ type: 'RESET_USER_DATA' });
      localStorage.removeItem('organization');
    } catch (error) {
      console.error('Error signing out user ', error);
    }
  };
  const handleDataRefresh = async () => {
    const data = await Auth.currentAuthenticatedUser({ bypassCache: true });
    if (data) {
      dispatch({
        type: 'FETCH_USER_DATA_SUCCESS',
        payload: { user: data },
      });
    }
  };
  const handleSetOrganization = async (orgId) => {
    if (orgId) {
      localStorage.setItem('organization', orgId);
      const organizationData = await getOrganization(orgId);

      dispatch({
        type: 'SET_ORGANIZATION',
        payload: { organization: organizationData },
      });
    }
  };
  return { state, handleSignout, handleDataRefresh, handleSetOrganization };
};
export default useAmplifyAuth;
