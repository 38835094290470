import React, { useState, useEffect, useContext } from 'react';
import db from '@/utils/database';
import LoggedInMultiClientTemplate from '@/templates/loggedInMultiClient';
import { Table } from 'evergreen-ui';
import {
  TableHead,
  HeaderCell,
  TableRow,
  TableCell,
} from '@/components/Table/style';
import { ViewLink } from '@/components/InvoiceTable/style';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import Button from '@/elements/Button';
import { DataStore } from '@aws-amplify/datastore';
import { Hub, API, Auth } from 'aws-amplify';
import { Organization } from '@/models';
import Modal from '@/elements/Modal';
import { AddOrganization } from './AddOrganization';
import { EditOrganization } from './EditOrganization';
import {
  LinksContainer,
  ClientFilterContainer,
  ClientFilterItem,
  ClientLogo,
  ProfileWithText,
} from './style';
import Type from '@/elements/Type';
import { Spacer } from '@/elements/Spacer/style';
import { orderBy, uniq } from 'lodash';
import { colour } from '@/styles/variables';
import { getCognitoFieldValue } from '@/utils/getCognitoFieldValue';
import { Divider } from '@/elements/Divider/style';
import { AuthContext } from '@/context/auth';
import { withRouter } from 'react-router-dom';
import { isUserAddClient } from '@/utils/isUserAdmin';

async function listOrganizations(setOrganizations) {
  const organizations = await db.getAll('organization');

  setOrganizations([
    ...orderBy(organizations, [(org) => org.name?.toLowerCase()], ['asc']),
  ]);
}

async function listUsers(setUsers) {
  let apiName = 'AdminQueries';
  let path = '/listUsers';
  let myInit = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };

  const { ...rest } = await API.get(apiName, path, myInit);

  setUsers(rest.Users.filter((x) => x.Enabled));
}

const ListOrganization = (props) => {
  const [organizations, setOrganizations] = useState([]);
  const [users, setUsers] = useState([]);

  const [activeFilter, setActiveFilter] = useState('all');
  const [activeView, setActiveView] = useState('table');

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedOrganization, setSelectedOrganization] = useState({});

  const { handleSetOrganization, user } = useContext(AuthContext);

  useEffect(() => {
    if (!user) {
      return;
    }
    listOrganizations(setOrganizations);
    listUsers(setUsers);

    const listenerData = (data) => {
      if (data.payload.event === 'ready') {
        listOrganizations(setOrganizations);
      }
    };
    Hub.listen('datastore', listenerData);

    const subscription = DataStore.observe(Organization).subscribe((msg) => {
      listOrganizations(setOrganizations);
    });

    const handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';

      if (condition === 'online') {
        listOrganizations(setOrganizations);
        listUsers(setUsers);
      }
    };

    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      subscription.unsubscribe();
      Hub.remove('datastore', listenerData);

      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, [user]);

  const allFilters = organizations
    ?.filter((x) => {
      if (!user) {
        return true;
      }
      if (!user.attributes['custom:organization']) {
        return true;
      }
      if (user.attributes['custom:organization'].split(',').length > 1) {
        return user.attributes['custom:organization'].indexOf(x.id) > -1;
      }
      return true;
    })
    ?.map((item) => item.type)
    ?.map((x) => x)
    ?.reduce((all, current) => {
      current.map((x) => all.push(x));
      return uniq(all);
    }, []);

  async function handleDelete(id) {
    const toDelete = await DataStore.query(Organization, id);
    await DataStore.delete(toDelete);
    setShowDeleteModal(false);
  }

  return (
    <LoggedInMultiClientTemplate hasTabs>
      <Grid>
        <Row>
          {activeView === 'add' && (
            <Col xs={12} mdOffset={2} md={8}>
              <Button
                simple
                withArrow
                colour={colour.charcoal}
                onClick={() => setActiveView('table')}
              >
                Back to clients
              </Button>
              <Spacer desktop={20} />
              <Type.H4>Add Client</Type.H4>
              <Divider desktop={20} />
              <AddOrganization setActiveView={setActiveView} />
            </Col>
          )}
          {activeView === 'edit' && (
            <Col xs={12} mdOffset={2} md={8}>
              <Button
                simple
                withArrow
                colour={colour.charcoal}
                onClick={() => setActiveView('table')}
              >
                Back to clients
              </Button>
              <Spacer desktop={20} />
              <Type.H4>Edit Client</Type.H4>
              <Divider desktop={20} />
              <EditOrganization
                setActiveView={setActiveView}
                organization={selectedOrganization}
              />
            </Col>
          )}
          {activeView === 'table' && (
            <>
              {isUserAddClient(user) && (
                <Col xs={12} md={3}>
                  <Button
                    width={'100%'}
                    border
                    onClick={() => setActiveView('add')}
                  >
                    Add Client
                  </Button>
                  <Spacer desktop={20} />
                </Col>
              )}
              <Col xs={12} md={isUserAddClient(user) ? 9 : 12}>
                <Row>
                  {activeView === 'table' && (
                    <>
                      <Col xs={12}>
                        <Type.H4>Clients</Type.H4>
                        <Spacer desktop={20} />
                      </Col>
                      <Col xs={12}>
                        <ClientFilterContainer>
                          <ClientFilterItem
                            isActive={activeFilter === 'all'}
                            onClick={() => setActiveFilter('all')}
                          >
                            All Clients
                          </ClientFilterItem>
                          {allFilters &&
                            allFilters.map((x, i) => (
                              <ClientFilterItem
                                isActive={activeFilter === x}
                                onClick={() => setActiveFilter(x)}
                                key={i}
                              >
                                {x}
                              </ClientFilterItem>
                            ))}
                        </ClientFilterContainer>
                        <Spacer desktop={30} />
                      </Col>
                      <Col xs={12}>
                        {!!organizations && (
                          <Table>
                            <TableHead paddingX={0}>
                              <HeaderCell
                                flexBasis={250}
                                flexShrink={0}
                                flexGrow={0}
                                paddingX={0}
                              ></HeaderCell>
                              <HeaderCell hiddenmobile="true">Type</HeaderCell>
                              <HeaderCell hiddenmobile="true">
                                Projects
                              </HeaderCell>
                              <HeaderCell hiddenmobile="true">Users</HeaderCell>
                              <HeaderCell
                                flexBasis={100}
                                flexShrink={0}
                                flexGrow={0}
                                paddingX={0}
                                hiddenmobile="true"
                              ></HeaderCell>
                            </TableHead>
                            <Table.Body height={'auto'}>
                              {organizations
                                .filter((x) => {
                                  if (!user) {
                                    return true;
                                  }
                                  if (!user.attributes['custom:organization']) {
                                    return true;
                                  }
                                  if (
                                    user.attributes[
                                      'custom:organization'
                                    ].split(',').length > 1
                                  ) {
                                    return (
                                      user.attributes[
                                        'custom:organization'
                                      ].indexOf(x.id) > -1
                                    );
                                  }
                                  return true;
                                })
                                .filter((i) => {
                                  if (activeFilter === 'all') {
                                    return true;
                                  }
                                  return i.type.includes(activeFilter);
                                })
                                .map((x, index) => {
                                  return (
                                    <TableRow
                                      key={index}
                                      height={'auto'}
                                      variation={'admin'}
                                    >
                                      <TableCell
                                        flexBasis={250}
                                        flexShrink={0}
                                        flexGrow={0}
                                        paddingX={0}
                                      >
                                        <ProfileWithText
                                          style={{ cursor: 'pointer' }}
                                          onClick={async () => {
                                            await handleSetOrganization(x.id);
                                            props.history.push('/requirements');
                                          }}
                                        >
                                          <ClientLogo
                                            src={x.logoImage?.replace(
                                              /%20/g,
                                              '+'
                                            )}
                                            alt={x.id}
                                          />
                                          <span>{x.name}</span>
                                        </ProfileWithText>
                                      </TableCell>
                                      <TableCell hiddenmobile="true">
                                        {x.type
                                          ? x.type.length > 1
                                            ? 'Both'
                                            : x.type[0]
                                          : null}
                                      </TableCell>
                                      <TableCell hiddenmobile="true">
                                        {x.projects ? x.projects.length : 0}
                                      </TableCell>
                                      <TableCell hiddenmobile="true">
                                        {users &&
                                          users
                                            .map((x) =>
                                              getCognitoFieldValue(
                                                'custom:organization',
                                                x.Attributes
                                              )
                                            )
                                            .filter((y) => y === x.id).length}
                                      </TableCell>
                                      <TableCell
                                        flexBasis={100}
                                        flexShrink={0}
                                        flexGrow={0}
                                        paddingX={0}
                                        hiddenmobile="true"
                                      >
                                        {isUserAddClient(user) && (
                                          <LinksContainer>
                                            <ViewLink
                                              onClick={() => {
                                                setActiveView('edit');
                                                setSelectedOrganization(x);
                                              }}
                                            >
                                              Edit
                                            </ViewLink>
                                            <ViewLink
                                              onClick={() => {
                                                setShowDeleteModal(true);
                                                setSelectedOrganization(x);
                                              }}
                                            >
                                              Delete
                                            </ViewLink>
                                          </LinksContainer>
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </Table.Body>
                          </Table>
                        )}

                        <Modal
                          title="Delete Organization"
                          isShown={showDeleteModal}
                          handleModalState={setShowDeleteModal}
                          handleAction={() =>
                            handleDelete(selectedOrganization.id)
                          }
                          type={'action'}
                          alignment={'center'}
                          buttonText="Yes"
                          shouldCloseOnEscapePress={false}
                          shouldCloseOnOverlayClick={false}
                        >
                          <p>
                            Are you sure you want to delete{' '}
                            {selectedOrganization.name}?
                          </p>
                        </Modal>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            </>
          )}
        </Row>
      </Grid>
    </LoggedInMultiClientTemplate>
  );
};

export default withRouter(ListOrganization);
