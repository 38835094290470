/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_appsync_graphqlEndpoint": "https://2ya3olvxbvgk3o5s2fc7uvucsm.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://mssn1tyv75.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "EmailQueries",
            "endpoint": "https://z7sj3b7j0h.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-2:b4fadc78-76cf-4267-ac4e-a33e63060d1b",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_23LOmDlFO",
    "aws_user_pools_web_client_id": "3e0mfp3vj6dl4gi9ku585kspnn",
    "oauth": {},
    "aws_user_files_s3_bucket": "appscore-client-portalportal-production",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
