import styled from 'styled-components';
import { colour } from '@/styles/variables';

export const StatusWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #fff;
  border: 1px solid ${colour.lightGreyOpacity};
  border-radius: 10px;

  .card-body {
    cursor: pointer;
  }

  .icon {
    max-width: 22.5px;
    margin-bottom: 10px;
  }

  h6 {
    font-size: 16px;
    line-height: 1.1;
    margin-bottom: 5px;
  }

  p {
    font-size: 13px;
    line-height: 16px;
  }

  .status-text {
    margin-bottom: 5px;
    font-weight: 600;
    text-transform: capitalize;
  }
`;

export const ButtonsContainer = styled.div`
  margin-top: 20px;
  position: relative;
  z-index: 1;
  button {
    padding-left: 0;
    padding-right: 12px;
  }
`;

export const DropdownIconTrigger = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
`;
