import styled, { css } from 'styled-components';
import { colour } from '@/styles/variables';

export const LinksContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  * {
    width: auto;
    display: inline-block;
    cursor: pointer;
    margin-left: 10px;
  }
`;

export const ClientFilterContainer = styled.ul`
  display: block;
  padding: 0;
  margin: 0;
  list-style-type: none;
`;
export const ClientFilterItem = styled.li`
  display: inline-block;
  opacity: 0.5;
  position: relative;
  cursor: pointer;
  font-weight: 700;
  margin-right: 20px;

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${colour.charcoal};
      opacity: 1;
      cursor: normal;

      &::after {
        background-color: ${({ theme }) => theme.colour.primary};
        height: 3px;
        width: 100%;
        position: absolute;
        content: ' ';
        left: 0;
        bottom: -15px;
      }
    `}
`;
