import styled from 'styled-components';
import { colour } from '@/styles/variables';

export const TaskListWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #fff;
  border: 1px solid ${colour.lightGreyOpacity};
  border-radius: 10px;
  min-height: 300px;
`;

export const TasksItemsContainer = styled.div``;
export const TasksItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  .task {
    &-name {
      font-size: 14px;
      line-height: 1;
      margin-bottom: 5px;

      a {
        color: ${colour.blue};
        font-size: 12px;
        margin-left: 5px;
      }
    }
    &-date {
      font-size: 13px;
      line-height: 1;
      color: ${colour.grey};
    }
  }

  button {
    font-size: 14px;
    padding-right: 0;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;
export const TasksItemColOne = styled.div`
  flex: ${({ hasButton }) =>
    hasButton ? '0 0 calc(100% - 78px)' : '0 0 100%'};
  max-width: ${({ hasButton }) => (hasButton ? 'calc(100% - 78px)' : '100%')};
`;
export const TasksItemColTwo = styled.div`
  flex: 0 0 78px;
  max-width: 78px;
`;
