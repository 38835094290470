import { DateTime } from 'luxon';

export const getDaysBetweenDates = (fromDate, toDate) => {
  // One day Time in ms (milliseconds)
  const one_day = 1000 * 60 * 60 * 24;

  // To Calculate the result in milliseconds and then converting into days
  const Result = Math.round(toDate.getTime() - fromDate.getTime()) / one_day;

  // To remove the decimals from the (Result) resulting days value
  return Result.toFixed(0);
};

export const isValidDate = (d) => {
  return d && d instanceof Date && !isNaN(d);
};

export const newDate = (value) => {
  return value ? new Date(value) : null;
};

export const getDurationsBetweenDates = (fromDate, toDate) => {
  let d1 = new Date(fromDate);
  let d2 = toDate === 'Present' ? new Date() : new Date(toDate);

  let years = d2.getFullYear() - d1.getFullYear();
  let months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  months = months <= 0 ? 0 : months - years * 12;

  if (years > 0 && months < 0) {
    years = years - 1;
    months = 12 + months;
  }

  let returnText =
    (years > 0 ? years + ' yrs ' : '') + (months > 0 ? months + ' mos' : '');

  // To remove the decimals from the (Result) resulting days value
  return returnText;
};

export const formatDateForHumans = (date, format = 'd LLL yyyy') => {
  return date ? DateTime.fromJSDate(date).toFormat(format) : '';
};

export const datesAreOnSameDay = (first, second) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export const datesIsTomorrow = (now, targetDate) =>
  now.getFullYear() === targetDate.getFullYear() &&
  now.getMonth() === targetDate.getMonth() &&
  now.getDate() + 1 === targetDate.getDate();
