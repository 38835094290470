import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Wrapper,
  NotificationToggleContainer,
  NotificationContentContainer,
  NotificationCount,
  HeadingContainer,
  NotifsContainer,
  NotifsItem,
} from './styles';
import { ReactComponent as Bell } from '@/static/icons/bell.svg';
import { Button } from '@/elements/Button/style';
import Type from '@/elements/Type';
import { TimeDiff } from '@/utils/timeDiff';
import { capitalizeFirstLetter } from '@/utils/capitalize';
import db from '@/utils/database';
import { AuthContext } from '@/context/auth';
// import { DataStore } from '@aws-amplify/datastore';
import { Hub } from 'aws-amplify';
// import { Notification } from '@/models';
import { orderBy } from 'lodash';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { isUserMultiClient } from '@/utils/isUserAdmin';

async function listNotifications(setNotifications, organization, email) {
  try {
    const notifications = await db.search('notification', email, 'for');
    const filteredNotification = notifications.filter(
      (x) => x.url.indexOf(organization) > -1
    );

    setNotifications(
      orderBy(filteredNotification, [(x) => JSON.parse(x.date)], ['desc'])
    );
  } catch (error) {
    console.warn(error + 'notif');
  }
}

const NotificationModule = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const notifContainerRef = useRef();

  const [notifications, setNotifications] = useState();

  const { user, handleSetOrganization, organization } = useContext(AuthContext);

  const history = useHistory();

  useEffect(() => {
    if (!user || !organization) {
      return;
    }
    
    const userEmail = user.attributes['email'];

    const listenerData = (data) => {
      if (data.payload.event === 'ready') {
        listNotifications(setNotifications, organization.id, userEmail);
      }
    };
    Hub.listen('datastore', listenerData);

    listNotifications(setNotifications, organization.id, userEmail);

    // const subscription = DataStore.observe(Notification).subscribe(() => {
    //   listNotifications(setNotifications, organization.id, userEmail);
    // });

    const handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';

      if (condition === 'online') {
        listNotifications(setNotifications, organization.id, userEmail);
      }
    };

    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      // subscription.unsubscribe();
      Hub.remove('datastore', listenerData);

      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!user || !organization) {
      return;
    }

    const handleClickOutside = (event) => {
      if (
        notifContainerRef &&
        !notifContainerRef.current.contains(event.target) &&
        menuOpen
      ) {
        setMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuOpen]);

  const handleMarkAsRead = (id) => {
    return db.update('notification', { seen: true }, id);
  };

  const handleMarkAllAsRead = () => {
    notifications.map((x) => handleMarkAsRead(x.id));
  };

  const getNotificationsCount = (notifications) => {
    const count = notifications.filter((x) => !x.seen)?.length;
    if (count <= 99) {
      return count;
    }

    if (count > 99) {
      return '99+';
    }
  };

  const handleNotificationItemClick = async (url, id) => {
    const disectedUrl = url.split('?');
    const queryParams = queryString.parse(disectedUrl[1]);

    await handleMarkAsRead(id);

    if (isUserMultiClient(user)) {
      handleSetOrganization(queryParams.orgId);
    }

    setMenuOpen(false);

    history.push(`${disectedUrl[0]}?reqId=${queryParams.reqId}`);
  };

  if (!organization) {
    return null;
  }

  return (
    <Wrapper ref={notifContainerRef}>
      <NotificationToggleContainer>
        <Bell onClick={() => setMenuOpen(!menuOpen)} />
      </NotificationToggleContainer>
      {notifications && getNotificationsCount(notifications) !== 0 && (
        <NotificationCount onClick={() => setMenuOpen(!menuOpen)}>
          {getNotificationsCount(notifications)}
        </NotificationCount>
      )}
      <NotificationContentContainer isOpen={menuOpen}>
        <HeadingContainer>
          <Type.H6>Notifications</Type.H6>
          {notifications && getNotificationsCount(notifications) !== 0 && (
            <Button
              simple
              onClick={() => {
                handleMarkAllAsRead();
              }}
            >
              Mark all as read
            </Button>
          )}
        </HeadingContainer>
        <NotifsContainer>
          {notifications && notifications.length === 0 && (
            <Type.P8>You have no notifications.</Type.P8>
          )}
          {notifications &&
            notifications.map((x) => {
              return (
                <NotifsItem key={x.id} seen={x.seen}>
                  <div
                    className="col-a"
                    onClick={() => {
                      handleNotificationItemClick(x.url, x.id);
                    }}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: x.content,
                      }}
                    ></p>
                    <span className="date">
                      {capitalizeFirstLetter(
                        TimeDiff(
                          new Date(JSON.parse(x.date)),
                          new Date(),
                          '',
                          ' ago'
                        )
                      )}
                    </span>
                  </div>
                  {!x.seen && (
                    <div className="col-b">
                      <button
                        className="notif-dot"
                        title="Mark as read"
                        onClick={() => {
                          handleMarkAsRead(x.id);
                        }}
                      ></button>
                    </div>
                  )}
                </NotifsItem>
              );
            })}
        </NotifsContainer>
      </NotificationContentContainer>
    </Wrapper>
  );
};

export default NotificationModule;
