import Modal from '@/elements/Modal';
import SideModal from '@/elements/SideSheet';
import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import Type from '@/elements/Type';
import styled from 'styled-components';
import { colour } from '@/styles/variables';
import {
  formatDateForHumans,
  getDurationsBetweenDates,
  newDate,
} from '@/utils/dates';
import Button from '@/elements/Button';
import DatePicker from 'react-datepicker';
import Icons from '@/elements/Icons';
import { isUserEditProfile } from '@/utils/isUserAdmin';
import { FinalForm, Checkbox } from '@/elements/Form';
import { generatePdf } from '@/utils/generatePdf';
import { Loader } from '@/elements/Loader/style';
import { Spacer } from '@/elements/Spacer/style';
import { toaster } from 'evergreen-ui';
import { hasEducations } from './ProfileForm';
import { ButtonsContainer } from '@/components/ActionCard/style';
import renderHTML from 'react-render-html';

const StyledWrap = styled.div`
  .title {
    color: ${colour.charcoal};
    margin: 10px 0 0px 0;
    font-size: 16px;
  }
  .profileBlock {
    margin-top: 40px;
  }
  ul {
    padding-left: 15px;
  }
  .lastExperience {
    &:after {
      display: none;
    }
  }
  .deactive {
    position: relative;
    transition: all 0.4s;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 50px;
      background: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1) 100%
      );
    }
  }
`;

const Feature = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 25px;
`;

const FeatureWrap = styled.div`
  display: flex;
  align-items: center;
`;

const ExperienceWrap = styled.div`
  margin-bottom: 23px;
  position: relative;
  padding-left: 30px;
  padding-bottom: 18px;

  &:before {
    display: block;
    content: '';
    width: 11px;
    height: 11px;
    background-color: ${colour.blue};
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 8px;
  }

  &:after {
    display: block;
    content: '';
    width: 1px;
    height: 100%;
    background-color: ${colour.blue};
    position: absolute;
    left: 5px;
    top: 25px;
  }
`;

const CollapseDiv = styled.div`
  overflow: hidden;
  max-height: ${(props) =>
    props.collapsed ? props.height + 'px' : props.scrollHeight + 30 + 'px'};
  transition: max-height 0.5s;
  position: relative;
`;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const CollapsibleInfo = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const [scrollHeight, setScrollHeight] = useState(0);
  const ref = useRef(null);
  const [width, height] = useWindowSize();

  useEffect(() => {
    setScrollHeight(ref.current.scrollHeight);
  }, [setScrollHeight, width, height]);

  return (
    <>
      <CollapseDiv
        className={
          scrollHeight > parseInt(props.height) && collapsed ? 'deactive' : ''
        }
        ref={ref}
        collapsed={collapsed}
        scrollHeight={scrollHeight}
        height={props.height}
      >
        <Type.Div8>
          {props.isHtml ? renderHTML(props.text) : props.text}
        </Type.Div8>
      </CollapseDiv>
      {scrollHeight > parseInt(props.height) && collapsed ? (
        <Button
          style={{ padding: 0, display: 'block', marginTop: '10px' }}
          simple
          color={colour.blue}
          onClick={() => {
            setCollapsed(false);
          }}
        >
          See more
        </Button>
      ) : (
        ''
      )}
      {scrollHeight > parseInt(props.height) && !collapsed ? (
        <Button
          style={{ padding: 0, display: 'block', marginTop: '10px' }}
          simple
          color={colour.blue}
          onClick={() => {
            setCollapsed(true);
          }}
        >
          See less
        </Button>
      ) : (
        ''
      )}
    </>
  );
};

const FormWrap = styled.form`
  display: inline-flex;
  flex-direction: column;
  float: right;
  .label,
  label {
    width: auto;
  }
  .input-checkbox {
    justify-content: flex-end;
  }
`;

export const SendEmailModal = (props) => {
  const { isShown, handleModalState, profile, handleAction } = props;
  let close = null;

  return (
    <Modal
      title="Email Appscore"
      isShown={isShown}
      handleModalState={handleModalState}
      closeFromExternal={(fn) => (close = fn)}
    >
      <p>
        An email will be sent to your Appscore representative, expressing your
        interest in {profile && profile.name}, they will be in touch to arrange
        an interview.
      </p>
      <FinalForm
        onSubmit={(values) => {
          handleAction(values.sendMeCopy);
          close();
        }}
        render={({ handleSubmit, form, values }) => (
          <FormWrap onSubmit={handleSubmit}>
            <div style={{ margin: '10px 0' }}>
              <Button
                type="button"
                simple
                onClick={() => {
                  close();
                }}
                style={{ marginRight: 10 }}
              >
                Cancel
              </Button>
              <Button type="submit">Send</Button>
            </div>
            <Checkbox label="Send me a copy" name="sendMeCopy" />
          </FormWrap>
        )}
      />
    </Modal>
  );
};

export const ProfileDetailModal = (props) => {
  const {
    profile,
    isShown,
    handleModalState,
    user,
    reqId,
    requirementTitle,
    history,
    handleNextStatusClick,
    nextStatus,
    handleRejectClick,
    handleUndoClick,
  } = props;

  const [isGenerating, setIsGenerating] = useState(false);

  const handleDownload = async () => {
    const dom = document.querySelector('#profileHtml');
    if (dom) {
      const main = dom.cloneNode(true);
      var tmp = document.createElement('body');
      tmp.appendChild(main);
      const html = tmp.innerHTML;
      setIsGenerating(true);
      try {
        const base64Pdf = await generatePdf(html);
        setIsGenerating(false);
        const anchorTag = document.createElement('a');
        anchorTag.href = base64Pdf;
        anchorTag.download =
          'Appscore - ' + requirementTitle + ' - ' + profile.name + '.pdf';
        anchorTag.click();
      } catch (error) {
        toaster.danger('The PDF download has failed. Please try again.');
        setIsGenerating(false);
      }
    }
  };

  return profile ? (
    <SideModal
      title={<div>{profile.name} </div>}
      profile={profile}
      headerFunctions={
        <FeatureWrap>
          {isUserEditProfile(user) && (
            <Feature
              style={{ marginLeft: 0 }}
              onClick={() => {
                handleModalState(false);
                history.push(
                  '/profiles/add?reqId=' + reqId + '&id=' + profile.id
                );
              }}
            >
              <Icons.Edit style={{ marginLeft: 5, width: '14px' }} />{' '}
              <Type.P8 style={{ marginLeft: '5px' }} color={colour.grey}>
                Edit
              </Type.P8>
            </Feature>
          )}
          <Feature onClick={handleDownload}>
            <Icons.Download style={{ marginLeft: 5, width: '18px' }} />{' '}
            <Type.P8 style={{ marginLeft: '5px' }} color={colour.blue}>
              Download
            </Type.P8>
          </Feature>
          <Feature>
            <ButtonsContainer style={{ marginTop: 0 }}>
              {handleNextStatusClick && nextStatus && (
                <Button
                  colour={colour.green}
                  simple
                  onClick={handleNextStatusClick}
                >
                  <Icons.Tick />
                  {nextStatus === 'Shortlist'
                    ? 'Request Interview'
                    : nextStatus}
                </Button>
              )}
              {handleRejectClick && nextStatus && (
                <Button colour={colour.red} simple onClick={handleRejectClick}>
                  <Icons.Cross />
                  Decline
                </Button>
              )}
              {!nextStatus && (
                <Button colour={colour.blue} simple onClick={handleUndoClick}>
                  <Icons.Undo />
                  Undo
                </Button>
              )}
            </ButtonsContainer>
          </Feature>
        </FeatureWrap>
      }
      isShown={isShown}
      handleModalState={handleModalState}
    >
      <StyledWrap>
        {isGenerating && <Loader overlay />}
        <Row style={{ marginTop: '50px' }}>
          <Col xs={12} sm={4} md={4}>
            <div>
              <Type.H7 className="title">Summary.</Type.H7>
              <CollapsibleInfo
                text={profile.summary}
                height={168}
                isHtml
              ></CollapsibleInfo>
            </div>
            <div className="profileBlock">
              {profile.skills && profile.skills[0] !== '' && (
                <>
                  <Type.H7 className="title">Skills.</Type.H7>
                  <CollapsibleInfo
                    text={
                      <>
                        <ul>
                          {profile.skills.map((x, i) => {
                            if (x.trim() !== '') {
                              if (/<\/?[a-z][\s\S]*>/i.test(x)) {
                                let xHtml = x.trim().replace(/<\/?ul>/g, '');
                                return renderHTML(xHtml.trim());
                              } else {
                                return <li key={'skill-' + i}>{x.trim()}</li>;
                              }
                            } else {
                              return null;
                            }
                          })}
                        </ul>
                      </>
                    }
                    height={170}
                  ></CollapsibleInfo>
                </>
              )}
            </div>
            <div className="profileBlock">
              {profile.domains && profile.domains[0] !== '' && (
                <>
                  <Type.H7 className="title">Domain Experience.</Type.H7>
                  <CollapsibleInfo
                    text={
                      <>
                        <ul>
                          {profile.domains.map((x, i) => {
                            return <li key={'domain' + i}>{x}</li>;
                          })}
                        </ul>
                      </>
                    }
                    height={170}
                  ></CollapsibleInfo>
                </>
              )}
            </div>
          </Col>
          <Col xs={12} sm={8} md={8}>
            <Type.H7 className="title" style={{ marginBottom: '15px' }}>
              Experience.
            </Type.H7>
            {profile.experiences &&
              profile.experiences.map((x, i) => {
                if (x.role === '' && x.company === '') {
                  return null;
                } else {
                  return (
                    <ExperienceWrap
                      key={'exp-' + i}
                      className={
                        i + 1 === profile.experiences.length
                          ? 'lastExperience'
                          : ''
                      }
                    >
                      <Type.P7 color={colour.blue} black>
                        {x.role}
                      </Type.P7>
                      <Type.P7 bold>{x.company}</Type.P7>
                      {(x.tenureStart || x.tenureEnd) && (
                        <Type.P7>
                          {formatDateForHumans(
                            newDate(x.tenureStart),
                            'MMM yyyy'
                          )}
                          {` - `}
                          {x.tenureEnd === 'Present'
                            ? ' Present'
                            : formatDateForHumans(
                                newDate(x.tenureEnd),
                                'MMM yyyy'
                              ) +
                              (getDurationsBetweenDates(
                                x.tenureStart,
                                x.tenureEnd
                              )
                                ? ' • ' +
                                  getDurationsBetweenDates(
                                    x.tenureStart,
                                    x.tenureEnd
                                  )
                                : '')}
                        </Type.P7>
                      )}
                      <CollapsibleInfo
                        text={x.responsibilities}
                        height={165}
                        isHtml
                        style={{ marginTop: '15px' }}
                      ></CollapsibleInfo>
                    </ExperienceWrap>
                  );
                }
              })}

            {hasEducations(profile.educations) && (
              <>
                <Type.H7
                  className="title"
                  style={{
                    marginBottom: '10px',
                    marginTop: '40px',
                  }}
                >
                  Education.
                </Type.H7>
                {profile.educations.map((x, i) => (
                  <div key={'edu-' + i}>
                    <Type.P7 bold color={colour.blue}>
                      {x.institute}
                    </Type.P7>
                    <Type.P8 bold>
                      {x.accolade} {x.course}
                    </Type.P8>
                    <Type.P8>{x.completion}</Type.P8>
                    {profile.educations.length !== i + 1 && (
                      <Spacer desktop={10} />
                    )}
                  </div>
                ))}
              </>
            )}

            {profile.certifications && profile.certifications[0] !== '' && (
              <>
                <Type.H7
                  className="title"
                  style={{
                    marginBottom: '10px',
                    marginTop: '40px',
                  }}
                >
                  Certifications.
                </Type.H7>
                <CollapsibleInfo
                  text={
                    <>
                      <ul>
                        {profile.certifications.map((x, i) => {
                          return <li key={'cert-' + i}>{x}</li>;
                        })}
                      </ul>
                    </>
                  }
                  height={170}
                ></CollapsibleInfo>
              </>
            )}
          </Col>
        </Row>
      </StyledWrap>
    </SideModal>
  ) : null;
};

export const RequirementDetailModal = (props) => {
  const { data, isShown, handleModalState } = props;
  if (data) {
    return (
      <Modal
        title={data.title}
        isShown={isShown}
        handleModalState={handleModalState}
      >
        <StyledWrap>
          <Row>
            <Col>
              <Type.P8 className="title">Ideal start date</Type.P8>
              <Type.P8>
                {formatDateForHumans(newDate(data.idealStartDate))}
              </Type.P8>
            </Col>
            <Col>
              <Type.P8 className="title">Duration</Type.P8>
              <Type.P8>{data.duration ? data.duration : 'Nil'}</Type.P8>
            </Col>
            <Col>
              <Type.P8 className="title">Positions</Type.P8>
              <Type.P8>{data.numberOfPositions}</Type.P8>
            </Col>
          </Row>
          <Type.P8 className="title">Project</Type.P8>
          <Type.P8>{data.projectDetails ? data.projectDetails : 'Nil'}</Type.P8>
          <Type.P8 className="title">Team</Type.P8>
          <Type.P8>{data.team ? data.team : 'Nil'}</Type.P8>
          <Type.P8 className="title">Skills</Type.P8>
          <Type.P8>
            {data.skills[0] !== '' ? data.skills.join(', ') : 'Nil'}
          </Type.P8>
          <Type.P8 className="title">Job Description</Type.P8>
          {data.jobDescriptionFile[0].name !== '' ? (
            data.jobDescriptionFile.map((x) => (
              <Type.P8 key={'jobFile-' + x.id}>
                <a href={x.url} target="_blank" rel="noopener noreferrer">
                  <Icons.Download
                    style={{
                      verticalAlign: 'middle',
                      marginRight: 10,
                    }}
                  />
                  {x.name}
                </a>
              </Type.P8>
            ))
          ) : (
            <Type.P8>
              A separate Job Description document is not available for this
              position.
            </Type.P8>
          )}
        </StyledWrap>
      </Modal>
    );
  } else {
    return null;
  }
};

export const ScheduleModal = ({
  isShown,
  handleModalState,
  handleAction,
  nextStatus,
  date,
  setProposedInterviewDate,
  proposedInterviewDates,
  setInterviewDate,
}) => (
  <Modal
    title="Please pick a time"
    buttonText="Schedule"
    type="action"
    isShown={isShown}
    handleModalState={handleModalState}
    handleAction={handleAction}
    shouldCloseOnOverlayClick={false}
    shouldCloseOnEscapePressbool={false}
  >
    {nextStatus === 'interviewProposed' ? (
      <>
        {date &&
          date.map((x, i) => (
            <div key={'prp' + i}>
              <p style={{ display: 'flex' }}>
                Schedule interview time{' '}
                {x && `at ${formatDateForHumans(x, 'ff')}`}
                {date.length > 1 && (
                  <Button
                    colour={colour.red}
                    simple
                    onClick={() =>
                      setProposedInterviewDate(
                        proposedInterviewDates.filter((y) => y !== x)
                      )
                    }
                  >
                    <Icons.Cross />
                  </Button>
                )}
              </p>
              <DatePicker
                selected={x}
                onChange={(x) => {
                  let payload = [...date];
                  payload[i] = x;
                  // console.log(x, payload)
                  setProposedInterviewDate(payload);
                }}
                inline
                timeFormat="HH:mm"
                timeIntervals={15}
                showTimeSelect
              />
            </div>
          ))}
        <Button
          simple
          onClick={() =>
            setProposedInterviewDate([...proposedInterviewDates, newDate()])
          }
        >
          + Add date
        </Button>
      </>
    ) : (
      <>
        <p>
          Schedule interview time{' '}
          {date && `at ${formatDateForHumans(newDate(date), 'ff')}`}
        </p>
        <DatePicker
          selected={date}
          onChange={(date) =>
            nextStatus === 'interviewProposed'
              ? setProposedInterviewDate(date)
              : setInterviewDate(date)
          }
          inline
          timeFormat="HH:mm"
          timeIntervals={15}
          showTimeSelect
        />
      </>
    )}
  </Modal>
);
