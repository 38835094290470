import styled, { css } from 'styled-components';
import { colour, breakpoint, trans } from '@/styles/variables';

const CommonTypeStyle = css`
  margin: ${(props) => (props.withMargin ? '0 0 1em 0' : 0)};
  font-family: 'Gilroy', sans-serif;
  text-shadow: ${(props) =>
    props.hasShadow ? '6px 6px 6px rgba(54, 53, 55, 0.1)' : 'none'};
  font-weight: ${(props) => (props.black ? 900 : props.bold ? 800 : 400)};
  color: ${(props) => (props.color ? props.color : colour.charcoal)};
  text-align: ${(props) => props.alignment && props.alignment};
  br.no-mobile {
    @media (max-width: ${breakpoint.max.small}) {
      display: none;
    }
  }
  animation: ${(props) =>
    props.animated
      ? `slidein 1s ${trans.easeOutExpo} 1.5s 1 normal both running`
      : 'none'};

  @keyframes slidein {
    from {
      clip-path: inset(0 100% 0 0);
    }
    to {
      clip-path: inset(0 0% 0 0);
    }
  }
`;

const StyleTypeSize1 = css`
  ${CommonTypeStyle}
  font-size: 36px;
  line-height: 48px;

  @media (min-width: ${breakpoint.min.medium}) {
    font-size: 72px;
    line-height: 84px;
  }
`;
const StyleTypeSize2 = css`
  ${CommonTypeStyle}
  font-size: 52px;
  line-height: 64px;
`;
const StyleTypeSize3 = css`
  ${CommonTypeStyle}
  font-size: 36px;
  line-height: 48px;

  @media (min-width: ${breakpoint.min.medium}) {
    font-size: 42px;
    line-height: 54px;
  }
`;
const StyleTypeSize4 = css`
  ${CommonTypeStyle}
  font-size: 28px;
  line-height: 40px;

  @media (min-width: ${breakpoint.min.medium}) {
    font-size: 36px;
    line-height: 48px;
  }
`;
const StyleTypeSize5 = css`
  ${CommonTypeStyle}
  font-size: 21px;
  line-height: 30px;

  @media (min-width: ${breakpoint.min.medium}) {
    font-size: 28px;
    line-height: 40px;
  }
`;
const StyleTypeSize6 = css`
  ${CommonTypeStyle}
  font-size: 18px;
  line-height: 27px;

  @media (min-width: ${breakpoint.min.medium}) {
    font-size: 21px;
    line-height: 30px;
  }
`;
const StyleTypeSize7 = css`
  ${CommonTypeStyle}
  font-size: 18px;
  line-height: 27px;
  small {
    font-size: 14px;
    line-height: 23px;
  }
`;

const StyleTypeSize10 = css`
  ${CommonTypeStyle}
  font-size: 16px;
  line-height: 26px;
  small {
    font-size: 14px;
    line-height: 23px;
  }
`;

const StyleTypeSize8 = css`
  ${CommonTypeStyle}
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  transition: color 0.3s;
`;

const StyleTypeSize9 = css`
  ${CommonTypeStyle}
  font-size: 14px;
  line-height: 24px;
  font-weight: 900;
  text-decoration: none;
  transition: color 0.3s;
`;

const StyledFieldLabel = css`
  ${CommonTypeStyle}
  font-size: 12px;
  line-height: 1;
`;

const Type = {
  H1: styled.h1`
    ${StyleTypeSize1};
    font-weight: 900;
  `,
  H2: styled.h2`
    ${StyleTypeSize2};
    font-weight: 900;
  `,
  H3: styled.h3`
    ${StyleTypeSize3};
    font-weight: 900;
  `,
  H4: styled.h4`
    ${StyleTypeSize4};
    font-weight: 900;
  `,
  H5: styled.h5`
    ${StyleTypeSize5};
    font-weight: 900;
  `,
  H6: styled.h6`
    ${StyleTypeSize6};
    font-weight: 900;
  `,
  H7: styled.h6`
    ${StyleTypeSize7};
    font-weight: 900;
  `,
  H8: styled.h6`
    ${StyleTypeSize8};
    font-weight: 900;
  `,
  P1: styled.p`
    ${StyleTypeSize1}
  `,
  P2: styled.p`
    ${StyleTypeSize2}
  `,
  P3: styled.p`
    ${StyleTypeSize3}
  `,
  P4: styled.p`
    ${StyleTypeSize4}
  `,
  P5: styled.p`
    ${StyleTypeSize5}
  `,
  P6: styled.p`
    ${StyleTypeSize6}
  `,
  P7: styled.p`
    ${StyleTypeSize10}
  `,
  P: styled.p`
    ${StyleTypeSize7}
    margin: ${(props) => (props.withMargin ? ' 1em 0' : 0)};
  `,
  P8: styled.p`
    ${StyleTypeSize8}
  `,
  P9: styled.a`
    ${StyleTypeSize9}
  `,
  FieldLabel: styled.p`
    ${StyledFieldLabel}
  `,
  MenuLink: styled.a`
    ${StyleTypeSize8}
  `,
  Div8: styled.div`
    ${StyleTypeSize8}
  `,
  ListContainer: styled.ul`
    margin-left: 20px;
    li {
      ${StyleTypeSize8}
    }
  `,
};

export default Type;
