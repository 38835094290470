import { API } from 'aws-amplify';

/**
 * Sends an email
 *
 * sample usage:
 * sendEmail('email@gmail.com', 'Email Subject', '<p>test</p>');
 *
 * @param {*} recipient - email of the recipient
 * @param {*} subject - Subject of the email
 * @param {*} content - Content of the email in HTML format
 */
export const sendEmail = async (
  recipient,
  subject,
  content,
  attachment = []
) => {
  let apiName = 'EmailQueries';
  let path = '/sendEmail';
  let data = {
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
      recipient: JSON.stringify(recipient.join()),
      subject: JSON.stringify(subject),
      content: JSON.stringify(content),
      attachment: JSON.stringify(attachment),
    },
  };

  return await API.post(apiName, path, data);
};
