import React, { useState } from 'react';
import RequirementForm from '@/pages/app/Requirement/RequirementForm';
import StepProgress from '@/components/StepProgress';
import LoggedInTemplate from '@/templates/loggedIn';
import { Col, Row, Grid } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { withRouter, Prompt } from 'react-router-dom';
import Modal from '@/elements/Modal';

const AddRequirement = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);

  const requirementId = new URLSearchParams(props.location.search).get('id');

  return (
    <LoggedInTemplate>
      <Wrap fluid>
        <Prompt
          when={shouldBlockNavigation}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        <Row className="row">
          <Col xs={12} sm={3} className="sidebar">
            <div className="sticky">
              <span
                onClick={() => {setShouldBlockNavigation(false); setShowCancelDialog(true)}}
                className="back"
              >{`< Cancel`}</span>
              <StepProgress
                direction={window.innerWidth < 768? "horizontal" : "vertical"}
                currentStep={activeStep}
                steps={['Overview', 'Details', 'Review']}
                setActiveStep={(i) => {
                  if(i < activeStep) {
                    document.getElementsByClassName("backStep")[0].setAttribute('step', i);
                    document.getElementsByClassName("backStep")[0].click();
                  } else {
                    document.getElementsByClassName("save")[0].setAttribute('step', i);
                    document.getElementsByClassName("save")[0].click();
                  }
                }}
              ></StepProgress>
            </div>
          </Col>
          <Col xs={12} sm={9} className="main">
            <RequirementForm
              requirementId={requirementId}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              setShouldBlockNavigation={() => setShouldBlockNavigation}
            />
          </Col>
        </Row>
      </Wrap>
      <Modal
        title="Are you sure you wish to cancel?"
        type="action"
        intent="danger"
        buttonText="Yes, I'm sure"
        isShown={showCancelDialog}
        handleModalState={setShowCancelDialog}
        handleAction={() => props.history.push('/requirements')}
        shouldCloseOnEscapePress={false}
        shouldCloseOnOverlayClick={false}
      >
        <p>All entered information will be lost</p>
      </Modal>
    </LoggedInTemplate>
  );
};

export default withRouter(AddRequirement);

const Wrap = styled(Grid)`
  background: linear-gradient(to right, #f1f3f4 50%, #fff 50%);
  @media(max-width:767px){
      background: #fff;
  }
  .row {
    min-height: 100vh;
  }
  .sidebar {
    background: #f1f3f4;
    padding-top: 30px;
    @media(max-width:767px){
      padding: 1rem 2rem;
      padding-bottom: 30px;
      margin: 0 -2rem;
    }
  }
  .sticky{
    position: sticky;
    top: 125px;
  }
  .main {
    background: #fff;
    padding: 30px;
    @media(max-width:767px){
      padding: 30px 1rem;
    }
  }
  .back {
    display: block;
    margin: 15px 0 50px 0;
    cursor: pointer;
    @media(max-width:767px){
      margin-bottom: 20px;
    }
  }
`;
