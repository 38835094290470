import React, { useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import Modal from '@/elements/Modal';
import { Button } from '@/elements/Button/style';
import { Table } from 'evergreen-ui';
import {
  TableRow,
  TableCell,
  TableHead,
  HeaderCell,
} from '@/components/Table/style';
import { ViewLink } from '@/components/InvoiceTable/style';
import { LinksContainer } from '@/pages/app/Organization/style';
import { Spacer } from '@/elements/Spacer/style';
import { ErrorBox } from '@/elements/ErrorBox/style';
import {
  PhotoUploadFieldWrap,
  ButtonGroupContainer,
} from '@/elements/Form/style';
import { TextInput, getValidator, FinalForm } from '@/elements/Form';
import { TextArea } from '@/elements/Form';
import { MultipleChoice } from '@/elements/Form';
import { renderRisk } from '@/components/RiskTable';
import { DatePickerInput } from '@/elements/Form';
import { formatDateForHumans } from '@/utils/dates';
import { CheckboxGroup } from '@/elements/Form';
import arrayMutators from 'final-form-arrays';

export const RisksField = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [error, setError] = useState('');
  const [errorAdd, setErrorAdd] = useState('');

  const [selectedItem, setSelectedItem] = useState({ name: '', index: 0 });

  const ownerOptions = ['Appscore'];

  if (props.selectedClient) {
    ownerOptions.push(props.selectedClient);
  }

  return (
    <PhotoUploadFieldWrap>
      <label className="label">{`${props.label}${
        props.validationType && props.validationType.indexOf('required') > -1
          ? '*'
          : ''
      }`}</label>
      <Spacer desktop={10} />
      <FieldArray
        name="type"
        validate={(value) => getValidator(props.validationType, value)}
        {...props}
      >
        {({ fields }) => {
          const handleDelete = () => {
            fields.remove(selectedItem.index);
          };

          const handleEdit = (values) => {
            const {
              title,
              description,
              riskLevel,
              chanceOfOccurence,
              mitigation,
              effect,
              date,
              owner,
            } = values;

            setError('');

            const taskItem = {
              title,
              description,
              riskLevel,
              chanceOfOccurence,
              mitigation,
              effect,
              owner,
              date: formatDateForHumans(new Date(date), 'yyyy-MM-dd'),
            };

            fields.update(selectedItem.index, taskItem);
            setIsModalOpen(false);
          };
          const handleAdd = (values) => {
            const {
              title,
              description,
              riskLevel,
              chanceOfOccurence,
              mitigation,
              effect,
              date,
              owner,
            } = values;

            setErrorAdd('');

            const taskItem = {
              title,
              description,
              riskLevel,
              chanceOfOccurence,
              mitigation,
              effect,
              owner,
              date: formatDateForHumans(new Date(date), 'yyyy-MM-dd'),
            };

            fields.push(taskItem);
            setIsAddModalOpen(false);
          };
          return (
            <>
              {fields.length !== 0 && (
                <>
                  <Table>
                    <TableHead paddingX={0}>
                      <HeaderCell>Title</HeaderCell>
                      <HeaderCell>Owner</HeaderCell>
                      <HeaderCell>Level</HeaderCell>
                      <HeaderCell
                        flexBasis={200}
                        flexShrink={0}
                        flexGrow={0}
                        paddingX={0}
                      ></HeaderCell>
                    </TableHead>
                    <Table.Body height={'auto'}>
                      {fields.map((x, i) => {
                        if(fields.value[i].title !== '') {
                          return (
                            <TableRow key={i} height={'auto'} variation={'admin'}>
                              <TableCell>{fields.value[i].title}</TableCell>
                              <TableCell>
                                {fields.value[i].owner &&
                                  (fields.value[i].owner.length === 1
                                    ? fields.value[i].owner[0]
                                    : 'Joint')}
                              </TableCell>
                              <TableCell>
                                {renderRisk(fields.value[i].riskLevel)}
                              </TableCell>
                              <TableCell
                                flexBasis={200}
                                flexShrink={0}
                                flexGrow={0}
                                paddingX={0}
                              >
                                <LinksContainer>
                                  <ViewLink
                                    onClick={() => {
                                      setIsModalOpen(true);
                                      setSelectedItem({
                                        ...fields.value[i],
                                        index: i,
                                      });
                                    }}
                                  >
                                    Edit
                                  </ViewLink>
                                  <ViewLink
                                    onClick={() => {
                                      setIsDeleteModalOpen(true);
                                      setSelectedItem({
                                        ...fields.value[i],
                                        index: i,
                                      });
                                    }}
                                  >
                                    Delete
                                  </ViewLink>
                                </LinksContainer>
                              </TableCell>
                            </TableRow>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Table.Body>
                  </Table>
                  <Spacer desktop={10} />
                </>
              )}
              {fields.length === 0 && 'No risks have been created '}
              <Button
                simple
                onClick={(e) => {
                  e.preventDefault();
                  setIsAddModalOpen(true);
                }}
              >
                + Add Key Risk
              </Button>

              <Modal
                title="Add Key Risk"
                isShown={isAddModalOpen}
                handleModalState={setIsAddModalOpen}
                type="plain"
                alignment="center"
                shouldCloseOnEscapePress={false}
                shouldCloseOnOverlayClick={false}
                isTall
              >
                <FinalForm
                  onSubmit={handleAdd}
                  mutators={{
                    ...arrayMutators,
                  }}
                  render={({ handleSubmit, values }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        {errorAdd && (
                          <ErrorBox slimPadding>{errorAdd}</ErrorBox>
                        )}
                        <TextInput
                          name="title"
                          component="input"
                          type="text"
                          validationType="required"
                          label="Key Risk Title"
                          placeholder="What is the title of the key risk?"
                        />
                        <DatePickerInput
                          name="date"
                          type="text"
                          dateFormat="dd-MM-yyyy"
                          label="Date"
                          validationType="required"
                          placeholder="DD/MM/YYYY"
                          value={values.date}
                          showAppend
                        />
                        <TextArea
                          name="description"
                          component="input"
                          type="text"
                          validationType="required"
                          label="Description"
                          placeholder="Enter a brief explanation of the key risk"
                        />
                        <CheckboxGroup
                          name="owner"
                          label="Owner"
                          validationType="requiredArray"
                          options={ownerOptions}
                        />
                        <MultipleChoice
                          name="riskLevel"
                          component="input"
                          type="text"
                          validationType="required"
                          label="Level"
                          options={['Low', 'Med', 'High']}
                        />
                        <MultipleChoice
                          name="chanceOfOccurence"
                          component="input"
                          type="text"
                          validationType="required"
                          label="Chance of Occurence"
                          options={['Low', 'Med', 'High']}
                        />
                        <TextArea
                          name="mitigation"
                          component="input"
                          type="text"
                          validationType="required"
                          label="Mitigation"
                          placeholder="Explain how this key risk could be averted"
                        />
                        <TextArea
                          name="effect"
                          component="input"
                          type="text"
                          validationType="required"
                          label="Effect"
                          placeholder="Explain how this will impact the project if not mitigated"
                        />

                        <ButtonGroupContainer justifyContent="flex-end" isTall>
                          <Button>Add</Button>
                          <Button
                            simple
                            onClick={(e) => {
                              e.preventDefault();
                              setIsAddModalOpen(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </ButtonGroupContainer>
                      </form>
                    );
                  }}
                ></FinalForm>
              </Modal>

              <Modal
                title="Edit Key Risk"
                isShown={isModalOpen}
                handleModalState={setIsModalOpen}
                type="plain"
                alignment="center"
                shouldCloseOnEscapePress={false}
                shouldCloseOnOverlayClick={false}
                isTall
              >
                <FinalForm
                  onSubmit={handleEdit}
                  initialValues={{
                    title: selectedItem.title,
                    description: selectedItem.description,
                    date: new Date(selectedItem.date),
                    riskLevel: selectedItem.riskLevel,
                    chanceOfOccurence: selectedItem.chanceOfOccurence,
                    mitigation: selectedItem.mitigation,
                    effect: selectedItem.effect,
                    owner: selectedItem.owner,
                  }}
                  mutators={{
                    ...arrayMutators,
                  }}
                  render={({ handleSubmit, values }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        {error && <ErrorBox slimPadding>{error}</ErrorBox>}
                        <TextInput
                          name="title"
                          component="input"
                          type="text"
                          validationType="required"
                          value={values.title}
                          label="Key Risk Title"
                          placeholder="What is the title of the key risk?"
                        />
                        <DatePickerInput
                          name="date"
                          type="text"
                          dateFormat="dd-MM-yyyy"
                          value={values.date}
                          label="Date"
                          validationType="required"
                          placeholder="DD/MM/YYYY"
                          showAppend
                        />
                        <TextArea
                          name="description"
                          component="input"
                          type="text"
                          validationType="required"
                          label="Description"
                          placeholder="Enter a brief explanation of the key risk"
                          value={values.description}
                        />
                        <CheckboxGroup
                          name="owner"
                          label="Owner"
                          validationType="requiredArray"
                          options={ownerOptions}
                          value={values.owner}
                        />
                        <MultipleChoice
                          name="riskLevel"
                          component="input"
                          type="text"
                          validationType="required"
                          label="Level"
                          options={['Low', 'Med', 'High']}
                          value={values.riskLevel}
                        />
                        <MultipleChoice
                          name="chanceOfOccurence"
                          component="input"
                          type="text"
                          validationType="required"
                          label="Chance of Occurence"
                          options={['Low', 'Med', 'High']}
                          value={values.chanceOfOccurence}
                        />
                        <TextArea
                          name="mitigation"
                          component="input"
                          type="text"
                          validationType="required"
                          label="Mitigation"
                          placeholder="Explain how this key risk could be averted"
                          value={values.mitigation}
                        />
                        <TextArea
                          name="effect"
                          component="input"
                          type="text"
                          validationType="required"
                          label="Effect"
                          placeholder="Explain how this will impact the project if not mitigated"
                          value={values.effect}
                        />
                        <ButtonGroupContainer justifyContent="flex-end" isTall>
                          <Button>Update</Button>
                          <Button
                            simple
                            onClick={(e) => {
                              e.preventDefault();
                              setIsModalOpen(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </ButtonGroupContainer>
                      </form>
                    );
                  }}
                ></FinalForm>
              </Modal>

              <Modal
                title="Delete key Risk"
                isShown={isDeleteModalOpen}
                handleModalState={setIsDeleteModalOpen}
                handleAction={() => handleDelete()}
                type={'action'}
                alignment={'center'}
                buttonText="Yes"
                shouldCloseOnEscapePress={false}
                shouldCloseOnOverlayClick={false}
              >
                <p>Are you sure you want to delete {selectedItem.title}?</p>
              </Modal>
            </>
          );
        }}
      </FieldArray>
    </PhotoUploadFieldWrap>
  );
};
