import styled from 'styled-components';

export const TabWrap = styled.div``;
export const TabHeader = styled.div`
  display: flex;
  position: relative;
`;
export const Tab = styled.div`
  display: inline-flex;
  max-width: 256px;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  transition: 0.3s;
  color: ${(props) => (props.active ? props.theme.colour.primary : '#363537')};
  border-bottom: 2px solid
    ${(props) => (props.active ? props.theme.colour.primary : 'transparent')};
  a {
    color: ${(props) =>
      props.active ? props.theme.colour.primary : '#363537'};
    text-decoration: none;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    border-color: ${(props) => props.theme.colour.primary};

    a {
      color: ${(props) => props.theme.colour.primary};
    }
  }
`;

export const TabBody = styled.div`
  display: ${(props) => (props.active ? 'block' : 'none')};
`;
