import React from "react";
import { StepsWrap, StepsList, Step, Circle } from "./style";
import Icons from "@/elements/Icons";

const StepProgress = (props) => {
  const { direction, steps, currentStep, setActiveStep } = props;
  return (
    <StepsWrap className={direction} direction={direction}>
      <StepsList direction={direction}>
        {steps.map((item, i) => {
          const status =
            i < currentStep ? "done" : i === currentStep ? "active" : "none";
          const specialLine =
            currentStep - i === 1
              ? "toBlue"
              : i - currentStep === 0
              ? "fromBlue"
              : "";
          return (
            <Step key={i} style={{cursor: 'pointer'}} status={status} onClick={() => setActiveStep(i)} specialLine={specialLine} direction={direction}>
              <div>
                {status === "done" ? (
                  <Icons.TickThickFilled />
                ) : status === "active" ? (
                  <Icons.ThreeDotsFilled />
                ) : (
                  <Circle />
                )}
                <p>{item}</p>
              </div>
            </Step>
          );
        })}
      </StepsList>
    </StepsWrap>
  );
};

export default StepProgress;
