import React, { useState } from 'react';
import db from '@/utils/database';
import Button from '@/elements/Button';
import { TextInput, FinalForm } from '@/elements/Form';
import arrayMutators from 'final-form-arrays';
import { CheckboxGroup } from '@/elements/Form';
import { ErrorBox } from '@/elements/ErrorBox/style';
import Spacer from '@/elements/Spacer';
import { SelectList } from '@/elements/Form';
import { getCognitoFieldValue } from '@/utils/getCognitoFieldValue';
import { ContactFields } from './style';
import { ClientTasks } from './Fields/ClientTasks';
import { RisksField } from './Fields/Risks';
import { Invoices } from './Fields/Invoices';
import { Documents } from './Fields/Documents';

const riskObj = {
  title: '',
  owner: null,
  date: null,
  riskLevel: null,
  chanceOfOccurence: null,
  description: null,
  mitigation: null,
  effect: null
}

const clientTaskObj = {
  taskName: '',
  dueDate: '',
  approvalDate: null,
  taskApprover: '',
  approved: '',
  documents: null
}

const changeRequestObj = {
  title: null,
  document: null
}

const documentObj = {
  title: null,
  document: null
};

const invoiceObj = {
  number: null,
  amount: null,
  dueDate: null,
  status: null,
  invoice: null
};

export const AddProject = (props) => {
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const { setActiveView, organizations, users } = props;

  const generateContactDetails = (userId) => {
    const user = users.find((x) => x.Username === userId);

    return {
      userId,
      name: getCognitoFieldValue('custom:name', user.Attributes),
      email: getCognitoFieldValue('email', user.Attributes),
      title: getCognitoFieldValue('custom:position', user.Attributes),
      phone: getCognitoFieldValue('custom:phoneNumber', user.Attributes),
      profileImage: getCognitoFieldValue(
        'custom:profileImage',
        user.Attributes
      ),
    };
  };

  const onSubmit = async (values) => {
    const {
      name,
      organization,
      areas,
      phase,
      appscoreContact,
      clientContact,
      clientTasks,
      risks,
      invoices,
      documents,
      description,
      status,
      changeRequests,
    } = values;

    setSubmitting(true);

    const projectData = {
      name,
      description,
      status,
      organization: { id: organization },
      areas: [''],
      invoices: invoices ? invoices : [invoiceObj],
      documents: documents ? documents : [documentObj],
      changeRequests: changeRequests ? changeRequests : [changeRequestObj],
      pointOfContact: generateContactDetails(appscoreContact),
      phase,
    };

    if (clientContact) {
      projectData.clientPointOfContact = generateContactDetails(clientContact);
    }

    if (areas && areas.length !== 0) {
      projectData.areas = areas;
    }

    if (clientTasks && clientTasks.length !== 0) {
      projectData.clientTasks = clientTasks;
    } else {
      projectData.clientTasks = [clientTaskObj];
    }

    if (risks && risks.length !== 0) {
      projectData.risks = risks;
    }  else {
      projectData.risks = [riskObj];
    }

    try {
      await db.add('project', projectData).then(() => {
        setSubmitting(false);
        setActiveView('table');
      });
    } catch (e) {
      setSubmitting(false);
      setError(
        typeof error === 'string'
          ? error
          : error.message ||
              'An unexpected error occurred. Please contact the site administrator.'
      );
    }
  };

  return (
    <>
      {error && (
        <ErrorBox>
          <>{error}</>
        </ErrorBox>
      )}
      <FinalForm
        onSubmit={onSubmit}
        initialValues={{ inContract: false }}
        mutators={{
          ...arrayMutators,
          resetAreas: (args, state, utils) => {
            utils.changeValue(state, 'areas', () => []);
          },
        }}
        render={({ handleSubmit, values, form, valid }) => {
          return (
            <form onSubmit={handleSubmit}>
              {organizations && (
                <SelectList
                  name="organization"
                  label="Client"
                  validationType={'required'}
                  component="select"
                  selectOnChange={form.mutators.resetAreas}
                >
                  <option disabled value="">
                    Select client
                  </option>
                  {organizations.map((x) => (
                    <option value={x.id} key={x.id}>
                      {x.name}
                    </option>
                  ))}
                </SelectList>
              )}

              {organizations &&
                values.organization &&
                (organizations.filter((x) => x.id === values.organization)[0]
                  .areas[0] === '' ? (
                  <div>
                    Selected client has no Department/Area
                    <Spacer desktop={15} />
                  </div>
                ) : (
                  <CheckboxGroup
                    name="areas"
                    label="Select Department/Area"
                    validationType="requiredArray"
                    options={
                      organizations.filter(
                        (x) => x.id === values.organization
                      )[0].areas
                    }
                    value={values.areas}
                  />
                ))}

              <TextInput
                name="name"
                component="input"
                type="text"
                validationType="required"
                label="Project Title"
                showAppend
              />

              <TextInput
                name="description"
                component="input"
                type="text"
                validationType="required"
                label="Short Description"
                placeholder="ex. Website | Development"
                showAppend
              />

              <SelectList
                name="status"
                label="What is the overall status of the project?"
                validationType="required"
                component="select"
              >
                <option disabled value="">
                  Select status
                </option>
                <option value="On Track">On Track</option>
                <option value="Okay">Okay</option>
                <option value="Delayed">Delayed</option>
              </SelectList>

              <SelectList
                name="phase"
                label="What phase is the project in?"
                validationType="required"
                component="select"
              >
                <option disabled value="">
                  Select phase
                </option>
                <option value="SOW">SOW</option>
                <option value="Design">Design</option>
                <option value="Development">Development</option>
                <option value="Beta">Beta</option>
                <option value="UAT">UAT</option>
                <option value="Release">Release</option>
                <option value="Warranty">Warranty</option>
              </SelectList>

              <ClientTasks
                name="clientTasks"
                type="text"
                label="Client Tasks"
              />

              <RisksField
                name="risks"
                type="text"
                label="Key Risks"
                selectedClient={
                  values.organization
                    ? organizations.find((x) => x.id === values.organization)
                        .name
                    : undefined
                }
              />

              <Invoices name="invoices" type="text" label="Invoices" />

              <Documents name="documents" type="text" label="Documents" />

              <Documents
                name="changeRequests"
                type="text"
                label="Change Requests"
              />

              <ContactFields>
                <label className="form-label">Points of Contact</label>
                <div className="form-row">
                  <div className="form-col">
                    {users && (
                      <SelectList
                        name="appscoreContact"
                        label="Appscore"
                        validationType={'required'}
                        component="select"
                      >
                        <option disabled value="">
                          Select
                        </option>
                        {users
                          .filter((x) =>
                            x.Attributes.find((x) => x.Value === 'PM' || x.Value === 'admin')
                          )
                          .map((x) => (
                            <option value={x.Username} key={x.Username}>
                              {getCognitoFieldValue(
                                'custom:name',
                                x.Attributes
                              )}
                            </option>
                          ))}
                      </SelectList>
                    )}
                  </div>
                  <div className="form-col">
                    {users && (
                      <SelectList
                        name="clientContact"
                        label="Client"
                        component="select"
                      >
                        <option disabled value="">
                          {values.organization
                            ? 'Select'
                            : 'Please select a client above'}
                        </option>
                        {values.organization &&
                          users
                            .filter((x) =>
                              x.Attributes.find(
                                (x) => x.Value === values.organization
                              )
                            )
                            .map((x) => (
                              <option value={x.Username} key={x.Username}>
                                {getCognitoFieldValue(
                                  'custom:name',
                                  x.Attributes
                                )}
                              </option>
                            ))}
                      </SelectList>
                    )}
                  </div>
                </div>
              </ContactFields>

              <Spacer desktop={15} />

              <Button disabled={submitting || !valid}>Done</Button>
            </form>
          );
        }}
      ></FinalForm>
    </>
  );
};
