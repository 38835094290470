import React from 'react';
import {
  TaskListWrapper,
  TasksItemsContainer,
  TasksItem,
  TasksItemColOne,
  TasksItemColTwo,
} from './style';
import Type from '@/elements/Type';
import { colour } from '@/styles/variables';
import { Button } from '@/elements/Button/style';
//import Icons from '@/elements/Icons';
import Spacer from '@/elements/Spacer';
import { TimeDiff } from '@/utils/timeDiff';
import { getDaysBetweenDates, formatDateForHumans } from '@/utils/dates';
import { DataStore } from '@aws-amplify/datastore';
import { Project } from '@/models';
import { useContext } from 'react';
import { AuthContext } from '@/context/auth';
import { useState } from 'react';
import Icons from '@/elements/Icons';

export const ClientTaskList = (props) => {
  const { projectId, title, clientTasks } = props;

  const { user } = useContext(AuthContext);

  const [approving, setApproving] = useState(false);

  const timeText = {
    futureText: 'Due in ',
    pastText: ' Past Due',
  };
  const now = new Date();

  const handleApproveClick = async (index) => {
    if (approving) {
      return;
    }

    setApproving(true);

    const original = await DataStore.query(Project, projectId);

    let updatedProject = JSON.parse(JSON.stringify(original));
    const userName = user.attributes['custom:name'];

    updatedProject.clientTasks[index] = {
      ...updatedProject.clientTasks[index],
      approved: true,
      taskApprover: userName,
      approvalDate: formatDateForHumans(new Date(), 'yyyy-MM-dd'),
    };

    const newClientTask = { clientTasks: updatedProject.clientTasks };

    await DataStore.save(
      Project.copyOf(original, (updated) => {
        for (const key in newClientTask) {
          updated[key] = newClientTask[key];
        }
      })
    );

    setApproving(false);
  };

  return (
    <TaskListWrapper>
      <Type.H6>{title}</Type.H6>
      <Spacer desktop={15} />
      <TasksItemsContainer>
        {clientTasks
          ? clientTasks.map((item, index) => {
              if (item.approved) {
                return null;
              }
              const daysBetween = getDaysBetweenDates(
                now,
                new Date(item.dueDate)
              );

              let dueDateText = TimeDiff(
                new Date(item.dueDate),
                now,
                timeText.futureText,
                timeText.pastText
              );

              if (daysBetween === '-0') {
                dueDateText = 'Due Today';
              } else if (daysBetween > 7) {
                dueDateText = formatDateForHumans(new Date(item.dueDate));
              }

              return (
                <TasksItem key={index}>
                  <TasksItemColOne hasButton={true}>
                    <Type.FieldLabel className={'task-name'}>
                      {item.taskName}
                      {item.documents && item.documents.length > 0 ? 
                       <a rel="noopener noreferrer" target="_blank" href={item.documents[0].document[0].url}><Icons.LinkBlue style={{width: '10px', height: '10px'}}/> See File</a> : null
                      }
                    </Type.FieldLabel>
                    <Type.P className={'task-date'}>{dueDateText}</Type.P>
                  </TasksItemColOne>
                  <TasksItemColTwo>
                    <Button
                      colour={colour.green}
                      border
                      mini
                      onClick={() => {
                        handleApproveClick(index);
                      }}
                    >
                      Approve
                    </Button>
                  </TasksItemColTwo>
                </TasksItem>
              );
            })
          : ''}
      </TasksItemsContainer>
    </TaskListWrapper>
  );
};

export const CompletedTaskList = (props) => {
  const { title, completedTasks } = props;

  return (
    <TaskListWrapper>
      <Type.H6>{title}</Type.H6>
      <Spacer desktop={15} />
      <TasksItemsContainer>
        {completedTasks &&
          completedTasks.map((item, index) => {
            return (
              <TasksItem key={index}>
                <TasksItemColOne hasButton={false}>
                  <Type.FieldLabel className={'task-name'}>
                    {item.taskName}
                    {item.documents && item.documents.length > 0 ? 
                      <a rel="noopener noreferrer" target="_blank" href={item.documents[0].document[0].url}><Icons.LinkBlue style={{width: '10px', height: '10px'}}/> See File</a> : null
                    }
                  </Type.FieldLabel>
                  <Type.P className={'task-date'}>
                    {item.taskApprover} |{' '}
                    {formatDateForHumans(new Date(item.approvalDate))}
                  </Type.P>
                </TasksItemColOne>
              </TasksItem>
            );
          })}
      </TasksItemsContainer>
    </TaskListWrapper>
  );
};
