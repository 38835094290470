import React from 'react';
import styled from 'styled-components';

const Hamburger = (props) => {
  return (
    <Wrapper {...props} className="hamburger-wrap">
      <div
        id="hamburger"
        className={`hamburglar ${props.isOpen ? 'is-open' : 'is-closed'}`}
      >
        <span className="burger-bun-top"></span>
        <span className="burger-bun-bot"></span>
      </div>
    </Wrapper>
  );
};

export default Hamburger;

export const Wrapper = styled.div`
  .hamburglar {
    position: relative;
    display: flex;
    cursor: pointer;
    width: 20px;
    height: 10px;
    padding-left: 0;
    padding-right: 20px;
    flex-direction: column;
    justify-content: space-between;
  }
  .burger-bun {
    &-bot,
    &-top {
      width: 20px;
      height: 2px;
      background-color: ${({ theme }) => theme.colour.primary};
      display: block;
    }
  }
`;
