import React, { useEffect, useState } from 'react';
import Type from '@/elements/Type';
import { colour } from '@/styles/variables';
import { formatDateForHumans, newDate } from '@/utils/dates';
import styled from 'styled-components';
import ProfilePdfStyling from './ProfilePdfStyling';
import { hasEducations, hasResponsibilities } from './ProfileForm';
import renderHTML from 'react-render-html';

export const ProfilePdfContainer = styled.div`
  position: absolute;
  left: -9999px;

  @media print {
    position: relative;
    left: unset;
  }
`;

const ProfilePdfTemplate = (props) => {
  const { profile, positionName } = props;

  const [breakExp, setBreakExp] = useState(true);

  useEffect(() => {
    setBreakExp(document.querySelector('.first-page').clientHeight < 1086);
  }, [profile]);

  if (!profile) {
    return null;
  }

  const getFirstPage = () => (
    <div>
      <svg
        id="logo"
        xmlns="http://www.w3.org/2000/svg"
        width="120"
        height="40"
        viewBox="0 0 120 40"
      >
        <path
          id="Path_6448"
          data-name="Path 6448"
          d="M586.89,143.4a20,20,0,1,0,20,20A20,20,0,0,0,586.89,143.4Zm7.7,30.02a2.3,2.3,0,1,1,2.3-2.3A2.3,2.3,0,0,1,594.59,173.422Zm2.3-8.9a2.3,2.3,0,1,1-4.6,0v-1.1a5.4,5.4,0,1,0-5.4,5.4h.1a2.3,2.3,0,1,1,0,4.6h-.1a10,10,0,1,1,10-10Z"
          transform="translate(-566.89 -143.402)"
          fill={colour.blue}
        />
        <path
          id="Path_6449"
          data-name="Path 6449"
          d="M10.9,89.005v.773A2.862,2.862,0,0,0,8.555,88.77c-2.21,0-3.875,1.916-3.875,4.437s1.666,4.437,3.875,4.437A2.862,2.862,0,0,0,10.9,96.636v.773h2.738v-8.4ZM9.166,95.039A1.835,1.835,0,1,1,10.9,93.207,1.7,1.7,0,0,1,9.166,95.039ZM20.05,88.77a2.862,2.862,0,0,0-2.342,1.008v-.773H14.971V100.77h2.738V96.636a2.862,2.862,0,0,0,2.342,1.008c2.21,0,3.875-1.916,3.875-4.437S22.26,88.77,20.05,88.77Zm-.61,6.269a1.835,1.835,0,1,1,1.732-1.832A1.7,1.7,0,0,1,19.44,95.039ZM29.945,88.77A2.862,2.862,0,0,0,27.6,89.778v-.773H24.865V100.77H27.6V96.636a2.862,2.862,0,0,0,2.342,1.008c2.21,0,3.875-1.916,3.875-4.437S32.155,88.77,29.945,88.77Zm-.61,6.269a1.835,1.835,0,1,1,1.732-1.832A1.7,1.7,0,0,1,29.335,95.039Zm7.85-3.58c0-.252.231-.4.61-.4a1.1,1.1,0,0,1,1.022.723l2.309-1.059a3.5,3.5,0,0,0-3.4-1.95c-1.682,0-3.315.924-3.315,2.824,0,2.975,4.024,2.42,4.024,3.294,0,.3-.264.454-.742.454a1.249,1.249,0,0,1-1.286-.924l-2.391,1.227c.66,1.429,1.962,2,3.694,2,1.682,0,3.562-.723,3.562-2.824C41.274,91.627,37.185,92.383,37.185,91.459Zm8.872,6.185a4.211,4.211,0,0,0,3.76-2.185l-2.342-1.378a1.568,1.568,0,0,1-1.435.84,1.715,1.715,0,1,1,1.435-2.605l2.342-1.378a4.229,4.229,0,0,0-3.76-2.168,4.437,4.437,0,0,0,0,8.874Zm8.229,0a4.437,4.437,0,1,0-4.4-4.437A4.371,4.371,0,0,0,54.286,97.644Zm0-2.689a1.75,1.75,0,1,1,1.666-1.748A1.637,1.637,0,0,1,54.286,94.955Zm8.1-4.387V89.005H59.646v8.4h2.738V93.93a1.99,1.99,0,0,1,2.474-2V88.837A2.46,2.46,0,0,0,62.383,90.568Zm5.492,3.664h5.69a4.485,4.485,0,0,0,.115-1.042,4.215,4.215,0,0,0-4.255-4.42A4.447,4.447,0,1,0,73.2,96.081l-1.979-1.412a2.084,2.084,0,0,1-1.5.588A1.775,1.775,0,0,1,67.875,94.232ZM67.842,92.3a1.574,1.574,0,0,1,1.616-1.176,1.506,1.506,0,0,1,1.55,1.176Z"
          transform="translate(46.32 -72.77)"
          fill={colour.blue}
        />
      </svg>

      <Type.H4
        bold
        color={colour.blue}
        style={{ marginTop: 25, lineHeight: 1 }}
      >
        {profile.name}.
      </Type.H4>
      <p style={{ fontSize: 20, marginTop: 0 }}>
        {profile.consultingRequirement && profile.consultingRequirement.title}
        {positionName && positionName}
        <br />
      </p>
      <Type.P style={{ fontWeight: 400, marginTop: 10 }}>
        <span
          dangerouslySetInnerHTML={{
            __html: profile.summary.replace(/<p><\/p>/g, '<br/>'),
          }}
        ></span>
      </Type.P>

      {profile.skills && profile.skills[0] !== '' && (
        <>
          <Type.H7 style={{ marginTop: 35 }} bold color={colour.blue}>
            Skills.
          </Type.H7>
          <ul
            className={`skills ${
              profile.skills && profile.skills.length > 9 ? 'split' : ''
            }`}
          >
            {profile.skills.length > 9 ? (
              <>
                <div className="splitted">
                  {profile.skills
                    .slice(0, Math.ceil(profile.skills.length / 2))
                    .map((x, i) =>
                      x.trim() !== '' ? (
                        /<\/?[a-z][\s\S]*>/i.test(x) ? (
                          <li key={x} className="html">
                            {renderHTML(x.trim().replace(/<\/?ul>/g, ''))}{' '}
                          </li>
                        ) : (
                          <li key={x}>{x.trim()}</li>
                        )
                      ) : null
                    )}
                </div>
                <div className="splitted">
                  {profile.skills
                    .slice(
                      Math.ceil(profile.skills.length / 2),
                      profile.skills.length
                    )
                    .map((x, i) =>
                      x.trim() !== '' ? (
                        /<\/?[a-z][\s\S]*>/i.test(x) ? (
                          <li key={x} className="html">
                            {renderHTML(x.trim().replace(/<\/?ul>/g, ''))}{' '}
                          </li>
                        ) : (
                          <li key={x}>{x.trim()}</li>
                        )
                      ) : null
                    )}
                </div>
              </>
            ) : (
              profile.skills.map((x, i) =>
                x.trim() !== '' ? (
                  /<\/?[a-z][\s\S]*>/i.test(x) ? (
                    <li key={x} className="html">
                      {renderHTML(x.trim().replace(/<\/?ul>/g, ''))}{' '}
                    </li>
                  ) : (
                    <li key={x}>{x.trim()}</li>
                  )
                ) : null
              )
            )}
          </ul>
        </>
      )}
      {profile.domains && profile.domains[0] !== '' && (
        <>
          <Type.H7 style={{ marginTop: 35 }} bold color={colour.blue}>
            Domain Knowledge.
          </Type.H7>
          <ul
            className={`domains ${
              profile.domains && profile.domains.length > 9 ? 'split' : ''
            }`}
          >
            {profile.domains.length > 9 ? (
              <>
                <div className="splitted">
                  {profile.domains
                    .slice(0, Math.ceil(profile.domains.length / 2))
                    .map((x, i) => x !== '' && <li key={x}>{x}</li>)}
                </div>
                <div className="splitted">
                  {profile.domains
                    .slice(
                      Math.ceil(profile.domains.length / 2),
                      profile.domains.length
                    )
                    .map((x, i) => x !== '' && <li key={x}>{x}</li>)}
                </div>
              </>
            ) : (
              profile.domains.map((x, i) => x !== '' && <li key={x}>{x}</li>)
            )}
          </ul>
        </>
      )}
    </div>
  );

  const getSecondPage = () => (
    <div>
      <div>
        <Type.H7 bold color={colour.blue}>
          Experience.
        </Type.H7>
        {profile.experiences &&
          profile.experiences.map((x, i) => (
            <section className="experience" key={`pro-${i}`}>
              {(x.tenureStart || x.tenureEnd) && (
                <p
                  style={{ fontWeight: 900, color: colour.blue }}
                  className="lh-1"
                >
                  {formatDateForHumans(newDate(x.tenureStart), 'LLL yyyy')}
                  {' - '}
                  {x.tenureEnd === 'Present'
                    ? 'Present'
                    : formatDateForHumans(newDate(x.tenureEnd), 'LLL yyyy')}
                </p>
              )}
              <p className="lh-1">
                <b className="lh-1">{x.company}</b>
              </p>
              <p className="lh-1" style={{ marginBottom: 10 }}>
                {x.role}
              </p>
              {hasResponsibilities(x.responsibilities) && (
                <>
                  <p style={{ marginTop: 15 }} className="lh-1">
                    <b className="lh-1">Overview</b>
                  </p>
                  <div
                    className="responsibilities lh-1"
                    dangerouslySetInnerHTML={{
                      __html: x.responsibilities.replace(/<p><\/p>/g, '<br/>'),
                    }}
                  ></div>
                </>
              )}
            </section>
          ))}
      </div>
      <div>
        {hasEducations(profile.educations) && (
          <>
            <Type.H7 style={{ marginTop: 30 }} bold color={colour.blue}>
              Education.
            </Type.H7>
            {profile.educations &&
              profile.educations.map((x, i) => (
                <section className="education" key={`edu-${i}`}>
                  <p className="lh-1">
                    <b className="lh-1">
                      {x.institute} ({x.completion})
                    </b>
                  </p>
                  <p className="lh-1">
                    {x.accolade} {x.course}
                  </p>
                </section>
              ))}
          </>
        )}

        {profile.certifications && profile.certifications[0] !== '' && (
          <>
            <Type.H7 style={{ marginTop: 30 }} bold color={colour.blue}>
              Certifications.
            </Type.H7>
            <ul
              className={`certifications ${
                profile.certifications && profile.certifications.length > 9
                  ? 'split'
                  : ''
              }`}
            >
              {profile.certifications.length > 9 ? (
                <>
                  <div className="splitted">
                    {profile.certifications
                      .slice(0, Math.ceil(profile.certifications.length / 2))
                      .map(
                        (x, i) =>
                          x !== '' && (
                            <li key={x}>{x.replace(/(<([^>]+)>)/gi, '')}</li>
                          )
                      )}
                  </div>
                  <div className="splitted">
                    {profile.certifications
                      .slice(
                        Math.ceil(profile.certifications.length / 2),
                        profile.certifications.length
                      )
                      .map(
                        (x, i) =>
                          x !== '' && (
                            <li key={x}>{x.replace(/(<([^>]+)>)/gi, '')}</li>
                          )
                      )}
                  </div>
                </>
              ) : (
                profile.certifications.map(
                  (x, i) =>
                    x !== '' && (
                      <li key={x}>{x.replace(/(<([^>]+)>)/gi, '')}</li>
                    )
                )
              )}
            </ul>
          </>
        )}
      </div>
    </div>
  );

  return (
    <>
      <main className="contentWrap">
        <div className="content">
          <div className="first-page page">
            {getFirstPage()}

            {breakExp && <div className="page-break"></div>}
            <div className="footer-space">&nbsp;</div>
            {getSecondPage()}
          </div>
        </div>
      </main>

      <footer>
        <div className="bg-logo">
          <svg
            enableBackground="new 0 0 40 40"
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m20 0c-11 0-20 9-20 20s9 20 20 20 20-9 20-20-9-20-20-20zm7.7 30c-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3 2.3 1 2.3 2.3c0 1.3-1 2.3-2.3 2.3zm2.3-8.9c0 1.3-1 2.3-2.3 2.3s-2.3-1-2.3-2.3v-1.1c0-3-2.4-5.4-5.4-5.4s-5.4 2.4-5.4 5.4 2.4 5.4 5.4 5.4h.1c1.3 0 2.3 1 2.3 2.3s-1 2.3-2.3 2.3h-.1c-5.5 0-10-4.5-10-10s4.5-10 10-10 10 4.5 10 10z"
              fill="#F2FAFE"
            />
          </svg>
        </div>
        <svg
          enableBackground="new 0 0 40 40"
          viewBox="0 0 40 40"
          width="40"
          height="40"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m20 0c-11 0-20 9-20 20s9 20 20 20 20-9 20-20-9-20-20-20zm7.7 30c-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3 2.3 1 2.3 2.3c0 1.3-1 2.3-2.3 2.3zm2.3-8.9c0 1.3-1 2.3-2.3 2.3s-2.3-1-2.3-2.3v-1.1c0-3-2.4-5.4-5.4-5.4s-5.4 2.4-5.4 5.4 2.4 5.4 5.4 5.4h.1c1.3 0 2.3 1 2.3 2.3s-1 2.3-2.3 2.3h-.1c-5.5 0-10-4.5-10-10s4.5-10 10-10 10 4.5 10 10z"
            fill="#03ade8"
          />
        </svg>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'right',
          }}
        >
          <a href="tel:1300282278">
            <b>
              1300 282 2<span style={{ letterSpacing: '-1.5px' }}>78</span>
            </b>
          </a>
          <a href="https://appscore.com.au" className="appscore-url">
            appscore
            <br />
            .com.au
          </a>
        </div>
      </footer>
      <ProfilePdfStyling />
    </>
  );
};

export default ProfilePdfTemplate;
