import React, { Component } from 'react';
import PublicTemplate from '@/templates/public';
import Type from '@/elements/Type';
import { Spacer } from '@/elements/Spacer/style';

class Terms extends Component {
  render() {
    return (
      <PublicTemplate wide>
        <Type.H6>Terms and Conditions for Appscore</Type.H6>
        <Spacer desktop={20} />
        <Type.H7 alignment={'left'}>Introduction</Type.H7>
        <Type.P8 alignment={'left'}>
          These Website Standard Terms and Conditions written on this webpage
          shall manage your use of our website, the Appscore Client Portal.
        </Type.P8>
        <Spacer desktop={15} />
        <Type.P8 alignment={'left'}>
          These Terms will be applied fully and affect to your use of this
          Website. By using this Website, you agreed to accept all terms and
          conditions written in here. You must not use this Website if you
          disagree with any of these Website Standard Terms and Conditions.
        </Type.P8>
        <Spacer desktop={15} />
        <Type.P8 alignment={'left'}>
          Minors or people below 18 years old are not allowed to use this
          Website.
        </Type.P8>
        <Spacer desktop={20} />
        <Type.H7 alignment={'left'}>Intellectual Property Rights</Type.H7>
        <Type.P8 alignment={'left'}>
          Other than the content you own, under these Terms, Appscore and/or its
          licensors own all the intellectual property rights and materials
          contained in this Website.
        </Type.P8>
        <Spacer desktop={15} />
        <Type.P8 alignment={'left'}>
          You are granted limited license only for purposes of viewing the
          material contained on this Website.
        </Type.P8>
        <Spacer desktop={20} />
        <Type.H7 alignment={'left'}>Restrictions</Type.H7>
        <Type.P8 alignment={'left'}>
          You are specifically restricted from all of the following:
        </Type.P8>
        <Type.ListContainer alignment={'left'}>
          <li>publishing any Website material in any other media;</li>
          <li>
            selling, sublicensing and/or otherwise commercializing any Website
            material;
          </li>
          <li>publicly performing and/or showing any Website material;</li>
          <li>
            using this Website in any way that is or may be damaging to this
            Website;
          </li>
          <li>
            using this Website in any way that impacts user access to this
            Website;
          </li>
          <li>
            using this Website contrary to applicable laws and regulations, or
            in any way may cause harm to the Website, or to any person or
            business entity;
          </li>
          <li>
            engaging in any data mining, data harvesting, data extracting or any
            other similar activity in relation to this Website;
          </li>
          <li>using this Website to engage in any advertising or marketing.</li>
        </Type.ListContainer>
        <Spacer desktop={15} />
        <Type.P8 alignment={'left'}>
          Certain areas of this Website are restricted from being access by you
          and Appscore may further restrict access by you to any areas of this
          Website, at any time, in absolute discretion. Any user ID and password
          you may have for this Website are confidential and you must maintain
          confidentiality as well.
        </Type.P8>
        <Spacer desktop={20} />
        <Type.H7 alignment={'left'}>Your Content</Type.H7>
        <Type.P8 alignment={'left'}>
          In these Website Standard Terms and Conditions, “Your Content” shall
          mean any audio, video text, images or other material you choose to
          display on this Website. By displaying Your Content, you grant
          Appscore a non-exclusive, worldwide irrevocable, sub-licensable
          license to use, reproduce, adapt, publish, translate and distribute it
          in any and all media.
        </Type.P8>
        <Spacer desktop={15} />
        <Type.P8 alignment={'left'}>
          Your Content must be your own and must not be invading any
          third-party's rights. Appscore reserves the right to remove any of
          Your Content from this Website at any time without notice.
        </Type.P8>
        <Spacer desktop={20} />
        <Type.H7 alignment={'left'}>No warranties</Type.H7>
        <Type.P8 alignment={'left'}>
          This Website is provided “as is,” with all faults, and Appscore
          express no representations or warranties, of any kind related to this
          Website or the materials contained on this Website. Also, nothing
          contained on this Website shall be interpreted as advising you.
        </Type.P8>
        <Spacer desktop={20} />
        <Type.H7 alignment={'left'}>Limitation of liability</Type.H7>
        <Type.P8 alignment={'left'}>
          In no event shall Appscore, nor any of its officers, directors and
          employees, shall be held liable for anything arising out of or in any
          way connected with your use of this Website whether such liability is
          under contract. Appscore, including its officers, directors and
          employees shall not be held liable for any indirect, consequential or
          special liability arising out of or in any way related to your use of
          this Website.
        </Type.P8>
        <Spacer desktop={20} />
        <Type.H7 alignment={'left'}>Indemnification</Type.H7>
        <Type.P8 alignment={'left'}>
          You hereby indemnify to the fullest extent Appscore from and against
          any and/or all liabilities, costs, demands, causes of action, damages
          and expenses arising in any way related to your breach of any of the
          provisions of these Terms.
        </Type.P8>
        <Spacer desktop={20} />
        <Type.H7 alignment={'left'}>Severability</Type.H7>
        <Type.P8 alignment={'left'}>
          If any provision of these Terms is found to be invalid under any
          applicable law, such provisions shall be deleted without affecting the
          remaining provisions herein.
        </Type.P8>
        <Spacer desktop={20} />
        <Type.H7 alignment={'left'}>Variation of Terms</Type.H7>
        <Type.P8 alignment={'left'}>
          Appscore is permitted to revise these Terms at any time as it sees
          fit, and by using this Website you are expected to review these Terms
          on a regular basis.
        </Type.P8>
        <Spacer desktop={20} />
        <Type.H7 alignment={'left'}>Assignment</Type.H7>
        <Type.P8 alignment={'left'}>
          Appscore is allowed to assign, transfer, and subcontract its rights
          and/or obligations under these Terms without any notification.
          However, you are not allowed to assign, transfer, or subcontract any
          of your rights and/or obligations under these Terms.
        </Type.P8>
        <Spacer desktop={20} />
        <Type.H7 alignment={'left'}>Entire Agreement</Type.H7>
        <Type.P8 alignment={'left'}>
          These Terms constitute the entire agreement between Appscore and you
          in relation to your use of this Website, and supersede all prior
          agreements and understandings.
        </Type.P8>
        <Spacer desktop={20} />
        <Type.H7 alignment={'left'}>Governing Law & Jurisdiction</Type.H7>
        <Type.P8 alignment={'left'}>
          These Terms will be governed by and interpreted in accordance with the
          laws of the State of Country, and you submit to the non-exclusive
          jurisdiction of the state and federal courts located in Country for
          the{' '}
        </Type.P8>
      </PublicTemplate>
    );
  }
}

export default Terms;
