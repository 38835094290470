import React, { useState } from 'react';
import {
  RiskTableWrapper,
  RiskLevelText,
  ViewLink,
  RiskModalContainer,
  RiskModalTitle,
  RiskItemDescContainer,
  RiskModalRow,
  // OwnerLogo,
  // OwnerLogoContainer,
} from './style';
import { TableHead, HeaderCell, TableCell, TableRow } from '../Table/style';
import { Table } from 'evergreen-ui';
import Type from '@/elements/Type';
import { formatDateForHumans } from '@/utils/dates';
import Modal from '@/elements/Modal';
import { colour } from '@/styles/variables';
import Icons from '@/elements/Icons';
import Spacer from '@/elements/Spacer';

export const renderRisk = (lvl) => {
  const level = lvl ? lvl.toLowerCase() : '';

  if (level === 'high') {
    return (
      <RiskLevelText>
        <span style={{ color: colour.red }}>{level}</span>
      </RiskLevelText>
    );
  }
  if (level === 'med') {
    return (
      <RiskLevelText>
        <span style={{ color: colour.orange }}>{level}</span>
      </RiskLevelText>
    );
  }
  if (level === 'low') {
    return (
      <RiskLevelText>
        <span style={{ color: colour.green }}>{level}</span>
      </RiskLevelText>
    );
  }
};

const RiskTable = (props) => {
  const { title, risks } = props;

  // const renderOwner = (owner) => {
  //   return (
  //     <OwnerLogoContainer>
  //       {owner.map((item, index) => {
  //         return (
  //           <OwnerLogo key={index}>
  //             <img src={item.logo} alt={item.name} />
  //           </OwnerLogo>
  //         );
  //       })}
  //       {owner.length > 1 ? ' Joint' : ` ${owner[0].name}`}
  //     </OwnerLogoContainer>
  //   );
  // };

  const renderOwnerText = (owner) => {
    if (!owner) {
      return null;
    }

    if (owner.length === 1) {
      return owner[0];
    }
    return 'Joint';
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeRiskIndex, setActiveRiskIndex] = useState(0);

  if (!risks) {
    return (
      <RiskTableWrapper>
        <Type.H6>{title}</Type.H6>
        <Spacer desktop={10} />
        <Type.P8>There are no risks associated with the project.</Type.P8>
      </RiskTableWrapper>
    );
  }

  const activeRisk = risks[activeRiskIndex];

  return (
    <RiskTableWrapper>
      <Type.H6>{title}</Type.H6>
      <Spacer desktop={10} />
      <Table>
        <TableHead paddingX={0}>
          <HeaderCell flexBasis={246} flexShrink={0} flexGrow={0} paddingX={0}>
            Title
          </HeaderCell>
          <HeaderCell hiddenmobile="true">Owner</HeaderCell>
          <HeaderCell hiddenmobile="true">Date</HeaderCell>
          <HeaderCell hiddenmobile="true">Level</HeaderCell>
          <HeaderCell flexBasis={50} flexShrink={0} flexGrow={0}></HeaderCell>
        </TableHead>
        <Table.Body>
          {risks.map((risk, index) => (
            <TableRow
              key={index}
              isSelectable
              onSelect={() => {
                setIsModalOpen(true);
                setActiveRiskIndex(index);
              }}
            >
              <TableCell
                flexBasis={246}
                flexShrink={0}
                flexGrow={0}
                paddingX={0}
              >
                {risk.title}
              </TableCell>
              <TableCell hiddenmobile="true">
                {renderOwnerText(activeRisk.owner)}
              </TableCell>
              <TableCell hiddenmobile="true">
                {formatDateForHumans(new Date(risk.date))}
              </TableCell>
              <TableCell hiddenmobile="true">
                <b>{renderRisk(risk.riskLevel)}</b>
              </TableCell>
              <TableCell
                flexBasis={50}
                flexShrink={0}
                flexGrow={0}
                paddingX={0}
              >
                <ViewLink>
                  <span className="hidden-mobile">View</span>
                  <Icons.ChevronRight />
                </ViewLink>
              </TableCell>
            </TableRow>
          ))}
        </Table.Body>
      </Table>
      <Modal
        title="Key Risk"
        isShown={isModalOpen}
        handleModalState={setIsModalOpen}
      >
        <RiskModalContainer>
          <RiskModalTitle>
            <Type.H6>{activeRisk.title}</Type.H6>
            <Type.P>{formatDateForHumans(new Date(activeRisk.date))}</Type.P>
          </RiskModalTitle>
          <RiskModalRow>
            <RiskItemDescContainer>
              <Type.P className="title">Description</Type.P>
              <Type.P>{activeRisk.description}</Type.P>
            </RiskItemDescContainer>
          </RiskModalRow>
          <RiskModalRow>
            <RiskItemDescContainer>
              <Type.P className="title">Owner</Type.P>
              {renderOwnerText(activeRisk.owner)}
            </RiskItemDescContainer>
            <RiskItemDescContainer>
              <Type.P className="title">Level</Type.P>
              <Type.P>
                <b>{renderRisk(activeRisk.riskLevel)}</b>
              </Type.P>
            </RiskItemDescContainer>
            <RiskItemDescContainer fullMobile>
              <Type.P className="title">Chances of Occurrence</Type.P>
              <Type.P>
                <b>{renderRisk(activeRisk.chanceOfOccurence)}</b>
              </Type.P>
            </RiskItemDescContainer>
          </RiskModalRow>
          <RiskModalRow>
            <RiskItemDescContainer>
              <Type.P className="title">Mitigation</Type.P>
              <Type.P>{activeRisk.mitigation}</Type.P>
            </RiskItemDescContainer>
          </RiskModalRow>
          <RiskModalRow>
            <RiskItemDescContainer>
              <Type.P className="title">Affect</Type.P>
              <Type.P>{activeRisk.effect}</Type.P>
            </RiskItemDescContainer>
          </RiskModalRow>
        </RiskModalContainer>
      </Modal>
    </RiskTableWrapper>
  );
};

export default RiskTable;
